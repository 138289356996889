import { GridColumnVisibilityModel } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useMemo } from "react";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import { InvestorChangeRequest } from "../../../api/types/changeRequestTypes";
import { getColumnDefinitions } from "./changeRequestsGridDataProvider";

interface Props {
  isLoading: boolean;
  apiRef?: React.MutableRefObject<GridApiPremium>;
  rows: InvestorChangeRequest[];
  onRowSelect?: (workflowId: string) => void;
  onRowScrollEnd?: () => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
}

const ChangeRequestsGrid = ({ isLoading, apiRef, rows, onRowSelect, onRowScrollEnd, columnVisibilityModel }: Props) => {
  const columns = useMemo(() => getColumnDefinitions(), []);

  return (
    <DataGrid<InvestorChangeRequest>
      apiRef={apiRef}
      rows={rows}
      columnVisibilityModel={columnVisibilityModel}
      columns={columns}
      getRowId={(row) => row.workflowId}
      loading={isLoading}
      onRowsScrollEnd={onRowScrollEnd}
      noRowsText="No requests yet"
      selectableRows
      disableColumnReorder
      onCellClick={({ row }) => onRowSelect?.(row.workflowId)}
    />
  );
};

export default ChangeRequestsGrid;
