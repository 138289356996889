import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { ObjectContact } from "../../../api/types/objectTypes";
import DeletePersonIcon from "../../../icons/DeletePersonIcon";
import { ContactAccessMatrixRow } from "./contactAccessMatrixGridDataProvider";

interface Props {
  objectId: string;
  objectType: string;
  removedContacts: ContactAccessMatrixRow[];
  currentObjectContacts: ObjectContact[];
  onClose: () => void;
  onSave: (newContacts: ObjectContact[]) => void;
}

const updateObjectContacts = withErrorHandling(adminApi.updateObjectContacts);

const RemoveContactAccessDialog = ({
  objectId,
  objectType,
  removedContacts,
  currentObjectContacts,
  onClose,
  onSave,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);
    const payload = {
      contacts: currentObjectContacts.filter((c) => !removedContacts.some((r) => r.contactId === c.contactId)),
    };
    const [newContacts, error] = await updateObjectContacts(objectType, objectId, payload);
    setSaving(false);

    if (error) {
      logError(error, "[RemoveContactAccessDialog] updateObjectContacts");
      sendNotificationError("Could not remove contact(s)");
      return;
    }

    sendNotification("Contact(s) removed successfully");
    onSave(newContacts);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Remove</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DeletePersonIcon />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              {removedContacts.length === 1 ? "Remove contact?" : "Remove contacts?"}
            </Typography>
            {removedContacts.length === 1 && (
              <Typography color="text.secondary" textAlign="center">
                You&apos;re about to remove <strong>{removedContacts[0]?.contactName}</strong> from the portfolio
                company. Access to this company will be lost.
              </Typography>
            )}
            {removedContacts.length > 1 && (
              <Typography color="text.secondary" textAlign="center">
                You&apos;re about to remove <strong>{removedContacts.length} contacts</strong> from the portfolio
                company. Access to this company will be lost.
              </Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={isSaving} onClick={handleSave}>
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveContactAccessDialog;
