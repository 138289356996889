import { Box, Button, DialogActions, Stack, Typography } from "@mui/material";
import PromptDialog from "../../../../components/dialog/PromptDialog";
import { LoadingButton } from "@mui/lab";
import { Status } from "../../../api/biClient.types";
import StatusBox from "../status/StatusBox";
import { formatString } from "../../../../utilities/stringFormatter";
import PromptDialogHeader from "../../../../components/dialog/PromptDialogHeader";
import IconDocument from "../../../icons/IconDocument";
import { useSharedReportingLocalization } from "../../../hooks/useLocalization";

interface Props {
  templateName: string;
  currentStatus: Status;
  onClose: () => void;
  loading: boolean;
  onConfirm: (status: Status) => void;
}
export default function SwitchStatusDialog({ templateName, currentStatus, onClose, loading, onConfirm }: Props) {
  const { report_templates: locale } = useSharedReportingLocalization();
  const title = currentStatus === Status.Live ? locale.unpublish_template_title : locale.publish_template_title;
  return (
    <PromptDialog open={true} sx={{ width: 514, minHeight: 337, p: 0 }}>
      <PromptDialogHeader text={title} onClose={onClose} disabled={loading} />
      <Stack sx={{ alignItems: "center", textAlign: "center", p: 3, gap: 3 }}>
        <Box sx={{ width: 80, height: 80, display: "flex", alignItems: "center", justifyContent: "center" }}>
          <IconDocument sx={{ width: "48.2px", height: "59.5px" }} />
        </Box>
        <Stack gap={2}>
          <Stack alignItems={"center"} gap={1}>
            <Typography variant="h6">{formatString(locale.you_are_about_to_set_status, templateName)}</Typography>
            <StatusBox status={currentStatus} />
          </Stack>
          <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>
            {currentStatus === Status.Live ? locale.template_will_be_unpublished : locale.template_will_be_published}
          </Typography>
        </Stack>
      </Stack>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="text" color="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          disabled={loading}
          loading={loading}
          onClick={() => onConfirm(currentStatus === Status.Live ? Status.Draft : Status.Live)}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </PromptDialog>
  );
}
