import { GridEventListener } from "@mui/x-data-grid-premium";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import { Field, ObjectFieldValues } from "../../../api/types/objectTypes";
import { useFilterContext } from "../../common/filters/FilterContext";
import { getEntitiesGridColumns } from "./entitiesGridDataProvider";

interface Props {
  entities: ObjectFieldValues[];
  fields: Field[];
  showLogos?: boolean;
  isLoading?: boolean;
  onCellClick?: GridEventListener<"cellClick">;
}

const EntitiesGrid = ({ entities, fields, isLoading, onCellClick, showLogos }: Props) => {
  const { filterState } = useFilterContext<ObjectFieldValues>();

  const columns = getEntitiesGridColumns(fields, filterState.visibleColumns, { showLogos });

  return (
    <DataGrid<ObjectFieldValues>
      rows={entities}
      columns={columns}
      loading={isLoading}
      disableColumnReorder
      selectableRows
      onCellClick={onCellClick}
    />
  );
};

export default EntitiesGrid;
