import GroupCollapseIcon from "@mui/icons-material/ArrowDropDownRounded";
import GroupExpandIcon from "@mui/icons-material/ArrowRightRounded";
import { IconButton } from "@mui/material";

interface Props {
  isExpanded: boolean;
}

const ExpandCollapseGridDetailButton = ({ isExpanded }: Props) => {
  return (
    <IconButton size="small" tabIndex={-1} aria-label={isExpanded ? "Close" : "Open"}>
      {isExpanded ? <GroupCollapseIcon fontSize="inherit" /> : <GroupExpandIcon fontSize="inherit" />}
    </IconButton>
  );
};

export default ExpandCollapseGridDetailButton;
