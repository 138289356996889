import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Stack, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import ColorTag from "../../../../../shared/components/ColorTag";
import { Category } from "../../../../api/types/accessTypes";
import CategoryTag from "../../../common/CategoryTag";

interface Props {
  categoryId: string | undefined;
  categories: Category[];
}

const UserConsentCategoryTag = ({ categoryId, categories }: Props) => {
  if (categoryId === undefined) {
    return <ColorTag color={grey[100]} text="All categories" />;
  }

  const category = categories.find((c) => c.id === categoryId);
  if (category === undefined) {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <ColorTag withBorder color="white" text="No category" />
        <Tooltip title="Category not found or deleted" arrow>
          <HelpOutlineIcon fontSize="small" color="action" />
        </Tooltip>
      </Stack>
    );
  }

  return <CategoryTag categoryName={category.name} />;
};

export default UserConsentCategoryTag;
