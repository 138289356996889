import { Chip } from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import StatusChip from "../../../shared/components/StatusChip";
import { PortalRoleWithNoneValue } from "../../api/types/accessTypes";
import { portalRoleCaptionMap } from "../../utilities/enumCaptions";

interface Props {
  role: PortalRoleWithNoneValue;
}

const PortalRoleChip = ({ role }: Props) => {
  switch (role) {
    case "LP_Admin": {
      return <StatusChip label={portalRoleCaptionMap["LP_Admin"]} color={blue[700]} />;
    }
    case "LP_Member": {
      return <StatusChip label={portalRoleCaptionMap["LP_Member"]} color={green[700]} />;
    }
    case "Portfolio_Monitoring_Portal_Member": {
      return <StatusChip label={portalRoleCaptionMap["Portfolio_Monitoring_Portal_Member"]} color={green[700]} />;
    }
    default: {
      return <Chip sx={{ backgroundColor: "transparent", border: 1, borderColor: grey[200] }} label="None" />;
    }
  }
};

export default PortalRoleChip;
