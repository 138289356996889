import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import adminApi, { Contact } from "../../../api/adminApi";
import { ObjectClassDefinition, ObjectContact } from "../../../api/types/objectTypes";
import AccessEditor, { AccessValue } from "../../common/AccessEditor";
import AddContactDialog from "../../pages/fund-structure/common/AddContactDialog";
import MultiselectAutocomplete from "../../pages/fund-structure/common/MultiselectAutocomplete";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  currentObjectContacts: ObjectContact[];
  allContacts: Contact[];
  onSave: (newContacts: ObjectContact[]) => void;
  onClose: () => void;
  onContactCreated: (newContact: Contact) => void;
}

const updateObjectContacts = withErrorHandling(adminApi.updateObjectContacts);

const AddContactAccessDialog = ({
  objectId,
  objectDefinition,
  currentObjectContacts,
  allContacts,
  onSave,
  onClose,
  onContactCreated,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [isCreateContactDialogOpen, setCreateContactDialogOpen] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);
  const [accessValue, setAccessValue] = useState<AccessValue>({
    selectedRole: objectDefinition.supportedContactRoles[0] ?? "None",
    selectedCategoryIds: objectDefinition.supportedContactCategories,
  });

  const contactOptions = useMemo(() => {
    const currentObjectContactIds = currentObjectContacts.map((c) => c.contactId);
    return allContacts.filter((c) => !currentObjectContactIds.includes(c.id)).sort(stringComparerBy((o) => o.name));
  }, [allContacts, currentObjectContacts]);

  const { existingContactNames, existingContactEmails } = useMemo(() => {
    const existingContactNames = allContacts.map((c) => c.name);
    const existingContactEmails = allContacts.map((c) => c.email.toLowerCase());
    return { existingContactNames, existingContactEmails };
  }, [allContacts]);

  const categoryOptions = useMemo(
    () =>
      objectDefinition.supportedContactCategories.map((category) => ({
        id: category,
        label: category,
      })),
    [objectDefinition.supportedContactCategories]
  );

  const handleContactCreate = (newContact: Contact) => {
    onContactCreated(newContact);
    setSelectedContacts((prev) => [...prev, newContact]);
  };

  const handleSave = async () => {
    const addedContacts: ObjectContact[] = selectedContacts.map((c) => ({
      contactId: c.id,
      userId: c.userId,
      role: accessValue.selectedRole === "None" ? undefined : accessValue.selectedRole,
      categories: accessValue.selectedCategoryIds,
    }));

    const payload = { contacts: [...currentObjectContacts, ...addedContacts] };
    setSaving(true);

    const [updatedContacts, error] = await updateObjectContacts(objectDefinition.objectType, objectId, payload);
    setSaving(false);

    if (error) {
      logError(error, "[AddContactAccessDialog] updateObjectContacts");
      sendNotificationError("Could not add contact(s)");
      return;
    }

    sendNotification("Contact(s) successfully added to this company");
    onSave(updatedContacts);
    onClose();
  };

  const isValid = selectedContacts.length > 0;

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add New</DialogTitle>
        <DialogCloseButton onClick={onClose} />
        <Divider />

        <DialogContent>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography color="text.secondary">
                Select one or more existing contacts or create multiple new ones.
              </Typography>
              <MultiselectAutocomplete
                options={contactOptions}
                values={selectedContacts}
                onSelected={(items) => setSelectedContacts(items)}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                getOptionDescription={(option) => option.email}
                placeholder="Contacts"
                noItemsText="No contacts found"
                onCreateOption={() => setCreateContactDialogOpen(true)}
              />
            </Stack>

            <AccessEditor
              value={accessValue}
              onChange={(update) => setAccessValue((prev) => ({ ...prev, ...update }))}
              roleOptions={["None", ...objectDefinition.supportedContactRoles]}
              categoryOptions={categoryOptions}
            />
          </Stack>
        </DialogContent>

        <Divider />
        <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
          <Button variant="text" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" loading={isSaving} onClick={handleSave} disabled={!isValid}>
            Add
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {isCreateContactDialogOpen && (
        <AddContactDialog
          mode="create"
          onClose={() => setCreateContactDialogOpen(false)}
          onCreate={handleContactCreate}
          existingContactNames={existingContactNames}
          existingContactEmails={existingContactEmails}
        />
      )}
    </>
  );
};

export default AddContactAccessDialog;
