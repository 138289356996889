import { Button, DialogActions, Divider, Grid2 } from "@mui/material";
import { useMemo } from "react";
import DialogHeader from "../../../../../../shared/components/dialog/DialogHeader";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import CopyReportContent from "./CopyReportContent";
import { ReportValidationType } from "./hooks/useReportValidation";

interface CopyToDialogSelectionProps {
  reports: ReportInfo[];
  reportValidation: ReportValidationType;
  copyConditionValues: boolean;
  onCopy: () => void;
  onClose: () => void;
  onCopyConditionValues: (value: boolean) => void;
}
export function CopyToDialogSelection({
  reports,
  reportValidation,
  copyConditionValues,
  onCopy,
  onClose,
  onCopyConditionValues,
}: CopyToDialogSelectionProps) {
  const copyAvailable = useMemo(() => {
    return (
      reportValidation.selectedCompanies.length > 0 &&
      reportValidation.selectedCompanies.every((c) => c.reports.every((r) => r.validated))
    );
  }, [reportValidation.selectedCompanies]);

  return (
    <>
      <DialogHeader text={`Copy Report${reports.length > 1 ? "s" : ""}`} onClose={onClose} />
      <Divider />
      <Grid2 container sx={{ flex: 1, width: "100%" }}>
        <CopyReportContent
          validation={reportValidation}
          copyConditionValues={copyConditionValues}
          onCopyConditionValues={onCopyConditionValues}
        />
      </Grid2>
      <Divider />
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" disabled={!copyAvailable} onClick={onCopy}>
          Copy Report
        </Button>
      </DialogActions>
    </>
  );
}
