import { FundWithFieldValues } from "../../../../api/adminApi";
import { SearchFilterDefinition, TableFilterDefinition } from "../../../common/filters/filterTypes";

export const filterDefinitions: TableFilterDefinition<FundWithFieldValues>[] = [
  {
    id: "name",
    name: "Name",
    type: "text",
    getFieldValue: (row) => row.name,
  },
  {
    id: "stage",
    name: "Stage",
    type: "multi_select",
    getFieldValue: (row) => row.fieldValues["Stage"]?.toString(),
  },
  {
    id: "investorsCount",
    name: "Investors",
    type: "number",
    getFieldValue: (row) => row.investorsCount,
  },
];

export const searchDefinition: SearchFilterDefinition<FundWithFieldValues> = {
  getFieldValues: (row) => [row.name, row.fieldValues["Stage"]?.toString(), row.fieldValues["Stage"]?.toString()],
};
