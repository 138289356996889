import CheckIcon from "@mui/icons-material/CheckRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { Button, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { useLocation, useParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../api/adminApi";
import { InvestorChangeRequest } from "../../../../api/types/changeRequestTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import FullHeightFlexPage from "../../../common/layout/FullHeightFlexPage";
import ScrollableColumns from "../../../common/layout/ScrollableColumns";
import StretchedScrollableContent from "../../../common/layout/StretchedScrollableContent";
import { NotesContextProvider } from "../../../notes/NotesContext";
import ChangeRequestsNotesSection from "../ChangeRequestsNotesSection";
import ApprovalConfirmationDialog from "./ApprovalConfirmationDialog";
import ChangeRequestDetailsSection from "./ChangeRequestDetailsSection";
import ChangeRequestDetailsTitle from "./ChangeRequestDetailsTitle";
import RejectionConfirmationDialog from "./RejectionConfirmationDialog";

type OpenDialog = "approve" | "reject";

const ChangeRequestDetailsPage = () => {
  const { id: workflowId } = useParams();
  const { clientCode, hasAnyPermission, updateMenuBadges } = useClientContext();
  const { state: navigationState } = useLocation();
  const [openDialog, setOpenDialog] = useState<OpenDialog>();

  const fetchDetails = useCallback(() => adminApi.getInvestorChangeRequestDetails(defined(workflowId)), [workflowId]);

  const [changeRequestDetails, fetchError, { setData }] = useFetch(fetchDetails);

  if (fetchError) {
    logError(fetchError, "[ChangeRequestDetailsPage]");
    return <DataLoadingFailed title="Loading change request details failed" />;
  }

  if (changeRequestDetails === undefined) {
    return <InlineLoader />;
  }

  const handleStatusChange = (changeRequest: InvestorChangeRequest, statusChangeComment?: string) => {
    setData((prevData) =>
      prevData
        ? {
            ...prevData,
            workflowStatus: changeRequest.workflowStatus,
            approvalStatusUpdatedByUserName: changeRequest.approvalStatusUpdatedByUserName,
            approvalStatusUpdatedAt: new Date().toISOString(),
            statusChangeComment: statusChangeComment ?? prevData.statusChangeComment,
          }
        : prevData
    );

    updateMenuBadges();
  };

  const hasPermissionsToViewNotes = hasAnyPermission(["ViewInvestorRequestsNotes", "ManageInvestorRequestsNotes"]);
  const hasPermissionsToManageNotes = hasAnyPermission(["ManageInvestorRequestsNotes"]);

  const showApprovalControls =
    changeRequestDetails.workflowStatus === "InProgress" && changeRequestDetails.canCurrentUserApprove;

  return (
    <FullHeightFlexPage>
      <GeneralPageHeader
        TitleComponent={<ChangeRequestDetailsTitle changeRequestDetails={changeRequestDetails} />}
        showDefaultBackButtonTo={`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.requests}?tab=${navigationState?.tab ?? ""}`}
      >
        {showApprovalControls && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              variant="outlined"
              color="success"
              startIcon={<CheckIcon />}
              onClick={() => setOpenDialog("approve")}
            >
              Approve Request
            </Button>
            <Button variant="outlined" color="error" startIcon={<CloseIcon />} onClick={() => setOpenDialog("reject")}>
              Reject
            </Button>
          </Stack>
        )}
      </GeneralPageHeader>
      <StretchedScrollableContent>
        <ScrollableColumns gridProps={{ columnSpacing: 2.5 }} layout={[{ xs: "grow" }, { width: "27rem" }]}>
          <ChangeRequestDetailsSection changeRequestDetails={changeRequestDetails} />
          {hasPermissionsToViewNotes && (
            <NotesContextProvider
              entityType="ChangeRequest"
              entityId={defined(workflowId)}
              hasPermissionsToManageNotes={hasPermissionsToManageNotes}
            >
              <ChangeRequestsNotesSection />
            </NotesContextProvider>
          )}
        </ScrollableColumns>
      </StretchedScrollableContent>
      <ApprovalConfirmationDialog
        workflowId={changeRequestDetails.workflowId}
        open={openDialog === "approve"}
        onClose={() => setOpenDialog(undefined)}
        onConfirmed={handleStatusChange}
      />
      <RejectionConfirmationDialog
        workflowId={changeRequestDetails.workflowId}
        open={openDialog === "reject"}
        onClose={() => setOpenDialog(undefined)}
        onConfirmed={handleStatusChange}
      />
    </FullHeightFlexPage>
  );
};

export default ChangeRequestDetailsPage;
