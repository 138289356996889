import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import { SxProps } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { formatISO, parseISO } from "date-fns";
import { getLowConfidenceStyles } from "../invoice-details/invoiceHelper";
import InvoiceControlWithWarning from "./InvoiceControlWithWarning";

interface Props {
  onChange: (date?: string) => void;
  value?: string;
  label?: string;
  validationError?: string;
  isLowConfident?: boolean;
  disabled?: boolean;
  containerSx?: SxProps;
}

const InvoiceDatePicker = ({
  onChange,
  value,
  validationError,
  label,
  isLowConfident,
  disabled,
  containerSx,
}: Props) => {
  return (
    <InvoiceControlWithWarning
      validationError={validationError}
      isLowConfident={isLowConfident}
      containerSx={containerSx}
    >
      <DatePicker
        value={value ? parseISO(value) : null}
        onChange={(newIssueDate, context) => {
          onChange(
            newIssueDate && !context?.validationError ? formatISO(newIssueDate, { representation: "date" }) : undefined
          );
        }}
        sx={{ flex: 1 }}
        slotProps={{
          textField: {
            fullWidth: true,
            error: !!validationError || !!isLowConfident,
            label: label,
            InputProps: { sx: { pr: 1.5 } },
            sx: !validationError && isLowConfident ? getLowConfidenceStyles() : undefined,
          },
        }}
        slots={{
          openPickerIcon: CalendarTodayRoundedIcon,
        }}
        disabled={disabled}
      />
    </InvoiceControlWithWarning>
  );
};

export default InvoiceDatePicker;
