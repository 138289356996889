import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-premium";
import { useCallback, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import PreviewFileDialog from "../../../../../shared/components/previewFile/PreviewFileDialog";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { DocumentViews } from "../../../../api/types/documentActivityTypes";
import { FileDownloadInfo } from "../../../../api/types/fileTypes";
import { getDocumentViewFileDownloadInfo } from "./documentViewFileHelper";
import { DocumentViewsGridContextProvider } from "./DocumentViewsGridContext";
import { columnDefinitions, isDetailPanelAvailable } from "./documentViewsGridDataProvider";
import DocumentViewsGridRowDetail from "./DocumentViewsGridRowDetail";

interface Props {
  isLoading: boolean;
  rows: DocumentViews[];
  onRowsScrollEnd: () => void;
}

const getFileDownloadInfo = withErrorHandling(getDocumentViewFileDownloadInfo);

const DocumentViewsGrid = ({ isLoading, rows, onRowsScrollEnd }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const gridApiRef = useGridApiRef();

  const [previewFileInfo, setPreviewFileInfo] = useState<FileDownloadInfo>();

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams<DocumentViews>) =>
      isDetailPanelAvailable(row) ? <DocumentViewsGridRowDetail row={row} /> : undefined,
    []
  );

  const getDetailPanelHeight = useCallback(() => 320, []);

  const handleShowFilePreview = async (documentView: DocumentViews) => {
    const [fileInfo, error] = await getFileDownloadInfo(documentView);
    if (error?.type === "NotFound") {
      sendNotification(
        "The file you are trying to view is no longer available. It was previously accessible, but it has now been removed."
      );
      return;
    } else if (error) {
      sendNotificationError("Could not get file download information");
      logError(error, `[DocumentViewsGrid] getDocumentViewFileDownloadInfo ${documentView.id}`);
      return;
    }

    setPreviewFileInfo(fileInfo);
  };

  return (
    <DocumentViewsGridContextProvider showFilePreview={handleShowFilePreview}>
      <DataGrid<DocumentViews>
        apiRef={gridApiRef}
        rows={rows}
        columns={columnDefinitions}
        loading={isLoading}
        multilineRows
        onRowsScrollEnd={onRowsScrollEnd}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        noRowsText="No document activity"
        disableColumnSorting
        disableColumnReorder
      />

      {previewFileInfo?.downloadUrl && (
        <PreviewFileDialog
          url={previewFileInfo.downloadUrl}
          fileName={previewFileInfo.fileName ?? ""}
          onClose={() => setPreviewFileInfo(undefined)}
        />
      )}
    </DocumentViewsGridContextProvider>
  );
};

export default DocumentViewsGrid;
