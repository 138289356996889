import { Typography } from "@mui/material";

interface Props {
  records: number;
  totalRecords?: number;
  hide?: boolean;
}

const RecordCounter = ({ records, totalRecords, hide }: Props) => {
  const totalCount = totalRecords ?? records;
  const totalCounterText = totalCount === 1 ? `${totalCount} record` : `${totalCount} records`;
  return (
    <Typography variant="subtitle2" visibility={hide ? "hidden" : "visible"}>
      {records < totalCount ? `${records} of ${totalCounterText}` : totalCounterText}
    </Typography>
  );
};

export default RecordCounter;
