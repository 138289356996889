import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import CustomDataGridHeaderToolbar from "../../common/grid/CustomDataGridHeaderToolbar";
import { useApprovalsGridActionsContext } from "./ApprovalsGridActionsContext";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  disabled?: boolean;
}

const ApprovalsGridHeaderToolbar = ({ selectedIds, setSelectedIds, disabled }: Props) => {
  const { onApproveClick, onRejectClick } = useApprovalsGridActionsContext();

  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button
        disabled={disabled}
        onClick={() => onApproveClick(selectedIds)}
        color="success"
        variant="text"
        startIcon={<CheckIcon />}
      >
        Approve
      </Button>
      <Button
        disabled={disabled}
        onClick={() => onRejectClick(selectedIds)}
        color="error"
        variant="text"
        startIcon={<CancelIcon />}
      >
        Reject
      </Button>
    </CustomDataGridHeaderToolbar>
  );
};

export default ApprovalsGridHeaderToolbar;
