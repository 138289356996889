import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogTitle, Divider, Drawer } from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import { except, intersect } from "../../../../shared/utilities/arrayHelper";
import adminApi from "../../../api/adminApi";
import { PortalRoleWithNoneValue } from "../../../api/types/accessTypes";
import { ObjectClassDefinition, ObjectContact } from "../../../api/types/objectTypes";
import AccessEditor, { AccessValue } from "../../common/AccessEditor";
import { ContactAccessMatrixRow } from "./contactAccessMatrixGridDataProvider";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  editedContacts: ContactAccessMatrixRow[];
  currentObjectContacts: ObjectContact[];
  onSave: (newContacts: ObjectContact[]) => void;
  onClose: () => void;
}

const updateObjectContacts = withErrorHandling(adminApi.updateObjectContacts);

const getInitialAccessValue = (editedContacts: ContactAccessMatrixRow[]): AccessValue => {
  const selectedRole = editedContacts.reduce<PortalRoleWithNoneValue | undefined>((result, contact, index) => {
    const contactRole = contact.role ?? "None";
    return index === 0 ? contactRole : result === contactRole ? result : undefined;
  }, undefined);

  const commonCategories = editedContacts.reduce<string[]>(
    (result, contact, index) => (index === 0 ? contact.categories : intersect(result, contact.categories)),
    []
  );

  const allCategories = editedContacts.flatMap((contact) => contact.categories);
  const indeterminateCategoryIds = except(allCategories, commonCategories);

  return {
    selectedRole,
    selectedCategoryIds: commonCategories,
    indeterminateCategoryIds,
  };
};

const EdiContactAccessDrawer = ({
  objectId,
  objectDefinition,
  editedContacts,
  currentObjectContacts,
  onSave,
  onClose,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [accessValue, setAccessValue] = useState<AccessValue>(() => getInitialAccessValue(editedContacts));

  const categoryOptions = useMemo(
    () =>
      objectDefinition.supportedContactCategories.map((category) => ({
        id: category,
        label: category,
      })),
    [objectDefinition.supportedContactCategories]
  );

  const handleSave = async () => {
    const updatedContacts: ObjectContact[] = editedContacts.map((c) => ({
      contactId: c.id,
      userId: c.userId,
      role: accessValue.selectedRole === "None" ? undefined : accessValue.selectedRole,
      categories: [
        ...accessValue.selectedCategoryIds,
        ...intersect(accessValue.indeterminateCategoryIds ?? [], c.categories),
      ],
    }));

    const otherContacts = currentObjectContacts.filter((c) => !editedContacts.some((ec) => ec.id === c.contactId));
    const payload = { contacts: [...otherContacts, ...updatedContacts] };

    setSaving(true);
    const [allUpdatedContacts, error] = await updateObjectContacts(objectDefinition.objectType, objectId, payload);
    setSaving(false);

    if (error) {
      logError(error, "[EdiContactAccessDrawer] updateObjectContacts");
      sendNotificationError("Could not updated contact(s)");
      return;
    }

    sendNotification("Contact(s) updated successfully");
    onSave(allUpdatedContacts);
    onClose();
  };

  const handleAccessChange = (update: Partial<AccessValue>) => {
    setAccessValue((prev) => ({ ...prev, ...update }));
    setDirty(true);
  };

  return (
    <Drawer anchor="right" open sx={{ "& .MuiDrawer-paper": { width: "25rem" } }}>
      <DialogTitle>
        {editedContacts.length === 1
          ? `Edit ${editedContacts[0]?.contactName}`
          : `Bulk Edit ${editedContacts.length} contacts`}
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <Box display="flex" flexDirection="column" flexGrow={1} py={2.5} px={3} height="100%" overflow="scroll">
        <AccessEditor
          value={accessValue}
          onChange={handleAccessChange}
          roleOptions={["None", ...objectDefinition.supportedContactRoles]}
          categoryOptions={categoryOptions}
        />
      </Box>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1, justifyContent: "flex-start" }}>
        <LoadingButton variant="contained" loading={isSaving} onClick={handleSave} disabled={!isDirty}>
          Apply
        </LoadingButton>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Drawer>
  );
};

export default EdiContactAccessDrawer;
