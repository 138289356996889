import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import NumbersRoundedIcon from "@mui/icons-material/NumbersRounded";
import TitleIcon from "@mui/icons-material/TitleRounded";
import { SvgIconProps } from "@mui/material";
import { MetricDataType } from "../../../../shared/api/portfolioMonitoringTypes";
import MoneyIcon from "../../../../shared/icons/MoneyIcon";
import SelectIcon from "../../../../shared/icons/SelectIcon";

interface Props extends SvgIconProps {
  dataType: MetricDataType;
}

const MetricDataTypeIcon = ({ dataType, ...iconProps }: Props) => {
  switch (dataType) {
    case "Text": {
      return <TitleIcon {...iconProps} />;
    }
    case "Number": {
      return <NumbersRoundedIcon {...iconProps} />;
    }
    case "Money": {
      return <MoneyIcon {...iconProps} />;
    }
    case "Date": {
      return <CalendarTodayRoundedIcon {...iconProps} />;
    }
    case "Select": {
      return <SelectIcon {...iconProps} />;
    }
    default: {
      return null;
    }
  }
};

export default MetricDataTypeIcon;
