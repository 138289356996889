import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Button, Chip, Stack } from "@mui/material";
import { blue } from "@mui/material/colors";
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridAutoGeneratedGroupNode,
  GridDataGroupNode,
  GridLeafNode,
  GridRenderCellParams,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import deepEqual from "fast-deep-equal";
import { Dispatch, PropsWithChildren, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { ApiResponse } from "../../../../../shared/api/types";
import BadgeDetached from "../../../../../shared/components/BadgeDetached";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { distinctBy, groupByToMap } from "../../../../../shared/utilities/arrayHelper";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi, {
  InvestorCommunicationMatrixCreate,
  InvestorCommunicationMatrixRemove,
  InvestorCommunicationMatrixUpdate,
  UpdateInvestorCommunicationMatrixRequest,
} from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import { useClientContext } from "../../../../context/ClientContext";
import usePageLeaveConfirmation from "../../../../hooks/usePageLeaveConfirmation";
import DataGridGroupingCell from "../../../common/grid/DataGridGroupingCell";
import GroupHeader from "../../../common/grid/GroupHeader";
import { useGridGroupsCollapsed } from "../../../common/grid/gridHooks";
import { getCheckedGridSx } from "../../../common/grid/gridStyles";
import SendInvitationsConfirmationDialog from "../common/SendInvitationsConfirmationDialog";
import CommunicationMatrixFilters from "./CommunicationMatrixFilters";
import CommunicationMatrixToolbar from "./CommunicationMatrixToolbar";
import MatrixBulkEdit from "./MatrixBulkEdit";
import {
  BulkPermissionsState,
  CommunicationMatrixEntityType,
  CommunicationMatrixModel,
  ContactPermissionsChange,
  EmptyId,
  getCommunicationMatrixColumns,
  getModelId,
  isEmptyFundInvestor,
  isEmptyInvestorContact,
} from "./matrixColumnDefinitions";
import { getBulkPermissionsState, updateSelectedModelsWithPermissions } from "./matrixHelper";

interface Props {
  entityType: CommunicationMatrixEntityType;
  noItemsMessage: string;
  renderGroupCellElement: (
    value: unknown,
    node: GridDataGroupNode | GridAutoGeneratedGroupNode | GridLeafNode
  ) => JSX.Element;
  renderGroupedRowCell: (model: CommunicationMatrixModel) => JSX.Element;
  renderGridToolbarComponent?: (isLoading: boolean) => JSX.Element;
  categories: Category[];
  initialModels: CommunicationMatrixModel[];
  setInitialModels: Dispatch<SetStateAction<CommunicationMatrixModel[]>>;
  models: CommunicationMatrixModel[];
  setModels: Dispatch<SetStateAction<CommunicationMatrixModel[]>>;
  getChangesWhereInvitationWouldBeSent: (
    originalPermissionModels: CommunicationMatrixModel[],
    modifiedPermissionModels: CommunicationMatrixModel[]
  ) => ContactPermissionsChange[];
  sortModelFunc: (a: CommunicationMatrixModel, b: CommunicationMatrixModel) => number;
  getTreeDataPath: (model: CommunicationMatrixModel) => string[];
  cloneModelWithoutChildren: (model: CommunicationMatrixModel) => CommunicationMatrixModel;
  childRowActionsDisabled?: boolean;
  groupRowActionsDisabled?: boolean;
  updateInvestorCommunicationMatrix: (
    payload: UpdateInvestorCommunicationMatrixRequest
  ) => Promise<ApiResponse<CommunicationMatrixModel[] | void>>;
  excludedColumns?: string[];
}

const CommunicationMatrix = ({
  entityType,
  noItemsMessage,
  categories,
  initialModels,
  setInitialModels,
  models,
  setModels,
  renderGridToolbarComponent,
  renderGroupCellElement,
  getChangesWhereInvitationWouldBeSent,
  sortModelFunc,
  getTreeDataPath,
  cloneModelWithoutChildren,
  renderGroupedRowCell,
  childRowActionsDisabled,
  groupRowActionsDisabled,
  updateInvestorCommunicationMatrix,
  excludedColumns,
}: Props) => {
  const apiRef = useGridApiRef();
  const { hasAnyPermission } = useClientContext();
  const hasAccessToManage = hasAnyPermission(["ManageFundStructure"]);
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { setShouldPrompt: setShouldPromptOnPageLeave } = usePageLeaveConfirmation(false);

  const [filteredModels, setFilteredModels] = useState<CommunicationMatrixModel[]>(cloneDeep(initialModels));
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invitationChanges, setInvitationChanges] = useState<ContactPermissionsChange[]>([]);
  const [bulkPermissionsState, setBulkPermissionsState] = useState<BulkPermissionsState>();
  const showToolbar = selectedIds.length > 0;
  const { isGroupExpanded } = useGridGroupsCollapsed(apiRef);

  const [investorPortalSettings] = useFetch(adminApi.getInvestorPortalSettings);

  const updateCommunicationMatrix = withErrorHandling(updateInvestorCommunicationMatrix);

  const getModelGroupingKey = useCallback(
    (model: CommunicationMatrixModel) => getTreeDataPath(model)[0] ?? "",
    [getTreeDataPath]
  );

  const handleModelChange = useCallback(
    (model: CommunicationMatrixModel) => {
      setModels((currentModels) => {
        const groupingKey = getModelGroupingKey(model);
        return currentModels.map((currentModel) => {
          if (getModelId(currentModel) === getModelId(model)) {
            return model;
          }
          if (getModelGroupingKey(currentModel) === groupingKey && model.isPrimary) {
            // Primary Contact is an exclusive selection
            return { ...currentModel, isPrimary: false };
          }
          return { ...currentModel };
        });
      });
    },
    [getModelGroupingKey, setModels]
  );

  const handleModelsRemove = useCallback(
    (ids: string[]) => {
      setModels((currentModels) => {
        if (!groupRowActionsDisabled) {
          return currentModels.filter((m) => !ids.includes(getModelId(m)));
        }

        // When groupRowActionsDisabled is true, and all child models get removed, keep empty group row models
        const newModels: CommunicationMatrixModel[] = [];
        const groupedModels = groupByToMap(currentModels, (m) => getModelGroupingKey(m));

        groupedModels.forEach((group) => {
          const notRemovedFromGroup = group.filter((m) => !ids.includes(getModelId(m)));
          if (notRemovedFromGroup.length > 0) {
            newModels.push(...notRemovedFromGroup);
            return;
          }

          if (group.length === 1) {
            const singleModel = defined(group[0]);
            if (getTreeDataPath(singleModel).length < 2) {
              newModels.push(singleModel);
              return;
            }
          }

          if (group[0]) {
            const emptyGroupModel = cloneModelWithoutChildren(group[0]);
            newModels.push(emptyGroupModel);
          }
        });

        return newModels;
      });
    },
    [cloneModelWithoutChildren, getModelGroupingKey, getTreeDataPath, groupRowActionsDisabled, setModels]
  );

  const rowEditingDisabled = (model: CommunicationMatrixModel) => !model.contactEmail;

  const isNewlyAdded = useCallback(
    (ids: string[]) => ids.some((id) => !initialModels.some((m) => getModelId(m) === id)),
    [initialModels]
  );

  const gridColumns = useMemo(
    () =>
      getCommunicationMatrixColumns({
        messageCategories: categories,
        excludedColumns,
        onChange: handleModelChange,
        onRemove: handleModelsRemove,
        readOnly: !hasAccessToManage,
        rowEditingDisabled,
        childRowActionsDisabled,
        groupRowActionsDisabled,
        apiRef,
      }),
    [
      categories,
      excludedColumns,
      handleModelChange,
      handleModelsRemove,
      hasAccessToManage,
      childRowActionsDisabled,
      groupRowActionsDisabled,
      apiRef,
    ]
  );

  const handleFilterChange = useCallback(
    (filteredRows: CommunicationMatrixModel[]) => {
      setFilteredModels(filteredRows.sort(sortModelFunc));
    },
    [sortModelFunc]
  );

  const changesExist = useMemo(() => !deepEqual(initialModels, models), [initialModels, models]);

  useEffect(() => {
    setShouldPromptOnPageLeave(changesExist);
  }, [changesExist, setShouldPromptOnPageLeave]);

  const handleCancelChanges = () => {
    setModels(cloneDeep(initialModels));
    setFilteredModels(cloneDeep(initialModels));
  };

  const handleSaveChanges = async () => {
    setInvitationChanges([]);

    const updatedModels = models.filter((model) => {
      const initialModel = initialModels.find((m) => getModelId(m) === getModelId(model));
      return !deepEqual(initialModel, model) && !isEmptyFundInvestor(model) && !isEmptyInvestorContact(model);
    });

    const removedModels: InvestorCommunicationMatrixRemove[] = initialModels.filter(
      (m) => !models.some((model) => getModelId(model) === getModelId(m))
    );

    const createdModels = models.filter((model) => isEmptyFundInvestor(model));

    if (updatedModels.length + removedModels.length + createdModels.length === 0) {
      setInitialModels(cloneDeep(models));
      return;
    }

    setIsLoading(true);

    const updates: InvestorCommunicationMatrixUpdate[] = updatedModels.map((model) => ({
      fundInvestorId: model.fundInvestorId,
      contactId: model.contactId,
      isPrimary: model.isPrimary,
      emailNotification: model.emailNotification,
      roles: model.roles,
      categories: model.categories,
    }));

    const removes: InvestorCommunicationMatrixRemove[] = removedModels.map((m) => ({
      fundInvestorId: m.fundInvestorId,
      contactId: m.contactId,
    }));

    const creates: InvestorCommunicationMatrixCreate[] = createdModels.map((m) => ({
      investorId: m.investorId,
      investorName: m.investorName,
      contactId: m.contactId === EmptyId ? undefined : m.contactId,
      isPrimary: m.isPrimary,
      emailNotification: m.emailNotification,
      roles: m.roles,
      categories: m.categories,
    }));

    const [modelsAfterUpdate, error] = await updateCommunicationMatrix({ updates, removes, creates });

    if (error) {
      sendNotificationError("Error saving communication matrix changes");
      logError(error, "Communication matrix");
    } else {
      sendNotification("Communication matrix changes saved successfully");
      if (modelsAfterUpdate) {
        setModels(cloneDeep(modelsAfterUpdate));
        setInitialModels(cloneDeep(modelsAfterUpdate));
      } else {
        setInitialModels(cloneDeep(models));
      }
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    const changes: ContactPermissionsChange[] = getChangesWhereInvitationWouldBeSent(initialModels, models);
    if (changes.length > 0 && investorPortalSettings?.settings.enableAutomaticInvites) {
      setInvitationChanges(distinctBy(changes, (c) => c.contact.id));
    } else {
      await handleSaveChanges();
    }
  };

  const handleRemove = () => {
    handleModelsRemove(selectedIds);
    setSelectedIds([]);
  };

  const handleBulkEditSubmit = (permissionsState: BulkPermissionsState) => {
    setModels((currentModels) => updateSelectedModelsWithPermissions(currentModels, selectedIds, permissionsState));
    deselectSelectedIds();
  };

  const handleBulkEditClick = () => {
    const selectedModels = models.filter((m) => selectedIds.includes(getModelId(m)));
    setBulkPermissionsState(getBulkPermissionsState(selectedModels, categories));
  };

  const deselectSelectedIds = () => {
    apiRef.current?.selectRows(selectedIds, false, true);
    setSelectedIds([]);
  };

  const selectedModels = models.filter((m) => selectedIds.includes(getModelId(m)));
  const disableBulkEditToolbarButton = selectedModels.every(isEmptyInvestorContact);
  const disableRemoveToolbarButton = groupRowActionsDisabled && selectedModels.every(isEmptyInvestorContact);

  return (
    <>
      <CommunicationMatrixFilters entityType={entityType} permissions={models} onFilterChange={handleFilterChange}>
        {changesExist && (
          <>
            <Button disabled={isLoading} onClick={handleCancelChanges} variant="text" color="secondary">
              Cancel
            </Button>
            <Button disabled={isLoading} onClick={handleSave} variant="contained" color="primary">
              Save Changes
            </Button>
          </>
        )}
        {!changesExist && hasAccessToManage && renderGridToolbarComponent?.(isLoading)}
      </CommunicationMatrixFilters>
      <DataGrid<CommunicationMatrixModel>
        apiRef={apiRef}
        checkboxSelection
        treeData
        loading={isLoading}
        getTreeDataPath={getTreeDataPath}
        rows={filteredModels}
        getRowId={getModelId}
        columns={gridColumns}
        multilineRows
        groupingColDef={{
          sortable: false,
          minWidth: 300,
          headerName: "Name",
          renderCell: (params: GridRenderCellParams) => (
            <DataGridGroupingCell
              renderGroupCellElement={(value, node) => (
                <GroupRow node={node} isNewlyAdded={isNewlyAdded}>
                  {renderGroupCellElement(value, node)}
                </GroupRow>
              )}
              renderCellElement={(model) => renderGroupedRowCell(model as CommunicationMatrixModel)}
              {...params}
            />
          ),
          renderHeader: (params) => <GroupHeader params={params} />,
        }}
        sx={(theme) => getCheckedGridSx(theme, showToolbar)}
        noRowsText={noItemsMessage}
        slots={{
          toolbar: () =>
            showToolbar ? (
              <CommunicationMatrixToolbar selectedIds={selectedIds}>
                <Button
                  disabled={isLoading || !hasAccessToManage || disableBulkEditToolbarButton}
                  onClick={handleBulkEditClick}
                  color="secondary"
                  variant="text"
                  startIcon={<EditIcon />}
                >
                  Bulk Edit
                </Button>
                <Button
                  disabled={isLoading || !hasAccessToManage || disableRemoveToolbarButton}
                  onClick={handleRemove}
                  color="error"
                  variant="text"
                  startIcon={<DeleteIcon />}
                >
                  Remove
                </Button>
              </CommunicationMatrixToolbar>
            ) : null,
        }}
        disableColumnReorder
        pinnedColumns={{
          left: [GRID_CHECKBOX_SELECTION_FIELD, GRID_TREE_DATA_GROUPING_FIELD],
          right: ["actions"],
        }}
        onRowSelectionModelChange={(ids) => setSelectedIds(ids as string[])}
        isGroupExpandedByDefault={isGroupExpanded}
      />
      <SendInvitationsConfirmationDialog
        open={invitationChanges.length > 0}
        onSubmit={handleSaveChanges}
        onClose={() => setInvitationChanges([])}
        changes={invitationChanges}
      />
      {bulkPermissionsState && (
        <MatrixBulkEdit
          initialPermissionsState={bulkPermissionsState}
          recordsCount={selectedIds.length}
          onClose={() => setBulkPermissionsState(undefined)}
          categories={[...categories]}
          onSubmit={handleBulkEditSubmit}
        />
      )}
    </>
  );
};

export default CommunicationMatrix;

interface GroupRowProps {
  node: GridDataGroupNode | GridAutoGeneratedGroupNode | GridLeafNode;
  isNewlyAdded: (ids: string[]) => boolean;
}

const GroupRow = ({ node, isNewlyAdded, children }: PropsWithChildren<GroupRowProps>) => {
  const isGroup = node.type === "group";
  const isEmptypGroup = node.type === "leaf" && node.depth === 0;
  const isNew = !isEmptypGroup && isNewlyAdded(isGroup ? (node.children as string[]) : [node.id as string]);

  return (
    <Stack direction="row" spacing={1} alignItems="center" maxWidth="100%" pl={isGroup ? 0 : 2}>
      {children}
      {isGroup && node.children && <BadgeDetached color="secondary" showZero badgeContent={node.children.length} />}
      {isNew && (
        <Chip
          sx={(t) => ({
            color: t.palette.info.main,
            background: blue[50],
          })}
          color="info"
          size="small"
          label="New"
        />
      )}
    </Stack>
  );
};
