import EditIcon from "@mui/icons-material/EditOutlined";
import MoveDownIcon from "@mui/icons-material/MoveDownRounded";
import MoveUpIcon from "@mui/icons-material/MoveUpRounded";

import { GridAutoGeneratedGroupNode, GridDataGroupNode } from "@mui/x-data-grid-premium";
import { useState } from "react";
import ActionsMenuButton from "../../../../../common/ActionsMenuButton";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import EditFileSectionDialog from "./EditFileSectionDialog";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import { moveSectionDownAction, moveSectionUpAction, renameSectionAction } from "./fundraisingDocumentsState";

interface Props {
  rowNode: GridDataGroupNode | GridAutoGeneratedGroupNode;
}

const FundraisingDocumentSectionActionsCell = ({ rowNode }: Props) => {
  const { isContentEditable } = useFundraisingDetailsPageContext();
  const { documentsState, setDocumentsState } = useFundraisingDocumentsContext();

  const [showEditSectionDialog, setShowEditSectionDialog] = useState(false);

  if (!isContentEditable || documentsState.isSaving) {
    return null;
  }

  const sectionName = rowNode.groupingKey?.toString();

  if (!sectionName) {
    return null;
  }

  const handleSectionRename = (newSectionName: string) => {
    setShowEditSectionDialog(false);
    setDocumentsState(renameSectionAction(sectionName, newSectionName));
  };

  const currentSectionIndex = documentsState.orderedSections.indexOf(sectionName);
  const canMoveUp = currentSectionIndex > 0;
  const canMoveDown = currentSectionIndex < documentsState.orderedSections.length - 1;

  return (
    <>
      <ActionsMenuButton
        items={[
          {
            label: "Rename",
            icon: <EditIcon color="action" />,
            onClick: () => setShowEditSectionDialog(true),
          },
          {
            label: "Move up",
            icon: <MoveUpIcon color="action" />,
            onClick: () => setDocumentsState(moveSectionUpAction(sectionName)),
            disabled: !canMoveUp,
          },
          {
            label: "Move down",
            icon: <MoveDownIcon color="action" />,
            onClick: () => setDocumentsState(moveSectionDownAction(sectionName)),
            disabled: !canMoveDown,
          },
        ]}
      />

      {showEditSectionDialog && (
        <EditFileSectionDialog
          editedSectionName={sectionName}
          onClose={() => setShowEditSectionDialog(false)}
          onCreated={handleSectionRename}
        />
      )}
    </>
  );
};

export default FundraisingDocumentSectionActionsCell;
