import { Box, ClickAwayListener, IconButton, Paper, Stack, Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import PencilNotFilledIcon from "../../../icons/PencilNotFilledIcon";
import { useFieldValuesContext } from "../FieldValuesContext";

interface DisplayValueProps {
  value: string;
  component?: JSX.Element | JSX.Element[] | null;
}

const DisplayValue = ({ value, component }: DisplayValueProps) => (
  <Box px={1.5} flexGrow={1} display="flex" alignItems="center" minHeight="2.5em">
    {component ? component : <Typography>{value}</Typography>}
  </Box>
);

interface Props {
  id?: string;
  displayValue: string;
  isEdit: boolean;
  onEditChange?: (isEdit: boolean) => void;
  isReadonly?: boolean;
  displayValueComponent?: JSX.Element | JSX.Element[] | null;
  displayHoverComponent?: JSX.Element | JSX.Element[] | null;
  onLeaveEditMode?: () => Promise<boolean>;
  emptyPlaceholder?: string;
  onPlaceholderClick?: () => boolean;
}

const FieldValueWrapper = ({
  id,
  displayValue,
  displayValueComponent,
  displayHoverComponent,
  isEdit,
  onEditChange,
  isReadonly,
  children,
  onLeaveEditMode,
  emptyPlaceholder,
}: PropsWithChildren<Props>) => {
  const [isHovered, setIsHovered] = useState(false);
  const { fieldValuesState, updateFieldValuesState } = useFieldValuesContext();

  const handlePlaceholderClick = async () => {
    if (fieldValuesState.isValid) {
      //do not allow multiple fields to be edited if validation is not passed
      onEditChange?.(true);
      if (fieldValuesState.fieldEditState !== "editing") {
        updateFieldValuesState({ fieldEditState: "editing" });
      }
    }
  };

  const handleLeaveEditMode = async () => {
    if (!isEdit) {
      return;
    }

    const isValid = await onLeaveEditMode?.();
    if (isValid === false) {
      return;
    }
    onEditChange?.(false);
  };

  if (isReadonly) {
    return <DisplayValue value={displayValue} component={displayValueComponent} />;
  }

  return (
    <ClickAwayListener onClickAway={handleLeaveEditMode}>
      <Paper
        id={id}
        elevation={0}
        sx={(t) => ({
          width: "100%",
          minHeight: t.spacing(4.5),
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: isHovered && !isEdit ? "rgba(35, 52, 59, 0.04)" : "transparent",
          },
        })}
        onClick={handlePlaceholderClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"} width="100%">
          {!isEdit && displayValue && <DisplayValue value={displayValue} component={displayValueComponent} />}

          {displayValue && isHovered && !isEdit && (
            <>
              {displayHoverComponent && (
                <Box mr={1} display="flex" onClick={(e) => e.stopPropagation()}>
                  {displayHoverComponent}
                </Box>
              )}
              <IconButton onClick={handlePlaceholderClick}>
                <PencilNotFilledIcon color="secondary" />
              </IconButton>
            </>
          )}

          {!displayValue && isHovered && !isEdit && (
            <Typography px={1.5} color="textSecondary">
              {emptyPlaceholder || "Add ..."}
            </Typography>
          )}

          {isEdit && children}
        </Stack>
      </Paper>
    </ClickAwayListener>
  );
};

export default FieldValueWrapper;
