import RollbackIcon from "@mui/icons-material/ReplayRounded";
import { Button, Typography } from "@mui/material";
import useConfirmationDialog from "../../../../hooks/useConfirmationDialog";
import DataBackupIcon from "../../../../icons/DataBackupIcon";

interface Props {
  showRollback?: boolean;
  onRollback: () => void;
}

const ImportDataDetailsActions = ({ showRollback, onRollback }: Props) => {
  const { ConfirmationDialogComponent, setConfirmDialogData } = useConfirmationDialog<string>();

  const handleRollbackImportClick = async () => {
    setConfirmDialogData({
      ids: [],
      icon: <DataBackupIcon sx={{ width: 80, height: 80 }} />,
      title: "Rollback import?",
      description: (
        <Typography color="textSecondary" textAlign="center">
          You&apos;re about to rollback all changes made by this import.
          <br />
          This action is permanent and can&apos;t be undone.
        </Typography>
      ),
      confirmButtonLabel: "Rollback",
      confirmButtonColor: "primary",
      confirmCallback: () => onRollback(),
    });
  };

  return (
    <>
      {showRollback && (
        <Button variant="outlined" color="secondary" startIcon={<RollbackIcon />} onClick={handleRollbackImportClick}>
          Rollback Import
        </Button>
      )}
      {ConfirmationDialogComponent}
    </>
  );
};

export default ImportDataDetailsActions;
