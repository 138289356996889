import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { InputAdornment, ListItemText, MenuItem, Select } from "@mui/material";
import { OrganizationRole, allOrganizationRoles } from "../../../../../../api/types/userManagementTypes";
import OrganizationRoleStatusTag from "../../OrganizationRoleStatusTag";
import { getSelectedSx, organizationRolesDataMap } from "../../definitions";

interface Props {
  role: OrganizationRole | undefined;
  onChange: (role: OrganizationRole) => void;
  disabled?: boolean;
}

const getRoleLabel = (role: string) => {
  const roleData = organizationRolesDataMap[role as OrganizationRole];
  return roleData ? roleData.label : "Role";
};

const OrganizationRoleFilter = ({ role, onChange, disabled }: Props) => {
  return (
    <Select
      disabled={disabled}
      sx={(theme) => ({
        ...getSelectedSx(theme, !!role),
        width: theme.spacing(20),
        ".MuiInputBase-input": { color: theme.palette.secondary.main },
      })}
      value={role ?? ""}
      displayEmpty
      onChange={(event) => onChange(event.target.value as OrganizationRole)}
      startAdornment={
        <InputAdornment position="start">
          <PersonOutlineIcon color={disabled ? "disabled" : "secondary"} />
        </InputAdornment>
      }
      renderValue={getRoleLabel}
    >
      {allOrganizationRoles.map((role) => (
        <MenuItem key={role} value={role}>
          <ListItemText
            primary={<OrganizationRoleStatusTag role={role} />}
            slotProps={{ primary: { component: "span" } }}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default OrganizationRoleFilter;
