import AddIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMoveOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/SaveAltRounded";
import { Button, Divider, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useRef, useState } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import FileAccessIcon from "../../../../../../icons/FileAccessIcon";
import CategoryTag from "../../../../../common/CategoryTag";
import CustomDataGridHeaderToolbar from "../../../../../common/grid/CustomDataGridHeaderToolbar";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import EditFileSectionDialog from "./EditFileSectionDialog";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import {
  deleteDocumentsAction,
  isChangeAccessOptionsAllowed,
  selectAccessOptions,
  updateDocumentsAccessOptionsAction,
  updateDocumentsCategoryAction,
  updateDocumentsSectionAction,
} from "./fundraisingDocumentsState";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  disabled?: boolean;
}

const FundraisingDocumentsGridToolbar = ({ selectedIds, setSelectedIds, disabled }: Props) => {
  const { fundraisingCategories, isContentEditable } = useFundraisingDetailsPageContext();
  const { documentsState, setDocumentsState, onDownloadFile, onDownloadMultipleFiles } =
    useFundraisingDocumentsContext();

  const [showNewSectionDialog, setShowNewSectionDialog] = useState(false);

  const [isSectionSelectorOpen, setSectionSelectorOpen] = useState(false);
  const changeSectionButtonRef = useRef<HTMLButtonElement>(null);

  const [isCategorySelectorOpen, setCategorySelectorOpen] = useState(false);
  const changeCategoryButtonRef = useRef<HTMLButtonElement>(null);

  const [isFileAccessSelectorOpen, setFileAccessSelectorOpen] = useState(false);
  const changeFileAccessButtonRef = useRef<HTMLButtonElement>(null);

  const handleDownloadClick = () => {
    if (selectedIds.length === 1) {
      onDownloadFile(defined(selectedIds[0]));
    } else {
      onDownloadMultipleFiles(selectedIds);
    }
  };

  const handleCreateNewSection = () => {
    setSectionSelectorOpen(false);
    setShowNewSectionDialog(true);
  };

  const handleNewSectionCreated = (section: string) => {
    setShowNewSectionDialog(false);
    setDocumentsState(updateDocumentsSectionAction(selectedIds, section));
  };

  const selectedUploadingDocuments = documentsState.documents.filter(
    (doc) => selectedIds.includes(doc.fileId) && doc.uploadStatus === "uploading"
  );

  const selectedDocumentsWithChangeableAccess = documentsState.documents.filter(
    (doc) => selectedIds.includes(doc.fileId) && isChangeAccessOptionsAllowed(doc)
  );

  const menuSlotProps = {
    paper: { sx: { minWidth: "12rem" } },
  };

  return (
    <>
      <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
        {isContentEditable && (
          <Button
            ref={changeSectionButtonRef}
            disabled={disabled}
            onClick={() => setSectionSelectorOpen(true)}
            color="secondary"
            variant="text"
            startIcon={<DriveFileMoveIcon />}
          >
            Change Section
          </Button>
        )}

        {isContentEditable && (
          <Button
            ref={changeCategoryButtonRef}
            disabled={disabled}
            onClick={() => setCategorySelectorOpen(true)}
            color="secondary"
            variant="text"
            startIcon={<EditIcon />}
          >
            Change Category
          </Button>
        )}

        {isContentEditable && (
          <Button
            ref={changeFileAccessButtonRef}
            disabled={disabled || selectedDocumentsWithChangeableAccess.length === 0}
            onClick={() => setFileAccessSelectorOpen(true)}
            color="secondary"
            variant="text"
            startIcon={<FileAccessIcon />}
          >
            File Access
          </Button>
        )}

        <Button
          disabled={disabled || selectedUploadingDocuments.length > 0}
          onClick={handleDownloadClick}
          color="secondary"
          variant="text"
          startIcon={<DownloadIcon />}
        >
          Download
        </Button>

        {isContentEditable && (
          <Button
            disabled={disabled}
            onClick={() => setDocumentsState(deleteDocumentsAction(selectedIds))}
            color="error"
            variant="text"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        )}

        <Menu
          open={isSectionSelectorOpen}
          onClose={() => setSectionSelectorOpen(false)}
          anchorEl={changeSectionButtonRef.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          slotProps={menuSlotProps}
        >
          {documentsState.orderedSections.map((section) => (
            <MenuItem
              key={section}
              value={section}
              onClick={() => setDocumentsState(updateDocumentsSectionAction(selectedIds, section))}
            >
              {section}
            </MenuItem>
          ))}

          <Divider />

          <MenuItem onClick={() => handleCreateNewSection()}>
            <ListItemIcon>
              <AddIcon color="primary" />
            </ListItemIcon>
            <ListItemText slotProps={{ primary: { color: "primary.main" } }}>Create New</ListItemText>
          </MenuItem>
        </Menu>

        <Menu
          open={isCategorySelectorOpen}
          onClose={() => setCategorySelectorOpen(false)}
          anchorEl={changeCategoryButtonRef.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          slotProps={menuSlotProps}
        >
          {fundraisingCategories.map((category) => (
            <MenuItem
              key={category.externalId}
              value={category.externalId}
              dense
              onClick={() => setDocumentsState(updateDocumentsCategoryAction(selectedIds, category.externalId))}
            >
              <CategoryTag categoryName={category.name} />
            </MenuItem>
          ))}
        </Menu>

        <Menu
          open={isFileAccessSelectorOpen}
          onClose={() => setFileAccessSelectorOpen(false)}
          anchorEl={changeFileAccessButtonRef.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          slotProps={menuSlotProps}
        >
          {selectAccessOptions.map(([value, options]) => (
            <MenuItem
              key={value}
              value={value}
              onClick={() =>
                setDocumentsState(
                  updateDocumentsAccessOptionsAction(
                    selectedDocumentsWithChangeableAccess.map((d) => d.fileId),
                    options
                  )
                )
              }
            >
              {value}
            </MenuItem>
          ))}
        </Menu>
      </CustomDataGridHeaderToolbar>

      {showNewSectionDialog && (
        <EditFileSectionDialog onClose={() => setShowNewSectionDialog(false)} onCreated={handleNewSectionCreated} />
      )}
    </>
  );
};

export default FundraisingDocumentsGridToolbar;
