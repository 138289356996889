import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { EntityFieldType } from "../../../../../api/types/objectTypes";
import { fieldTypeCaptionMap } from "../../../../../utilities/enumCaptions";
import FieldIcon from "../../../../common/domain-icons/FieldIcon";
import { entityFieldTypesForUdf } from "./userDefinedFieldConfigurations";

interface Props {
  fieldType: EntityFieldType;
  onChange: (fieldType: EntityFieldType) => void;
  disabled?: boolean;
}

const Item = ({ fieldType }: { fieldType: EntityFieldType }) => (
  <Stack direction="row" spacing={1}>
    <FieldIcon fieldType={fieldType} color="action" />
    <Typography>{fieldTypeCaptionMap[fieldType]}</Typography>
  </Stack>
);

const FieldTypeSelect = ({ fieldType, onChange, disabled }: Props) => {
  const handleChange = (e: SelectChangeEvent) => {
    onChange(e.target.value as EntityFieldType);
  };

  return (
    <Select
      fullWidth
      disabled={disabled}
      value={fieldType}
      onChange={handleChange}
      renderValue={(value) => <Item fieldType={value} />}
    >
      {entityFieldTypesForUdf.map((type) => (
        <MenuItem key={type} value={type}>
          <Item fieldType={type} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default FieldTypeSelect;
