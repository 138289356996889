import { Popover, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { MessageDeliveryInfo, MessageDeliveryRecipient } from "../../../../../../api/types/documentCollectionTypes";
import ContactDeliveryDetails from "./ContactDeliveryDetails";
import ContactDeliveryInfo from "./ContactDeliveryInfo";

interface Props {
  contactDeliveries: MessageDeliveryInfo[];
  messageRequestId: string;
}

interface ContactRowProps {
  deliveryInfo: MessageDeliveryInfo;
  messageRequestId: string;
}

const getContactId = (recipient: MessageDeliveryRecipient) =>
  `${recipient.referenceId}_${recipient.email}_${recipient.name}`;

const ContactRow = ({ deliveryInfo, messageRequestId }: ContactRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <TableRow
        sx={{
          "&.MuiTableRow-root": {
            td: { borderBottom: 0 },
          },
        }}
      >
        <TableCell>
          <ContactDeliveryInfo deliveryInfo={deliveryInfo} onClick={(e) => setAnchorEl(e.currentTarget)} />
        </TableCell>
      </TableRow>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <ContactDeliveryDetails recipient={deliveryInfo.recipient} messageRequestId={messageRequestId} />
      </Popover>
    </>
  );
};

const DocumentDetailsInfo = ({ contactDeliveries, messageRequestId }: Props) => {
  return (
    <Stack spacing={1} p={3}>
      <Typography variant="subtitle2">Contacts</Typography>

      {contactDeliveries.length === 0 && (
        <Typography color="text.secondary">
          There are currently no contacts associated with this investor who have access to the selected document
          category.
        </Typography>
      )}

      {contactDeliveries.length > 0 && (
        <TableContainer sx={{ maxHeight: "calc(100vh - 21rem)" }}>
          <Table>
            <TableBody>
              {contactDeliveries.map((delivery, i) => (
                <ContactRow
                  key={getContactId(delivery.recipient) + `${i}`}
                  deliveryInfo={delivery}
                  messageRequestId={messageRequestId}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default DocumentDetailsInfo;
