import { ListItemText, Select, Typography } from "@mui/material";
import MenuItemWithoutHighlight from "../../../shared/components/MenuItemWithoutHighlight";
import { PortalRoleWithNoneValue } from "../../api/types/accessTypes";
import PortalRoleChip from "./PortalRoleChip";

interface Props {
  role: PortalRoleWithNoneValue | undefined;
  onChange: (role: PortalRoleWithNoneValue) => void;
  roleOptions: PortalRoleWithNoneValue[];
}

const PortalRoleSelector = ({ role, onChange, roleOptions }: Props) => {
  return (
    <Select
      variant="standard"
      disableUnderline
      sx={{
        ".MuiInputBase-input": {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      }}
      value={role ?? ""}
      displayEmpty
      onChange={(e) => onChange(e.target.value as PortalRoleWithNoneValue)}
      renderValue={
        role === undefined
          ? () => (
              <Typography variant="caption" color="text.secondary">
                Select
              </Typography>
            )
          : undefined
      }
    >
      {roleOptions.map((role) => (
        <MenuItemWithoutHighlight key={role} value={role}>
          <ListItemText slotProps={{ primary: { component: "span" } }} primary={<PortalRoleChip role={role} />} />
        </MenuItemWithoutHighlight>
      ))}
    </Select>
  );
};

export default PortalRoleSelector;
