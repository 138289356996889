import { Stack, Typography } from "@mui/material";
import { ReportAccessType } from "../../api/biClient.types";
import { getLabelByAccess } from "./utils";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { AccessModeMenu } from "./AccessModeMenu";
import { useCallback, useMemo, useRef, useState } from "react";

interface Props {
  access: ReportAccessType;
  allowedAccesses: ReportAccessType[];
  disabled: boolean;
  hasRemove?: boolean;
  onSelect: (access: ReportAccessType) => void;
  onRemove?: () => void;
}
export default function AccessModeMenuLabel({
  access,
  allowedAccesses,
  disabled,
  hasRemove,
  onSelect,
  onRemove,
}: Props) {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null | undefined>(null);

  const canBeEdited = useMemo(() => {
    if (access === ReportAccessType.Owner) {
      if (allowedAccesses.includes(ReportAccessType.Owner)) {
        return true;
      }
      return false;
    }
    return true;
  }, [access, allowedAccesses]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <Stack
        ref={(r) => (menuRef.current = r)}
        direction={"row"}
        alignItems={"center"}
        spacing={0.5}
        sx={{ cursor: canBeEdited && !disabled ? "pointer" : "default" }}
        onClick={() => !disabled && setOpen(true)}
      >
        <Typography variant="body2" color={disabled ? "text.disabled" : "text.primary"}>
          {getLabelByAccess(access)}
        </Typography>
        {canBeEdited && <ArrowDropDownRoundedIcon color="action" />}
      </Stack>
      {canBeEdited && (
        <AccessModeMenu
          access={access}
          allowedAccesses={allowedAccesses}
          menuRef={menuRef.current}
          hasRemove={hasRemove}
          open={open}
          onSelect={onSelect}
          onRemove={onRemove}
          onClose={handleClose}
        />
      )}
    </>
  );
}
