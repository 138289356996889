import { ClickAwayListener, Stack } from "@mui/material";
import { DatePicker, DateView } from "@mui/x-date-pickers-pro";
import { isValid } from "date-fns";
import deepEqual from "fast-deep-equal";
import { useEffect, useState } from "react";
import { ValidationResult, invalidResult, validResult } from "../../../../shared/utilities/validators";
import { DateComponent, DateEntityFieldConfiguration, FieldAttribute } from "../../../api/types/objectTypes";
import { useFieldValuesContext } from "../FieldValuesContext";
import { findNextEntityInput, findPreviousEntityInput } from "../entityFieldNavigator";
import { formatDateFieldValue } from "../helpers";
import FieldValueWrapper from "./FieldValueWrapper";

interface Props {
  value: Date | null;
  fieldConfiguration: DateEntityFieldConfiguration;
  fieldAttributes: FieldAttribute[];
  onChange: (newValue: Date | null, validationResult: ValidationResult) => void;
  fieldInputId?: string;
}

interface DateValidationResult extends ValidationResult {
  showValidation?: boolean;
}

const dateComponentToViewMap: Record<DateComponent, DateView> = {
  Day: "day",
  Month: "month",
  Year: "year",
};

const getCalendarHeaderFormat = (fieldConfiguration: DateEntityFieldConfiguration): string | undefined => {
  if (fieldConfiguration.dateComponents !== undefined && !fieldConfiguration.dateComponents.includes("Year")) {
    return "MMMM";
  }

  return undefined;
};

const getMinDate = (fieldConfiguration: DateEntityFieldConfiguration): Date | undefined => {
  if (fieldConfiguration.dateComponents !== undefined && !fieldConfiguration.dateComponents.includes("Year")) {
    return new Date(1900, 0, 1);
  }

  return undefined;
};

const getMaxDate = (fieldConfiguration: DateEntityFieldConfiguration): Date | undefined => {
  if (fieldConfiguration.dateComponents !== undefined && !fieldConfiguration.dateComponents.includes("Year")) {
    return new Date(1900, 11, 31);
  }

  return undefined;
};

const EntityDateFieldValueComponent = ({
  value,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: Props) => {
  const [date, setDate] = useState(value);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [validationResult, setValidationResult] = useState<DateValidationResult>(validResult());
  const { updateFieldValuesState } = useFieldValuesContext();

  const handleDateChange = (date: Date | null) => {
    if (date && !isValid(date)) {
      const invalidDateResult = invalidResult("Invalid date");
      setValidationResult(invalidDateResult);
      onChange(null, invalidDateResult);
      return;
    }

    if (fieldAttributes.includes("Required") && date === null) {
      const requiredInvalidResult = invalidResult("Required field. Please select the necessary date.");
      setValidationResult(requiredInvalidResult);
      onChange(null, requiredInvalidResult);
    } else {
      setValidationResult(validResult());
    }

    setDate(date);
  };

  useEffect(() => {
    if (value !== undefined && !deepEqual(value, date)) {
      setDate(value || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleValueOnLeave = () => {
    if (!validationResult.isValid) {
      setValidationResult({ ...validationResult, showValidation: true });
      return;
    }

    if (!deepEqual(date, value)) {
      onChange(date, validationResult);
    } else {
      updateFieldValuesState({ fieldEditState: "viewing", isValid: true });
    }

    setIsEdit(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleValueOnLeave();
      return;
    }

    if (e.key === "Tab" && fieldInputId) {
      const nextElement = e.shiftKey ? findPreviousEntityInput(fieldInputId) : findNextEntityInput(fieldInputId);
      if (nextElement) {
        e.preventDefault();
        nextElement.click();
      }
      return;
    }
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    const openCalendar = document.querySelector(".MuiDateCalendar-root");
    const targetNode = event.target as Node;
    if (openCalendar && targetNode && openCalendar.contains(targetNode)) {
      return;
    }

    handleValueOnLeave();
  };

  return (
    <Stack direction="row">
      {isEdit && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <DatePicker
            autoFocus
            disabled={fieldAttributes.includes("ReadOnly")}
            value={date}
            onChange={handleDateChange}
            views={fieldConfiguration?.dateComponents?.map((component) => dateComponentToViewMap[component])}
            minDate={getMinDate(fieldConfiguration)}
            maxDate={getMaxDate(fieldConfiguration)}
            slotProps={{
              field: {
                clearable: true,
              },
              textField: {
                size: "small",
                error: !validationResult.isValid && validationResult.showValidation,
                helperText: validationResult.showValidation ? validationResult.error : "",
                InputProps: {
                  error: !validationResult.isValid && validationResult.showValidation,
                  sx: { width: 240 },
                },
                onKeyDown: handleKeyDown,
              },
              calendarHeader: {
                format: getCalendarHeaderFormat(fieldConfiguration),
              },
            }}
          />
        </ClickAwayListener>
      )}
      {!isEdit && (
        <FieldValueWrapper
          id={fieldInputId}
          isReadonly={fieldAttributes.includes("ReadOnly")}
          displayValue={formatDateFieldValue(date, fieldConfiguration)}
          isEdit={!validationResult.isValid}
          onEditChange={setIsEdit}
        />
      )}
    </Stack>
  );
};

export default EntityDateFieldValueComponent;
