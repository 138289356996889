import { Stack, Typography } from "@mui/material";
import { useParams } from "react-router";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";

const ObjectLayoutPageTitle = () => {
  const { configurationId } = useParams();
  const { objectDefinition, configurations } = useObjectDefinitionContext();

  const configurationName = configurations.find((c) => c.id === configurationId)?.name ?? "";

  return (
    <Stack py={2} spacing={0.5}>
      <Typography variant="h6">{objectDefinition.objectName}</Typography>
      {configurationName && (
        <Typography variant="caption" color="text.secondary">
          {configurationName} configuration
        </Typography>
      )}
    </Stack>
  );
};

export default ObjectLayoutPageTitle;
