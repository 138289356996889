import ReceiptIcon from "@mui/icons-material/ReceiptLongOutlined";
import SettingsIcon from "@mui/icons-material/SettingsRounded";
import { SvgIconProps } from "@mui/material";
import { EntriliaProduct } from "../../../../shared/api/types";
import CardAccountDetailsIcon from "../../../icons/CardAccountDetailsIcon";
import FundAccountingIcon from "../../../icons/FundAccountingIcon";
import PortfolioMonitoringIcon from "../../../icons/PortfolioMonitoringIcon";
import ReportingIcon from "../../../icons/ReportingIcon";

interface Props extends SvgIconProps {
  product: EntriliaProduct;
}

const EntriliaProductIcon = ({ product, ...iconProps }: Props) => {
  switch (product) {
    case "FundAccounting": {
      return <FundAccountingIcon {...iconProps} />;
    }
    case "InvestorRelationsManagement": {
      return <CardAccountDetailsIcon {...iconProps} />;
    }
    case "ReportingPortal": {
      return <ReportingIcon {...iconProps} />;
    }
    case "ExpenseManagement": {
      return <ReceiptIcon {...iconProps} />;
    }
    case "PortfolioMonitoring": {
      return <PortfolioMonitoringIcon {...iconProps} />;
    }
    case "CompanyManagement": {
      return <SettingsIcon {...iconProps} />;
    }
    default: {
      return null;
    }
  }
};

export default EntriliaProductIcon;
