import CheckIcon from "@mui/icons-material/CheckRounded";
import RemoveIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { Contact } from "../../../api/adminApi";
import { ObjectContact } from "../../../api/types/objectTypes";
import PortalRoleChip from "../../common/PortalRoleChip";

export interface ContactAccessMatrixRow extends ObjectContact {
  id: string;
  contactName: string;
  contactEmail: string;
}

export interface GridActions {
  onEditContacts: (contacts: ContactAccessMatrixRow[]) => void;
  onRemoveContacts: (contacts: ContactAccessMatrixRow[]) => void;
}

interface ActionsCellProps {
  row: ContactAccessMatrixRow;
  actions: GridActions;
  hasPermissionsToManage: boolean;
}

const ActionsCell = ({ row, actions, hasPermissionsToManage }: ActionsCellProps) => {
  if (!hasPermissionsToManage) {
    return null;
  }

  return (
    <>
      <Tooltip title="Edit" arrow>
        <IconButton onClick={() => actions.onEditContacts([row])}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Remove" arrow>
        <IconButton color="error" onClick={() => actions.onRemoveContacts([row])}>
          <RemoveIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const getContactAccessMatrixRows = (
  objectContacts: ObjectContact[],
  allContacts: Contact[]
): ContactAccessMatrixRow[] => {
  const allContactsMap = allContacts.reduce((result, contact) => {
    result.set(contact.id, contact);
    return result;
  }, new Map<string, Contact>());

  return objectContacts
    .map((objectContact) => {
      const contact = allContactsMap.get(objectContact.contactId);
      return {
        ...objectContact,
        id: objectContact.contactId,
        contactName: contact?.name || "Unknown",
        contactEmail: contact?.email || "",
      };
    })
    .sort((a, b) => a.contactName.localeCompare(b.contactName));
};

const getCategoryColumnDefinition = (category: string, isLast: boolean): GridColDef<ContactAccessMatrixRow> => ({
  field: category,
  headerName: category,
  minWidth: 160,
  flex: isLast ? 1 : undefined,
  renderCell: ({ row }) => (row.categories.includes(category) ? <CheckIcon color="primary" /> : null),
});

export const getColumnDefinitions = (
  categories: string[],
  actions: GridActions,
  hasPermissionsToManage: boolean
): GridColDef<ContactAccessMatrixRow>[] => [
  {
    field: "contactName",
    headerName: "Name",
    width: 320,
    renderCell: ({ row }) => (
      <Stack spacing={0.5} width="100%">
        <Typography variant="subtitle2">{row.contactName}</Typography>
        {row.contactEmail && (
          <Typography color="text.secondary" variant="caption">
            {row.contactEmail}
          </Typography>
        )}
      </Stack>
    ),
  },
  {
    field: "role",
    headerName: "Role",
    width: 160,
    renderCell: ({ row }) => <PortalRoleChip role={row.role ?? "None"} />,
  },
  ...categories.map((category, index) => getCategoryColumnDefinition(category, index === categories.length - 1)),
  {
    field: "actions",
    headerName: "",
    resizable: false,
    align: "right",
    width: 80,
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => (
      <ActionsCell row={row} actions={actions} hasPermissionsToManage={hasPermissionsToManage} />
    ),
  },
];
