import { Stack, Typography } from "@mui/material";
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef } from "@mui/x-data-grid-premium";
import StaticCircularProgress from "../../../../../../shared/components/StaticCircularProgress";
import { convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { formatDurationInSeconds } from "../../../../../../shared/utilities/formatters";
import { DocumentViews } from "../../../../../api/types/documentActivityTypes";
import ExpandCollapseGridDetailButton from "../../../../common/grid/ExpandCollapseGridDetailButton";

export const isDetailPanelAvailable = (row: DocumentViews) => Object.values(row.pageViews).some((val) => val > 0);

export const columnDefinitions: GridColDef<DocumentViews>[] = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: ({ row, value }) =>
      isDetailPanelAvailable(row) ? <ExpandCollapseGridDetailButton isExpanded={value} /> : null,
  },
  {
    field: "userInfo",
    headerName: "Contact",
    flex: 2,
    renderCell: ({ row }) => (
      <Stack spacing={0.5} width="100%">
        <Typography variant="subtitle2" noWrap>
          {row.userInfo.userName}
        </Typography>
        <Typography variant="caption" color="text.secondary" noWrap>
          {row.userInfo.userEmail}
        </Typography>
      </Stack>
    ),
  },
  {
    field: "sessionStart",
    headerName: "Viewed at",
    flex: 1.5,
    valueFormatter: (value: string) => (value ? convertISODateTime(value) : ""),
  },
  {
    field: "totalViewDuration",
    headerName: "View Duration",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: (value: number) => (value ? formatDurationInSeconds(value) : ""),
  },
  {
    field: "completionPercentage",
    headerName: "View Completion",
    type: "number",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: ({ row }) => {
      if (!row.completionPercentage) {
        return null;
      }

      return (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography>{row.completionPercentage + "%"}</Typography>
          <StaticCircularProgress size={20} value={row.completionPercentage} />
        </Stack>
      );
    },
  },
  {
    field: "downloadTimes",
    headerName: "Downloaded at",
    flex: 1.5,
    renderCell: ({ row }) => {
      const lastDownloadTime = row.downloadTimes[row.downloadTimes.length - 1];
      return lastDownloadTime ? convertISODateTime(lastDownloadTime) : null;
    },
  },
];
