import { CompanyInfo } from "./clientTypes";

export const allOrganizationRoles = ["Org_Owner", "Org_Member", "Org_ExternalUser"] as const;
export type OrganizationRole = (typeof allOrganizationRoles)[number];

export type MemberRole =
  | "AP_Viewer"
  | "AP_ExternalUser"
  | "AP_Admin"
  | "RP_Viewer"
  | "RP_Maker"
  | "RP_Admin"
  | "FundOps_Member"
  | "FundOps_Admin"
  | "FundOps_Viewer"
  | "Company_Settings_Viewer"
  | "Company_Settings_Admin"
  | "ExpenseMng_Member"
  | "ExpenseMng_Admin"
  | "Portfolio_Monitoring_Member"
  | "Portfolio_Monitoring_Admin";

export type AccessRole = OrganizationRole | MemberRole;

export const allClientUserTypes = ["External", "Internal", "Support"] as const;
export type ClientUserType = (typeof allClientUserTypes)[number];

export interface OrganizationMembersData {
  users: OrganizationMember[];
}

export interface OrganizationMember {
  userId: string;
  name: string;
  email: string;
  userType: ClientUserType;
  hasAccessToAllClients: boolean;
  organizationClientCode: string;
  organizationName: string;
  organizationPermissions: {
    roles: AccessRole[];
  };
  clientsAccess: ClientsAccess[];
  lastActivity?: string;
  firstAppearanceDate?: string;
  accessToDeveloperPortal: string[];
}

export interface ClientsAccess {
  clientCode: string;
  clientName: string;
  clientPermissions: {
    roles: MemberRole[];
    entitiesAccess?: string[];
    allowAccessToAllEntities: boolean;
  };
}

export interface OrganizationDeactivatedMembersData {
  deactivatedUsers: OrganizationDeactivatedMember[];
}

export interface OrganizationDeactivatedMember {
  userId: string;
  name: string;
  email: string;
  disabledAt: string;
}

export interface OrganizationMemberInvitation {
  invitationId: string;
  email: string;
  isGuest: boolean;
  organizationRole: OrganizationRole;
  invitedAt: string;
  invitationStatus: InvitationStatus;
}

export type InvitationStatus = "PendingApproval" | "Sent" | "Redeemed";

export interface OrganizationMembersInvitationData {
  invitations: OrganizationMemberInvitation[];
}

export interface CompanyChange {
  clientCode: string;
  roles: MemberRole[];
}

export interface UpdateOrganizationUserAccessRequest {
  newOrganizationRole?: OrganizationRole;
  accessChanges: CompanyChange[];
  entitiesAccess: Record<string, EntitiesAccessConfig>;
}

export interface EntitiesAccessConfig {
  allowAccessToAllEntities: boolean;
  allowedEntityIds: string[];
}

export interface CreateInvitationRequest {
  organizationRole: OrganizationRole;
  emails: string[];
  roles: ClientRoles[];
  entitiesAccess: Record<string, EntitiesAccessConfig>;
}

interface ClientRoles {
  clientCode: string;
  roles: MemberRole[];
}

export interface CompaniesResponse {
  companies: CompanyWithData[];
}

export interface CompanyWithData extends CompanyInfo {
  entities?: AccessLevelEntity[];
  availableApplications: AppCodes[];
}

export interface AccessLevelEntity {
  id: string;
  name: string;
  no?: string;
}

export type AppCodes =
  | "BC"
  | "InvestorPortal"
  | "DWH"
  | "ApiGateway"
  | "SF"
  | "Plaid"
  | "Yodlee"
  | "ExpenseManagement"
  | "PortfolioMonitoring";
