import RefreshIcon from "@mui/icons-material/CachedRounded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Box, Button, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import SearchField from "../../../../shared/components/inputs/SearchField";
import ExcelIcon from "../../../../shared/icons/fileFormats/ExcelIcon";
import ColumnSelector from "../columns/ColumnSelector";
import { useFilterContext } from "./FilterContext";
import FilterControl from "./FilterControl";
import Filters from "./Filters";

interface Props {
  recordCount: number;
  totalCount: number;
  isLoading?: boolean;
  onRefresh?: () => void;
  onExportToCsv?: () => void;
  onImportFromCsv?: () => void;
  hasColumnSelection?: boolean;
  withoutSearch?: boolean;
}

const FiltersPanel = ({
  recordCount,
  totalCount,
  isLoading,
  onRefresh,
  onExportToCsv,
  onImportFromCsv,
  hasColumnSelection,
  withoutSearch,
  children,
}: PropsWithChildren<Props>) => {
  const { filterState, dispatchFilters } = useFilterContext();

  const handleSearch = (searchTerm: string) => dispatchFilters({ type: "search", searchTerm });

  return (
    <Stack width="100%" spacing={2}>
      <Box width="100%" display="flex" justifyContent="space-between">
        <FilterControl recordCount={recordCount} totalCount={totalCount} hideRecordCount={isLoading} />
        <Stack direction="row" spacing={1} alignItems="center">
          {!withoutSearch && (
            <SearchField initialValue={filterState.search.value} debounceTimeMs={300} onSearch={handleSearch} />
          )}
          {onRefresh && (
            <Button
              variant="outlined"
              color="secondary"
              disabled={!filterState.initialized || isLoading}
              startIcon={<RefreshIcon />}
              onClick={onRefresh}
            >
              Refresh
            </Button>
          )}
          {onExportToCsv && (
            <Button
              variant="outlined"
              color="secondary"
              disabled={!filterState.initialized || isLoading || totalCount === 0}
              startIcon={<ExitToAppIcon />}
              sx={{ minWidth: "auto", whiteSpace: "nowrap" }}
              onClick={onExportToCsv}
            >
              Export to CSV
            </Button>
          )}
          {onImportFromCsv && (
            <Button
              variant="outlined"
              color="secondary"
              disabled={!filterState.initialized || isLoading}
              startIcon={<ExcelIcon />}
              sx={{ minWidth: "auto", whiteSpace: "nowrap" }}
              onClick={onImportFromCsv}
            >
              Import from CSV
            </Button>
          )}
          {hasColumnSelection && <ColumnSelector disabled={isLoading} />}
          {children}
        </Stack>
      </Box>
      <Box width="100%" display={filterState.showFilters ? "flex" : "none"} justifyContent="space-between">
        <Filters isLoading={isLoading} />
      </Box>
    </Stack>
  );
};

export default FiltersPanel;
