import { GRID_TREE_DATA_GROUPING_FIELD, GridEventListener, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useCallback, useEffect, useMemo } from "react";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import {
  getVisibleRowIds,
  selectNexRow,
  selectPreviousRow,
} from "../../../../../../shared/utilities/dataGridApiHelper";
import { DocumentInfo } from "../../../../../api/types/documentCollectionTypes";
import { useGridGroupsExpanded } from "../../../../common/grid/gridHooks";
import { fundLevelColumns, fundLevelGroupingColumn, getFundLevelRows } from "./documentsTableDataProvider";

interface Props {
  apiRef: React.MutableRefObject<GridApiPremium>;
  documents: DocumentInfo[];
  showPublishingStatus: boolean;
  onSelectionChange: (selection: GridRowSelectionModel) => void;
}

const DocumentsTableFundLevel = ({ documents, showPublishingStatus, onSelectionChange, apiRef }: Props) => {
  const { isGroupExpanded } = useGridGroupsExpanded(apiRef);

  const rows = useMemo(() => getFundLevelRows(documents), [documents]);

  useEffect(() => {
    const firstRowId = getVisibleRowIds(apiRef)[0];
    if (firstRowId) {
      setTimeout(() => {
        apiRef.current.setRowSelectionModel([firstRowId]);
        apiRef.current.setCellFocus(firstRowId, GRID_TREE_DATA_GROUPING_FIELD);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowSelectionChange", (newSelection) => {
      onSelectionChange(newSelection);
    });
  }, [apiRef, onSelectionChange]);

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = useCallback(
    (_, e) => {
      if (e.code !== "ArrowUp" && e.code !== "ArrowDown") {
        return;
      }

      if (e.code === "ArrowDown") {
        selectNexRow(apiRef);
      } else if (e.code === "ArrowUp") {
        selectPreviousRow(apiRef);
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [apiRef]
  );

  return (
    <DataGrid
      apiRef={apiRef}
      treeData
      getTreeDataPath={(row) => [row.group, row.investor]}
      groupingColDef={fundLevelGroupingColumn}
      columns={fundLevelColumns}
      rows={rows}
      onCellKeyDown={handleCellKeyDown}
      isGroupExpandedByDefault={isGroupExpanded}
      disableRowSelectionOnClick={false}
      disableMultipleRowSelection
      disableColumnSorting
      columnVisibilityModel={{
        publishingStatus: showPublishingStatus,
      }}
      sx={{
        ".MuiDataGrid-row": {
          "&:hover": {
            cursor: "pointer",
          },
        },
      }}
    />
  );
};

export default DocumentsTableFundLevel;
