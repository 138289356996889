import { TextField } from "@mui/material";

interface Props {
  value: string;
  onChange: (newValue: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const MetricTextField = ({ value, onChange, readOnly, disabled }: Props) => {
  return (
    <TextField
      fullWidth
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
    />
  );
};

export default MetricTextField;
