import { generatePath, useMatch } from "react-router";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

const CompanySmartViewsMenu = () => {
  const { clientCode } = useClientContext();
  const routePattern = `/:clientCode/${pageRoutes.smartViews}/:page/*`;
  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  return (
    <SubMenuList>
      <MenuLinkItem
        subItem
        active={currentPage === pageRoutes.companyReports}
        title="Reports"
        href={buildFullPath(pageRoutes.companyReports)}
      />
      <MenuLinkItem
        subItem
        active={currentPage === pageRoutes.apiReports}
        title="Analytics API"
        href={buildFullPath(pageRoutes.apiReports)}
      />
    </SubMenuList>
  );
};

export default CompanySmartViewsMenu;
