import { useCallback, useState } from "react";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { getCheckedGridSx } from "../../../../../common/grid/gridStyles";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import {
  columnDefinitions,
  gridColumnVisibilityModel,
  gridRowGroupingModel,
  groupingColumnDefinition,
} from "./fundraisingDocumentsGridDataProvider";
import FundraisingDocumentsGridToolbar from "./FundraisingDocumentsGridToolbar";
import { FundraisingDocument } from "./fundraisingDocumentsState";

const FundraisingDocumentsGrid = () => {
  const { filteredDocuments, documentsState } = useFundraisingDocumentsContext();
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const isGroupExpanded = useCallback(() => true, []);

  const isRowSelectable = useCallback(
    ({ row }: { row: FundraisingDocument }) =>
      !!row.fileId &&
      (row.uploadStatus === undefined || row.uploadStatus === "error" || row.uploadStatus === "ready_for_upload"),
    []
  );

  const showToolbar = selectedRowIds.length > 0;

  return (
    <DataGrid<FundraisingDocument>
      getRowId={(row) => row.fileId}
      rows={filteredDocuments}
      columns={columnDefinitions}
      groupingColDef={groupingColumnDefinition}
      rowGroupingModel={gridRowGroupingModel}
      columnVisibilityModel={gridColumnVisibilityModel}
      sx={(theme) => getCheckedGridSx(theme, showToolbar)}
      noRowsText="No files"
      slots={{
        toolbar: () =>
          showToolbar ? (
            <FundraisingDocumentsGridToolbar
              selectedIds={selectedRowIds}
              setSelectedIds={setSelectedRowIds}
              disabled={documentsState.isSaving}
            />
          ) : null,
      }}
      checkboxSelection
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
      isGroupExpandedByDefault={isGroupExpanded}
      isRowSelectable={isRowSelectable}
      disableColumnPinning
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default FundraisingDocumentsGrid;
