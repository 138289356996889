import { Box, Container, Typography } from "@mui/material";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";
import { getColumnDefinitions } from "./objectLayoutsGridDataProvider";

const ObjectLayouts = () => {
  const { configurations } = useObjectDefinitionContext();

  return (
    <Container maxWidth="md" disableGutters sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2.5}>
        <Typography color="text.secondary">Customize layouts for different companies in the organization.</Typography>
      </Box>
      <DataGrid<DataModelConfiguration>
        columns={getColumnDefinitions()}
        rows={configurations}
        noRowsText="No configurations yet"
        disableColumnReorder
        disableMultipleRowSelection
      />
    </Container>
  );
};

export default ObjectLayouts;
