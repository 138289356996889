import { Button, Grid2, Typography } from "@mui/material";
import HorizontalFill from "../../../../../../../shared/components/HorizontalFill";
import { useLocalization } from "../../../../../../hooks/useLocalization";

export function Failed({ onRetry }: { onRetry: () => void }) {
  const { reports_copyto: locale } = useLocalization();

  return (
    <Grid2 container sx={{ gap: 0.8, flexWrap: "nowrap", alignItems: "center", width: "100%" }}>
      <Typography variant="subtitle2" color="error">
        {locale.validation_error}
      </Typography>
      <HorizontalFill />
      <Button
        color="error"
        onClick={(evt) => {
          evt.stopPropagation();
          onRetry();
        }}
      >
        Try Again
      </Button>
    </Grid2>
  );
}
