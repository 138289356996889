import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { Category } from "../../../../../api/types/accessTypes";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import { DetailsPageTab, FundraisingStatusTransition } from "../fundraisingsPageTypes";

interface Props {
  fundraising: Fundraising;
  hasEditPermissions: boolean;
  isContentEditable: boolean;
  fundraisingCategories: Category[];
  isPageDeactivatedOnPortal: boolean;
  onRename: () => void;
  onStatusChange: (statusTransition: FundraisingStatusTransition) => void;
  onConfirmSave: (onSaveConfirmed: () => void) => void;
  onDelete: () => void;
  onTabChange: (tab: DetailsPageTab) => void;
  onUpdated: (fundraising: Fundraising) => void;
  refreshCategories: () => void;
  refreshFundraising: () => void;
}

const FundraisingDetailsPageContext = createContext<Props | undefined>(undefined);

export const FundraisingDetailsPageContextProvider = ({
  fundraising,
  hasEditPermissions,
  isContentEditable,
  fundraisingCategories,
  isPageDeactivatedOnPortal,
  onRename,
  onStatusChange,
  onConfirmSave,
  onDelete,
  onTabChange,
  onUpdated,
  refreshCategories,
  refreshFundraising,
  children,
}: PropsWithChildren<Props>) => (
  <FundraisingDetailsPageContext.Provider
    value={{
      fundraising,
      hasEditPermissions,
      isContentEditable,
      fundraisingCategories,
      isPageDeactivatedOnPortal,
      onRename,
      onStatusChange,
      onConfirmSave,
      onDelete,
      onTabChange,
      onUpdated,
      refreshCategories,
      refreshFundraising,
    }}
  >
    {children}
  </FundraisingDetailsPageContext.Provider>
);

export const useFundraisingDetailsPageContext = () =>
  defined(useContext(FundraisingDetailsPageContext), "Attempt to use FundraisingDetailsPageContext without provider");
