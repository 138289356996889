import { EntityOptionFieldType } from "../../../../shared/api/dictionaryTypes";
import { logError } from "../../../../shared/logging";
import { useClientContext } from "../../../context/ClientContext";
import { getSelectOptionsFromDictionary } from "../../../utilities/dictionariesHelper";
import { EntityFieldComponentProps, EntityFieldComponentPropsBase } from "../entityFieldTypes";
import {
  fieldsEditSettings,
  getInvalidFieldValueError,
  getMultiselectOptionsFromValue,
  isStringArray,
} from "../helpers";
import EntityFieldMultiSelectComponent from "./EntityFieldMultiSelectComponent";

const EntityMultiselectField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  if (!isStringArray(value) && typeof value !== "string" && value !== undefined && value !== null) {
    logError(getInvalidFieldValueError(fieldId, fieldConfiguration?.$type, value), "[EntityMultiselectField]");
    return null;
  }

  if (fieldConfiguration?.$type !== "Select") {
    const error = `Invalid configuration for Multi-Select field: ${fieldConfiguration?.$type}`;
    logError(error, "[EntitySelectField]");
    return null;
  }

  return (
    <EntityFieldMultiSelectForTypeComponent
      value={getMultiselectOptionsFromValue(value)}
      fieldId={fieldId}
      fieldConfiguration={fieldConfiguration}
      fieldAttributes={fieldAttributes}
      fieldType={fieldConfiguration.fieldType}
      fieldInputId={fieldInputId}
      onChange={(values, entityField, validationResult) =>
        onChange(
          values ? values.join(fieldsEditSettings.multiselectItemsSeparator) : undefined,
          entityField,
          validationResult
        )
      }
    />
  );
};

interface EntityFieldMultiSelectForTypeComponentProps extends EntityFieldComponentProps<string[]> {
  fieldType: EntityOptionFieldType;
}

const EntityFieldMultiSelectForTypeComponent = ({
  value: values,
  fieldId,
  fieldAttributes,
  fieldType,
  onChange,
  fieldInputId,
}: EntityFieldMultiSelectForTypeComponentProps) => {
  const { dictionaries } = useClientContext();
  const options = getSelectOptionsFromDictionary(fieldType, dictionaries);
  return (
    <EntityFieldMultiSelectComponent
      values={values}
      options={options}
      fieldAttributes={fieldAttributes}
      fieldInputId={fieldInputId}
      onChange={(newValues, validationResult) =>
        onChange(newValues.length > 0 ? newValues : undefined, fieldId, validationResult)
      }
    />
  );
};

export default EntityMultiselectField;
