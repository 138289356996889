import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { SentEmail } from "../../../../api/types/emailsTypes";
import { columnDefinitions } from "./emailDeliveryLogGridDataProvider";

interface Props {
  apiRef?: React.MutableRefObject<GridApiPremium>;
  isLoading?: boolean;
  emails: SentEmail[];
  onRowScrollEnd?: () => void;
}

const EmailDeliveryLogGrid = ({ apiRef, isLoading, emails, onRowScrollEnd }: Props) => {
  return (
    <DataGrid<SentEmail>
      apiRef={apiRef}
      loading={isLoading}
      columns={columnDefinitions}
      rows={emails}
      multilineRows
      noRowsText="No emails yet"
      disableColumnSorting
      disableColumnReorder
      onRowsScrollEnd={onRowScrollEnd}
    />
  );
};

export default EmailDeliveryLogGrid;
