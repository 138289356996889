import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import {
  Box,
  Button,
  DialogActions,
  Divider,
  Grid2,
  LinearProgress,
  Link,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { useCallback, useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import DialogHeader from "../../../../../../shared/components/dialog/DialogHeader";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { useUserContext } from "../../../../../context/UserContext";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { getViewReportUrl } from "../utilities/editReportUrl";
import CancelPromptDialog from "./CancelPromptDialog";
import { CompanyMultipleReportsListItem } from "./CompanyMultipleReportsListItem";
import { ValidationInfoItem, ValidationItemContainer } from "./CompanyMultipleReportsListItemContainer";
import { CompanySingleReportListItem } from "./CompanySingleReportListItem";
import { InProgressIcon } from "./controls/InProgressIcon";
import { ReportInProgress } from "./controls/ReportInProgress";
import { ResultButtongroup, TabNames } from "./controls/ResultButtongroup";
import { CopyCompany, CopyCompanyReport } from "./hooks/CopyCompany";
import { SelectedCompany } from "./hooks/SelectedCompany";
import useReportCopying from "./hooks/useReportCopying";

interface Props {
  reports: ReportInfo[];
  selectedCompanies: SelectedCompany[];
  copyConditionValues: boolean;
  override: boolean;
  onClose: (success?: boolean) => void;
}
export function CopyToDialogCopying({ reports, selectedCompanies, copyConditionValues, override, onClose }: Props) {
  const { reports_copyto: locale } = useLocalization();
  const { clients } = useUserContext();
  const { copying, groupsCreating, retryCopy, cancel } = useReportCopying(
    selectedCompanies,
    copyConditionValues,
    override
  );
  const [selectedTab, setSelectedTab] = useState<TabNames>("all");
  const [showPrompt, setShowPrompt] = useState(false);

  const allCopyingReports = useMemo(() => copying.flatMap((c) => c.reports), [copying]);
  const isMultipleReports = useMemo(() => reports.length > 1, [reports]);

  const companyList = useMemo(() => {
    return clients
      .filter((c) => c.type === "FundManager")
      .map(
        (c): ListItemData => ({
          value: c.clientCode,
          label: c.title,
          iconSrc: c.branding.logoMarkUrl,
        })
      );
  }, [clients]);

  const copiedToAllCompanies = useMemo(() => allCopyingReports.every((r) => r.copied || r.error), [allCopyingReports]);
  const copiedWithErrors = useMemo(() => allCopyingReports.some((r) => r.error), [allCopyingReports]);
  const copiedCount = useMemo(() => allCopyingReports.filter((r) => r.copied).length, [allCopyingReports]);
  const progressValue = useMemo(() => {
    const value = (copiedCount / allCopyingReports.length) * 100;
    return Number.isNaN(value) ? 0 : value;
  }, [allCopyingReports.length, copiedCount]);

  const filteredCompanyList = useMemo(() => {
    switch (selectedTab) {
      case "all":
        return copying;
      case "copied":
        return copying
          .filter((c) => c.reports.some((r) => r.copied))
          .map((c) => ({ ...c, reports: c.reports.filter((r) => r.copied) }));
      case "failed":
        return copying
          .filter((c) => c.reports.some((r) => r.error))
          .map((c) => ({ ...c, reports: c.reports.filter((r) => r.error) }));
    }
  }, [copying, selectedTab]);

  const handleClosing = useCallback(() => {
    if (copiedToAllCompanies) {
      onClose(copiedToAllCompanies);
    } else {
      setShowPrompt(true);
    }
  }, [copiedToAllCompanies, onClose]);

  if (groupsCreating) {
    return <InlineLoader text={locale.creating_groups} />;
  }

  return (
    <>
      <DialogHeader text={`Copy Report${reports.length > 1 ? "s" : ""}`} onClose={handleClosing} />
      <Divider />
      <Grid2 container sx={{ flex: 1, flexDirection: "column", width: "100%" }}>
        <Grid2 container sx={{ flex: 1, justifyContent: "center", p: 2, width: "100%" }}>
          <Grid2 container sx={{ gap: 1, width: "55%" }}>
            <Grid2 container sx={{ flexDirection: "column", width: "100%" }}>
              <Typography color="secondary">{locale.do_not_close_window}</Typography>
              <Stack direction="row" alignItems="center" spacing={1} pt={0.5}>
                <BorderLinearProgress variant="determinate" value={progressValue} sx={{ flex: 1 }} />
                <Typography color="secondary">
                  {copiedCount}/{allCopyingReports.length}
                </Typography>
              </Stack>
              <Stack direction="row" gap={1} height={20}>
                {copiedToAllCompanies && (
                  <Typography color="secondary">
                    {copiedWithErrors ? locale.copied_with_errors : locale.copied_successfully}
                  </Typography>
                )}
              </Stack>
              <Grid2 pt={2.5} width="50%">
                <ResultButtongroup copying={copying} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
              </Grid2>
              <ScrollableFlexContainer>
                {!isMultipleReports && (
                  <SingleReportCopying
                    companies={filteredCompanyList}
                    companyList={companyList}
                    retryCopy={retryCopy}
                  />
                )}
                {isMultipleReports && (
                  <MultipleReportsCopying
                    companies={filteredCompanyList}
                    companyList={companyList}
                    retryCopy={retryCopy}
                  />
                )}
              </ScrollableFlexContainer>
            </Grid2>
          </Grid2>
        </Grid2>
        <Divider />
        <DialogActions sx={{ px: 2, py: 1.5 }}>
          <Button variant="contained" onClick={handleClosing}>
            Close
          </Button>
        </DialogActions>
      </Grid2>
      {showPrompt && (
        <CancelPromptDialog
          reports={reports}
          onOkay={() => {
            cancel();
            onClose();
          }}
          onCancel={() => setShowPrompt(false)}
        />
      )}
    </>
  );
}

interface SingleReportCopyingProps {
  companies: CopyCompany[];
  companyList: ListItemData[];
  retryCopy: (report: CopyCompanyReport) => void;
}

function SingleReportCopying({ companies, companyList, retryCopy }: SingleReportCopyingProps) {
  const { reports_copyto: locale } = useLocalization();
  return (
    <Stack sx={{ gap: 1, pt: 2, pb: 1 }}>
      {companies.map((company, index) => {
        const report = defined(company.reports.at(0));
        const branding = companyList.find((c) => c.value === company.company.code);

        const getAction = () => {
          if (report.copied) {
            return <OpenReport companyReport={report} />;
          }
          if (report.error) {
            return <TryCopyAgain onRetry={() => retryCopy(report)} />;
          }
          return undefined;
        };

        return (
          <CompanySingleReportListItem
            key={company.company.code + index}
            company={company.company}
            companyBranding={branding}
            inProgress={report.copying || (!report.copied && !report.error)}
            Action={getAction()}
          >
            {report.copying && <ReportInProgress text={locale.copying} />}
            {!report.copying && !report.copied && !report.error && <CopyWaiting />}
            {report.error && <CopyError />}
            {report.copied && <Copied />}
          </CompanySingleReportListItem>
        );
      })}
    </Stack>
  );
}
function MultipleReportsCopying({ companies, companyList, retryCopy }: SingleReportCopyingProps) {
  const { reports_copyto: locale } = useLocalization();

  const getAction = useCallback(
    (report: CopyCompanyReport) => {
      if (report.copied) {
        return <OpenReport sx={{ top: 15 }} companyReport={report} />;
      }
      if (report.error) {
        return <TryCopyAgain sx={{ top: 20 }} onRetry={() => retryCopy(report)} />;
      }
      return undefined;
    },
    [retryCopy]
  );

  return (
    <Stack sx={{ gap: 1, pt: 2, pb: 1 }}>
      {companies.map((company, index) => {
        const branding = companyList.find((c) => c.value === company.company.code);
        const isCopying = company.reports.some((r) => r.copying);

        return (
          <CompanyMultipleReportsListItem
            key={company.company.code + index}
            company={company.company}
            companyBranding={branding}
            Summary={isCopying ? <CopyingInfo company={company} /> : <CopiedInfo company={company} />}
          >
            {company.reports.map((report) => (
              <ValidationItemContainer
                key={report.report.reportId}
                report={report.report}
                completed={report.copied}
                Action={getAction(report)}
              >
                {report.copying && <ReportInProgress text={locale.copying} />}
                {!report.copying && !report.copied && !report.error && <CopyWaiting />}
                {report.error && <CopyError />}
                {report.copied && <Copied />}
              </ValidationItemContainer>
            ))}
          </CompanyMultipleReportsListItem>
        );
      })}
    </Stack>
  );
}

function CopyWaiting() {
  const { reports_copyto: locale } = useLocalization();
  return <Typography color="secondary">{locale.waiting_to_be_copied}</Typography>;
}

function CopyError() {
  const { reports_copyto: locale } = useLocalization();
  return <Typography color="error">{locale.copying_error}</Typography>;
}

function Copied() {
  const { reports_copyto: locale } = useLocalization();

  return (
    <Stack flexGrow={1} direction="row" alignItems="center" gap={0.5}>
      <CheckRoundedIcon color="success" />
      <Typography sx={{ color: "success.main" }}>{locale.report_copied}</Typography>
    </Stack>
  );
}
function OpenReport({ companyReport, sx }: { companyReport: CopyCompanyReport; sx?: SxProps<Theme> }) {
  const link = useMemo(() => {
    return getViewReportUrl(companyReport.toCompanyCode, companyReport.newReportId || "");
  }, [companyReport.toCompanyCode, companyReport.newReportId]);

  return (
    <Box position={"relative"}>
      <Link
        href={link}
        target="_blank"
        sx={{
          whiteSpace: "nowrap",
          textDecoration: "none",
          position: "absolute",
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(1.2),
          ...sx,
        }}
      >
        <Typography variant="subtitle2">Open Report</Typography>
      </Link>
    </Box>
  );
}

function TryCopyAgain({ onRetry, sx }: { onRetry?: () => void; sx?: SxProps<Theme> }) {
  return (
    <Box position={"relative"}>
      <Button
        color="error"
        sx={{
          whiteSpace: "nowrap",
          position: "absolute",
          right: (theme) => theme.spacing(1),
          top: (theme) => theme.spacing(0.5),
          ...sx,
        }}
        onClick={onRetry}
      >
        Try Again
      </Button>
    </Box>
  );
}

function CopyingInfo({ company }: { company: CopyCompany }) {
  const validatedCount = useMemo(() => {
    return company.reports.filter((r) => r.copied || r.error).length;
  }, [company.reports]);

  return (
    <Stack direction={"row"} spacing={1}>
      <Typography color={"secondary"}>
        {validatedCount}/{company.reports.length}
      </Typography>
      <InProgressIcon />
    </Stack>
  );
}

function CopiedInfo({ company }: { company: CopyCompany }) {
  const failedCount = useMemo(() => {
    return company.reports.filter((r) => r.error).length;
  }, [company.reports]);

  const successCount = useMemo(() => {
    return company.reports.filter((r) => r.copied && !r.error).length;
  }, [company.reports]);

  return (
    <Stack direction="row" spacing={1.5}>
      <ValidationInfoItem count={failedCount} Icon={ErrorRoundedIcon} color="error" />
      <ValidationInfoItem count={successCount} Icon={CheckRoundedIcon} color="success" />
    </Stack>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 4,
  },
}));
