import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { useCallback, useReducer, useState } from "react";
import { useNavigate } from "react-router";
import { createApiResponse } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi, { Investor, InvestorWithFieldValues } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import { createReducer, getEmptyState, getVisibleColumnsAndFiltersIds } from "../../../common/filters/filterState";
import {
  getColumnDefinitionForField,
  getFilterDefinitionForField,
} from "../../../entityFields/entityFieldFilterDefinitions";
import DeleteInvestorDialog from "./DeleteInvestorDialog";
import InvestorCreateDialog from "./InvestorCreateDialog";
import InvestorsGrid from "./InvestorsGrid";
import { getPredefinedFilterDefinitions, getSearchDefinition } from "./filterDefinitions";
import { getPredefinedColumnDefinitions } from "./investorsGridDefinitions";

interface DialogState {
  investorToDelete?: Investor;
  openDialog: "create_investor" | "delete_investor" | undefined;
}

const FundStructureInvestorsPage = () => {
  const navigate = useNavigate();
  const { hasAnyPermission, clientCode, dictionaries } = useClientContext();

  const [dialogState, setDialogState] = useState<DialogState>({ openDialog: undefined });

  const [filterState, dispatchFilters] = useReducer(
    createReducer<InvestorWithFieldValues>(),
    getEmptyState(`${clientCode}_investors_filter_v2`)
  );

  const getInvestors = useCallback(async () => {
    if (!filterState.initialized) {
      return createApiResponse({ items: [] });
    }

    const fieldIds = getVisibleColumnsAndFiltersIds(filterState);
    return adminApi.searchInvestors({ fieldIds, includeContacts: true, includeFunds: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.initialized, filterState.visibleColumns, filterState.visibleFilters]);

  const getInvestorObjectDefinition = useCallback(() => adminApi.getObjectDefinition("Investor"), []);

  const [objectDefinitionWithPermissions, fetchDefinitionError, { isFetching: isFetchingObjectDefinition }] = useFetch(
    getInvestorObjectDefinition,
    ({ objectClassDefinition }) => {
      const predefinedFilterDefinitions = getPredefinedFilterDefinitions();
      const fieldFilterDefinitions = objectClassDefinition.fields.map((field) =>
        getFilterDefinitionForField(field, dictionaries)
      );

      const predefinedColumnDefinitions = getPredefinedColumnDefinitions();
      const fieldColumnDefinitions = objectClassDefinition.fields.map((field) => getColumnDefinitionForField(field));

      dispatchFilters({
        type: "init",
        searchDefinition: getSearchDefinition(),
        filterDefinitions: [...predefinedFilterDefinitions, ...fieldFilterDefinitions],
        columnDefinitions: [...predefinedColumnDefinitions, ...fieldColumnDefinitions],
      });
    }
  );

  const [investorsResp, fetchInvestorsError, { isFetching: isFetchingInvestors, setData: setInvestorsResp }] =
    useFetch(getInvestors);

  const handleNavigateToInvestor = useCallback((investorId: string) => navigate(`./${investorId}/main`), [navigate]);

  if (fetchDefinitionError || fetchInvestorsError) {
    logError(fetchDefinitionError || fetchInvestorsError, "[FundStructureInvestorsPage]");
    return <DataLoadingFailed title="Failed to load investors" />;
  }

  const { objectClassDefinition, objectPermissions } = objectDefinitionWithPermissions ?? {};
  const fields = objectClassDefinition?.fields ?? [];
  const investors = investorsResp?.items ?? [];
  const hasAccessToManage = hasAnyPermission(objectPermissions?.objectDataWritePermissions ?? []);

  const handleDeleteInvestor = (investor: Investor) => {
    setDialogState({ openDialog: "delete_investor", investorToDelete: investor });
  };

  const handleInvestorDeleted = (investorId: string) => {
    setDialogState({ openDialog: undefined });

    if (investors) {
      setInvestorsResp({ items: investors.filter(({ id }) => id !== investorId) });
    }
  };

  const isLoading = isFetchingObjectDefinition || isFetchingInvestors || investorsResp === undefined;

  return (
    <>
      <GeneralPageHeader title="Investors">
        {hasAccessToManage && (
          <Button
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => setDialogState({ openDialog: "create_investor" })}
            variant="contained"
            startIcon={<AddIcon />}
            disabled={isLoading}
          >
            New Investor
          </Button>
        )}
      </GeneralPageHeader>
      <FilterContextProvider
        filterState={filterState}
        dispatchFilters={dispatchFilters}
        allRowsForSelectOptions={investors}
      >
        <InvestorsGrid
          investors={investors}
          fields={fields}
          isLoading={isLoading}
          onDeleteInvestor={handleDeleteInvestor}
          onNavigateToInvestorDetails={handleNavigateToInvestor}
        />
      </FilterContextProvider>
      <InvestorCreateDialog
        open={dialogState.openDialog === "create_investor"}
        onClose={() => setDialogState({ openDialog: undefined })}
        investors={investors}
        onCreated={handleNavigateToInvestor}
      />
      {dialogState.investorToDelete && (
        <DeleteInvestorDialog
          open={dialogState.openDialog === "delete_investor"}
          onClose={() => setDialogState({ openDialog: undefined })}
          onDeleted={handleInvestorDeleted}
          investor={dialogState.investorToDelete}
        />
      )}
    </>
  );
};

export default FundStructureInvestorsPage;
