import { Typography, useTheme } from "@mui/material";

interface Props {
  text: string;
  color: string;
  textColor?: string;
  fontWeight?: number;
  withBorder?: boolean;
}

const ColorTag = ({ text, color, textColor, fontWeight, withBorder }: Props) => {
  const theme = useTheme();
  const resolvedTextColor = textColor || theme.palette.text.primary;

  return (
    <Typography
      variant="caption"
      sx={{
        borderRadius: "1rem",
        px: "0.5rem",
        lineHeight: "1.25rem",
        textAlign: "center",
        width: "max-content",
        display: "inline-block",
        border: withBorder ? `1px solid ${resolvedTextColor}` : undefined,
      }}
      style={{
        color: resolvedTextColor,
        backgroundColor: color,
        fontWeight,
      }}
    >
      {text}
    </Typography>
  );
};

export default ColorTag;
