import { SxProps, Theme, useTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";

const useMergedSx = (...sxArray: (SxProps<Theme> | undefined)[]) => {
  const theme = useTheme();
  return getMergedSx(theme, ...sxArray);
};

export const getMergedSx = (theme: Theme, ...sxArray: (SxProps<Theme> | undefined)[]) => {
  const resolvedSxArray = sxArray.map((sx) => (typeof sx === "function" ? sx(theme) : sx));

  return resolvedSxArray.reduce((acc, sx) => deepmerge(acc, sx || {}, { clone: true }), {});
};

export default useMergedSx;
