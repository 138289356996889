import { Stack, Typography } from "@mui/material";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { Category } from "../../../../api/types/accessTypes";
import { UserConsentDetails } from "../../../../api/types/userConsentTypes";
import UserConsentCategoryTag from "./UserConsentCategoryTag";

interface Props {
  consentDetails: UserConsentDetails;
  categories: Category[];
}

const UserConsentDetailsPageTitle = ({ consentDetails, categories }: Props) => {
  return (
    <Stack spacing={0.5} pb={1.5} pt={1}>
      <Stack direction="row" spacing={1} alignItems="baseline">
        <Typography variant="h6">{consentDetails.name}</Typography>
        <UserConsentCategoryTag categoryId={consentDetails.categoryId} categories={categories} />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="caption" color="text.secondary">
          User Consent
        </Typography>
        <Typography variant="caption" color="text.secondary">
          •
        </Typography>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="caption" color="text.secondary">
            Modified at:
          </Typography>
          <Typography variant="caption">{convertISODateTime(consentDetails.lastModified)}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserConsentDetailsPageTitle;
