import { LoadingButton } from "@mui/lab";
import { Button, Container, Divider, Stack } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import EmailTemplateEditor, { EmailTemplateSettings } from "../../../common/email-templates/EmailTemplateEditor";
import AutomaticInvitesSection from "./AutomaticInvitesSection";
import SendTestInvitationEmail from "./SendTestInvitationEmail";

const snippets = [
  { value: "{{FundName}}", displayName: "Fund Name" },
  { value: "{{InvestorName}}", displayName: "Investor Name" },
  { value: "{{RecipientName}}", displayName: "Contact Name" },
];

interface SettingsForm {
  enableAutomaticInvites: boolean;
  subject: string;
  body: string;
}

const updateInvestorPortalSettings = withErrorHandling(adminApi.updateInvestorPortalSettings);

const InvitationEmailSection = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [currentSettings, setCurrentSettings] = useState<SettingsForm>();
  const [isSaving, setSaving] = useState(false);

  const [savedSettings, fetchError, { isFetching, setData: setSavedSettings }] = useFetch(
    adminApi.getInvestorPortalSettings,
    (data) => {
      setCurrentSettings({
        enableAutomaticInvites: data.settings.enableAutomaticInvites,
        subject: data.settings.contactInvitationEmailSubject,
        body: data.settings.contactInvitationEmailMessageText,
      });
    }
  );

  const { hasPermissions } = useClientContext();

  if (fetchError) {
    logError(fetchError, "getInvestorPortalSettings");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  if (isFetching || !currentSettings) {
    return <InlineLoader />;
  }

  const handleSave = async () => {
    const enableAutomaticInvites = currentSettings.enableAutomaticInvites;
    const contactInvitationEmailSubject = currentSettings.subject.trim();
    const contactInvitationEmailMessageText = currentSettings.body.trim();

    setSaving(true);

    const [resp, error] = await updateInvestorPortalSettings({
      enableAutomaticInvites,
      contactInvitationEmailSubject,
      contactInvitationEmailMessageText,
    });

    setSaving(false);

    if (error) {
      logError(error, "[InvitationEmailEditor]");
      sendNotificationError("Could not save invitation email settings");
      return;
    }

    sendNotification("Invitation email settings saved");
    setSavedSettings(resp);
  };

  const handleReset = () => {
    if (savedSettings) {
      setCurrentSettings({
        enableAutomaticInvites: savedSettings.settings.enableAutomaticInvites,
        subject: savedSettings.settings.contactInvitationEmailSubject,
        body: savedSettings.settings.contactInvitationEmailMessageText,
      });
    }
  };

  const handleEmailSettingsChange = (update: Partial<EmailTemplateSettings>) =>
    setCurrentSettings((prev) =>
      prev
        ? {
            ...prev,
            ...update,
          }
        : undefined
    );

  const handleAutomaticInvitesChange = (checked: boolean) => {
    setCurrentSettings((prev) =>
      prev
        ? {
            ...prev,
            enableAutomaticInvites: checked,
          }
        : undefined
    );
  };

  const hasPermissionsToEdit = hasPermissions(["ManageInvestorPortalSettings"]);

  const isDirty =
    !!savedSettings &&
    !deepEqual(
      {
        enableAutomaticInvites: savedSettings.settings.enableAutomaticInvites,
        subject: savedSettings.settings.contactInvitationEmailSubject,
        body: savedSettings.settings.contactInvitationEmailMessageText,
      },
      currentSettings
    );

  const validationErrors = {
    subject: isDirty && !currentSettings.subject.trim(),
    body: isDirty && !currentSettings.body.trim(),
  };

  const isValid = !validationErrors.subject && !validationErrors.body;

  return (
    <Container maxWidth="md" disableGutters>
      <Stack py={2.5} spacing={3}>
        <AutomaticInvitesSection
          enabled={currentSettings.enableAutomaticInvites}
          onChange={handleAutomaticInvitesChange}
          disabled={isFetching || !hasPermissionsToEdit}
        />

        <Stack spacing={3}>
          <EmailTemplateEditor
            snippets={snippets}
            settings={currentSettings}
            onChange={handleEmailSettingsChange}
            disabled={isFetching || !hasPermissionsToEdit}
            validationErrors={validationErrors}
          />

          <Stack direction="row" spacing={1}>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled={!isDirty || !isValid || !hasPermissionsToEdit}
              loading={isSaving}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
            <Button variant="text" color="secondary" disabled={!isDirty || !hasPermissionsToEdit} onClick={handleReset}>
              Cancel
            </Button>
          </Stack>
        </Stack>

        {hasPermissionsToEdit && (
          <>
            <Divider />
            <SendTestInvitationEmail disabled={isDirty} />
          </>
        )}
      </Stack>
    </Container>
  );
};

export default InvitationEmailSection;
