import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button } from "@mui/material";
import CustomDataGridHeaderToolbar from "../../grid/CustomDataGridHeaderToolbar";
import { useFilesContext } from "../FilesContext";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  disabled?: boolean;
}

const FilesGridHeaderToolbar = ({ selectedIds, setSelectedIds, disabled }: Props) => {
  const { onDownloadMultipleFiles, onDeleteMultipleFiles, hasPermissionsToManage } = useFilesContext();

  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button
        disabled={disabled}
        onClick={() => onDownloadMultipleFiles(selectedIds)}
        color="secondary"
        variant="text"
        startIcon={<DownloadIcon />}
      >
        Download
      </Button>
      {hasPermissionsToManage && (
        <Button
          disabled={disabled}
          onClick={() => onDeleteMultipleFiles(selectedIds)}
          color="error"
          variant="text"
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      )}
    </CustomDataGridHeaderToolbar>
  );
};

export default FilesGridHeaderToolbar;
