import { defined } from "../utilities/typeHelper";
import { AuthSettings } from "./authTypes";

interface AuthSettingsProvider {
  authSettings: AuthSettings | undefined;
}

const authSettingsProvider: AuthSettingsProvider = {
  authSettings: undefined,
};

export const initAuthSettings = (authSettings: AuthSettings) => {
  authSettingsProvider.authSettings = authSettings;
};

export const getAuthSettings = (): AuthSettings =>
  defined(authSettingsProvider.authSettings, "Auth settings not initialized");
