import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Stack } from "@mui/material";
import { useCallback, useState } from "react";
import { Navigate, useParams } from "react-router";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import { useObjectDefinitionContext } from "../ObjectDefinitionContext";
import { ObjectLayoutContextProvider } from "./ObjectLayoutContext";
import ObjectLayoutEditor from "./ObjectLayoutEditor";
import ObjectLayoutPageTitle from "./ObjectLayoutPageTitle";
import { getCreatePageLayoutRequest, getInitialState, initLayoutAction } from "./objectLayoutState";

const saveLayout = withErrorHandling(adminApi.saveObjectConfigurationLayout);

const ObjectLayoutPage = () => {
  const { configurationId } = useParams();
  const { objectDefinition, hasEditPermissions } = useObjectDefinitionContext();
  const { clientCode } = useClientContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [layoutState, setLayoutState] = useState(getInitialState(objectDefinition, configurationId ?? ""));
  const [isSaving, setSaving] = useState(false);

  const pathToObjectLayouts = `/${clientCode}/${pageRoutes.settings}/${pageRoutes.dataModel}/${pageRoutes.orgObjects}/${objectDefinition.objectType}?tab=layouts`;

  const getLayout = useCallback(
    async () => adminApi.getObjectConfigurationLayout(objectDefinition.objectType, configurationId ?? ""),
    [configurationId, objectDefinition.objectType]
  );

  const [pageLayout, layoutFetchError, { isFetching }] = useFetch(getLayout, (data) =>
    setLayoutState(initLayoutAction(data))
  );

  if (!configurationId || !hasEditPermissions) {
    return <Navigate to={pathToObjectLayouts} />;
  }

  if (layoutFetchError) {
    logError(layoutFetchError, "[ObjectLayoutPage] getLayout");
    return <DataLoadingFailed title="Failed to load page layout" />;
  }

  if (pageLayout === undefined || isFetching) {
    return <InlineLoader />;
  }

  const handleCancelClick = () => {
    setLayoutState(initLayoutAction(pageLayout));
  };

  const handleSaveClick = async () => {
    setSaving(true);
    const [savedLayout, error] = await saveLayout(objectDefinition.objectType, getCreatePageLayoutRequest(layoutState));
    setSaving(false);

    if (error) {
      logError(error, "[ObjectLayoutPage] saveLayout");
      sendNotificationError("Failed to save layout");
      return;
    }

    setLayoutState(initLayoutAction(savedLayout));
    sendNotification("Layout saved successfully");
  };

  return (
    <ObjectLayoutContextProvider layoutState={layoutState} setLayoutState={setLayoutState}>
      <GeneralPageHeader TitleComponent={<ObjectLayoutPageTitle />} showDefaultBackButtonTo={pathToObjectLayouts}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="text"
            color="secondary"
            disabled={isSaving || !layoutState.isDirty || Boolean(layoutState.isAddingFieldToGroup)}
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSaving}
            variant="contained"
            disabled={!layoutState.isDirty || Boolean(layoutState.isAddingFieldToGroup)}
            onClick={handleSaveClick}
          >
            Save Changes
          </LoadingButton>
        </Stack>
      </GeneralPageHeader>
      <Box width="100%" height="100%" py={2.5} bgcolor="background.grey">
        <Container maxWidth="md" disableGutters sx={{ height: "100%" }}>
          <ScrollableFlexContainer sx={{ height: "100%" }}>
            <ObjectLayoutEditor />
          </ScrollableFlexContainer>
        </Container>
      </Box>
    </ObjectLayoutContextProvider>
  );
};

export default ObjectLayoutPage;
