import { EntityOptionFieldType } from "../../shared/api/dictionaryTypes";
import { ClientDictionaries } from "../api/types/clientTypes";
import { SelectOption } from "../api/types/objectTypes";

export const dictionariesPropMap: Record<EntityOptionFieldType, keyof ClientDictionaries> = {
  Country: "countries",
  State: "states",
  Currency: "currencies",
  TaxForm: "taxForms",
  InvestorCategory: "investorCategories",
  FormPfName: "formPfNames",
  Chapter3Status: "chapter3Statuses",
  Chapter4FatcaStatus: "chapter4FatcaStatuses",
  Irs1099Code: "irs1099Codes",
  IndustryOrSector: "industriesAndSectors",
  Geography: "geographies",
};

export const getSelectOptionsFromDictionary = (
  optionFieldType: EntityOptionFieldType,
  clientDictionaries: ClientDictionaries
): SelectOption[] => {
  const dictionary = clientDictionaries[dictionariesPropMap[optionFieldType]];
  return dictionary
    ? dictionary
        .filter((o) => !!o.code.trim() && !!o.displayName.trim())
        .map((o) => ({ label: o.displayName, value: o.code }))
        .sort((a, b) => a.label.localeCompare(b.label))
    : [];
};

export const getDictionaryDisplayNameFromCode = (
  code: string,
  optionFieldType: EntityOptionFieldType,
  clientDictionaries: ClientDictionaries
) => {
  const dictionary = clientDictionaries[dictionariesPropMap[optionFieldType]];
  return dictionary?.find((item) => item.code === code)?.displayName;
};
