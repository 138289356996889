import AddRoundedIcon from "@mui/icons-material/AddRounded";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router";
import TooltipWrapper from "../../../../../shared/components/TooltipWrapper";
import { useSharedReportingLocalization } from "../../../../../shared/reporting/hooks/useLocalization";
import { pageRoutes } from "../../../../routes";

interface Props {
  onAddTemplate: () => void;
  readonly?: boolean;
}
export default function OrganizationTemplatesPageHeader({ onAddTemplate, readonly }: Props) {
  const { report_templates: locale } = useSharedReportingLocalization();
  return (
    <Stack sx={{ flexDirection: "row", alignItems: "center", flex: 1, justifyContent: "space-between", py: 2, px: 3 }}>
      <Typography variant="subtitle1">Report Templates</Typography>
      <Box id="controls" sx={{ display: "flex", gap: 1 }}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<FolderOpenRoundedIcon />}
          component={Link}
          to={pageRoutes.reportLevels}
        >
          Group Settings
        </Button>
        <TooltipWrapper title={locale.cant_manage_templates} placement="bottom" disableHoverListener={!readonly}>
          <span>
            <Button variant="contained" startIcon={<AddRoundedIcon />} onClick={onAddTemplate} disabled={readonly}>
              New Template
            </Button>
          </span>
        </TooltipWrapper>
      </Box>
    </Stack>
  );
}
