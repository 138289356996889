import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Divider, Grid2, Stack, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import PromptDialog from "../../../../components/dialog/PromptDialog";
import PromptDialogHeader from "../../../../components/dialog/PromptDialogHeader";
import SearchField from "../../../../components/inputs/SearchField";
import { FundType, ReportTemplate, ReportTemplateDto } from "../../../api/biClient.types";
import CompanyTemplatesGrid from "../CompanyTemplatesGrid";
import TemplatesMenu from "../TemplatesMenu";
import TemplateInfoDialog from "./TemplateInfoDialog";

interface Props {
  templates: ReportTemplateDto[];
  hideCreateBlankReport?: boolean;
  createBlankReport?: () => void;
  onClose: () => void;
  onQuickUse: (template: ReportTemplate) => void;
}

export default function CompanyTemplatesDialog({
  templates,
  hideCreateBlankReport,
  createBlankReport,
  onClose,
  onQuickUse,
}: Props) {
  const [selectedField, setSelectedFilter] = useState<{
    fieldValue?: string;
    fieldName?: string;
  }>();
  const [searchText, setSearchText] = useState("");
  const [templateInfo, setTemplateInfo] = useState<ReportTemplate | undefined>();

  const handleTemplateNameClick = useCallback((template: ReportTemplate) => {
    setTemplateInfo(template);
  }, []);

  const filteredTemplates = useMemo(() => {
    return templates
      .filter(
        (t) => t.name.toLowerCase().indexOf(searchText) > -1 || t.groupName.toLowerCase().indexOf(searchText) > -1
      )
      .filter((rep) => filterPredicate(rep, selectedField?.fieldName, selectedField?.fieldValue));
  }, [searchText, selectedField?.fieldName, selectedField?.fieldValue, templates]);

  return (
    <PromptDialog open sx={{ width: "100%", height: "100%", maxHeight: 800, maxWidth: 1300, p: 0 }}>
      <PromptDialogHeader text="Report Templates" onClose={onClose} />
      <Divider orientation="horizontal" flexItem />
      <Grid2 container sx={{ flex: 1, height: "100%", width: "100%", flexWrap: "nowrap" }}>
        <Grid2 sx={(theme) => ({ width: "100%", maxWidth: theme.spacing(35), gap: 2.5, display: "flex" })}>
          <Stack flex={1}>
            <TemplatesMenu
              displayingTemplates={templates}
              allTemplatesList={templates}
              onSelect={(item) => setSelectedFilter({ fieldValue: item?.value, fieldName: item?.fieldName })}
              sx={{ py: 2.5, px: 2, flex: 1 }}
              showNewLabel={true}
            />
            {!hideCreateBlankReport && (
              <Box sx={(theme) => ({ display: "flex", p: 2, borderTop: `1px solid ${theme.palette.divider}` })}>
                <Button
                  variant="contained"
                  sx={{ px: 1.25, flex: 1 }}
                  startIcon={<AddRoundedIcon />}
                  onClick={() => createBlankReport?.()}
                >
                  Create Blank Report
                </Button>
              </Box>
            )}
          </Stack>
        </Grid2>
        <Divider orientation="vertical" flexItem />
        <Grid2 sx={{ display: "flex", flexDirection: "column", width: "100%", pt: 3, pr: 3, pb: 2, pl: 2.5, gap: 2.5 }}>
          <Grid2 container sx={{ justifyContent: "space-between", width: "100%" }}>
            <Typography variant="subtitle2">{`${filteredTemplates.length} record${
              filteredTemplates.length !== 1 ? "(s)" : ""
            }`}</Typography>
            <SearchField onSearch={(text) => setSearchText(text.toLowerCase())} debounceTimeMs={300} />
          </Grid2>
          <CompanyTemplatesGrid
            templates={filteredTemplates}
            onTemplateNameClick={handleTemplateNameClick}
            onQuickUse={onQuickUse}
          />
        </Grid2>
      </Grid2>
      {templateInfo && (
        <TemplateInfoDialog
          template={templateInfo}
          onClose={() => setTemplateInfo(undefined)}
          onUseTemplate={(t) => {
            setTemplateInfo(undefined);
            onQuickUse(t);
          }}
        />
      )}
    </PromptDialog>
  );
}

function filterPredicate(template: ReportTemplateDto, fieldName?: string, fieldValue?: string) {
  switch (fieldName) {
    case "Fund Types":
      return template.fundTypes.includes(fieldValue as FundType);
    case "Groups":
      return template.groupName === fieldValue;
    default:
      return true;
  }
}
