import { Grid2, Grid2Props, GridSize } from "@mui/material";
import { PropsWithChildren } from "react";

interface LayoutItem {
  width?: number | string;
  xs?: GridSize;
}

interface Props {
  gridProps?: Grid2Props;
  layout?: LayoutItem[];
}

const ScrollableColumns = ({ gridProps, layout, children }: PropsWithChildren<Props>) => {
  const columns = children as React.ReactElement[];
  const layoutItems = layout ?? [];

  return (
    <Grid2 columns={columns.length} container height="100%" sx={{ overflow: "hidden" }} {...gridProps}>
      {columns.map((column, index) => {
        const { xs, width } = layoutItems[index] ?? {};
        return (
          <Grid2
            key={index}
            size={{ xs: width ? false : (xs ?? 1) }}
            height="100%"
            width={width}
            sx={{ overflowY: "auto" }}
          >
            {column}
          </Grid2>
        );
      })}
    </Grid2>
  );
};

export default ScrollableColumns;
