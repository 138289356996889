import { Stack, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import UserAvatar from "../../../../../shared/components/UserAvatar";
import { convertISODateTime } from "../../../../../shared/utilities/dateUtils";
import { SentEmail } from "../../../../api/types/emailsTypes";
import EmailStatus from "../../../tables/EmailStatus";

interface RecipientProps {
  recipientName: string;
  email: string;
}

const Recipient = ({ recipientName, email }: RecipientProps) => (
  <Stack direction="row" alignItems="center" spacing={1.5} width="100%">
    <UserAvatar size="medium" userName={recipientName} />
    <Stack spacing={0.5} width="calc(100% - 32px)">
      <Typography variant="subtitle2" noWrap>
        {recipientName}
      </Typography>
      <Tooltip title={email}>
        <Typography variant="caption" noWrap>
          {email}
        </Typography>
      </Tooltip>
    </Stack>
  </Stack>
);

export const columnDefinitions: GridColDef<SentEmail>[] = [
  {
    field: "recipientName",
    headerName: "Recipient",
    width: 240,
    renderCell: ({ row }) => <Recipient recipientName={row.recipientName ?? ""} email={row.email ?? ""} />,
  },
  {
    field: "subject",
    headerName: "Subject",
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip title={row.subject}>
        <Typography noWrap>{row.subject}</Typography>
      </Tooltip>
    ),
  },
  {
    field: "source",
    headerName: "Source",
    flex: 1,
    renderCell: ({ row }) => (
      <Tooltip title={row.source}>
        <Typography noWrap>{row.source}</Typography>
      </Tooltip>
    ),
  },
  {
    field: "sentAt",
    headerName: "Sent",
    width: 160,
    renderCell: ({ row }) => (
      <Typography variant="caption" color="text.secondary">
        {convertISODateTime(row.sentAt)}
      </Typography>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    width: 112,
    renderCell: ({ row }) => (
      <EmailStatus
        status={row.status}
        deliveryFailureMessage={row.deliveryFailureMessage}
        deliveryFailureMessageExplanation={row.deliveryFailureMessageExplanation}
      />
    ),
  },
];
