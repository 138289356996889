import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Category } from "../../../../api/types/accessTypes";

interface Props {
  categoryId: string | undefined;
  categories: Category[];
  onChange: (newCategoryId: string | undefined) => void;
}

function UserConsentCategorySelect({ categories, onChange, categoryId }: Props) {
  const handleChange = (e: SelectChangeEvent) => {
    const selectedValue = e.target.value;
    onChange(selectedValue && selectedValue !== "all" ? selectedValue : undefined);
  };

  const allCategoriesItemLabel = "All categories";

  return (
    <FormControl fullWidth>
      <InputLabel id="category-select-label">Category</InputLabel>
      <Select
        fullWidth
        labelId="category-select-label"
        label="Category"
        value={categoryId || "all"}
        onChange={handleChange}
        renderValue={(value) =>
          value ? categories.find((c) => c.id === value)?.name || allCategoriesItemLabel : allCategoriesItemLabel
        }
        displayEmpty
      >
        <MenuItem value="all">
          <Typography>{allCategoriesItemLabel}</Typography>
        </MenuItem>
        {categories.map((category) => (
          <MenuItem key={category.id} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default UserConsentCategorySelect;
