import { Navigate, useParams } from "react-router";
import ObjectDetailsPage from "../../../entityFields/ObjectDetailsPage";

const PortfolioCompanyDetailsPage = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to=".." />;
  }

  return <ObjectDetailsPage objectId={id} objectType="PortfolioCompany" showDefaultBackButtonTo=".." showLogoSection />;
};

export default PortfolioCompanyDetailsPage;
