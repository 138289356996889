import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, Theme, Typography } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { OrganizationMember } from "../../../../../api/types/userManagementTypes";
import MemberDialogIcon from "../../../../common/MemberDialogIcon";
import CustomDataGridHeaderToolbar from "../../../../common/grid/CustomDataGridHeaderToolbar";
import { getCheckedGridSx } from "../../../../common/grid/gridStyles";
import { useMembersContext } from "../MembersContext";
import { getDefaultGridSx } from "../definitions";
import MemberRowActionsMenu from "./MemberRowActionsMenu";
import { getMembersGridColumns } from "./membersGridDefinitions";

const MembersGrid = () => {
  const {
    members,
    filteredMembers,
    isLoading,
    selectedMemberIds,
    setSelectedMembersIds,
    hasAccessToManageOrganizationUsers,
    deleteMembers,
    currentUserData,
  } = useMembersContext();
  const [menuDisplayedMember, setMenuDisplayedMember] = useState<OrganizationMember | undefined>(undefined);
  const [currentUserId] = currentUserData;

  const columns = useMemo(() => {
    const cols: GridColDef<OrganizationMember>[] = getMembersGridColumns({ currentUserId });
    if (hasAccessToManageOrganizationUsers) {
      cols.push({
        field: "actions",
        headerName: "",
        width: 80,
        cellClassName: "grid-row-actions",
        align: "right",
        renderCell: ({ row }) => <MemberRowActionsMenu onSelected={setMenuDisplayedMember} member={row} />,
      });
    }
    return cols;
  }, [currentUserId, hasAccessToManageOrganizationUsers]);

  const showToolbar = hasAccessToManageOrganizationUsers && !!selectedMemberIds?.length;
  const isCurrentUserSelected = selectedMemberIds?.some((id) => id === currentUserId);
  const disableDeleteSelected = selectedMemberIds?.length === members.length || isCurrentUserSelected;

  const getGridSx = (theme: Theme) =>
    deepmerge(getDefaultGridSx(theme), getCheckedGridSx(theme, showToolbar), { clone: true });

  return (
    <DataGrid<OrganizationMember>
      checkboxSelection
      rowSelectionModel={selectedMemberIds}
      getRowId={(row) => row.userId}
      sx={(theme) => getGridSx(theme)}
      columns={columns}
      rows={filteredMembers}
      multilineRows
      getRowClassName={(params) => {
        const member = params.row as OrganizationMember;
        if (member.userId === menuDisplayedMember?.userId) {
          return "Mui-hovered";
        }
        if (isCurrentUserSelected && member.userId === currentUserId) {
          return "current-member-row";
        }
        if (member.userType === "Support") {
          return "support-member-row";
        }
        return "";
      }}
      loading={isLoading}
      noRowsIcon={<MemberDialogIcon />}
      noRowsText="No members"
      slots={{
        toolbar: () =>
          showToolbar ? (
            <CustomDataGridHeaderToolbar selection={[selectedMemberIds, setSelectedMembersIds]}>
              <Button
                disabled={disableDeleteSelected}
                onClick={() => deleteMembers(selectedMemberIds)}
                color="error"
                variant="text"
                startIcon={<DeleteOutlineIcon />}
              >
                Remove
              </Button>
              {isCurrentUserSelected && (
                <Typography sx={{ ml: 1 }} variant="caption" color="textSecondary">
                  (Current user is selected but is not allowed to be deleted)
                </Typography>
              )}
            </CustomDataGridHeaderToolbar>
          ) : null,
      }}
      onRowSelectionModelChange={(newSelection) => {
        setSelectedMembersIds(newSelection as string[]);
      }}
    />
  );
};

export default MembersGrid;
