import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import CompanyIcon from "../../../../icons/CompanyIcon";
import { ClientConfigurationAssignment } from "./configurationsListState";

const assignClientToConfiguration = withErrorHandling(adminApi.assignClientToDataModelConfiguration);

interface Props {
  assignment: ClientConfigurationAssignment;
  onClose: () => void;
  onAssigned: (assignment: ClientConfigurationAssignment) => void;
}

const AssignClientToConfigurationDialog = ({ assignment, onClose, onAssigned }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [loading, setLoading] = useState(false);

  const handleApplyClick = async () => {
    setLoading(true);
    const [, error] = await assignClientToConfiguration({
      targetConfigurationId: assignment.toConfiguration.id,
      clientCode: assignment.clientCode,
    });
    setLoading(false);

    if (error) {
      logError(error, "[AssignClientToConfigurationDialog]");
      sendNotificationError("Failed to assign the client to configuration");
      return;
    }

    sendNotification("Configuration updated successfully");
    onAssigned(assignment);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Change Configuration</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <CompanyIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Change configuration for {assignment.clientTitle}?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You&apos;re about to change configuration from <strong>{assignment.fromConfiguration.name}</strong> to{" "}
              <strong>{assignment.toConfiguration.name}</strong>. Changing the configuration will affect the display of
              layouts in objects. Custom data on objects may be hidden.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={loading} onClick={handleApplyClick}>
          Apply
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignClientToConfigurationDialog;
