import { Box, Checkbox, FormControlLabel, Paper, Stack, Typography } from "@mui/material";
import PortalPageStatusChip from "./PortalPageStatusChip";
import PortalPageStatusSelect from "./PortalPageStatusSelect";
import { PortalPageStatus } from "./state";

interface Props {
  title: string;
  toggleValue: PortalPageStatus;
  onToggleChange: (value: PortalPageStatus) => void;
  toggleDisabled?: boolean;
  checkboxLabel?: string;
  checkedValue?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  checkboxDisabled?: boolean;
}

const PortalPageSettingsEdit = ({
  title,
  toggleValue,
  onToggleChange,
  toggleDisabled,
  checkboxLabel,
  checkedValue,
  onCheckedChange,
  checkboxDisabled,
}: Props) => {
  return (
    <Paper variant="outlined" square={false} sx={{ width: "100%", borderRadius: "8px" }}>
      <Stack width="100%" spacing={1.25} p={1.5} pr={1.5} pl={2}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="subtitle2">{title}</Typography>
          {toggleDisabled && <PortalPageStatusChip value={toggleValue} />}
          {!toggleDisabled && <PortalPageStatusSelect value={toggleValue} onChange={onToggleChange} />}
        </Box>
        {checkedValue !== undefined && onCheckedChange !== undefined && (
          <FormControlLabel
            control={
              <Checkbox
                checked={checkedValue}
                disabled={checkboxDisabled}
                onChange={(_, checked) => onCheckedChange(checked)}
              />
            }
            label={<Typography>{checkboxLabel}</Typography>}
          />
        )}
      </Stack>
    </Paper>
  );
};

export default PortalPageSettingsEdit;
