import { FormCellValue, SaveSubmissionLayoutBlockInputRequest } from "../../../api/dataCollectionTypes";

export type EditedFormValuesState = Record<string, EditedFormBlockValuesState>;

interface EditedFormBlockValuesState {
  formCellValues: FormCellValue[];
}

export const getInitialEditedFormValuesState = (): EditedFormValuesState => ({});

// Selectors

export const isEditedFormValuesStateEmpty = (state: EditedFormValuesState): boolean =>
  Object.values(state).every((values) => values.formCellValues.length === 0);

export const getFormUpdatesPerBlock = (
  state: EditedFormValuesState
): [string, SaveSubmissionLayoutBlockInputRequest][] => {
  const updates: [string, SaveSubmissionLayoutBlockInputRequest][] = [];

  for (const [blockId, blockValues] of Object.entries(state)) {
    const { formCellValues } = blockValues;

    if (formCellValues.length > 0) {
      updates.push([blockId, { formCellValues }]);
    }
  }

  return updates;
};

// Actions

type StateAction = (state: EditedFormValuesState) => EditedFormValuesState;

const areFormCellValuesEqual = (a: FormCellValue, b: FormCellValue): boolean =>
  a.rowId === b.rowId && a.columnId === b.columnId && a.rowExtensionId === b.rowExtensionId;

export const addFormCellValueAction =
  (blockId: string, formCellValue: FormCellValue): StateAction =>
  (state) => {
    const prevBlockCellValues = state[blockId]?.formCellValues ?? [];

    // If the value was already edited, replace it
    const valueToReplace = prevBlockCellValues.find((value) => areFormCellValuesEqual(value, formCellValue));

    const newBlockCellValues = valueToReplace
      ? prevBlockCellValues.map((value) => (value === valueToReplace ? formCellValue : value))
      : [...prevBlockCellValues, formCellValue];

    return {
      ...state,
      [blockId]: {
        formCellValues: newBlockCellValues,
      },
    };
  };
