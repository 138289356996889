import { Container } from "@mui/material";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import PortalPagesSettingsEditor from "./PortalPagesSettingsEditor";

const PortalPagesSettings = () => {
  const [settingsResp, error] = useFetch(adminApi.getInvestorPortalSettings);

  if (error) {
    logError(error, "getInvestorPortalSettings");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  if (!settingsResp) {
    return <InlineLoader />;
  }

  const { enabledFeatures, enabledPreviewOnlyFeatures } = settingsResp.settings;

  return (
    <Container maxWidth="md" disableGutters>
      <PortalPagesSettingsEditor
        initialEnabledFeatures={enabledFeatures ?? []}
        initialEnabledPreviewOnlyFeatures={enabledPreviewOnlyFeatures ?? []}
      />
    </Container>
  );
};

export default PortalPagesSettings;
