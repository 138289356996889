import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import MoneyIcon from "@mui/icons-material/AttachMoney";
import { Button, FormControlLabel, Stack, Switch } from "@mui/material";
import { useMemo } from "react";
import DateRangeSelect from "../../../../../../shared/components/DateRangeSelect";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import MultiSelectFilter from "../../../../../../shared/components/MultiSelectFilter";
import useDebounce from "../../../../../../shared/hooks/useDebounce";
import {
  DescriptiveDate,
  anytime,
  lastMonth,
  lastQuarter,
  lastYear,
  next30Days,
  nextQuarter,
  nextSevenDays,
  thisMonth,
  thisQuarter,
  thisYear,
  today,
} from "../../../../../../shared/utilities/dateFilterHelper";
import { CashFlowRequest, DistributionStatus } from "../../../../../api/adminApi";

interface Props {
  isLoading: boolean;
  request: CashFlowRequest;
  onChange: (changedRequest: CashFlowRequest) => void;
  fundNames: string[];
}

const paymentStatuses: Array<[string, DistributionStatus]> = [
  ["Paid", "Paid"],
  ["Unpaid", "Unpaid"],
  ["Partially Paid", "PartlyPaid"],
];

const InvestorCapitalActivityFilters = ({ request, onChange, isLoading, fundNames }: Props) => {
  const anyFiltersApplied =
    !!request.fundNames?.length ||
    !!request.search?.length ||
    !!request.paymentStatuses?.length ||
    !!request.from ||
    !!request.to ||
    !!request.descriptiveDate;

  const updateFiltersState = (update: Partial<CashFlowRequest>) => onChange({ ...request, ...update });

  const onFiltersChange = updateFiltersState;

  const onSearch = useDebounce((search: string) => {
    updateFiltersState({ search: search.toLowerCase() });
  }, 500);

  const clearFilters = () =>
    updateFiltersState({
      fundNames: undefined,
      search: undefined,
      paymentStatuses: undefined,
      from: undefined,
      to: undefined,
      descriptiveDate: undefined,
    });

  const handleDateRangeChange = (dateRange: DescriptiveDate) =>
    onFiltersChange({
      descriptiveDate: dateRange,
      from: dateRange.from?.toISOString(),
      to: dateRange.to?.toISOString(),
    });

  const filterDates = useMemo(
    () => [
      anytime,
      today,
      nextSevenDays,
      next30Days,
      nextQuarter,
      thisMonth,
      thisQuarter,
      thisYear,
      lastMonth,
      lastQuarter,
      lastYear,
    ],
    []
  );

  return (
    <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
      <Stack direction={"row"} spacing={1.5}>
        <MultiSelectFilter
          disabled={isLoading}
          sortOptions
          placeholder="Funds"
          options={fundNames.map((fundName) => ({ value: fundName, label: fundName }))}
          selectedValues={request.fundNames ?? []}
          onChange={(fundNames) => onFiltersChange({ fundNames })}
          StartIcon={MoneyIcon}
        />
        <MultiSelectFilter
          disabled={isLoading}
          sortOptions
          placeholder="Status"
          options={paymentStatuses.map((status) => ({ label: status[0], value: status[1] }))}
          selectedValues={request.paymentStatuses ?? []}
          onChange={(paymentStatuses) => onFiltersChange({ paymentStatuses })}
          StartIcon={AccountBalanceWalletOutlinedIcon}
        />
        <DateRangeSelect
          disabled={isLoading}
          predefinedRanges={filterDates}
          dateRange={request.descriptiveDate}
          onChange={handleDateRangeChange}
        />
        {anyFiltersApplied && <Button onClick={clearFilters}>Clear All</Button>}
      </Stack>
      <Stack direction={"row"} spacing={1.5}>
        <FormControlLabel
          value="end"
          control={
            <Switch
              checked={request.isInvestorPortalMode}
              onChange={() => onFiltersChange({ isInvestorPortalMode: !request.isInvestorPortalMode })}
              color="primary"
            />
          }
          label="Investor Portal Data"
          labelPlacement="end"
        />
        <SearchField disabled={isLoading} onSearch={onSearch} initialValue={request.search} />
      </Stack>
    </Stack>
  );
};

export default InvestorCapitalActivityFilters;
