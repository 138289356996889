import { Stack } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router";
import RecordCounter from "../../../../../shared/components/filters/RecordCounter";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { FundraisingInfo } from "../../../../api/types/fundraisingTypes";
import { columnDefinitions } from "./fundraisingsGridDataProvider";
import PageDeactivatedAlert from "./PageDeactivatedAlert";

interface Props {
  fundraisings: FundraisingInfo[];
  isLoading: boolean;
  showPageDeactivatedWarning: boolean;
}

const FundraisingsList = ({ fundraisings, isLoading, showPageDeactivatedWarning }: Props) => {
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (field !== "actions") {
      navigate(row.id);
    }
  };

  return (
    <Stack spacing={2} height="100%">
      {showPageDeactivatedWarning && <PageDeactivatedAlert />}
      <RecordCounter records={fundraisings.length} hide={isLoading} />
      <DataGrid<FundraisingInfo>
        rows={fundraisings}
        columns={columnDefinitions}
        loading={isLoading}
        selectableRows
        onCellClick={handleCellClick}
        noRowsText="No fundraisings yet"
        disableColumnReorder
        disableColumnSorting
      />
    </Stack>
  );
};

export default FundraisingsList;
