import CalendarIcon from "@mui/icons-material/CalendarTodayOutlined";
import {
  Divider,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { endOfDay, startOfDay } from "date-fns";
import { useState } from "react";
import { DescriptiveDate } from "../utilities/dateFilterHelper";
import { formatDateShort } from "../utilities/dateUtils";
import DateRangeSelector from "./DateRangeSelector";

interface Props {
  predefinedRanges: DescriptiveDate[];
  dateRange: DescriptiveDate | undefined;
  onChange: (dateRange: DescriptiveDate) => void;
  placeholder?: string;
  disabled?: boolean;
  fullWidth?: boolean;
}

const customRangeTitle = "Custom range";

const DateRangeSelect = ({ predefinedRanges, dateRange, onChange, disabled, placeholder, fullWidth }: Props) => {
  const [customRangeRefEl, setCustomRangeRefEl] = useState<Element | null>(null);
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [selectedCustomRange, setSelectedCustomRange] = useState<DescriptiveDate | undefined>(
    dateRange?.title === customRangeTitle ? dateRange : undefined
  );

  const handleChange = ({ target }: SelectChangeEvent) => {
    const dateRange = predefinedRanges.find((d) => d.title === target.value);
    if (dateRange) {
      setSelectedCustomRange(undefined);
      onChange(dateRange);
    }
  };

  const handleCustomPeriodClick = () => {
    setShowCustomRange(true);
  };

  const handleApplyCustomRange = ([from, to]: [Date, Date]) => {
    const custom = {
      title: customRangeTitle,
      description: `${formatDateShort(from)}-${formatDateShort(to)}`,
      from: startOfDay(from),
      to: endOfDay(to),
    };

    setSelectedCustomRange(custom);
    onChange(custom);
    setShowCustomRange(false);
  };

  return (
    <>
      <Select
        disabled={disabled}
        ref={(r: Element) => setCustomRangeRefEl(r)}
        value={dateRange?.title ?? ""}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <CalendarIcon color={disabled ? "disabled" : "action"} />
          </InputAdornment>
        }
        displayEmpty
        fullWidth={fullWidth}
        renderValue={(rangeTitle) => {
          if (!rangeTitle) {
            return placeholder ?? "Date";
          }

          const selectedRange = predefinedRanges.find((d) => d.title === rangeTitle) ?? selectedCustomRange;
          if (selectedRange) {
            return selectedRange.description ?? rangeTitle;
          }

          return rangeTitle;
        }}
        sx={{
          width: fullWidth ? undefined : "14rem",
          ".MuiInputBase-input": {
            color: dateRange ? "text.primary" : "text.secondary",
          },
        }}
      >
        {predefinedRanges.map((dateRange) => (
          <MenuItem key={dateRange.title} value={dateRange.title}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" minWidth="16rem">
              <Typography>{dateRange.title}</Typography>
              <Typography color="text.secondary">{dateRange.description}</Typography>
            </Stack>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem key={customRangeTitle} value={customRangeTitle} onClick={handleCustomPeriodClick}>
          <Typography>{customRangeTitle}</Typography>
        </MenuItem>
      </Select>

      <Popover
        open={showCustomRange}
        anchorEl={customRangeRefEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <DateRangeSelector
          from={dateRange?.from ?? new Date()}
          to={dateRange?.to ?? new Date()}
          onApply={handleApplyCustomRange}
          onCancel={() => setShowCustomRange(false)}
        />
      </Popover>
    </>
  );
};

export default DateRangeSelect;
