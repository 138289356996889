import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import { IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import { EntityFieldType } from "../../../../../api/types/objectTypes";
import { fieldTypeCaptionMap } from "../../../../../utilities/enumCaptions";
import FieldIcon from "../../../../common/domain-icons/FieldIcon";
import { entityFieldTypesForUdf } from "./userDefinedFieldConfigurations";

interface Props {
  onSelect: (fieldType: EntityFieldType) => void;
}

const FieldTypeSelectionList = ({ onSelect }: Props) => {
  return (
    <Stack width="26rem" alignItems="flex-start">
      <List sx={{ width: "100%", px: 0.5, py: 1 }}>
        {entityFieldTypesForUdf.map((fieldType) => (
          <ListItem
            key={fieldType}
            onClick={() => onSelect(fieldType)}
            secondaryAction={
              <IconButton onClick={() => onSelect(fieldType)}>
                <ChevronRightIcon />
              </IconButton>
            }
          >
            <Stack direction="row" spacing={1.5}>
              <FieldIcon fieldType={fieldType} color="action" />
              <Typography component="span">{fieldTypeCaptionMap[fieldType]}</Typography>
            </Stack>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

export default FieldTypeSelectionList;
