import { createSvgIcon } from "@mui/material";

const FileAccessIcon = createSvgIcon(
  <svg viewBox="0 0 18 18" fill="currentColor">
    <path d="M6 10.5H7.875C8.0875 10.5 8.26563 10.4281 8.40938 10.2844C8.55313 10.1406 8.625 9.9625 8.625 9.75C8.625 9.5375 8.55313 9.35938 8.40938 9.21563C8.26563 9.07188 8.0875 9 7.875 9H6C5.7875 9 5.60937 9.07188 5.46562 9.21563C5.32187 9.35938 5.25 9.5375 5.25 9.75C5.25 9.9625 5.32187 10.1406 5.46562 10.2844C5.60937 10.4281 5.7875 10.5 6 10.5ZM6 13.5H6.75C6.9625 13.5 7.14063 13.4281 7.28438 13.2844C7.42813 13.1406 7.5 12.9625 7.5 12.75C7.5 12.5375 7.42813 12.3594 7.28438 12.2156C7.14063 12.0719 6.9625 12 6.75 12H6C5.7875 12 5.60937 12.0719 5.46562 12.2156C5.32187 12.3594 5.25 12.5375 5.25 12.75C5.25 12.9625 5.32187 13.1406 5.46562 13.2844C5.60937 13.4281 5.7875 13.5 6 13.5ZM3.75 16.5C3.3375 16.5 2.98438 16.3531 2.69063 16.0594C2.39688 15.7656 2.25 15.4125 2.25 15V3C2.25 2.5875 2.39688 2.23438 2.69063 1.94063C2.98438 1.64688 3.3375 1.5 3.75 1.5H9.13125C9.33125 1.5 9.52187 1.5375 9.70312 1.6125C9.88438 1.6875 10.0437 1.79375 10.1812 1.93125L13.8188 5.56875C13.9563 5.70625 14.0625 5.86562 14.1375 6.04688C14.2125 6.22813 14.25 6.41875 14.25 6.61875V6.8625C14.25 7.075 14.1781 7.25313 14.0344 7.39688C13.8906 7.54063 13.7125 7.6125 13.5 7.6125C13.2875 7.6125 13.1094 7.54063 12.9656 7.39688C12.8219 7.25313 12.75 7.075 12.75 6.8625V6.75H9.75C9.5375 6.75 9.35938 6.67813 9.21563 6.53438C9.07188 6.39063 9 6.2125 9 6V3H3.75V15H7.89375C8.10625 15 8.28438 15.0719 8.42813 15.2156C8.57188 15.3594 8.64375 15.5375 8.64375 15.75C8.64375 15.9625 8.57188 16.1406 8.42813 16.2844C8.28438 16.4281 8.10625 16.5 7.89375 16.5H3.75Z" />
    <path d="M12.375 11.25C12.5875 11.25 12.7656 11.1781 12.9094 11.0344C13.0531 10.8906 13.125 10.7125 13.125 10.5C13.125 10.2875 13.0531 10.1094 12.9094 9.96562C12.7656 9.82187 12.5875 9.75 12.375 9.75C12.1625 9.75 11.9844 9.82187 11.8406 9.96562C11.6969 10.1094 11.625 10.2875 11.625 10.5C11.625 10.7125 11.6969 10.8906 11.8406 11.0344C11.9844 11.1781 12.1625 11.25 12.375 11.25ZM12.4875 17.7375L11.7375 16.9875C11.7125 16.9625 11.675 16.875 11.625 16.725V13.3875C11.075 13.225 10.625 12.9156 10.275 12.4594C9.925 12.0031 9.75 11.475 9.75 10.875C9.75 10.15 10.0063 9.53125 10.5188 9.01875C11.0313 8.50625 11.65 8.25 12.375 8.25C13.1 8.25 13.7188 8.50625 14.2313 9.01875C14.7438 9.53125 15 10.15 15 10.875C15 11.4375 14.8406 11.9375 14.5219 12.375C14.2031 12.8125 13.8 13.125 13.3125 13.3125L13.9875 13.9875C14.0625 14.0625 14.1 14.15 14.1 14.25C14.1 14.35 14.0625 14.4375 13.9875 14.5125L13.3875 15.1125C13.3125 15.1875 13.275 15.275 13.275 15.375C13.275 15.475 13.3125 15.5625 13.3875 15.6375L13.9875 16.2375C14.0625 16.3125 14.1 16.4 14.1 16.5C14.1 16.6 14.0625 16.6875 13.9875 16.7625L13.0125 17.7375C12.9375 17.8125 12.85 17.85 12.75 17.85C12.65 17.85 12.5625 17.8125 12.4875 17.7375Z" />
  </svg>,

  "FileAccessIcon"
);

export default FileAccessIcon;
