import { useCallback, useState } from "react";
import PromptDialog from "../../../components/dialog/PromptDialog";
import { logError } from "../../../logging";
import { RenameReportRequest, Report } from "../../api/biClient.types";
import { useSharedReportingLocalization } from "../../hooks/useLocalization";
import { FromReport } from "./FromReport";
import SaveReportFormWrapper from "./SaveReportFormWrapper";

interface Props {
  report: FromReport;
  renameReport: (report: RenameReportRequest) => Promise<Report | undefined>;
  onChanged: (report: Report) => void;
  onClose: () => void;
}

export default function RenameReport(props: Props) {
  const { report, renameReport, onChanged, onClose } = props;

  const { report: locale } = useSharedReportingLocalization();
  const [error, setError] = useState<string>();
  const [saving, setSaving] = useState(false);

  const onSaveClicked = useCallback(
    async (name: string) => {
      setSaving(true);
      try {
        const resp = await renameReport({ reportId: report.reportId, name, clientCode: report.clientCode });
        if (resp === undefined) {
          setError(locale.rename_error);
        } else {
          onChanged(resp);
          onClose();
        }
      } catch (e) {
        logError(e, "[RenameReport] renameReport");
        setError(locale.rename_error);
      } finally {
        setSaving(false);
      }
    },
    [locale.rename_error, onChanged, onClose, renameReport, report.clientCode, report.reportId]
  );

  return (
    <PromptDialog open={true}>
      <SaveReportFormWrapper
        report={report}
        error={error}
        title="Rename Report"
        saveBtnTitle="Save"
        saving={saving}
        showName
        onClose={onClose}
        onSave={onSaveClicked}
      />
    </PromptDialog>
  );
}
