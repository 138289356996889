import { Grid2, Stack, Typography } from "@mui/material";
import HorizontalFill from "../../../components/HorizontalFill";
import { ReportAccessType } from "../../api/biClient.types";
import AccessModeMenuLabel from "./AccessModeMenuLabel";
import { useSharingReportContext } from "./contexts/SharingReportContext";

interface Props {
  disabled: boolean;
}
export default function EveryoneSharing({ disabled }: Props) {
  const { sharedForCompanyAccess, setSharedForCompanyAccess, company } = useSharingReportContext();

  return (
    <>
      <Grid2
        container
        sx={(theme) => ({
          borderWidth: 1,
          borderColor: theme.palette.divider,
          borderStyle: "solid",
          borderRadius: 1,
          py: 0.8,
          pl: 1,
          pr: 2,
          alignItems: "center",
          gap: 1.5,
          width: "100%",
        })}
      >
        <img src={company.logoMarkUrl} alt="" width={32} height={32} />
        <Stack>
          <Typography variant="subtitle2">{`Everyone at ${company.name}`}</Typography>
          <Typography variant="body2" color={"secondary"}>
            Members who have permission in the Reporting product.
          </Typography>
        </Stack>
        <HorizontalFill />
        <AccessModeMenuLabel
          access={sharedForCompanyAccess}
          disabled={disabled}
          allowedAccesses={[
            ReportAccessType.FullAccess,
            ReportAccessType.Write,
            ReportAccessType.Read,
            ReportAccessType.NoAccess,
          ]}
          onSelect={setSharedForCompanyAccess}
        />
      </Grid2>
    </>
  );
}
