import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../../shared/icons/BinIcon";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataCollectionRequestDetails } from "../../../../api/types/dataCollectionTypes";

interface Props {
  dataRequestId: string;
  submissionIds: string[];
  recipientNames: string[];
  open: boolean;
  onClose: () => void;
  onApply: (dataRequest: DataCollectionRequestDetails) => void;
}

const deleteDataRequestSubmissions = withErrorHandling(adminApi.deleteDataRequestSubmissions);

const DeleteSubmissionsDialog = ({ dataRequestId, submissionIds, recipientNames, open, onClose, onApply }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);

  const handleApply = async () => {
    setSaving(true);
    const [dataRequest, error] = await deleteDataRequestSubmissions(dataRequestId, { submissionIds });
    setSaving(false);

    if (error) {
      logError(error, "[DeleteSubmissionsDialog] deleteDataRequestSubmissions");
      sendNotificationError("Failed to delete");
      return;
    }

    sendNotification("Deleted successfully");
    onApply(dataRequest);
  };

  const prompt =
    recipientNames.length > 1
      ? `Delete ${recipientNames.length} submissions?`
      : `Delete submission for ${recipientNames[0]}?`;

  const descriptionPart1 =
    recipientNames.length > 1
      ? `You're about to delete ${recipientNames.length} submissions.`
      : `You're about to delete the submission.`;

  const description = `${descriptionPart1} ${submissionIds.length > 1 ? "The submissions" : "It"} will be permanently deleted and will not be published on the portal.`;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              {prompt}
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              {description}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={isSaving} onClick={handleApply}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSubmissionsDialog;
