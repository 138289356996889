import { Grid2 } from "@mui/material";
import LoaderBox from "../../../../../shared/components/LoaderBox";
import { FundStage, FundWithFieldValues } from "../../../../api/adminApi";
import FundInfoCard from "./FundInfoCard";

interface Props {
  funds: FundWithFieldValues[];
  onFundClick: (id: string) => void;
  isLoading: boolean;
}

const FundsCardList = ({ funds, onFundClick, isLoading }: Props) => (
  <LoaderBox loading={isLoading}>
    <Grid2 container spacing={2.5}>
      {funds.map((fund, index) => (
        <Grid2 key={index}>
          <FundInfoCard
            name={fund.name}
            investorsCount={fund.investorsCount ?? 0}
            stage={fund.fieldValues["Stage"] as FundStage}
            onClick={() => onFundClick(fund.id)}
          />
        </Grid2>
      ))}
    </Grid2>
  </LoaderBox>
);

export default FundsCardList;
