import deepEqual from "fast-deep-equal";
import { FileAccessOption } from "../../../../../../../shared/api/fundraisingTypes";
import { ApiError } from "../../../../../../../shared/api/types";
import { combineComparers, distinct, numberComparerBy } from "../../../../../../../shared/utilities/arrayHelper";
import cloneDeep from "../../../../../../../shared/utilities/cloneDeep";
import { isImageFile, isPdfFile } from "../../../../../../../shared/utilities/fileHelper";
import { generateGuid } from "../../../../../../../shared/utilities/generateGuid";
import { fileToUploadValidator } from "../../../../../../../shared/utilities/validators";
import {
  FundraisingFile,
  UpdateFundraisingRequest,
  UploadFundraisingFileRequest,
} from "../../../../../../api/types/fundraisingTypes";

export interface FundraisingDocumentsState {
  initialFiles: FundraisingFile[];
  documents: FundraisingDocument[];
  orderedSections: string[];
  searchTerm: string;
  isSaving?: boolean;
}

export interface FundraisingDocument extends FundraisingFile {
  file?: File;
  uploadStatus?: UploadFileStatus;
  validationError?: string;
  uploadError?: ApiError;
  isDownloading?: boolean;
}

export type UploadFileStatus = "ready_for_upload" | "uploading" | "upload_completed" | "error";

const isPersistedDocument = (doc: FundraisingDocument): boolean => doc.uploadStatus === undefined;

const sortFiles = <T extends FundraisingFile>(files: T[], orderedSections: string[]): T[] =>
  files
    .map((doc, docIndex) => ({
      ...doc,
      docIndex,
      sectionOrder: orderedSections.indexOf(doc.section ?? ""),
    }))
    .sort(
      combineComparers(
        numberComparerBy((d) => (d.sectionOrder < 0 ? Infinity : d.sectionOrder)),
        numberComparerBy((d) => d.docIndex)
      )
    );

const appendMissingSectionsFromFiles = (files: FundraisingFile[], existingSections: string[]): string[] => {
  const missingSections = files.reduce<string[]>(
    (result, file) => (file.section && !existingSections.includes(file.section) ? [...result, file.section] : result),
    []
  );

  return distinct([...existingSections, ...missingSections]);
};

export const getInitialFundraisingDocumentsState = (
  files: FundraisingFile[],
  fileSections: string[]
): FundraisingDocumentsState => {
  const orderedSections = appendMissingSectionsFromFiles(files, fileSections);

  return {
    initialFiles: cloneDeep(files),
    documents: sortFiles(files, orderedSections),
    orderedSections,
    searchTerm: "",
  };
};

// Selectors

export const getFilteredDocuments = (state: FundraisingDocumentsState): FundraisingDocument[] =>
  state.searchTerm === ""
    ? [...state.documents]
    : state.documents.filter((doc) => doc.name.toLowerCase().includes(state.searchTerm));

const getDocumentsForUpload = (state: FundraisingDocumentsState): FundraisingDocument[] =>
  state.documents.filter((doc) => doc.uploadStatus === "ready_for_upload" && doc.file);

const getDocumentsWithErrors = (state: FundraisingDocumentsState): FundraisingDocument[] =>
  state.documents.filter((doc) => doc.uploadStatus === "error" && doc.file);

interface UploadFundraisingFileRequestWithFileId extends UploadFundraisingFileRequest {
  fileId: string;
}

export const getUploadRequests = (state: FundraisingDocumentsState): UploadFundraisingFileRequestWithFileId[] => {
  const documentsForUpload = getDocumentsForUpload(state);
  return documentsForUpload.map(({ fileId, file, externalCategoryId, section, accessOptions }) => ({
    fileId,
    file: file as File,
    externalCategoryId,
    section,
    accessOptions,
  }));
};

export const getUpdateRequest = (
  state: FundraisingDocumentsState,
  uploadedFileIdMap: Record<string, string>
): Partial<UpdateFundraisingRequest> => {
  const uploadedFileIds = Object.keys(uploadedFileIdMap);
  const documentsToUpload = state.documents.filter(
    (doc) => isPersistedDocument(doc) || uploadedFileIds.includes(doc.fileId)
  );

  // Always send all persisted files to the server; the absence means the file should be deleted
  const fileUpdates = documentsToUpload.map((doc) => ({
    fileId: uploadedFileIdMap[doc.fileId] ?? doc.fileId,
    externalCategoryId: doc.externalCategoryId,
    section: doc.section,
    accessOptions: doc.accessOptions,
  }));

  const allFileSections = documentsToUpload.map((doc) => doc.section ?? "").filter(Boolean);

  // Only send sections that are present in persisted files since we don't allow creating sections separately from files
  const fileSections = distinct(state.orderedSections.filter((section) => allFileSections.includes(section)));

  return { fileUpdates, fileSections };
};

const toChangesModel = (file: FundraisingFile) => ({
  fileId: file.fileId,
  name: file.name,
  externalCategoryId: file.externalCategoryId,
  section: file.section,
  accessOptions: file.accessOptions,
});

const isAnyPersistedDocumentUpdated = (state: FundraisingDocumentsState): boolean => {
  const persistedDocuments = state.documents.filter(isPersistedDocument).map(toChangesModel);
  const initialFiles = state.initialFiles.map(toChangesModel);
  return !deepEqual(persistedDocuments, initialFiles);
};

export const doChangesExist = (state: FundraisingDocumentsState): boolean =>
  getDocumentsForUpload(state).length > 0 || isAnyPersistedDocumentUpdated(state);

export const doChangesOrErrorsExist = (state: FundraisingDocumentsState): boolean =>
  doChangesExist(state) || getDocumentsWithErrors(state).length > 0;

export const isChangeAccessOptionsAllowed = (doc: FundraisingDocument) => isPdfFile(doc.name) || isImageFile(doc.name);

export const selectAccessOptions: [string, FileAccessOption[]][] = [
  ["View", ["View"]],
  ["View & Download", ["View", "Download"]],
];

// Actions

type StateAction = (state: FundraisingDocumentsState) => FundraisingDocumentsState;

export const searchAction =
  (value: string): StateAction =>
  (state) => ({ ...state, searchTerm: value.trim().toLowerCase() });

export const startSavingAction = (): StateAction => (state) => ({ ...state, isSaving: true });

export const finishSavingAction =
  (updatedFiles: FundraisingFile[]): StateAction =>
  (state) => ({
    ...state,
    initialFiles: cloneDeep(updatedFiles),
    documents: [
      ...state.documents.filter((d) => d.uploadStatus === "error"),
      ...sortFiles(updatedFiles, state.orderedSections),
    ],
    searchTerm: "",
    isSaving: false,
  });

export const startDownloadingFileAction =
  (fileId: string): StateAction =>
  (state) => ({
    ...state,
    documents: state.documents.map((doc) => (doc.fileId === fileId ? { ...doc, isDownloading: true } : doc)),
  });

export const stopDownloadingFileAction = (): StateAction => (state) => ({
  ...state,
  documents: state.documents.map((doc) => ({ ...doc, isDownloading: false })),
});

export const maxFileSize = 50 * 1024 * 1024;

export const acceptedFileExtensions = [
  ".pdf",
  ".docx",
  ".doc",
  ".xlsx",
  ".xlsm",
  ".xls",
  ".pptx",
  ".ppt",
  ".jpg",
  ".jpeg",
  ".png",
  ".webp",
];

const validateFile = fileToUploadValidator({
  maxFileSize,
  acceptedFileExtensions,
});

export const addDocumentsToUploadAction =
  (files: File[], externalCategoryId: string | undefined, section: string): StateAction =>
  (state) => {
    const documentsToUpload: FundraisingDocument[] = files.map((file) => {
      const validationResult = validateFile(file);
      return {
        file,
        fileId: generateGuid(),
        name: file.name,
        size: file.size,
        uploadStatus: validationResult.isValid ? "ready_for_upload" : "error",
        validationError: validationResult.error,
        externalCategoryId,
        section,
      };
    });

    const newDocuments: FundraisingDocument[] = [...state.documents, ...documentsToUpload];

    const orderedSections = state.orderedSections.includes(section)
      ? [...state.orderedSections]
      : [...state.orderedSections, section];

    return { ...state, documents: sortFiles(newDocuments, orderedSections), orderedSections };
  };

export const updateDocumentsSectionAction =
  (fileIds: string[], section: string): StateAction =>
  (state) => {
    const newDocuments = state.documents.map((doc) =>
      fileIds.includes(doc.fileId) ? { ...doc, section } : { ...doc }
    );

    const orderedSections = state.orderedSections.includes(section)
      ? [...state.orderedSections]
      : [...state.orderedSections, section];

    return {
      ...state,
      documents: sortFiles(newDocuments, orderedSections),
      orderedSections,
    };
  };

export const updateDocumentsCategoryAction =
  (fileIds: string[], externalCategoryId: string | undefined): StateAction =>
  (state) => {
    const newDocuments = state.documents.map((doc) =>
      fileIds.includes(doc.fileId) ? { ...doc, externalCategoryId } : { ...doc }
    );

    return {
      ...state,
      documents: sortFiles(newDocuments, state.orderedSections),
    };
  };

export const updateDocumentsAccessOptionsAction =
  (fileIds: string[], accessOptions: FileAccessOption[]): StateAction =>
  (state) => {
    const newDocuments = state.documents.map((doc) =>
      fileIds.includes(doc.fileId) ? { ...doc, accessOptions } : { ...doc }
    );

    return {
      ...state,
      documents: sortFiles(newDocuments, state.orderedSections),
    };
  };

export const uploadInProgressAction =
  (fileId: string): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.map((doc) =>
      doc.fileId === fileId ? { ...doc, uploadStatus: "uploading" as UploadFileStatus } : doc
    );

    return { ...state, documents: updatedDocuments };
  };

export const uploadErrorAction =
  (fileId: string, error: ApiError): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.map((doc) =>
      doc.fileId === fileId ? { ...doc, uploadStatus: "error" as UploadFileStatus, uploadError: error } : doc
    );

    return { ...state, documents: updatedDocuments };
  };

export const uploadCompletedAction =
  (fileId: string): StateAction =>
  (state) => {
    const updatedDocuments = state.documents.map((doc) =>
      doc.fileId === fileId ? { ...doc, uploadStatus: "upload_completed" as UploadFileStatus } : doc
    );

    return { ...state, documents: updatedDocuments };
  };

export const deleteDocumentsAction =
  (fileIds: string[]): StateAction =>
  (state) => {
    const newDocuments = state.documents.filter((doc) => !fileIds.includes(doc.fileId));
    return { ...state, documents: newDocuments };
  };

export const moveDocumentUpAction =
  (fileId: string): StateAction =>
  (state) => {
    const index = state.documents.findIndex((doc) => doc.fileId === fileId);
    if (index <= 0) {
      return state;
    }

    const newDocuments = [...state.documents];

    [newDocuments[index - 1], newDocuments[index]] = [
      newDocuments[index] as FundraisingDocument,
      newDocuments[index - 1] as FundraisingDocument,
    ];

    return { ...state, documents: newDocuments };
  };

export const moveDocumentDownAction =
  (fileId: string): StateAction =>
  (state) => {
    const index = state.documents.findIndex((doc) => doc.fileId === fileId);
    if (index < 0 || index >= state.documents.length - 1) {
      return state;
    }

    const newDocuments = [...state.documents];

    [newDocuments[index], newDocuments[index + 1]] = [
      newDocuments[index + 1] as FundraisingDocument,
      newDocuments[index] as FundraisingDocument,
    ];

    return { ...state, documents: newDocuments };
  };

export const moveSectionUpAction =
  (section: string): StateAction =>
  (state) => {
    const index = state.orderedSections.indexOf(section);
    if (index <= 0) {
      return state;
    }

    const newSections = [...state.orderedSections];
    [newSections[index - 1], newSections[index]] = [newSections[index] as string, newSections[index - 1] as string];

    return {
      ...state,
      orderedSections: newSections,
      documents: sortFiles(state.documents, newSections),
    };
  };

export const moveSectionDownAction =
  (section: string): StateAction =>
  (state) => {
    const index = state.orderedSections.indexOf(section);
    if (index < 0 || index >= state.orderedSections.length - 1) {
      return state;
    }

    const newSections = [...state.orderedSections];
    [newSections[index], newSections[index + 1]] = [newSections[index + 1] as string, newSections[index] as string];

    return {
      ...state,
      orderedSections: newSections,
      documents: sortFiles(state.documents, newSections),
    };
  };

export const renameSectionAction =
  (oldSection: string, newSection: string): StateAction =>
  (state) => {
    const index = state.orderedSections.indexOf(oldSection);
    if (index < 0) {
      return state;
    }

    const newSections = [...state.orderedSections];
    newSections[index] = newSection;

    const newDocuments = state.documents.map((doc) =>
      doc.section === oldSection ? { ...doc, section: newSection } : { ...doc }
    );

    return { ...state, orderedSections: newSections, documents: newDocuments };
  };
