import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../../shared/icons/BinIcon";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";

const deleteConfiguration = withErrorHandling(adminApi.deleteDataModelConfiguration);

interface Props {
  deletedConfiguration: DataModelConfiguration;
  onClose: () => void;
  onDeleted: (deletedConfiguration: DataModelConfiguration) => void;
}

const DeleteConfigurationDialog = ({ deletedConfiguration, onClose, onDeleted }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    setLoading(true);
    const [, error] = await deleteConfiguration(deletedConfiguration.id);
    setLoading(false);

    if (error) {
      logError(error, "[DeleteConfigurationDialog]");
      sendNotificationError("Failed to delete the configuration");
      return;
    }

    sendNotification("Configuration deleted successfully");
    onDeleted(deletedConfiguration);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Delete configuration?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You&apos;re about to delete <strong>{deletedConfiguration.name}</strong> configuration. This action is
              permanent and can&apos;t be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={loading} onClick={handleDeleteClick}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfigurationDialog;
