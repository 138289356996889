import { generatePath, useMatch } from "react-router";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

export default function OrganizationSmartViewsMenu() {
  const { clientCode, hasAnyPermission } = useClientContext();

  const routePattern = `/:clientCode/${pageRoutes.smartViews}/:page/*`;
  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const hasAccessToOrganizationReports = hasAnyPermission(["ManageOrganizationReports"]);
  const hasAccessToOrganizationTemplates = hasAnyPermission(["ManageOrganizationReportTemplates"]);

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  return (
    <SubMenuList>
      {hasAccessToOrganizationReports && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.organizationReports}
          title="Reports"
          href={buildFullPath(pageRoutes.organizationReports)}
        />
      )}
      {hasAccessToOrganizationTemplates && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.reportTemplates}
          title="Report Templates"
          href={buildFullPath(pageRoutes.reportTemplates)}
        />
      )}
      {hasAccessToOrganizationReports && (
        <MenuLinkItem
          key="apiReporting"
          subItem
          active={currentPage === pageRoutes.organizationApiReports}
          title="Analytics API"
          href={buildFullPath(pageRoutes.organizationApiReports)}
        />
      )}
    </SubMenuList>
  );
}
