import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { MetricTableBlockRow, TableBlockColumn } from "../../../../api/inputFormTypes";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import MetricSelectField from "../fields/MetricSelectField";
import MetricTextField from "../fields/MetricTextField";
import MultilineMetricTextField from "../fields/MultilineMetricTextField";
import TrafficLightSelectField, { TrafficLightValue } from "../fields/TrafficLightSelectField";
import ScenarioColumnHeader from "./ScenarioColumnHeader";

interface Props {
  blockId: string;
  columnDefinitions: TableBlockColumn[];
  rowDefinitions: MetricTableBlockRow[];
}

const trafficLightToNumberMap: Record<TrafficLightValue, number> = {
  green: 1,
  yellow: 2,
  red: 3,
};

const numberToTrafficLightMap: Record<number, TrafficLightValue> = Object.fromEntries(
  Object.entries(trafficLightToNumberMap).map(([key, value]) => [value, key as TrafficLightValue])
);

const HeadRow = ({ columnDefinitions }: { columnDefinitions: TableBlockColumn[] }) => {
  return (
    <TableRow>
      <TableCell>Metric</TableCell>
      {columnDefinitions.map((columnDef) => (
        <TableCell key={columnDef.id}>
          <ScenarioColumnHeader columnDefinition={columnDef} />
        </TableCell>
      ))}
    </TableRow>
  );
};

interface CellEditorProps {
  row: MetricTableBlockRow;
  columnDefinition: TableBlockColumn;
  onValueChange: (rowId: string, columnId: string, value: unknown) => void;
}

const CellEditor = ({ row, columnDefinition, onValueChange }: CellEditorProps) => {
  const { isSubmissionEditable } = useDataSubmissionFormContext();

  if (row.type !== "Metric") {
    return null;
  }

  const cellValue = row.values[columnDefinition.id];

  const readOnly = !columnDefinition.isEditable;
  const disabled = !isSubmissionEditable || readOnly;

  if (row.dataType === "Select" && row.valueSource) {
    return (
      <MetricSelectField
        value={cellValue?.toString()}
        onChange={(value) => onValueChange(row.id, columnDefinition.id, value)}
        valueSource={row.valueSource}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  if (row.dataType === "Text") {
    return (
      <MultilineMetricTextField
        value={cellValue?.toString() ?? ""}
        onChange={(value) => onValueChange(row.id, columnDefinition.id, value)}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  if (row.dataType === "Number") {
    const numericValue = Number(cellValue) || undefined;
    const trafficLightValue = numericValue ? numberToTrafficLightMap[numericValue] : undefined;
    return (
      <TrafficLightSelectField
        value={trafficLightValue}
        onChange={(value) => onValueChange(row.id, columnDefinition.id, trafficLightToNumberMap[value])}
        readOnly={readOnly}
        disabled={disabled}
      />
    );
  }

  return (
    <MetricTextField
      value={cellValue?.toString() ?? ""}
      onChange={(value) => onValueChange(row.id, columnDefinition.id, value)}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

interface BodyRowProps {
  row: MetricTableBlockRow;
  columnDefinitions: TableBlockColumn[];
  onValueChange: (rowId: string, columnId: string, value: unknown) => void;
}

const BodyRow = ({ row, columnDefinitions, onValueChange }: BodyRowProps) => {
  if (row.type !== "Metric") {
    return null;
  }

  return (
    <TableRow>
      <TableCell>{row.name}</TableCell>
      {columnDefinitions.map((columnDef) => (
        <TableCell key={columnDef.id}>
          <CellEditor row={row} columnDefinition={columnDef} onValueChange={onValueChange} />
        </TableCell>
      ))}
    </TableRow>
  );
};

const MonthlyInternalTableInputForm = ({ blockId, columnDefinitions, rowDefinitions }: Props) => {
  const [rows, setRows] = useState<MetricTableBlockRow[]>([...rowDefinitions]);

  const { onBlockCellValueEdit } = useDataSubmissionFormContext();

  const handleValueChange = (rowId: string, columnId: string, value: unknown) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.id === rowId
          ? {
              ...row,
              values: {
                ...row.values,
                [columnId]: value,
              },
            }
          : row
      )
    );

    onBlockCellValueEdit(blockId, {
      rowId,
      columnId,
      value,
    });
  };

  return (
    <TableContainer>
      <Table
        sx={(t) => ({
          ".MuiTableCell-head": {
            ":first-of-type": {
              borderRight: `1px solid ${t.palette.divider}`,
            },
          },
          ".MuiTableCell-body": {
            borderBottom: 0,
            ":first-of-type": {
              borderRight: `1px solid ${t.palette.divider}`,
            },
          },
        })}
      >
        <TableHead>
          <HeadRow columnDefinitions={columnDefinitions} />
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <BodyRow key={row.id} row={row} columnDefinitions={columnDefinitions} onValueChange={handleValueChange} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MonthlyInternalTableInputForm;
