import { LoadingButton } from "@mui/lab";
import { Box, Button, FormControlLabel, Paper, Stack, Switch, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { isValid as isValidDate, parseISO, startOfToday } from "date-fns";
import { ChangeEvent, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import HtmlEditor from "../../../../../shared/components/htmlEditor/HtmlEditor";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { stringToBase64 } from "../../../../../shared/utilities/stringHelper";
import { invalidResult, requiredValidator, validResult } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { UserConsentDetails } from "../../../../api/types/userConsentTypes";
import UpdateConsentVersionDialog from "./UpdateConsentVersionDialog";

interface Props {
  consentDetails: UserConsentDetails;
  onUpdated: (consentDetails: UserConsentDetails) => void;
  readOnly: boolean;
}

type FormField = "confirmationText" | "content" | "activationDate" | "isActive";

interface EditorState {
  confirmationText: string;
  htmlContent: string;
  activationDate: Date | null;
  isActive: boolean;
  touchedFields: FormField[];
  isSaving: boolean;
  htmlEditorRenderKey: number;
}

interface ValidationResult {
  isValid: boolean;
  errors: Partial<Record<FormField, string | undefined>>;
}

const getInitialEditorState = (consentDetails: UserConsentDetails): EditorState => ({
  confirmationText: consentDetails.customAgreementText || `I agree to ${consentDetails.name}`,
  htmlContent: consentDetails.content ?? "",
  activationDate: consentDetails.activeFrom ? parseISO(consentDetails.activeFrom) : null,
  isActive: !!consentDetails.activeFrom,
  touchedFields: [],
  isSaving: false,
  htmlEditorRenderKey: 1,
});

const validate = (state: EditorState): ValidationResult => {
  const confirmationTextValidationResult = requiredValidator(state.confirmationText);
  const confirmationTextError = state.touchedFields.includes("confirmationText")
    ? confirmationTextValidationResult.error
    : undefined;

  const contentValidationResult = requiredValidator(state.htmlContent);
  const contentError = state.touchedFields.includes("content") ? contentValidationResult.error : undefined;

  const activationDateValidationResult =
    state.isActive && (state.activationDate === null || !isValidDate(state.activationDate))
      ? invalidResult("Invalid date")
      : validResult();
  const activationDateError = state.touchedFields.includes("activationDate")
    ? activationDateValidationResult.error
    : undefined;

  const isValid =
    confirmationTextValidationResult.isValid &&
    contentValidationResult.isValid &&
    activationDateValidationResult.isValid;

  return {
    isValid,
    errors: {
      confirmationText: confirmationTextError,
      content: contentError,
      activationDate: activationDateError,
    },
  };
};

const htmlEditorToolbar = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "italic", "underline"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  ["clean"],
];

const updateUserConsent = withErrorHandling(adminApi.updateUserConsent);

const UserConsentEditor = ({ consentDetails, onUpdated, readOnly }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [state, setState] = useState(getInitialEditorState(consentDetails));
  const [showUpdateVariantDialog, setShowUpdateVariantDialog] = useState(false);

  const handleConfirmationTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      confirmationText: e.target.value,
      touchedFields: [...prev.touchedFields, "confirmationText"],
    }));
  };

  const handleHtmlContentChange = (value: string, source: "user" | "api" | "silent") => {
    setState((prev) => ({
      ...prev,
      htmlContent: value,
      touchedFields: source === "user" ? [...prev.touchedFields, "content"] : prev.touchedFields,
    }));
  };

  const handleIsActiveChange = (isActive: boolean) => {
    setState((prev) => ({
      ...prev,
      isActive,
      activationDate: isActive && prev.activationDate === null ? startOfToday() : prev.activationDate,
      touchedFields: [...prev.touchedFields, "isActive"],
    }));
  };

  const handleActivationDateChange = (date: Date | null) => {
    setState((prev) => ({
      ...prev,
      activationDate: date,
      touchedFields: [...prev.touchedFields, "activationDate"],
    }));
  };

  const handleConfirmSave = async (updateVersion: boolean) => {
    setShowUpdateVariantDialog(false);

    setState((prev) => ({ ...prev, isSaving: true }));

    const payload = {
      contentEncoded: stringToBase64(state.htmlContent),
      activeFrom: state.isActive && state.activationDate !== null ? state.activationDate.toISOString() : undefined,
      customAgreementText: state.confirmationText,
    };

    const [updatedConsent, error] = await updateUserConsent(consentDetails.name, payload, updateVersion);

    setState((prev) => ({ ...prev, isSaving: false }));

    if (error) {
      logError(error, "[UserConsentEditor] updateUserConsent");
      sendNotificationError("Could not update user consent");
      return;
    }

    setState((prev) => ({ ...prev, touchedFields: [] }));
    sendNotification(`User consent updated successfully`);
    onUpdated(updatedConsent);
  };

  const handleSave = async () => {
    if (state.isActive) {
      setShowUpdateVariantDialog(true);
    } else {
      await handleConfirmSave(false);
    }
  };

  const handleReset = () => {
    setState({ ...getInitialEditorState(consentDetails), htmlEditorRenderKey: -state.htmlEditorRenderKey });
  };

  const validationResult = validate(state);

  return (
    <>
      <Stack spacing={4} flex={1} pt={4} height="100%">
        <Box>
          <HtmlEditor
            disabled={readOnly}
            key={state.htmlEditorRenderKey}
            initialHtmlContent={consentDetails.content ?? ""}
            onChange={handleHtmlContentChange}
            styles={{ editor: { height: "calc(100vh - 560px)" } }}
            toolbar={htmlEditorToolbar}
          />
        </Box>

        <TextField
          disabled={readOnly}
          fullWidth
          label="Confirmation text"
          value={state.confirmationText}
          onChange={handleConfirmationTextChange}
          error={!!validationResult.errors.confirmationText}
          helperText={validationResult.errors.confirmationText}
        />

        <Paper variant="outlined">
          <Stack spacing={1} p={2}>
            <FormControlLabel
              sx={{ alignItems: "flex-start" }}
              control={
                <Switch
                  disabled={readOnly}
                  checked={state.isActive}
                  onChange={(_, checked) => handleIsActiveChange(checked)}
                />
              }
              label={
                <Stack sx={{ pl: 2 }}>
                  <Typography variant="subtitle2">Activation Date</Typography>
                  <Typography color="text.secondary" sx={{ mb: 1 }}>
                    Date when consent will be activated on the portal. If the date is not set, consent won’t be visible
                    to investors. After activation, you can deactivate the consent by turning off this toggle. To
                    activate again, turn the toggle back on and pick a new date.
                  </Typography>
                </Stack>
              }
            />

            <DatePicker
              disablePast
              value={state.activationDate}
              onChange={handleActivationDateChange}
              disabled={readOnly || !state.isActive}
              slotProps={{
                textField: {
                  size: "small",
                  sx: { width: "14rem" },
                  error: !!validationResult.errors.activationDate,
                },
              }}
            />
          </Stack>
        </Paper>

        <Stack direction="row" spacing={1}>
          <LoadingButton
            variant="contained"
            loading={state.isSaving}
            onClick={handleSave}
            disabled={readOnly || state.touchedFields.length === 0 || !validationResult.isValid}
          >
            Save
          </LoadingButton>
          <Button
            variant="text"
            color="secondary"
            onClick={handleReset}
            disabled={readOnly || state.touchedFields.length === 0}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>

      <UpdateConsentVersionDialog
        open={showUpdateVariantDialog}
        onClose={() => setShowUpdateVariantDialog(false)}
        onConfirm={handleConfirmSave}
      />
    </>
  );
};

export default UserConsentEditor;
