import { Stack } from "@mui/material";
import { useMemo } from "react";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { Investor, InvestorWithFieldValues } from "../../../../api/adminApi";
import { Field } from "../../../../api/types/objectTypes";
import { useFilterContext } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { filterRecords } from "../../../common/filters/filterState";
import { getInvestorsGridColumns } from "./investorsGridDefinitions";

interface Props {
  investors: InvestorWithFieldValues[];
  fields: Field[];
  isLoading: boolean;
  onNavigateToInvestorDetails: (investorId: string) => void;
  onDeleteInvestor: (investor: Investor) => void; // #6829 not used for now: disabled on the backend
}

const InvestorsGrid = ({ investors, fields, isLoading, onNavigateToInvestorDetails }: Props) => {
  const { filterState } = useFilterContext<InvestorWithFieldValues>();

  const columns = useMemo(
    () => getInvestorsGridColumns(fields, filterState, onNavigateToInvestorDetails),
    [fields, filterState, onNavigateToInvestorDetails]
  );

  const filteredInvestors = filterRecords(filterState, investors).sort((a, b) => a.title.localeCompare(b.title));

  return (
    <>
      <Stack spacing={2} height="100%" px={3} pb={1} pt={2.5}>
        <FiltersPanel
          totalCount={investors.length}
          recordCount={filteredInvestors.length}
          isLoading={isLoading}
          hasColumnSelection
        />
        <DataGrid<InvestorWithFieldValues>
          getRowId={(row) => row.id}
          noRowsText="No investors"
          loading={isLoading}
          columns={columns}
          rows={filteredInvestors}
          disableColumnReorder
        />
      </Stack>
    </>
  );
};

export default InvestorsGrid;
