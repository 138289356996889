import { Container, Stack, Typography } from "@mui/material";
import { useClientContext } from "../../../../context/ClientContext";
import ApplicationStatusTag from "../../../common/ApplicationStatusTag";
import InvestorPortalLinks from "./InvestorPortalLinks";

const GeneralInvestorPortalSettings = () => {
  const { investorPortalApp } = useClientContext();

  return (
    <Container maxWidth="md" disableGutters>
      <Stack py={2.5} spacing={3}>
        <Typography variant="subtitle1">Portal Links</Typography>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography color="secondary" width="3.5rem">
            Status
          </Typography>
          <ApplicationStatusTag appStatus={investorPortalApp?.status} />
        </Stack>
        <InvestorPortalLinks />
      </Stack>
    </Container>
  );
};

export default GeneralInvestorPortalSettings;
