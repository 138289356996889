import { getSharedVariables } from "../../variables";
import { RedirectKeys } from "../authTypes";

export function redirectToEmailVerification() {
  window.location.href = `${getSharedVariables().LOGIN_APP_URI}/verificationnotcompleted`;
}

export function redirectToLoginSignin() {
  const currentUrl = new URL(window.location.href);
  const urlParams = new URLSearchParams();
  const prompt = currentUrl.searchParams.get(RedirectKeys.Prompt);
  if (prompt !== null) {
    urlParams.set(RedirectKeys.Prompt, prompt);
    currentUrl.searchParams.delete(RedirectKeys.Prompt);
  }
  urlParams.set(
    RedirectKeys.State,
    JSON.stringify({ origin: currentUrl.origin, originalPath: `${currentUrl.pathname}${currentUrl.search}` })
  );
  window.location.href = `${getSharedVariables().LOGIN_APP_URI}/signin?${urlParams.toString()}`;
}

export function redirectToLogout() {
  window.location.href = `${getSharedVariables().LOGIN_APP_URI}/logout`;
}

export function redirectToUnauthorized() {
  window.location.href = `${getSharedVariables().LOGIN_APP_URI}/unauthorized`;
}
