import { Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowSelectionModel,
  GridSortModel,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useRef, useState } from "react";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";
import { convertISODateTime } from "../../../../../../../shared/utilities/dateUtils";
import { nameof } from "../../../../../../../shared/utilities/typeHelper";
import CustomDataGridHeaderToolbar from "../../../../../common/grid/CustomDataGridHeaderToolbar";
import GroupHeaderWithSelection from "../../../../../common/grid/GroupHeaderWithSelection";
import { PrivateChip, SharedChip } from "../../grid/AccessChips";
import { CustomToolbarActions } from "../../grid/CustomToolbarActions";
import NameColumn from "../../grid/NameColumn";
import { getViewApiReportUrl } from "../../utilities/editReportUrl";
import ReportMenuAction from "../ReportMenuAction";
import { useApiReportsManagerContext } from "../contexts/ReportsUiManagerContext";

interface Props {
  reports: ReportInfo[];
}

const ReportsGrid = ({ reports }: Props) => {
  const { deleteReports } = useApiReportsManagerContext();
  const [currentReport, setCurrentReport] = useState<ReportInfo>();
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: nameof<ReportInfo>("name"), sort: "asc" }]);

  const apiRef = useGridApiRef();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const currentReportRef = useRef<ReportInfo>();
  currentReportRef.current = currentReport;

  const showToolbar = useMemo(() => selectedIds.length > 0, [selectedIds]);
  const selectedReports = useMemo(
    () => selectedIds.map((id) => reports.find((r) => r.reportId === id)).filter((r): r is ReportInfo => !!r),
    [reports, selectedIds]
  );

  const columns = useMemo(
    (): GridColDef<ReportInfo>[] => [
      {
        field: nameof<ReportInfo>("name"),
        headerName: "Name",
        sortable: true,
        aggregable: false,
        hideable: false,
        pinnable: false,
        filterable: false,
        flex: 1,
        renderHeader: (params) => (
          <GroupHeaderWithSelection
            headerName={params.colDef.headerName}
            permissions={["ManageReports"]}
            hideExpandCollapse
          />
        ),
        renderCell: (params: GridRenderCellParams<ReportInfo, unknown, unknown>) => {
          return (
            <NameColumn
              report={params.row}
              href={getViewApiReportUrl(params.row.clientCode, params.row.reportId)}
              permissions={["ManageReports"]}
              sx={{ "& .highlighted-action": { pl: 1 } }}
            />
          );
        },
      },
      {
        field: nameof<ReportInfo>("authorization"),
        headerName: "Access",
        flex: 0.3,
        renderCell: (params) => {
          if (params.row.authorization.private) {
            return <PrivateChip />;
          }
          return <SharedChip />;
        },
        valueGetter: (_, row) => row.authorization?.private ?? false,
      },
      {
        field: nameof<ReportInfo>("createdBy"),
        headerName: "Created by",
        flex: 0.5,
      },
      {
        field: nameof<ReportInfo>("modifiedBy"),
        headerName: "Modified by",
        flex: 0.5,
      },
      {
        field: nameof<ReportInfo>("updateAt"),
        headerName: "Modified at",
        flex: 0.4,
        valueFormatter: (value) => convertISODateTime(value),
        renderCell: (params) => (
          <Typography className="MuiDataGrid-cellContent" color={"secondary"}>
            {params.formattedValue}
          </Typography>
        ),
      },
      {
        field: "actions",
        sortable: false,
        resizable: false,
        headerName: "",
        width: 80,
        cellClassName: "grid-row-actions",
        align: "right",
        renderCell: (params) => <ReportMenuAction report={params.row} onReportSelected={setCurrentReport} />,
      },
    ],
    []
  );

  const getRowClassName = useCallback((params: GridRowClassNameParams<ReportInfo>) => {
    if (params.row.reportId === currentReportRef.current?.reportId) {
      return "Mui-hovered";
    }
    return "";
  }, []);

  const handleRowSelectionChanged = useCallback(
    (ids: GridRowSelectionModel) => {
      setSelectedIds(ids as string[]);
    },
    [setSelectedIds]
  );

  return (
    <DataGrid<ReportInfo>
      apiRef={apiRef}
      columns={columns}
      rows={reports}
      getRowId={(row) => row.reportId}
      slots={{
        toolbar: () => {
          return showToolbar ? (
            <CustomDataGridHeaderToolbar
              sx={{
                px: 0,
                width: "100%",
                borderBottom: "1px solid",
                borderColor: "divider",
              }}
              selection={[selectedIds, setSelectedIds]}
            >
              <CustomToolbarActions selectedReports={selectedReports} onDelete={deleteReports} hideCopy />
            </CustomDataGridHeaderToolbar>
          ) : null;
        },
      }}
      sx={(theme) => ({
        ".MuiDataGrid-columnHeaders": {
          visibility: showToolbar ? "hidden" : "visible",
          height: showToolbar ? 0 : undefined,
          borderColor: theme.palette.divider,
          ".MuiDataGrid-columnHeader:nth-of-type(2)": {
            p: 0,
            outline: "none",
          },

          ".MuiDataGrid-columnHeader": {
            "&--sorted": {
              " .MuiDataGrid-iconButtonContainer": {
                visibility: "inherit",
              },
            },
            ".MuiCheckbox-root": {
              visibility: "hidden",

              "&.MuiCheckbox-indeterminate": {
                visibility: "visible",
              },
              "&.Mui-checked": {
                visibility: "visible",
              },
            },
            "&:focus": {
              outline: "none",
            },

            "&:hover": {
              ".MuiCheckbox-root": {
                visibility: "visible",
              },
            },
          },
        },

        ".MuiDataGrid-row": {
          ".MuiCheckbox-root": {
            visibility: "hidden",

            "&.MuiCheckbox-indeterminate": {
              visibility: "visible",
            },
            "&.Mui-checked": {
              visibility: "visible",
            },
          },
          "&:hover": {
            ".MuiDataGrid-cell.grid-row-actions": {
              button: {
                visibility: "visible",
              },
            },
            ".highlighted-action": {
              color: theme.palette.primary.main,
            },
            ".MuiCheckbox-root": {
              visibility: "visible",
            },
          },
          "&.Mui-selected": {
            ".MuiCheckbox-root": {
              visibility: "visible",
            },
          },
          ".MuiDataGrid-cell": {
            outline: "none",
            borderBottomColor: theme.palette.divider,
          },
          ".MuiDataGrid-cell:nth-of-type(2)": {
            paddingLeft: 0,
          },
        },
        ".MuiDataGrid-cell": {
          "&.grid-row-actions": {
            button: {
              visibility: "hidden",
            },
          },
        },
      })}
      showCellVerticalBorder={false}
      showColumnVerticalBorder={false}
      disableAggregation
      getRowClassName={getRowClassName}
      rowSelectionModel={selectedIds}
      sortingOrder={["asc", "desc"]}
      disableMultipleColumnsSorting
      sortModel={sortModel}
      onRowSelectionModelChange={handleRowSelectionChanged}
      onSortModelChange={setSortModel}
      shiftActionsLeft
    />
  );
};

export default ReportsGrid;
