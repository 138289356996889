import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import {
  allDataCollectionSubmissionStatuses,
  DataCollectionSubmissionStatus,
} from "../../../../../shared/api/dataCollectionTypes";
import { logError } from "../../../../../shared/logging";
import { stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import { convertISODateOnly } from "../../../../../shared/utilities/dateUtils";
import { dataSubmissionStatusCaptionsMap } from "../../../../../shared/utilities/enumCaptions";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import adminApi from "../../../../api/adminApi";
import {
  allDataCollectionRequestAuditoryValues,
  DataCollectionRequestAuditory,
  DataCollectionSubmissionInfo,
  SearchSubmissionsRequest,
} from "../../../../api/types/dataCollectionTypes";
import { FilterState } from "../../../common/filters/filterState";
import {
  DateFilter,
  MultiSelectFilter,
  SelectOption,
  TableFilter,
  TableFilterDefinition,
} from "../../../common/filters/filterTypes";

const searchObjects = withErrorHandling(adminApi.searchObjects);
const getDataCollectionRequestTemplates = withErrorHandling(adminApi.getDataCollectionRequestTemplates);

const getRecipientOptions = async () => {
  const [resp, error] = await searchObjects("PortfolioCompany", { fieldIds: [] });
  if (error) {
    logError(error, "[getSelectOptions] searchObjects");
    return [];
  }

  return resp.items.map((i) => ({ value: i.id, label: i.name })).sort(stringComparerBy((c) => c.label));
};

const getTemplates = async () => {
  const [templates, error] = await getDataCollectionRequestTemplates();
  if (error) {
    logError(error, "[getSelectOptions] getDataCollectionRequestTemplates");
    return [];
  }
  return templates.map((t) => ({ value: t.templateId, label: t.name })).sort(stringComparerBy((c) => c.label));
};

export const selectOptionsResolver = async (filterId: string): Promise<SelectOption[]> => {
  switch (filterId) {
    case "recipientObjectId":
      return getRecipientOptions();
    case "requestTemplateId":
      return getTemplates();
    default: {
      logError(`Unknown async select filterId: ${filterId}`, "[getSelectOptions]");
      return [];
    }
  }
};
export const filterDefinitions: TableFilterDefinition<DataCollectionSubmissionInfo>[] = [
  {
    id: "recipientObjectId",
    name: "Portfolio Company",
    type: "multi_select",
    operatorOptions: ["one_of"],
    asyncOptions: true,
  },
  {
    id: "requestTemplateId",
    name: "Template",
    type: "multi_select",
    operatorOptions: ["one_of"],
    asyncOptions: true,
  },
  {
    id: "status",
    name: "Status",
    type: "multi_select",
    operatorOptions: ["one_of"],
    predefinedOptions: allDataCollectionSubmissionStatuses.map((status) => ({
      value: status,
      label: dataSubmissionStatusCaptionsMap[status],
    })),
  },
  {
    id: "auditory",
    name: "Recipient Type",
    type: "multi_select",
    operatorOptions: ["one_of"],
    predefinedOptions: allDataCollectionRequestAuditoryValues.map((auditory) => ({
      value: auditory,
      label: autoFormatCamelCase(auditory),
    })),
  },
  {
    id: "reportingDate",
    name: "Reporting Date",
    type: "date",
    operatorOptions: ["range"],
  },
];

export const getFilterRequestPayload = (
  filterState: FilterState<DataCollectionSubmissionInfo>
): Partial<SearchSubmissionsRequest> => {
  const getMultiSelectFilterValues = (
    filterId: string,
    visibleFilters: TableFilter<DataCollectionSubmissionInfo>[]
  ) => {
    const filter = visibleFilters.find((f) => f.id === filterId) as
      | MultiSelectFilter<DataCollectionSubmissionInfo>
      | undefined;
    const selectedValues = filter?.value?.operator === "one_of" ? filter.value.selectedValues : undefined;
    return selectedValues?.length ? selectedValues : undefined;
  };

  const getDateRangeFilterValues = (filterId: string, visibleFilters: TableFilter<DataCollectionSubmissionInfo>[]) => {
    const filter = visibleFilters.find((f) => f.id === filterId) as
      | DateFilter<DataCollectionSubmissionInfo>
      | undefined;
    if (filter?.value?.operator !== "range") {
      return [undefined, undefined];
    }

    const { range } = filter.value;
    const from = range?.[0];
    const to = range?.[1];
    return [from ? convertISODateOnly(from) : undefined, to ? convertISODateOnly(to) : undefined];
  };

  const recipientObjectIds = getMultiSelectFilterValues("recipientObjectId", filterState.visibleFilters);

  const requestTemplateIds = getMultiSelectFilterValues("requestTemplateId", filterState.visibleFilters);

  const statuses = getMultiSelectFilterValues("status", filterState.visibleFilters) as
    | DataCollectionSubmissionStatus[]
    | undefined;

  const auditory = getMultiSelectFilterValues("auditory", filterState.visibleFilters) as
    | DataCollectionRequestAuditory[]
    | undefined;

  const [reportingDateFrom, reportingDateTo] = getDateRangeFilterValues("reportingDate", filterState.visibleFilters);

  return {
    searchTerm: filterState.search.value || undefined,
    recipientObjectIds,
    requestTemplateIds,
    statuses,
    auditory,
    reportingDateFrom,
    reportingDateTo,
  };
};
