import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";
import { FundraisingDocument } from "../../../../api/adminApi";

export interface FundraisingDocumentsFilterValue {
  searchTerm: string;
  statuses: FundraisingStatus[];
  categoryIds: string[];
  fundIds: string[];
  investorIds: string[];
}

export const getInitialFilters = (): FundraisingDocumentsFilterValue => ({
  searchTerm: "",
  statuses: [],
  categoryIds: [],
  fundIds: [],
  investorIds: [],
});

const matchesSearch = (document: FundraisingDocument, searchTerm: string) =>
  document.fundraisingName.toLowerCase().includes(searchTerm) || document.name.toLowerCase().includes(searchTerm);

const matchesFilters = (document: FundraisingDocument, filter: FundraisingDocumentsFilterValue) =>
  matchesSearch(document, filter.searchTerm.trim().toLowerCase()) &&
  (filter.fundIds.length === 0 ||
    filter.fundIds.some((fundId) => document.fundInvestors.some((fi) => fi.fundId === fundId))) &&
  (filter.investorIds.length === 0 ||
    filter.investorIds.some((investorId) => document.fundInvestors.some((fi) => fi.investorId === investorId))) &&
  (filter.categoryIds.length === 0 || filter.categoryIds.includes(document.categoryId ?? "")) &&
  (filter.statuses.length === 0 || filter.statuses.includes(document.status));

export const filterFundraisingDocuments = (
  allDocuments: FundraisingDocument[],
  filter: FundraisingDocumentsFilterValue
): FundraisingDocument[] => allDocuments.filter((doc) => matchesFilters(doc, filter));
