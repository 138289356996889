import { Autocomplete, Box, ListItem, ListItemIcon, ListItemText, TextField } from "@mui/material";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import TypographyTooltipEllipsis from "../../../../../shared/components/TypographyTooltipEllipsis";
import { stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import FieldIcon from "../../../common/domain-icons/FieldIcon";
import FieldSourceBadge from "../../../entityFields/FieldSourceBadge";
import { LayoutField } from "./objectLayoutState";

interface Props {
  fields: LayoutField[];
  onAdd: (fieldId: string) => void;
  onCancel: () => void;
}

const AddFieldSelect = ({ fields, onAdd, onCancel }: Props) => {
  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") {
      e.stopPropagation();
      e.preventDefault();
      onCancel();
    }
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" pl={1} pr={1.5}>
      <Autocomplete
        fullWidth
        openOnFocus
        options={fields.sort(stringComparerBy((f) => f.name))}
        getOptionLabel={(f) => f.name}
        onChange={(_, field) => field && onAdd(field.id)}
        renderInput={(inputProps) => (
          <TextField {...inputProps} autoFocus placeholder="Type field name..." onKeyDown={handleInputKeyDown} />
        )}
        renderOption={(props, field) => (
          <ListItem
            {...props}
            key={field.id}
            secondaryAction={
              <Box pr={1} component="span">
                <FieldSourceBadge fieldSource={field.source} />
              </Box>
            }
          >
            <ListItemIcon sx={{ pl: 0, pr: 1.5, minWidth: 0 }}>
              <FieldIcon fieldType={field.type} color="action" />
            </ListItemIcon>
            <ListItemText sx={{ flexGrow: 1 }}>
              <TypographyTooltipEllipsis text={field.name} typographyProps={{ component: "span", pr: 4 }} />
            </ListItemText>
          </ListItem>
        )}
      />
      <CloseIconButton onClick={onCancel} />
    </Box>
  );
};

export default AddFieldSelect;
