import { Stack } from "@mui/material";
import { useState } from "react";
import CopyToBufferInput from "../../../../../shared/components/inputs/CopyToBufferInput";
import { combineValidators, emailValidator, requiredValidator } from "../../../../../shared/utilities/validators";
import { useClientContext } from "../../../../context/ClientContext";
import { getVariables } from "../../../../variables";

const signUpUrlEmailValidator = combineValidators(requiredValidator, emailValidator);

const InvestorPortalLinks = () => {
  const { clientCode } = useClientContext();
  const [signUpEmailText, setSignUpEmailText] = useState("email@example.com");

  const investorPortalUrl = `${getVariables().IR_PORTAL_URI}/${clientCode}`;

  return (
    <Stack spacing={4}>
      <CopyToBufferInput
        disabled
        label="Base Portal URL"
        addOpenButtonForUrl
        text={investorPortalUrl}
        description="Provides access to the portal's login page."
      />
      <CopyToBufferInput
        disabled
        label="Sign-up URL"
        text={`${investorPortalUrl}/register`}
        description="Users with configured access can use this link to sign up for an account on the portal."
      />
      <CopyToBufferInput
        label="Custom sign-up URL"
        beginDisabledText={`${investorPortalUrl}/register?email=`}
        text={signUpEmailText}
        onChange={setSignUpEmailText}
        inputValidator={signUpUrlEmailValidator}
        disableCopyIfNotValid
        description="Allows inviting a specific user to register for the portal. Replace email@example.com with the recipient's email, and share the link with them."
      />
    </Stack>
  );
};

export default InvestorPortalLinks;
