import { SvgIconComponent } from "@mui/icons-material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Collapse, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { PropsWithChildren } from "react";
import useToggleState from "../../../shared/hooks/useToggleState";
import { useMainMenuContext } from "../leftSideMenu/MainMenuContext";

interface Props {
  title: string;
  Icon?: SvgIconComponent;
}

const MenuGroupItem = ({ title, Icon, children }: PropsWithChildren<Props>) => {
  const { persistedClosedItems, addClosedItem, removeClosedItem } = useMainMenuContext();

  const [opened, toggleOpened] = useToggleState(!persistedClosedItems.includes(title));

  const handleToggleClick = () => {
    const wasOpened = opened;
    toggleOpened();
    if (wasOpened) {
      addClosedItem(title);
    } else {
      removeClosedItem(title);
    }
  };

  return (
    <>
      <ListItem
        onClick={handleToggleClick}
        disablePadding
        secondaryAction={
          <IconButton edge="end" aria-label="navigate">
            {opened ? <ArrowDropDownRoundedIcon color="action" /> : <ArrowRightRoundedIcon color="action" />}
          </IconButton>
        }
      >
        <ListItemButton sx={{ py: 1, px: 2 }}>
          {Icon && (
            <ListItemIcon sx={{ minWidth: 36 }}>
              <Icon />
            </ListItemIcon>
          )}
          <ListItemText sx={{ m: 0 }} slotProps={{ primary: { noWrap: true } }}>
            {title}
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <Collapse sx={{ pl: 4 }} in={opened} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
};

export default MenuGroupItem;
