import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Divider, Grid2, IconButton, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { AccessPermission } from "../../../../shared/api/types";
import AuthorizedBox from "../../../../shared/components/AuthorizedBox";
import HorizontalFill from "../../../../shared/components/HorizontalFill";
import { useClientPermissionsContext } from "../../../../shared/contexts/ClientPermissionsContext";
import { ReportGroup } from "../../../../shared/reporting/api/biClient.types";

interface Props {
  level: ReportGroup;
  sx?: SxProps<Theme>;
  clone?: boolean;
  onDragMove?: (group: ReportGroup, replaceGroup: ReportGroup) => void;
  onEndMoveItem?: (group: ReportGroup) => void;
  onShowMenu?: (anchorEL: HTMLElement | null, groupId: string) => void;
  permissions?: AccessPermission[];
}

export const GroupItem = (props: Props) => {
  const { level, sx, clone, onDragMove, onEndMoveItem, onShowMenu, permissions } = props;

  const { hasAnyPermission } = useClientPermissionsContext();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [focused, setFocused] = useState(false);

  const allowDradAndDrop = hasAnyPermission(["ManageReports", "ManageOrganizationReportTemplates"]);

  const [{ isDragging }, drag, dragPreview] = useDrag(() => ({
    type: "level",
    item: level,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (monitor.didDrop()) {
        onEndMoveItem?.call(this, item);
      }
    },
  }));

  const [, drop] = useDrop(
    () => ({
      accept: ["level"],
      hover(item: ReportGroup) {
        if (item.id !== level.id) {
          onDragMove?.call(this, item, level);
        }
      },
    }),
    []
  );

  useEffect(() => {
    dragPreview(getEmptyImage());
  }, [dragPreview]);

  return (
    <>
      <Grid2
        role="Handle"
        ref={(node) => {
          if (allowDradAndDrop) drag(drop(node));
        }}
        container
        onMouseEnter={() => setFocused(true)}
        onMouseLeave={() => setFocused(false)}
        sx={{
          height: "36px",
          alignItems: "center",
          gap: 2,
          px: 1,
          backgroundColor: focused || clone ? "#f5f5f5" : "#fff",
          opacity: isDragging ? 0 : 1,
          borderTop: clone ? "1px solid #E0E0E0" : "inherit",
          borderBottom: clone ? "1px solid #E0E0E0" : "inherit",
          width: "100%",
          ":hover": { cursor: "pointer" },
          ...sx,
        }}
      >
        <Grid2>
          <DragIndicatorIcon
            sx={(theme) => ({
              visibility: focused || clone ? "visible" : "hidden",
              color: theme.palette.secondary.light,
            })}
          />
        </Grid2>
        <Typography>{level.caption}</Typography>
        <HorizontalFill />
        <AuthorizedBox permissions={[...(permissions || [])]}>
          <IconButton
            ref={(r) => setAnchorEl(r)}
            sx={(theme) => ({
              visibility: focused || clone ? "visible" : "hidden",
              color: theme.palette.secondary.light,
              ":hover": { cursor: "pointer" },
            })}
            onClick={() => onShowMenu?.apply(null, [anchorEl, level.id])}
          >
            <MoreHorizIcon />
          </IconButton>
        </AuthorizedBox>
      </Grid2>
      <Divider flexItem orientation="horizontal" />
    </>
  );
};

export default GroupItem;
