import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext } from "react";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import { FundraisingDocument, FundraisingDocumentsState } from "./fundraisingDocumentsState";

interface Props {
  documentsState: FundraisingDocumentsState;
  setDocumentsState: Dispatch<SetStateAction<FundraisingDocumentsState>>;
  filteredDocuments: FundraisingDocument[];
  onPreviewFile: (fileId: string) => void;
  onDownloadFile: (fileId: string) => void;
  onDownloadMultipleFiles: (fileIds: string[]) => void;
}

const FundraisingDocumentsContext = createContext<Props | undefined>(undefined);

export const FundraisingDocumentsContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <FundraisingDocumentsContext.Provider value={contextValue}>{children}</FundraisingDocumentsContext.Provider>
);

export const useFundraisingDocumentsContext = () =>
  defined(useContext(FundraisingDocumentsContext), "Attempt to use FundraisingDocumentsContext without provider");
