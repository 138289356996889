import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

const UnsupportedField = ({ children }: PropsWithChildren) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="2.5rem"
      width="100%"
      sx={(t) => ({ border: `1px dashed ${t.palette.divider}` })}
    >
      <Typography variant="caption" color="warning.main">
        {children}
      </Typography>
    </Box>
  );
};

export default UnsupportedField;
