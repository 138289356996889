import { isImageFile, isPdfFile } from "../../utilities/fileHelper";
import FileCannotBePreviewedDialog from "./FileCannotBePreviewedDialog";
import PdfViewerDialog from "./pdfViewer/PdfViewerDialog";
import PreviewImageDialog from "./PreviewImageDialog";

interface Props {
  url: string;
  fileName: string;
  documentViewSessionId?: string;
  preventDownload?: boolean;
  onClose: () => void;
}

const PreviewFileDialog = ({ url, fileName, documentViewSessionId, preventDownload, onClose }: Props) => {
  if (isPdfFile(fileName)) {
    return (
      <PdfViewerDialog
        open
        onClose={onClose}
        fileSrc={url}
        fileName={fileName}
        documentViewSessionId={documentViewSessionId}
        preventDownload={preventDownload}
      />
    );
  }

  if (isImageFile(fileName)) {
    return <PreviewImageDialog src={url} title={fileName} onClose={onClose} />;
  }

  return <FileCannotBePreviewedDialog url={url} fileName={fileName} onClose={onClose} />;
};

export default PreviewFileDialog;
