import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import { Box, Button, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import { GridEventListener, GridRowEditStopReasons, GridRowModes, GridRowModesModel } from "@mui/x-data-grid-premium";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import { EntriliaProduct } from "../../../../../../../shared/api/types";
import DataLoadingFailed from "../../../../../../../shared/components/DataLoadingFailed";
import RecordCounter from "../../../../../../../shared/components/filters/RecordCounter";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../../shared/logging";
import { makeLighterBackgroundFromColor } from "../../../../../../../shared/utilities/colorHelper";
import { generateGuid } from "../../../../../../../shared/utilities/generateGuid";
import { getChangedFields } from "../../../../../../../shared/utilities/objectHelper";
import adminApi from "../../../../../../api/adminApi";
import {
  DataImportChangesFilterValue,
  DataImportState,
  ImportFieldDefinition,
} from "../../../../../../api/types/dataImportTypes";
import { useClientContext } from "../../../../../../context/ClientContext";
import GridPaginationFooter from "../../../../../common/GridPaginationFooter";
import {
  getPermissionsToManageImport,
  isImportApplicationAllowed,
  isImportEditable,
} from "../../../importDataStateHelper";
import DataImportChangesListFilter from "./DataImportChangesFilter";
import DataImportChangesGridToolbar from "./DataImportChangesGridToolbar";
import { DataImportChangesListActionsContextProvider } from "./DataImportChangesListActionsContext";
import { createNewRow, getColumnDefinitions, getNewValuesObject } from "./dataImportChangesGridProvider";
import { DataImportChangeRowModel } from "./dataImportChangesGridTypes";
import {
  changeFilterAction,
  changePageAction,
  changeRowsPerPageAction,
  getInitialState,
  toggleShowChangesAction,
  updateTotalsAction,
} from "./dataImportChangesListState";

interface Props {
  dataImportId: string;
  dataImportState: DataImportState;
  productAreas: EntriliaProduct[];
  fileDataCatalogueId: string;
  importFieldDefinitions: ImportFieldDefinition[];
}

const addDataImportChange = withErrorHandling(adminApi.addDataImportChange);
const updateDataImportChange = withErrorHandling(adminApi.updateDataImportChange);
const excludeDataImportChange = withErrorHandling(adminApi.excludeDataImportChange);
const revertExcludedDataImportChange = withErrorHandling(adminApi.revertExcludedDataImportChange);
const excludeChangesWithErrors = withErrorHandling(adminApi.excludeChangesWithErrors);

const DataImportChangesList = ({
  dataImportId,
  dataImportState,
  productAreas,
  fileDataCatalogueId,
  importFieldDefinitions,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { dictionaries, hasPermissions } = useClientContext();
  const { sendNotificationError } = useNotificationContext();

  const [state, setState] = useState(
    getInitialState((searchParams.get("filter") as DataImportChangesFilterValue) || undefined)
  );

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [isOperationInProgress, setOperationInProgress] = useState(false);

  const getChanges = useCallback(
    () =>
      adminApi.searchDataImportChanges(dataImportId, {
        fileDataCatalogueId,
        filter: state.filter,
        paging: {
          totals: state.page === 0,
          page: state.page,
          size: state.rowsPerPage,
        },
      }),
    [dataImportId, fileDataCatalogueId, state.filter, state.page, state.rowsPerPage]
  );

  const getTotals = useCallback(
    () => adminApi.getDataImportChangesTotals(dataImportId, fileDataCatalogueId),
    [dataImportId, fileDataCatalogueId]
  );

  const [
    changesResp,
    fetchChangesError,
    { isFetching: isFetchingChanges, fetch: fetchChanges, setData: setChangesResp },
  ] = useFetch(getChanges, (data) => {
    if (data.totalPages !== undefined && data.total !== undefined) {
      setState(updateTotalsAction(data.total, data.totalPages));
    }
  });

  const [totalsResp, totalsError, { fetch: fetchTotals }] = useFetch(getTotals);

  const fetchError = fetchChangesError || totalsError;
  const isLoading = isFetchingChanges || isOperationInProgress;

  if (fetchError) {
    logError(fetchError, "[DataImportChangesList] searchDataImportChanges");
    return <DataLoadingFailed title="Failed to load changes" />;
  }

  const isGridEditable =
    isImportEditable(dataImportState) && hasPermissions(getPermissionsToManageImport(productAreas));

  const columns = getColumnDefinitions(
    importFieldDefinitions,
    dictionaries,
    isGridEditable,
    state.showChanges,
    rowModesModel
  );

  const leftPinnedColumns = [
    "rowNumber",
    "action",
    ...importFieldDefinitions.filter((d) => d.isObjectIdentifierField).map((d) => d.name),
  ];

  const rows: DataImportChangeRowModel[] = changesResp?.items ?? [];

  const handleShowChangesToggle = () => {
    setState(toggleShowChangesAction());
  };

  const handleExcludeChange = async (id: string) => {
    const row = rows.find((r) => r.id === id);
    if (row?.isNew) {
      setChangesResp((prev) =>
        prev === undefined ? prev : { ...prev, items: prev.items.filter((item) => item.id !== id) }
      );
      return;
    }

    setOperationInProgress(true);
    const [result, error] = await excludeDataImportChange(dataImportId, id);
    setOperationInProgress(false);

    if (error && !result) {
      logError(error, "[DataImportChangesList] excludeDataImportChange");
      sendNotificationError("Failed to exclude the change");
      return;
    }

    fetchChanges();
    fetchTotals();
  };

  const handleRevertExcludedChange = async (id: string) => {
    setOperationInProgress(true);
    const [result, error] = await revertExcludedDataImportChange(dataImportId, id);
    setOperationInProgress(false);

    if (error && !result) {
      logError(error, "[DataImportChangesList] revertExcludedDataImportChange");
      sendNotificationError("Failed to revert the excluded change");
      return;
    }

    fetchChanges();
    fetchTotals();
  };

  const handleExcludeAllRowsWithErrors = async () => {
    setOperationInProgress(true);
    const [result, error] = await excludeChangesWithErrors(dataImportId);
    setOperationInProgress(false);

    if (error && !result) {
      logError(error, "[DataImportChangesList] excludeChangesWithErrors");
      sendNotificationError("Failed to exclude the changes");
      return;
    }

    fetchChanges();
    fetchTotals();
  };

  const handleCancelEditing = (id: string) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    }));

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setChangesResp((prev) =>
        prev === undefined ? prev : { ...prev, items: prev.items.filter((item) => item.id !== id) }
      );
    }
  };

  const handleApplyChanges = (id: string) => {
    setRowModesModel((prev) => ({
      ...prev,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleAddRowClick = () => {
    const id = generateGuid();
    const newItem = createNewRow(id, dataImportId, fileDataCatalogueId, importFieldDefinitions);
    setChangesResp((prev) => (prev === undefined ? prev : { ...prev, items: [newItem, ...prev.items] }));

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit },
    }));
  };

  const processRowUpdate = async (updatedRow: DataImportChangeRowModel, currentRow: DataImportChangeRowModel) => {
    if (updatedRow.isNew) {
      const changes = getNewValuesObject(updatedRow);

      setOperationInProgress(true);
      const [row, error] = await addDataImportChange(dataImportId, {
        dataImportFileCatalogueId: fileDataCatalogueId,
        changes,
      });
      setOperationInProgress(false);

      if (error) {
        logError(error, "[DataImportChangesList] addDataImportChange");
        sendNotificationError("Failed to add new row");
        return updatedRow;
      }

      fetchChanges();
      fetchTotals();

      return { ...row, isNew: false };
    }

    const currentChangesObj = getNewValuesObject(currentRow);
    const updatedChangesObj = getNewValuesObject(updatedRow);

    const changes = getChangedFields(currentChangesObj, updatedChangesObj);
    if (Object.keys(changes).length === 0) {
      return currentRow;
    }

    setOperationInProgress(true);
    const [row, error] = await updateDataImportChange(dataImportId, updatedRow.id, { changes });
    setOperationInProgress(false);

    if (error) {
      logError(error, "[DataImportChangesList] updateDataImportChange");
      sendNotificationError("Failed to update the row");
      return currentRow;
    }

    fetchTotals();
    fetchChanges();

    return { ...row, isNew: false };
  };

  const handleRowEditStop: GridEventListener<"rowEditStop"> = ({ id, reason }, e) => {
    if (reason === GridRowEditStopReasons.rowFocusOut) {
      e.defaultMuiPrevented = true;
      handleCancelEditing(id.toString());
    }
  };

  const handleFilterChange = (newValue: DataImportChangesFilterValue | undefined) => {
    setState(changeFilterAction(newValue));
    if (newValue !== undefined) {
      setSearchParams({ filter: newValue });
    } else {
      setSearchParams({});
    }
  };

  return (
    <Box height="100%" pt={2.5} px={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <DataImportChangesListFilter
            value={state.filter}
            onChange={handleFilterChange}
            allCount={totalsResp?.allCount}
            changesCount={totalsResp?.changesCount}
            errorsCount={totalsResp?.errorsCount}
            excludedCount={totalsResp?.excludedCount}
          />
          <RecordCounter
            records={rows.length}
            totalRecords={(state.totalFilteredItems ?? 0) + rows.filter(({ isNew }) => isNew).length}
            hide={isLoading || state.totalFilteredItems === 0}
          />
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <FormControlLabel
            control={<Switch checked={state.showChanges} onChange={handleShowChangesToggle} />}
            label={<Typography>Show changes</Typography>}
          />
          <Button
            disabled={!totalsResp?.errorsCount || isLoading || !isImportApplicationAllowed(dataImportState)}
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={handleExcludeAllRowsWithErrors}
          >
            Exclude all rows with errors
          </Button>
        </Stack>
      </Stack>

      <DataImportChangesListActionsContextProvider
        onExcludeChange={handleExcludeChange}
        onRevertExcludedChange={handleRevertExcludedChange}
        onCancelEditing={handleCancelEditing}
        onApplyChanges={handleApplyChanges}
      >
        <DataGrid<DataImportChangeRowModel>
          rows={rows}
          columns={columns}
          loading={isLoading}
          pinnedColumns={{ left: leftPinnedColumns, right: ["gridActions"] }}
          columnVisibilityModel={{ gridActions: isGridEditable }}
          disableColumnReorder
          showCellVerticalBorder
          showColumnVerticalBorder
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          hideFooter={false}
          slots={{
            toolbar: () =>
              isGridEditable ? (
                <DataImportChangesGridToolbar
                  onAddRowClick={handleAddRowClick}
                  addRowDisabled={state.filter !== undefined}
                />
              ) : null,
            footer: () =>
              state.totalFilteredPages !== undefined ? (
                <GridPaginationFooter
                  totalPages={state.totalFilteredPages}
                  rowsPerPage={state.rowsPerPage}
                  pageIndex={state.page}
                  onPageIndexChange={(value) => setState(changePageAction(value))}
                  onRowsPerPageChange={(value) => setState(changeRowsPerPageAction(value))}
                />
              ) : null,
          }}
          containerSx={{ height: "calc(100% - 64px)" }}
          sx={(t) => ({
            borderColor: t.palette.divider,
            ".MuiDataGrid-columnHeaders": {
              borderTop: `1px solid ${t.palette.divider}`,
              ".MuiDataGrid-columnHeader": {
                ":first-of-type": {
                  borderLeft: `1px solid ${t.palette.divider}`,
                },
              },
            },
            ".MuiDataGrid-row": {
              ".MuiDataGrid-cell": {
                ":first-of-type": {
                  borderLeft: `1px solid ${t.palette.divider}`,
                },
              },
              "& .row-number-cell": {
                backgroundColor: t.palette.background.grey,
                color: t.palette.text.secondary,
              },
              "& .row-number-cell-error": {
                backgroundColor: makeLighterBackgroundFromColor(t.palette.error.main),
                color: t.palette.text.secondary,
              },
              "& .change-value-cell-error": {
                backgroundColor: makeLighterBackgroundFromColor(t.palette.error.main),
              },
              "& .excluded-row-cell": {
                color: t.palette.text.disabled,
              },
            },
            ".MuiDataGrid-columnHeader": {
              backgroundColor: t.palette.background.grey,
            },
          })}
        />
      </DataImportChangesListActionsContextProvider>
    </Box>
  );
};

export default DataImportChangesList;
