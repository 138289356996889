import { Box, Stack, Typography } from "@mui/material";
import ColorEdit from "../../../common/ColorEdit";
import SectionTitle from "./SectionTitle";

interface Props {
  title: string;
  subtitle: string;
  color: string;
  disabled?: boolean;
  onChange: (newColor: string) => void;
}

const EditColorSection = ({ title, subtitle, color, onChange, disabled }: Props) => (
  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
    <SectionTitle text={title} description={subtitle} />
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography color="text.secondary">{color.toUpperCase()}</Typography>
      <ColorEdit
        disabled={disabled}
        color={color}
        buttonSize={24}
        onColorChange={onChange}
        popover={{
          anchorOrigin: { vertical: "center", horizontal: "right" },
          transformOrigin: { vertical: "center", horizontal: "left" },
        }}
      />
    </Stack>
  </Box>
);

export default EditColorSection;
