import { useCallback, useMemo } from "react";
import { ReportTemplate, ReportTemplateDto } from "../../../../../../shared/reporting/api/biClient.types";
import CompanyTemplatesDialog from "../../../../../../shared/reporting/components/reportTemplates/dialogs/CompanyTemplatesDialog";
import ReportTemplatesButton from "../../../../../../shared/reporting/components/reportTemplates/ReportTemplatesButton";
import { getCompanyReportTemplateUrl, getEditReportUrl } from "../utilities/editReportUrl";

interface Props {
  openTemplates: boolean;
  templates: ReportTemplateDto[];
  onSetTemplateOpen: (open: boolean) => void;
  clientCode: string;
  disabled: boolean;
  loading: boolean;
}
export default function ReportTemplates({
  openTemplates,
  templates,
  onSetTemplateOpen,
  clientCode,
  disabled,
  loading,
}: Props) {
  const showBadge = useMemo(() => {
    return templates.some((t) => t.isNew);
  }, [templates]);

  const handleQuickUse = useCallback(
    (template: ReportTemplate) => {
      window.open(getCompanyReportTemplateUrl(clientCode, template.reportId, "new"), "_blank", "noopener noreferrer");
    },
    [clientCode]
  );

  return (
    <>
      <ReportTemplatesButton
        onClick={() => onSetTemplateOpen(true)}
        showBadge={showBadge}
        disabled={disabled}
        loading={loading}
      />
      {openTemplates && templates && (
        <CompanyTemplatesDialog
          templates={templates}
          onClose={() => onSetTemplateOpen(false)}
          createBlankReport={() => window.open(getEditReportUrl(clientCode, "new"), "_blank", "noopener noreferrer")}
          onQuickUse={handleQuickUse}
        />
      )}
    </>
  );
}
