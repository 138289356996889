import { Box, ListItemIcon, ListItemText, MenuItem, MenuItemProps } from "@mui/material";
import { ReactNode } from "react";
import BadgeDetached from "../../../../shared/components/BadgeDetached";

interface Props extends MenuItemProps {
  text: string;
  Icon?: ReactNode;
  badgeCount?: number;
}

const VerticalNavigationMenuItem = ({ Icon, text, badgeCount, ...menuItemProps }: Props) => (
  <MenuItem {...menuItemProps}>
    {Icon && <ListItemIcon>{Icon}</ListItemIcon>}
    <ListItemText slotProps={{ primary: { noWrap: true } }}>{text}</ListItemText>
    {badgeCount !== undefined && (
      <Box>
        <BadgeDetached badgeContent={badgeCount} color={menuItemProps.selected ? "primary" : "secondary"} />
      </Box>
    )}
  </MenuItem>
);

export default VerticalNavigationMenuItem;
