import AddIcon from "@mui/icons-material/AddRounded";
import { Button, Stack } from "@mui/material";
import { GridEventListener } from "@mui/x-data-grid-premium";
import { generatePath, useLocation, useNavigate } from "react-router";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import { logError } from "../../../../shared/logging";
import { useClientContext } from "../../../context/ClientContext";
import { useObjectListWithFilters } from "../../../hooks/useObjectListWithFilters";
import { pageRoutes } from "../../../routes";
import FeatureToggleBoundary from "../../common/FeatureToggleBoundary";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import { FilterContextProvider } from "../../common/filters/FilterContext";
import FiltersPanel from "../../common/filters/FiltersPanel";
import EntitiesGrid from "./EntitiesGrid";

interface Props {
  objectType: string;
  objectDetailsPathPattern: string;
  objectsTitle?: string;
  showLogos?: boolean;
}

const ObjectsListPage = ({ objectType, objectDetailsPathPattern, objectsTitle, showLogos }: Props) => {
  const { clientCode, hasAnyPermission } = useClientContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [result, error] = useObjectListWithFilters(objectType);

  if (error) {
    logError(error, "[ObjectsListPage]");
    return <DataLoadingFailed />;
  }

  const {
    isLoading,
    entities,
    filteredEntities,
    objectName,
    objectSource,
    objectFields,
    supportedFeatures,
    hasPermissionsForDataImport,
    objectPermissions,
    filterState,
    dispatchFilters,
  } = result;

  const hasAccessToCsvImport = hasPermissionsForDataImport && supportedFeatures.includes("DataImport");

  const handleCellClick: GridEventListener<"cellClick"> = ({ row }) => {
    const pathToObjectDetails = generatePath(objectDetailsPathPattern, { clientCode, type: objectType, id: row.id });
    navigate(pathToObjectDetails);
  };

  const handleCsvImportButtonClick = () => {
    navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}/${pageRoutes.importCsvData}`, {
      state: {
        referrerPath: pathname,
        preSelectedObjectType: objectType,
      },
    });
  };

  const showNewRecordButton =
    hasAnyPermission(objectPermissions.objectDataWritePermissions) && objectSource === "Custom";

  const handleCreateObject = () => {
    const pathToNewObject = generatePath(objectDetailsPathPattern, { clientCode, type: objectType, id: "new" });
    navigate(pathToNewObject);
  };

  return (
    <FilterContextProvider
      filterState={filterState}
      dispatchFilters={dispatchFilters}
      allRowsForSelectOptions={entities}
    >
      <GeneralPageHeader title={objectsTitle || objectName}>
        {showNewRecordButton && (
          <FeatureToggleBoundary featureToggle="CustomObjectsEnabled">
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateObject}>
              New Record
            </Button>
          </FeatureToggleBoundary>
        )}
      </GeneralPageHeader>
      <Stack spacing={2} height="100%" px={3} pb={1} pt={2.5}>
        <FiltersPanel
          totalCount={entities.length}
          recordCount={filteredEntities.length}
          isLoading={isLoading}
          hasColumnSelection
          onImportFromCsv={hasAccessToCsvImport ? handleCsvImportButtonClick : undefined}
        />
        <EntitiesGrid
          entities={filteredEntities}
          fields={objectFields}
          isLoading={isLoading}
          onCellClick={handleCellClick}
          showLogos={showLogos}
        />
      </Stack>
    </FilterContextProvider>
  );
};

export default ObjectsListPage;
