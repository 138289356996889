import { Link, Typography } from "@mui/material";
import qs from "qs";
import { Link as RouterLink } from "react-router";
import { DataImportChangesFilterValue } from "../../../../api/types/dataImportTypes";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import StatTile from "../../../common/StatTile";

interface Props {
  dataImportId: string;
  title: string;
  value: number | undefined;
  filterValue?: DataImportChangesFilterValue;
}

const ImportDataStatTile = ({ dataImportId, title, value, filterValue }: Props) => {
  const { clientCode } = useClientContext();

  const query = filterValue ? `?${qs.stringify({ filter: filterValue })}` : "";
  const routerPath = `/${clientCode}/${pageRoutes.settings}/${pageRoutes.importData}/${dataImportId}/${pageRoutes.importReview}${query}`;

  return (
    <StatTile title={title} value={value?.toLocaleString() || "-"} sx={{ flex: 1 }}>
      {value !== undefined ? (
        <Link component={RouterLink} to={routerPath} underline="none">
          View
        </Link>
      ) : (
        <Typography color="text.disabled">View</Typography>
      )}
    </StatTile>
  );
};

export default ImportDataStatTile;
