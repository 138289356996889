import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Link, Typography } from "@mui/material";
import { parseISO } from "date-fns";
import TypographyTooltipEllipsis from "../../../shared/components/TypographyTooltipEllipsis";
import { Maybe } from "../../../shared/types";
import { formatMoney, formatNumber, formatPercentage } from "../../../shared/utilities/formatters";
import { tryGetUrlWithProtocol } from "../../../shared/utilities/urlHelper";
import { EntityFieldConfiguration, EntityFieldType } from "../../api/types/objectTypes";
import { useClientContext } from "../../context/ClientContext";
import { getDictionaryDisplayNameFromCode } from "../../utilities/dictionariesHelper";
import { formatDateFieldValue, getMultiselectOptionsFromValue } from "./helpers";

interface Props {
  type: EntityFieldType;
  configuration: EntityFieldConfiguration | undefined;
  value: unknown;
}

const EntityValueGridCell = ({ type, configuration, value }: Props) => {
  const { dictionaries } = useClientContext();

  switch (type) {
    case "Email": {
      return (
        <Link href={`mailto:${value}`} underline="hover" target="_blank" onClick={(e) => e.stopPropagation()}>
          <TypographyTooltipEllipsis text={`${value}`} />
        </Link>
      );
    }
    case "Url": {
      const url = value ? tryGetUrlWithProtocol(value.toString()) : undefined;
      return url ? (
        <Link href={url.href} underline="hover" target="_blank" color="primary" onClick={(e) => e.stopPropagation()}>
          {`${value}`}
        </Link>
      ) : null;
    }
    case "Select": {
      if (!value) {
        return null;
      }
      const optionFieldType = configuration?.$type === "Select" && configuration.fieldType;
      const resolvedValue =
        (optionFieldType && getDictionaryDisplayNameFromCode(value.toString(), optionFieldType, dictionaries)) ||
        value.toString();
      return <TypographyTooltipEllipsis text={resolvedValue} />;
    }
    case "UserDefinedOptionsSelect": {
      if (!value) {
        return null;
      }
      const configurationOptions =
        (configuration?.$type === "UserDefinedOptionsSelect" && configuration.userDefinedOptions) || [];
      const resolvedValue = configurationOptions.find((option) => option.value === value)?.label || value.toString();
      return <TypographyTooltipEllipsis text={resolvedValue} />;
    }
    case "MultiSelect": {
      const optionValues = getMultiselectOptionsFromValue(value as Maybe<string | string[]>);
      return <TypographyTooltipEllipsis text={optionValues.join(", ")} />;
    }
    case "UserDefinedOptionsMultiSelect": {
      const optionValues = getMultiselectOptionsFromValue(value as Maybe<string | string[]>);
      const configurationOptions =
        (configuration?.$type === "UserDefinedOptionsSelect" && configuration.userDefinedOptions) || [];
      const displayValues = optionValues.map(
        (optionValue) =>
          configurationOptions.find((configOption) => configOption.value === optionValue)?.label ?? optionValue
      );
      return <TypographyTooltipEllipsis text={displayValues.join(", ")} />;
    }
    case "Date": {
      if (!value) {
        return null;
      }

      const dateFieldConfiguration = configuration?.$type === "Date" ? configuration : undefined;
      const dateValue = parseISO(value.toString());
      return <Typography>{formatDateFieldValue(dateValue, dateFieldConfiguration)}</Typography>;
    }
    case "Number": {
      if (typeof value !== "number" || Number.isNaN(value)) {
        return null;
      }
      const maximumFractionDigits = (configuration?.$type === "Number" && configuration.precision) || 0;
      return <Typography>{formatNumber(value, { maximumFractionDigits })}</Typography>;
    }
    case "Money": {
      if (typeof value !== "number" || Number.isNaN(value)) {
        return null;
      }
      const currencyCode = (configuration?.$type === "Money" && configuration.currencyCode) || "USD";
      const maximumFractionDigits = (configuration?.$type === "Money" && configuration.precision) || 0;
      return <Typography>{formatMoney(value, currencyCode, { maximumFractionDigits })}</Typography>;
    }
    case "Percent": {
      if (typeof value !== "number" || Number.isNaN(value)) {
        return null;
      }
      const maximumFractionDigits = (configuration?.$type === "Percent" && configuration.precision) || 0;
      return <Typography>{formatPercentage(0.01 * value, { maximumFractionDigits })}</Typography>;
    }
    case "Checkbox": {
      return value ? <CheckRoundedIcon color="primary" /> : null;
    }
    // TODO #9346 We don't receive lookup object values with response yet => don't allow column selection of such fields
    // case "Lookup": {
    //   const objectId = value;
    //   if (typeof objectId !== "string") {
    //     return null;
    //   }

    //   const resolvedValue = lookupObjects[fieldId]?.find((l) => l.objectId === objectId)?.fieldValue;
    //   if (!resolvedValue) {
    //     return null;
    //   }

    //   return <TypographyTooltipEllipsis text={resolvedValue} />;
    // }
    default: {
      return value ? <TypographyTooltipEllipsis text={`${value}`} /> : null;
    }
  }
};

export default EntityValueGridCell;
