import DragIndicatorIcon from "@mui/icons-material/DragIndicatorRounded";
import { ListItem, ListItemIcon } from "@mui/material";
import CloseIconButton from "../../../../shared/components/CloseIconButton";
import TypographyTooltipEllipsis from "../../../../shared/components/TypographyTooltipEllipsis";

interface Props {
  columnTitle: string;
  onHide: () => void;
  disabled?: boolean;
}

const ColumnSelectionItem = ({ columnTitle, onHide, disabled }: Props) => {
  return (
    <ListItem draggable={!disabled} secondaryAction={<CloseIconButton onClick={onHide} disabled={disabled} />}>
      <ListItemIcon sx={{ minWidth: "unset", mr: 0.75 }}>
        <DragIndicatorIcon sx={{ cursor: disabled ? undefined : "grab" }} />
      </ListItemIcon>
      <TypographyTooltipEllipsis text={columnTitle} />
    </ListItem>
  );
};

export default ColumnSelectionItem;
