import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";
import { Menu } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import ActionMenuItem from "../../../../../../shared/components/ActionMenuItem";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import DuplicateReportIcon from "../../../../../../shared/icons/DuplicateReportIcon";
import FolderManagedIcon from "../../../../../../shared/icons/FolderManagedIcon";
import biClient from "../../../../../../shared/reporting/api/biClient";
import { ClientInfo, ReportTemplate, Status } from "../../../../../../shared/reporting/api/biClient.types";
import { useGroupsContext } from "../../../../../../shared/reporting/components/groups/contexts/GroupsContext";
import DeleteTemplateDialog from "../../../../../../shared/reporting/components/reportTemplates/dialogs/DeleteTemplateDialog";
import DuplicateTemplateDialog from "../../../../../../shared/reporting/components/reportTemplates/dialogs/DuplicateTemplateDialog";
import RunWithCompanyDialog from "../../../../../../shared/reporting/components/reportTemplates/dialogs/RunWithCompanyDialog";
import SaveTemplateDialog from "../../../../../../shared/reporting/components/reportTemplates/dialogs/SaveTemplateDialog";
import SwitchStatusDialog from "../../../../../../shared/reporting/components/reportTemplates/dialogs/SwitchStatusDialog";
import { useReportTemplatesContext } from "../../../../../../shared/reporting/contexts/ReportTemplatesContext";
import { useSharedReportingLocalization } from "../../../../../../shared/reporting/hooks/useLocalization";
import { useClientContext } from "../../../../../context/ClientContext";

interface Props {
  anchorEl: HTMLElement | null;
  template: ReportTemplate;
  companies: ClientInfo[];
  open: boolean;
  readonly?: boolean;
  onClose: () => void;
  getEditReportTemplateUrl: (organization: string, dataSource: string, templateId: string) => string;
}

export default function ReportTemplateActions({
  template,
  anchorEl,
  open,
  companies,
  onClose,
  getEditReportTemplateUrl,
  readonly,
}: Props) {
  const { clientCode } = useClientContext();
  const { report_templates: locale } = useSharedReportingLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [openSwitchStatusDialog, setSwitchStatusDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openRunWithDialog, setOpenRunWithDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const {
    actions: { switchStatus, reloadReportTemplates },
  } = useReportTemplatesContext();

  const { groups } = useGroupsContext();

  const changeStatus = useCallback(
    async (template: ReportTemplate, status: Status) => {
      setLoading(true);

      const result = await switchStatus(template, status);
      if (result) {
        sendNotification(locale.status_changed);
      } else {
        sendNotificationError(locale.status_change_error);
      }

      setLoading(false);
      setSwitchStatusDialog(false);
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [switchStatus]
  );

  const handleEditTemplate = useCallback(() => {
    onClose();
    setOpenEditDialog(true);
  }, [onClose]);

  const handleDuplicateTemplate = useCallback(() => {
    onClose();
    setOpenDuplicateDialog(true);
  }, [onClose]);

  const handleChangeStatus = useCallback(() => {
    onClose();
    setSwitchStatusDialog(true);
  }, [onClose]);

  const handleDeleteTemplate = useCallback(() => {
    onClose();
    setOpenDeleteDialog(true);
  }, [onClose]);

  const getRunTemplateHref = useMemo(() => {
    return getEditReportTemplateUrl(template.organization, template.dataSource, template.reportId);
  }, [template, getEditReportTemplateUrl]);

  const openRunWithCompany = useCallback(() => {
    onClose();
    setOpenRunWithDialog(true);
  }, [onClose]);

  const onRunTemplate = useCallback(() => {
    const hasNoAccessToDataSource = !companies.some((c) => c.clientCode === template.dataSource);
    if (hasNoAccessToDataSource || !template.dataSource) {
      openRunWithCompany();
      return;
    }
    onClose();
    window.open(getRunTemplateHref, "_blank", "noopener noreferrer");
  }, [onClose, template.dataSource, getRunTemplateHref, openRunWithCompany, companies]);

  return (
    <>
      <Menu anchorEl={anchorEl} open={open} onClose={() => onClose()}>
        <ActionMenuItem
          key={"run"}
          icon={<PlayArrowRoundedIcon />}
          title="Run Report template"
          onClick={onRunTemplate}
          disabled={readonly}
        />
        <ActionMenuItem
          key={"edit"}
          icon={<FolderManagedIcon />}
          title="Edit template information"
          onClick={handleEditTemplate}
          disabled={readonly}
        />
        <ActionMenuItem
          key={"duplicate"}
          icon={<DuplicateReportIcon />}
          title="Duplicate"
          onClick={handleDuplicateTemplate}
        />
        <ActionMenuItem
          key={"status"}
          icon={<PublishedWithChangesRoundedIcon />}
          title={`Make ${template.status === Status.Live ? "Draft" : "Live"}`}
          onClick={handleChangeStatus}
        />
        <ActionMenuItem
          key={"delete"}
          icon={<DeleteOutlineOutlinedIcon />}
          title="Delete"
          onClick={handleDeleteTemplate}
          sx={(theme) => ({
            color: theme.palette.error.main,
            svg: {
              color: theme.palette.error.main,
            },
          })}
        />
      </Menu>
      {openSwitchStatusDialog && template && (
        <SwitchStatusDialog
          templateName={template.name}
          currentStatus={template.status}
          onClose={() => setSwitchStatusDialog(false)}
          onConfirm={(s) => changeStatus(template, s)}
          loading={loading}
        />
      )}
      {openDeleteDialog && template && (
        <DeleteTemplateDialog
          templates={[template]}
          onClose={() => setOpenDeleteDialog(false)}
          doDelete={(templates) =>
            biClient.deleteReportTemplates({
              templates: templates.map((t) => ({ templateId: t.reportId, organization: t.organization })),
            })
          }
          onDeleted={() => {
            sendNotification(locale.deleted);
            reloadReportTemplates();
          }}
        />
      )}
      {openRunWithDialog && template && (
        <RunWithCompanyDialog
          template={template}
          companies={companies}
          getEditReportTemplateUrl={getEditReportTemplateUrl}
          onClose={() => setOpenRunWithDialog(false)}
          onRun={() => setOpenRunWithDialog(false)}
        />
      )}
      {openEditDialog && template && (
        <SaveTemplateDialog
          title="Edit Template information"
          template={template}
          companies={companies}
          templateConfiguration={undefined}
          onClose={() => setOpenEditDialog(false)}
          onSaved={() => {
            reloadReportTemplates();
            setOpenEditDialog(false);
            sendNotification(locale.saved);
          }}
        />
      )}
      {openDuplicateDialog && template && (
        <DuplicateTemplateDialog
          organization={clientCode}
          groups={groups[template.organization] || []}
          template={template}
          onClose={() => setOpenDuplicateDialog(false)}
          onChanged={() => {
            reloadReportTemplates();
            setOpenDuplicateDialog(false);
            sendNotification(locale.duplicated);
          }}
        />
      )}
    </>
  );
}
