import { TabPanel } from "@mui/lab";
import { useState } from "react";
import { useSearchParams } from "react-router";
import { InvestorChangeRequestState } from "../../../../shared/api/changeRequestTypes";
import { parseSearchParamWithFallback } from "../../../../shared/utilities/searchParamsHelper";
import GeneralPageHeader from "../../common/GeneralPageHeader";
import PageTabs from "../../common/PageTabs";
import ChangeRequestsList from "./ChangeRequestsList";

interface ChangeRequestsTabPanelProps {
  value: string;
  stateFilter?: InvestorChangeRequestState;
}

const tabValues = ["Pending", "Completed", "Rejected", "All"] as const;
type TabValue = (typeof tabValues)[number];

const ChangeRequestsTabPanel = ({ value, stateFilter }: ChangeRequestsTabPanelProps) => (
  <TabPanel value={value} sx={{ height: "100%", px: 3, py: 2.5 }}>
    <ChangeRequestsList stateFilter={stateFilter} />
  </TabPanel>
);

const ChangeRequestsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TabValue>(
    parseSearchParamWithFallback(searchParams, "tab", tabValues)
  );

  const handleTabChange = (tab: TabValue) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  return (
    <>
      <GeneralPageHeader title="Requests" hideBorderLine />
      <PageTabs
        tabs={tabValues}
        labels={["Pending", "Approved", "Rejected", "All"]}
        value={selectedTab}
        onChange={handleTabChange}
      >
        <ChangeRequestsTabPanel value="Pending" stateFilter="Pending" />
        <ChangeRequestsTabPanel value="Completed" stateFilter="Completed" />
        <ChangeRequestsTabPanel value="Rejected" stateFilter="Rejected" />
        <ChangeRequestsTabPanel value="All" />
      </PageTabs>
    </>
  );
};

export default ChangeRequestsPage;
