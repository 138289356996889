import { SvgIconProps } from "@mui/material";
import { ObjectFeature } from "../../../api/types/objectTypes";
import DatabaseIcon from "../../../icons/DatabaseIcon";
import FilesIcon from "../../../icons/FilesIcon";
import NoteIcon from "../../../icons/NoteIcon";
import PortfolioMonitoringIcon from "../../../icons/PortfolioMonitoringIcon";

interface Props extends SvgIconProps {
  objectFeature: ObjectFeature;
}

const ObjectFeatureIcon = ({ objectFeature, ...iconProps }: Props) => {
  switch (objectFeature) {
    case "Notes": {
      return <NoteIcon {...iconProps} />;
    }
    case "Files": {
      return <FilesIcon {...iconProps} />;
    }
    case "PortfolioMetrics": {
      return <PortfolioMonitoringIcon {...iconProps} />;
    }
    case "DataImport": {
      return <DatabaseIcon {...iconProps} />;
    }
    default: {
      return null;
    }
  }
};

export default ObjectFeatureIcon;
