import { LoadingButton } from "@mui/lab";
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import ChipsMultilineInput from "../../../../../shared/components/inputs/ChipsMultilineInput";
import { emailValidator } from "../../../../../shared/utilities/validators";
import { BackupMasterConfiguration } from "../../../../api/adminApi";
import { DataBackupPageState } from "../dataBackupPageState";
import DataBackupConfigurationHeader from "./DataBackupConfigurationHeader";
import DataBackupConfigurationTimeSettings from "./DataBackupConfigurationTimeSettings";

interface Props {
  state: DataBackupPageState;
  readonly: boolean;
  onConfigurationChange: (data: Partial<BackupMasterConfiguration>) => void;
  onConfigurationSave: () => void;
}

const DataBackupConfigurationContainer = ({ state, onConfigurationSave, onConfigurationChange, readonly }: Props) => {
  return (
    <Stack spacing={3} maxWidth={520}>
      <DataBackupConfigurationHeader />
      <DataBackupConfigurationTimeSettings
        hour={state.configuration.hour}
        onHourChange={(hour) => onConfigurationChange({ hour })}
        readonly={readonly}
        validationError={state.errors.hourError}
      />
      <Stack spacing={3}>
        <Stack spacing={2}>
          <FormControl>
            <TextField
              label="Access Key ID"
              placeholder="Access Key ID"
              value={state.configuration.awsBackupConfiguration.accessKey}
              onChange={(e) =>
                onConfigurationChange({
                  awsBackupConfiguration: { ...state.configuration.awsBackupConfiguration, accessKey: e.target.value },
                })
              }
              disabled={readonly}
            />
          </FormControl>
          <FormControl>
            <TextField
              label="Secret Access Key"
              placeholder="Secret Access Key"
              value={state.configuration.awsBackupConfiguration.secretKey}
              onChange={(e) =>
                onConfigurationChange({
                  awsBackupConfiguration: { ...state.configuration.awsBackupConfiguration, secretKey: e.target.value },
                })
              }
              disabled={readonly}
            />
          </FormControl>
        </Stack>
        <Stack spacing={2}>
          <FormControl>
            <TextField
              label="Bucket Name"
              placeholder="Bucket Name"
              value={state.configuration.awsBackupConfiguration.bucket}
              onChange={(e) =>
                onConfigurationChange({
                  awsBackupConfiguration: { ...state.configuration.awsBackupConfiguration, bucket: e.target.value },
                })
              }
              disabled={readonly}
            />
          </FormControl>
          <FormControl>
            <InputLabel>Region</InputLabel>
            <Select
              label="Region"
              value={state.configuration.awsBackupConfiguration.region}
              onChange={(e) =>
                onConfigurationChange({
                  awsBackupConfiguration: { ...state.configuration.awsBackupConfiguration, region: e.target.value },
                })
              }
              disabled={readonly}
            >
              {Object.entries(state.configurationOptions || []).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  <Typography>{value}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <TextField
              label="Bucket Folder Path"
              placeholder="Bucket Folder Path (optional)"
              value={state.configuration.awsBackupConfiguration.path}
              onChange={(e) =>
                onConfigurationChange({
                  awsBackupConfiguration: { ...state.configuration.awsBackupConfiguration, path: e.target.value },
                })
              }
              disabled={readonly}
            />
          </FormControl>
        </Stack>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="subtitle2">Notification</Typography>
            <Typography color="secondary">
              Specify the email address to receive notifications in the event of a backup error.
            </Typography>
          </Stack>
          <ChipsMultilineInput
            values={state.configuration.notifiedUserEmails}
            placeholder="Enter one or multiple emails, comma or space separated"
            onChange={(emails) => onConfigurationChange({ notifiedUserEmails: emails })}
            inputValidator={emailValidator}
            freeSolo
            removeDuplicatesOnPaste={false}
            disabled={readonly}
          />
        </Stack>
      </Stack>
      <Stack flexDirection={"row"} display={readonly ? "none" : "flex"}>
        <LoadingButton
          variant="contained"
          onClick={onConfigurationSave}
          disabled={!state.isConfigurationDirty || !state.isValid}
        >
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default DataBackupConfigurationContainer;
