import { getSharedVariables } from "../variables";
import { memoryStorageService, sessionStorageService } from "./storage";

const parseVersion = (version: unknown) => parseInt(version?.toString() ?? "", 10) || undefined;

const getLocalVersion = () => parseVersion(getSharedVariables().APP_VERSION);

const getCurrentVersion = () => memoryStorageService.getItem("app_version");

export const updateCurrentAppVersion = (appVersionHeader: unknown): void => {
  const currentAppVersion = parseVersion(appVersionHeader);
  if (currentAppVersion !== undefined) {
    memoryStorageService.setItem("app_version", currentAppVersion);
  }
};

export const isAppUpdateAvailable = (): boolean => {
  const localVersion = getLocalVersion();
  const currentVersion = getCurrentVersion();
  return localVersion !== undefined && currentVersion !== undefined && localVersion !== currentVersion;
};

const getLastUpdatePopupTime = () => sessionStorageService.getItemWithFallback("last_update_popup_time", 0);

export const saveLastAppUpdatePopupTime = (): void => {
  sessionStorageService.setItem("last_update_popup_time", Date.now());
};

export const isAppUpdatePopupExpired = (cooldownMs: number): boolean =>
  Date.now() - getLastUpdatePopupTime() > cooldownMs;
