export const allMetricDataTypes = ["Text", "Number", "Money", "Date", "Select"] as const;
export type MetricDataType = (typeof allMetricDataTypes)[number];

export const allMetricAggegationTypes = ["EndOfPeriod", "Sum"] as const;
export type MetricAggregationType = (typeof allMetricAggegationTypes)[number];

export interface MetricFormatConfiguration {
  numberDisplayStyle?: NumberDisplayStyle;
  numberOfDecimals?: number;
}

export interface MetricExtensionConfiguration {
  extensionName: string;
  globalDictionaryName?: GlobalDictionaryType;
  allowedValues?: string[];
}

export interface MetricValueSourceConfiguration {
  globalDictionaryName?: GlobalDictionaryType;
  allowedValues?: string[];
}

export const allNumberDisplayStyles = ["Percentage", "Thousands", "Millions", "Billions", "Multiple"] as const;
export type NumberDisplayStyle = (typeof allNumberDisplayStyles)[number];

type GlobalDictionaryType =
  | "Country"
  | "State"
  | "Currency"
  | "TaxForm"
  | "InvestorCategory"
  | "FormPfName"
  | "Chapter3Status"
  | "Chapter4FatcaStatus"
  | "Irs1099Code"
  | "IndustryOrSector"
  | "Geography";
