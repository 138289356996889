import { useCallback, useState } from "react";
import { ApiResponse } from "../../../../../../shared/api/types";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import biClient from "../../../../../../shared/reporting/api/biClient";
import { ReportTemplate, Status } from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  getTemplates: () => Promise<ApiResponse<ReportTemplate[]>>;
}
export default function useReportTemplates({ getTemplates }: Props) {
  const [statusUpdateError, setStatusUpdateError] = useState<string | undefined>(undefined);
  const [deleteError, setDeleteError] = useState<string | undefined>(undefined);

  const [reportTemplates, errorLoad, { fetch: reloadReportTemplates, setData: setReportTemplates }] =
    useFetch(getTemplates);

  const addReportTemplate = useCallback(
    async (template: ReportTemplate) => {
      const response = await biClient.addReportTemplate({ template });
      if (response.success && reportTemplates !== undefined) {
        setReportTemplates([...reportTemplates, response.data]);
      }
    },
    [reportTemplates, setReportTemplates]
  );

  const switchStatus = useCallback(
    async (template: ReportTemplate, status: Status) => {
      try {
        const response = await biClient.updateReportTemplateStatus({
          templateId: template.reportId,
          status: status,
        });
        if (response.success) {
          setReportTemplates(
            (reportTemplates ?? []).map((t) => (t.reportId === template.reportId ? response.data : t))
          );
          return true;
        }
        setStatusUpdateError(response.error?.message);
        return false;
      } catch (error) {
        logError(error, "[useReportTemplates] updateReportTemplateStatus");
        setStatusUpdateError("Failed to switch status");
        return false;
      }
    },
    [reportTemplates, setReportTemplates]
  );

  const deleteReportTemplates = useCallback(
    async (templates: ReportTemplate[]) => {
      const ids = templates.map((t) => t.reportId);
      try {
        const response = await biClient.deleteReportTemplates({
          templates: templates.map((r) => ({ templateId: r.reportId, organization: r.organization })),
        });
        if (response.success) {
          setReportTemplates((reportTemplates ?? []).filter((t) => !ids.includes(t.reportId)));
          return true;
        }
        setDeleteError(response.error?.message);
        return false;
      } catch (error) {
        logError(error, "[useReportTemplates] deleteReportTemplates");
        setDeleteError("Failed to delete templates");
        return false;
      }
    },
    [reportTemplates, setReportTemplates]
  );

  return {
    templates: reportTemplates ?? [],
    ui: { errorLoad, loaded: reportTemplates !== undefined || errorLoad !== undefined, statusUpdateError, deleteError },
    actions: { addReportTemplate, reloadReportTemplates, switchStatus, deleteReportTemplates },
  };
}
