import LanguageIcon from "@mui/icons-material/LanguageRounded";
import { FundraisingStatus } from "../../../../../shared/api/fundraisingTypes";
import SuccessIcon from "../../../../icons/SuccessIcon";
import VisibleIcon from "../../../../icons/VisibleIcon";
import CombinedPaperIcon from "../../../common/CombinedPaperIcon";

interface Props {
  status: FundraisingStatus;
}

const FundraisingStatusUpdateIcon = ({ status }: Props) => {
  switch (status) {
    case "Preview":
      return <CombinedPaperIcon Icon={VisibleIcon} />;
    case "Live":
      return <CombinedPaperIcon Icon={LanguageIcon} />;
    case "Completed":
      return <SuccessIcon sx={{ width: 80, height: 80 }} />;
    default:
      return null;
  }
};

export default FundraisingStatusUpdateIcon;
