import { GridEventListener } from "@mui/x-data-grid-premium";
import { useNavigate } from "react-router";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { DataCollectionRequestInfo } from "../../../../api/types/dataCollectionTypes";
import { columnDefinitions } from "./dataRequestsGridDataProvider";

interface Props {
  rows: DataCollectionRequestInfo[];
  isLoading?: boolean;
  onRowsScrollEnd: () => void;
}

const DataRequestsGrid = ({ rows, isLoading, onRowsScrollEnd }: Props) => {
  const navigate = useNavigate();

  const handleCellClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (field !== "actions") {
      navigate(`${row.id}`);
    }
  };

  return (
    <DataGrid<DataCollectionRequestInfo>
      rows={rows}
      columns={columnDefinitions}
      loading={isLoading}
      selectableRows
      onRowsScrollEnd={onRowsScrollEnd}
      onCellClick={handleCellClick}
      noRowsText="No data requests"
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default DataRequestsGrid;
