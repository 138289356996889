import { TabPanel } from "@mui/lab";
import { useState } from "react";
import { useSearchParams } from "react-router";
import { parseSearchParamWithFallback } from "../../../../../shared/utilities/searchParamsHelper";
import { useClientContext } from "../../../../context/ClientContext";
import { pageRoutes } from "../../../../routes";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import ObjectLayouts from "../layouts/ObjectLayouts";
import ObjectFields from "../object-fields/ObjectFields";
import ObjectDetailsGeneral from "./ObjectDetailsGeneral";
import ObjectDetailsTitle from "./ObjectDetailsTitle";

const tabValues = ["general", "fields", "layouts"] as const;
type TabValue = (typeof tabValues)[number];

const tabPanelSx = { px: 3, py: 2.5, height: "100%" };

const ObjectDetailsPage = () => {
  const { clientCode } = useClientContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<TabValue>(
    parseSearchParamWithFallback(searchParams, "tab", tabValues)
  );

  const handleTabChange = (tab: TabValue) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  const pathToObjectsList = `/${clientCode}/${pageRoutes.settings}/${pageRoutes.dataModel}?tab=objects`;

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<ObjectDetailsTitle />}
        showDefaultBackButtonTo={pathToObjectsList}
        hideBorderLine
      />
      <PageTabs
        tabs={tabValues}
        labels={["General", "Fields", "Layouts"]}
        value={selectedTab}
        onChange={handleTabChange}
      >
        <TabPanel value="general" sx={tabPanelSx}>
          <ObjectDetailsGeneral />
        </TabPanel>
        <TabPanel value="fields" sx={{ ...tabPanelSx, overflowY: "hidden" }}>
          <ObjectFields />
        </TabPanel>
        <TabPanel value="layouts" sx={tabPanelSx}>
          <ObjectLayouts />
        </TabPanel>
      </PageTabs>
    </>
  );
};

export default ObjectDetailsPage;
