import AddIcon from "@mui/icons-material/AddRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Box, Button, IconButton, List, ListItem, ListItemText, Stack } from "@mui/material";
import { useState } from "react";
import { Category } from "../../../../../../api/types/accessTypes";
import EditCategoryDialog from "./EditCategoryDialog";

interface Props {
  categories: Category[];
  readOnly?: boolean;
  onCreated: (newCategory: Category) => void;
  onUpdated: (updatedCategory: Category) => void;
  onDeleted: (categoryId: string) => void;
}

interface DialogState {
  open: boolean;
  editedCategory?: Category;
}

const CategoriesEditor = ({ categories, readOnly, onCreated, onUpdated, onDeleted }: Props) => {
  const [dialogState, setDialogState] = useState<DialogState>({ open: false });

  const handleAddCategory = () => {
    setDialogState({ open: true });
  };

  const handleEditCategory = (editedCategory: Category) => {
    setDialogState({ open: true, editedCategory });
  };

  const existingCategoryNames = categories.filter((c) => c.id !== dialogState.editedCategory?.id).map((c) => c.name);

  return (
    <>
      <Stack overflow="hidden" alignItems="flex-start" maxHeight="calc(100% - 160px)">
        {!readOnly && (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Button variant="contained" startIcon={<AddIcon />} sx={{ ml: 2, mb: 2 }} onClick={handleAddCategory}>
              Add New
            </Button>
          </Box>
        )}

        <List sx={{ width: "100%", px: 0.5, py: 0, overflowY: "auto" }}>
          {categories.map((category) => (
            <ListItem
              key={category.externalId}
              secondaryAction={
                readOnly ? undefined : (
                  <IconButton size="small" onClick={() => handleEditCategory(category)}>
                    <EditIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText>{category.name}</ListItemText>
            </ListItem>
          ))}
        </List>
      </Stack>

      {dialogState.open && (
        <EditCategoryDialog
          editedCategory={dialogState.editedCategory}
          existingCategoryNames={existingCategoryNames}
          onCreated={onCreated}
          onUpdated={onUpdated}
          onDeleted={onDeleted}
          onClose={() => setDialogState({ open: false })}
        />
      )}
    </>
  );
};

export default CategoriesEditor;
