import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import LanguageIcon from "@mui/icons-material/LanguageRounded";
import RevertIcon from "@mui/icons-material/ReplayRounded";
import { IconButton, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import DataSubmissionStatusTag from "../../../../../../shared/components/dataCollection/DataSubmissionStatusTag";
import FormattedDueDate from "../../../../../../shared/components/dataCollection/FormattedDueDate";
import InlineItemsList from "../../../../../../shared/components/InlineItemsList";
import { distinctBy } from "../../../../../../shared/utilities/arrayHelper";
import { convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { RequestSubmissionContact, RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";
import LanguageOffIcon from "../../../../../icons/LanguageOffIcon";
import { useDataRequestPageContext } from "./DataRequestPageContext";

const ActionsCell = ({ row }: { row: RequestSubmissionInfo }) => {
  const {
    hasPermissionsToManage,
    onPublishSubmissions,
    onUnpublishSubmissions,
    onRequestChangesForSubmissions,
    onDeleteSubmissions,
  } = useDataRequestPageContext();

  if (!hasPermissionsToManage) {
    return null;
  }

  if (row.status === "Draft") {
    return (
      <>
        <Tooltip title="Publish" arrow>
          <IconButton onClick={() => onPublishSubmissions([row])}>
            <LanguageIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete" arrow>
          <IconButton color="error" onClick={() => onDeleteSubmissions([row])}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  if (row.status === "Pending") {
    return (
      <Tooltip title="Unpublish" arrow>
        <IconButton onClick={() => onUnpublishSubmissions([row])}>
          <LanguageOffIcon />
        </IconButton>
      </Tooltip>
    );
  }

  if (row.status === "Completed") {
    return (
      <Tooltip title="Request Changes" arrow>
        <IconButton onClick={() => onRequestChangesForSubmissions([row])}>
          <RevertIcon color="warning" />
        </IconButton>
      </Tooltip>
    );
  }

  return null;
};

export const columnDefinitions: GridColDef<RequestSubmissionInfo>[] = [
  {
    field: "recipientName",
    headerName: "Portfolio Company",
    cellClassName: "highlighted-action",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => <DataSubmissionStatusTag status={row.status} />,
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 0.5,
    minWidth: 112,
    renderCell: ({ row }) => <FormattedDueDate dueDateIsoString={row.dueDate} />,
  },
  {
    field: "recipientContacts",
    headerName: "Contacts",
    flex: 1,
    minWidth: 160,
    renderCell: ({ row }) => {
      if (row.recipientContacts.length === 0) {
        return "-";
      }

      return (
        <InlineItemsList<RequestSubmissionContact>
          displayCount={1}
          items={distinctBy(row.recipientContacts, (c) => c.contactName)}
          propGetter={(c) => c.contactName}
          justify="flex-start"
        />
      );
    },
  },
  {
    field: "submittedBy",
    headerName: "Submitted by",
    flex: 1,
    minWidth: 160,
  },
  {
    field: "submittedAt",
    headerName: "Submitted at",
    flex: 0.5,
    minWidth: 160,
    valueFormatter: (value: string | undefined) => convertISODateTime(value),
  },
  {
    field: "actions",
    headerName: "",
    resizable: false,
    width: 80,
    cellClassName: "grid-row-actions",
    align: "right",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
