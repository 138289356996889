import { useState } from "react";
import { withErrorHandling } from "../../../shared/api/axiosHelper";
import ConsentAgreementDialog from "../../../shared/components/consentAgreement/ConsentAgreementDialog";
import {
  acceptAgreementAction,
  getInitialConsentAgreementsState,
} from "../../../shared/components/consentAgreement/consentAgreementsState";
import { ConsentAgreement } from "../../../shared/components/consentAgreement/types";
import { logError } from "../../../shared/logging";
import adminApi from "../../api/adminApi";

interface Props {
  consentAgreements: ConsentAgreement[];
}

const updateUserConsentAgreements = withErrorHandling(adminApi.updateUserConsentAgreements);

const ConsentAgreementContainer = ({ consentAgreements }: Props) => {
  const [saving, setSaving] = useState(false);

  const [state, setState] = useState(getInitialConsentAgreementsState(consentAgreements));

  const saveConsentsAndReload = async (acceptedConsentAgreements: ConsentAgreement[]) => {
    setSaving(true);
    const [, error] = await updateUserConsentAgreements(acceptedConsentAgreements);
    setSaving(false);

    if (error) {
      logError(error, "[ConsentAgreementContainer] updateUserConsentAgreements");
      return;
    }

    window.location.reload();
  };

  const handleAgreementAccept = async (acceptedAgreement: ConsentAgreement) => {
    if (state.isLast) {
      await saveConsentsAndReload([...state.acceptedAgreements, acceptedAgreement]);
    } else {
      setState(acceptAgreementAction());
    }
  };

  if (!state.nextAgreementToAccept) {
    return null;
  }

  return (
    <ConsentAgreementDialog
      saving={saving}
      open={true}
      consentAgreement={state.nextAgreementToAccept}
      onAccept={handleAgreementAccept}
      portalTitle="Admin Portal"
    />
  );
};

export default ConsentAgreementContainer;
