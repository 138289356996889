import RemoveIcon from "@mui/icons-material/CloseRounded";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Button } from "@mui/material";
import CustomDataGridHeaderToolbar from "../../common/grid/CustomDataGridHeaderToolbar";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  onEditSelectedContacts: (ids: string[]) => void;
  onRemoveSelectedContacts: (ids: string[]) => void;
}

const ContactAccessMatrixGridToolbar = ({
  selectedIds,
  setSelectedIds,
  onEditSelectedContacts,
  onRemoveSelectedContacts,
}: Props) => {
  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button
        color="secondary"
        variant="text"
        startIcon={<EditIcon />}
        onClick={() => onEditSelectedContacts(selectedIds)}
      >
        Bulk Edit
      </Button>
      <Button
        color="error"
        variant="text"
        startIcon={<RemoveIcon />}
        onClick={() => onRemoveSelectedContacts(selectedIds)}
      >
        Remove
      </Button>
    </CustomDataGridHeaderToolbar>
  );
};

export default ContactAccessMatrixGridToolbar;
