import { Paper, Stack } from "@mui/material";
import { PropsWithChildren } from "react";

const BlockSection = ({ children }: PropsWithChildren) => {
  return (
    <Paper variant="outlined" sx={{ flex: 1, p: 2, display: "flex", flexDirection: "column" }}>
      <Stack spacing={2} flex={1}>
        {children}
      </Stack>
    </Paper>
  );
};

export default BlockSection;
