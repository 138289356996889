import { blue, green } from "@mui/material/colors";
import StatusChip from "../../../../../shared/components/StatusChip";
import { PortalPageStatus } from "./state";

interface Props {
  value: PortalPageStatus;
}

const PortalPageStatusChip = ({ value }: Props) => {
  switch (value) {
    case "off":
      return <StatusChip label="Off" withDot color={(t) => t.palette.text.secondary} />;
    case "preview":
      return <StatusChip label="Preview" withDot color={blue[700]} />;
    case "live":
      return <StatusChip label="Live" withDot color={green[700]} />;
    default:
      return null;
  }
};

export default PortalPageStatusChip;
