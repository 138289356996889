import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { dataSubmissionStatusCaptionsMap } from "../../../../../shared/utilities/enumCaptions";
import adminApi from "../../../../api/adminApi";
import { DataCollectionRequestDetails } from "../../../../api/types/dataCollectionTypes";
import LanguageOffIcon from "../../../../icons/LanguageOffIcon";
import CombinedPaperIcon from "../../../common/CombinedPaperIcon";

interface Props {
  dataRequestId: string;
  submissionIds: string[];
  recipientNames: string[];
  open: boolean;
  onClose: () => void;
  onApply: (dataRequest: DataCollectionRequestDetails) => void;
}

const unpublishDataRequestSubmissions = withErrorHandling(adminApi.unpublishDataRequestSubmissions);

const UnpublishSubmissionsDialog = ({
  dataRequestId,
  submissionIds,
  recipientNames,
  open,
  onClose,
  onApply,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);

  const handleApply = async () => {
    setSaving(true);
    const [dataRequest, error] = await unpublishDataRequestSubmissions(dataRequestId, { submissionIds });
    setSaving(false);

    if (error) {
      logError(error, "[UnpublishSubmissionsDialog] unpublishDataRequestSubmissions");
      sendNotificationError("Failed to unpublish");
      return;
    }

    sendNotification("Unpublished successfully");
    onApply(dataRequest);
  };

  const prompt =
    recipientNames.length > 1
      ? `Unpublish ${recipientNames.length} submissions`
      : `Unpublish submission for ${recipientNames[0]}`;

  const description = `The ${submissionIds.length > 1 ? "submissions" : "submission"} will be returned to ${dataSubmissionStatusCaptionsMap["Draft"]} and will not be available for viewing by users`;

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Unpublish</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <CombinedPaperIcon Icon={LanguageOffIcon} iconColor="action" />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              {prompt}
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              {description}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={isSaving} onClick={handleApply}>
          Unpublish
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UnpublishSubmissionsDialog;
