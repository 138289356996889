import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import { EmailTrackingItem } from "../../../../../api/types/documentCollectionTypes";
import { columnDefinitions } from "./emailsTableDataProvider";

interface Props {
  emails: EmailTrackingItem[];
}

const EmailsTable = ({ emails }: Props) => {
  return (
    <DataGrid<EmailTrackingItem>
      columns={columnDefinitions}
      rows={emails}
      multilineRows
      disableColumnSorting
      disableColumnReorder
    />
  );
};

export default EmailsTable;
