import { Checkbox, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { useState } from "react";
import ListWithLazyRender from "../../../../../shared/components/ListWithLazyRender";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import useDebounce from "../../../../../shared/hooks/useDebounce";
import { makeLighterBackgroundFromColor } from "../../../../../shared/utilities/colorHelper";
import OperatorSelect from "../OperatorSelect";
import { FilterValue, MultiSelectFilter, MultiSelectFilterOperator, SelectOption } from "../filterTypes";
import { multiSelectFilterOperatorOptions } from "../handlers/multiSelectFilter";
import EmptyFilterBody from "./EmptyFilterBody";
import SelectedOptionsPanel from "./SelectedOptionsPanel";

interface Props {
  filter: MultiSelectFilter;
  onUpdateValue: (newValue: FilterValue) => void;
  options: SelectOption[];
}

const isEmptyFilterBody = (operator: MultiSelectFilterOperator) => ["empty", "not_empty"].includes(operator);

const getOperatorOptions = (filter: MultiSelectFilter) =>
  filter.operatorOptions !== undefined
    ? multiSelectFilterOperatorOptions.filter((option) => filter.operatorOptions?.includes(option.value))
    : multiSelectFilterOperatorOptions;

const MultiSelectFilterEditorCore = ({ options, filter, onUpdateValue }: Props) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(filter.value.selectedValues ?? []);
  const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(options);

  const handleOperatorChange = (operator: MultiSelectFilterOperator) => {
    if (isEmptyFilterBody(operator)) {
      setSelectedValues([]);
      setFilteredOptions(options);
      onUpdateValue({ operator, selectedValues: [] });
    } else {
      onUpdateValue({ operator, selectedValues: filter.value.selectedValues });
    }
  };

  const updateSelectedValuesInFilter = useDebounce((selectedValues: string[]) => {
    const selectedLabels = selectedValues.map(
      (value) => options.find((option) => option.value === value)?.label ?? value
    );

    onUpdateValue({ operator: filter.value.operator, selectedValues, selectedLabels });
  }, 500);

  const handleSelectionChange = (values: string[]) => {
    setSelectedValues(values);
    updateSelectedValuesInFilter(values);
  };

  const handleRemoveOption = (removedOptionValue: string) =>
    handleSelectionChange(selectedValues.filter((value) => value !== removedOptionValue));

  const handleSearch = (label: string) => {
    setFilteredOptions(
      !label ? options : options.filter((option) => option.label.toLowerCase().includes(label.toLowerCase()))
    );
  };

  const handleToggleOption = (optionValue: string, isSelected: boolean) => {
    const newValues = isSelected ? selectedValues.filter((o) => o !== optionValue) : [...selectedValues, optionValue];
    handleSelectionChange(newValues);
  };

  const emptyFilterBody = isEmptyFilterBody(filter.value.operator);
  const operatorOptions = getOperatorOptions(filter);

  return (
    <Stack
      spacing={1}
      sx={(t) => ({
        overflow: "hidden",
        width: t.spacing(47.5),
      })}
    >
      <Stack spacing={2} px={2} pt={2} pb={1}>
        <OperatorSelect<MultiSelectFilterOperator>
          filterName={filter.name}
          options={operatorOptions}
          value={filter.value.operator}
          onChange={handleOperatorChange}
        />
        {emptyFilterBody && <EmptyFilterBody />}
        {!emptyFilterBody && (
          <>
            {selectedValues.length > 0 && (
              <SelectedOptionsPanel
                selectedValues={selectedValues}
                options={options}
                onRemoveOption={handleRemoveOption}
                onClear={() => handleSelectionChange([])}
              />
            )}
            {options.length > 10 && <SearchField fullWidth onSearch={handleSearch} />}
          </>
        )}
      </Stack>
      {!emptyFilterBody && <Divider />}
      {!emptyFilterBody && (
        <ListWithLazyRender sx={(t) => ({ width: "100%", overflowY: "auto", px: 2, pt: 0, maxHeight: t.spacing(55) })}>
          {filteredOptions.map((option) => {
            const isSelected = selectedValues.includes(option.value);
            return (
              <ListItem
                key={option.value}
                value={option.value}
                dense
                disablePadding
                sx={(t) => ({
                  bgcolor: isSelected ? makeLighterBackgroundFromColor(t.palette.primary.main) : undefined,
                })}
              >
                <ListItemButton role={undefined} onClick={() => handleToggleOption(option.value, isSelected)} dense>
                  <ListItemIcon sx={{ minWidth: 0 }}>
                    <Checkbox edge="start" tabIndex={-1} disableRipple checked={isSelected} />
                  </ListItemIcon>
                  <ListItemText primary={option.label} slotProps={{ primary: { noWrap: true } }} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </ListWithLazyRender>
      )}
    </Stack>
  );
};

export default MultiSelectFilterEditorCore;
