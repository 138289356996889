import { Select } from "@mui/material";
import MenuItemWithoutHighlight from "../../../../../shared/components/MenuItemWithoutHighlight";
import PortalPageStatusChip from "./PortalPageStatusChip";
import { allPortalPageStatuses, PortalPageStatus } from "./state";

interface Props {
  value: PortalPageStatus;
  onChange: (newValue: PortalPageStatus) => void;
}

const PortalPageStatusSelect = ({ value, onChange }: Props) => {
  return (
    <Select
      variant="standard"
      disableUnderline
      value={value}
      onChange={(e) => onChange(e.target.value as PortalPageStatus)}
      sx={{
        ".MuiInputBase-input": {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      {allPortalPageStatuses.map((status) => (
        <MenuItemWithoutHighlight key={status} value={status}>
          <PortalPageStatusChip value={status} />
        </MenuItemWithoutHighlight>
      ))}
    </Select>
  );
};

export default PortalPageStatusSelect;
