import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import DocumentSuccess from "../../../../../../shared/icons/DocumentSuccess";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import { DataCollectionSubmissionDetails } from "../../../../../api/types/dataCollectionTypes";

interface Props {
  submission: DataCollectionSubmissionDetails;
  open: boolean;
  onClose: () => void;
  onSubmitted: () => void;
}

const submitDataSubmission = withErrorHandling(adminApi.submitDataSubmission);

const SubmitConfirmationDialog = ({ open, onClose, onSubmitted, submission }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    const [, error] = await submitDataSubmission(submission.id);
    setSubmitting(false);

    if (error) {
      logError(error, "[SubmitConfirmationDialog] submitDataSubmission");
      sendNotificationError("Submission failed");
      return;
    }

    sendNotification("Submission successful");
    onSubmitted();
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: "32.5rem" } }}>
      <DialogTitle>Submit</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DocumentSuccess sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              Submit {submission.requestTemplateName}?
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              You&apos;re about to submit this form.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" loading={isSubmitting} onClick={handleSubmit}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitConfirmationDialog;
