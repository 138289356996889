import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import LanguageButton from "@mui/icons-material/LanguageRounded";
import RevertIcon from "@mui/icons-material/ReplayRounded";
import { Button } from "@mui/material";
import { RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";
import LanguageOffIcon from "../../../../../icons/LanguageOffIcon";
import CustomDataGridHeaderToolbar from "../../../../common/grid/CustomDataGridHeaderToolbar";
import { useDataRequestPageContext } from "./DataRequestPageContext";

interface Props {
  allSubmissions: RequestSubmissionInfo[];
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
}

const DataRequestSubmissionsGridToolbar = ({ allSubmissions, selectedIds, setSelectedIds }: Props) => {
  const { onPublishSubmissions, onUnpublishSubmissions, onRequestChangesForSubmissions, onDeleteSubmissions } =
    useDataRequestPageContext();

  const selectedSubmissions = allSubmissions.filter((submission) => selectedIds.includes(submission.id));

  const isPublishAllowed = selectedSubmissions.every((submission) => submission.status === "Draft");
  const isDeleteAllowed = selectedSubmissions.every((submission) => submission.status === "Draft");
  const isUnpublishAllowed = selectedSubmissions.every((submission) => submission.status === "Pending");
  const isRequestChangesAllowed = selectedSubmissions.every((submission) => submission.status === "Completed");

  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button
        color="secondary"
        variant="text"
        disabled={!isPublishAllowed}
        startIcon={<LanguageButton />}
        onClick={() => onPublishSubmissions(selectedSubmissions)}
      >
        Publish
      </Button>
      <Button
        color="error"
        variant="text"
        disabled={!isDeleteAllowed}
        startIcon={<DeleteIcon />}
        onClick={() => onDeleteSubmissions(selectedSubmissions)}
      >
        Delete
      </Button>
      <Button
        color="secondary"
        variant="text"
        disabled={!isUnpublishAllowed}
        startIcon={<LanguageOffIcon />}
        onClick={() => onUnpublishSubmissions(selectedSubmissions)}
      >
        Unpublish
      </Button>
      <Button
        color="warning"
        variant="text"
        disabled={!isRequestChangesAllowed}
        startIcon={<RevertIcon />}
        onClick={() => onRequestChangesForSubmissions(selectedSubmissions)}
      >
        Request Changes
      </Button>
    </CustomDataGridHeaderToolbar>
  );
};

export default DataRequestSubmissionsGridToolbar;
