import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { reportingPeriodCaptionsMap } from "../../../../../../shared/utilities/enumCaptions";
import { autoFormatCamelCase } from "../../../../../../shared/utilities/stringHelper";
import { DataCollectionRequestTemplateVersion } from "../../../../../api/types/dataCollectionTypes";

interface Props {
  template: DataCollectionRequestTemplateVersion | undefined;
}

const PropertyValue = ({ label, value }: { label: string; value: string | undefined }) => (
  <Typography noWrap>
    <Typography component="span" variant="caption" color="text.secondary">
      {label}:{" "}
    </Typography>
    <Typography component="span" variant="caption">
      {value ?? ""}
    </Typography>
  </Typography>
);

const TemplateSettingsSection = ({ template }: Props) => {
  return (
    <Stack spacing={1} bgcolor={grey[100]} p={1.25} width="100%">
      <Typography variant="subtitle2" color="text.secondary">
        Template Settings
      </Typography>

      <Stack spacing={1} direction="row" alignItems="center" flexWrap="wrap">
        <PropertyValue label="Scenario" value={template?.scenarioName} />
        <Typography variant="caption">•</Typography>
        <PropertyValue label="Companies" value={template?.recipientObjectIds.length.toLocaleString()} />
        <Typography variant="caption">•</Typography>
        <PropertyValue
          label="Period"
          value={template?.reportingPeriod ? reportingPeriodCaptionsMap[template.reportingPeriod] : undefined}
        />
        <Typography variant="caption">•</Typography>
        <PropertyValue
          label="Recipient Type"
          value={template?.auditory ? autoFormatCamelCase(template.auditory) : undefined}
        />
      </Stack>
    </Stack>
  );
};

export default TemplateSettingsSection;
