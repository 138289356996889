import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import { except } from "../../../../../../../shared/utilities/arrayHelper";
import {
  combineValidators,
  invalidResult,
  requiredValidator,
  uniqueValidator,
  ValidationResult,
} from "../../../../../../../shared/utilities/validators";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";

interface Props {
  editedSectionName?: string;
  onClose: () => void;
  onCreated: (sectionName: string) => void;
}

interface FormState {
  sectionName: string;
  isDirty: boolean;
  validationResult: ValidationResult;
}

const EditFileSectionDialog = ({ editedSectionName, onClose, onCreated }: Props) => {
  const { documentsState } = useFundraisingDocumentsContext();

  const [formState, setFormState] = useState<FormState>({
    sectionName: editedSectionName ?? "",
    isDirty: false,
    validationResult: invalidResult(""),
  });

  const isNew = editedSectionName === undefined;

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const otherSections = except(documentsState.orderedSections, [editedSectionName ?? ""]);

    const validateSectionName = combineValidators(
      requiredValidator,
      uniqueValidator("Such section already exists", otherSections)
    );

    setFormState({
      sectionName: e.target.value,
      isDirty: true,
      validationResult: validateSectionName(e.target.value),
    });
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isNew ? "New Section" : "Rename Section"}</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          variant="outlined"
          onChange={handleNameChange}
          value={formState.sectionName}
          error={formState.isDirty && !formState.validationResult.isValid}
          helperText={formState.isDirty ? formState.validationResult.error : undefined}
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onCreated(formState.sectionName)}
          disabled={formState.sectionName === editedSectionName || !formState.validationResult.isValid}
        >
          {isNew ? "Create" : "Rename"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFileSectionDialog;
