import {
  CalculateByField,
  FieldConfiguration,
  FieldConfigurationType,
  IrrCashFlowFrequency,
  MeasureDateRange,
} from "../../reporting/api/biClient.types";
import { formatMoney, formatPercentage } from "../../utilities/formatters";

//This data will come from dashboard widgets builder and metadata
export interface TabularDataFieldConfig {
  title: string;
  format?: (value: string | number | undefined, currencyCode: string) => string;
  calculateTotal?: boolean;
  hidden?: boolean;
  isKey?: boolean;
  align?: "left" | "right" | "center";
  component?: JSX.Element;
}

export type TabularDataFieldConfigMap = FieldConfiguration & { config: TabularDataFieldConfig };

const tryFormatMoney = (value: string | number | undefined, currencyCode: string, invertValue?: boolean) => {
  if (value === undefined) {
    return "-";
  }

  if (typeof value === "string") {
    const parsed = parseFloat(value);
    if (isNaN(parsed)) {
      return "-";
    }
    value = parsed;
  }

  if (value === 0 || Object.is(value, -0)) {
    return "-";
  }

  if (invertValue) {
    value = -value;
  }

  return formatMoney(value, currencyCode, { currencySign: "accounting" });
};

const tryFormatPercentage = (value: string | number | undefined | null) => {
  if (value === undefined || value === null) {
    return "-";
  }

  if (typeof value === "string") {
    value = parseFloat(value);
    if (isNaN(value)) {
      return "-";
    }
  }

  if (value === 0 || Object.is(value, -0)) {
    return "-";
  }

  return formatPercentage(value, { maximumFractionDigits: 2 });
};

const investorNameField: TabularDataFieldConfigMap = {
  guid: "1a019e1b-14d7-4032-90ef-0843ee8193cd",
  type: FieldConfigurationType.Dimension,
  name: "InvestorName",
  config: { title: "Investor" },
};

const entityNoField: TabularDataFieldConfigMap = {
  guid: "67ba5e55-2458-4686-8a69-e735ff7601e6",
  type: FieldConfigurationType.Dimension,
  name: "EntityNo",
  config: { title: "No.", hidden: true, isKey: true },
};

const communicationGroupNameField: TabularDataFieldConfigMap = {
  guid: "b7a4ee6f-940c-4cb7-b4f9-091a254c7bb8",
  type: FieldConfigurationType.Dimension,
  name: "CommunicationGroupName",
  config: { title: "Fund" },
};

const netIRRField: TabularDataFieldConfigMap = {
  type: FieldConfigurationType.Irr,
  name: "NetIrr",
  guid: "e50a5cf3-1ffe-43fc-b116-bf8efaf33985",
  dateRange: MeasureDateRange.EndingBalance,
  customLabel: "Net IRR",
  calculateByField: CalculateByField.Lcy,
  cashFlowFrequency: IrrCashFlowFrequency.Daily,
  config: {
    title: "Net IRR",
    format: (value) => tryFormatPercentage(value),
    align: "right",
  },
};

const balanceField: TabularDataFieldConfigMap = {
  type: FieldConfigurationType.Measure,
  name: "PartnersCapital",
  guid: "38de34c3-5b49-47e6-9c0f-3c20539864fa",
  dateRange: MeasureDateRange.EndingBalance,
  customLabel: "Balance",
  calculateByField: CalculateByField.Lcy,
  config: {
    title: "Balance",
    calculateTotal: true,
    format: (value, currencyCode) => tryFormatMoney(value, currencyCode, true),
    align: "right",
  },
};

const unfundedField: TabularDataFieldConfigMap = {
  type: FieldConfigurationType.Measure,
  name: "Unfunded",
  guid: "cc999e43-e6fe-4c6e-96ea-87f66f7e168c",
  dateRange: MeasureDateRange.EndingBalance,
  customLabel: "Unfunded",
  calculateByField: CalculateByField.Lcy,
  config: {
    title: "Unfunded",
    calculateTotal: true,
    format: (value, currencyCode) => tryFormatMoney(value, currencyCode),
    align: "right",
  },
};
const commitmentField: TabularDataFieldConfigMap = {
  type: FieldConfigurationType.Measure,
  name: "Commitment",
  guid: "645718e8-1f2b-439c-9381-9a04389e9c61",
  dateRange: MeasureDateRange.EndingBalance,
  customLabel: "Commitment",
  calculateByField: CalculateByField.Lcy,
  config: {
    title: "Commitment",
    calculateTotal: true,
    format: (value, currencyCode) => tryFormatMoney(value, currencyCode),
    align: "right",
  },
};

const contributionsField: TabularDataFieldConfigMap = {
  type: FieldConfigurationType.Measure,
  name: "Contributions",
  guid: "bc9e0bf7-0da0-4143-9c6c-a400b23137e6",
  dateRange: MeasureDateRange.EndingBalance,
  customLabel: "Contributions",
  calculateByField: CalculateByField.Lcy,

  config: {
    title: "Contributions",
    calculateTotal: true,
    format: (value, currencyCode) => tryFormatMoney(value, currencyCode, true),
    align: "right",
  },
};

const distributionsField: TabularDataFieldConfigMap = {
  type: FieldConfigurationType.Measure,
  name: "Distributions",
  guid: "8e2af8ca-8f19-4062-9694-8d5933e44276",
  dateRange: MeasureDateRange.EndingBalance,
  customLabel: "Distributions",
  calculateByField: CalculateByField.Lcy,

  config: {
    title: "Distributions",
    calculateTotal: true,
    format: (value, currencyCode) => tryFormatMoney(value, currencyCode, true),
    align: "right",
  },
};

const dashboardBaseBalanceFields: TabularDataFieldConfigMap[] = [
  commitmentField,
  unfundedField,
  contributionsField,
  distributionsField,
  balanceField,
  netIRRField,
];

const getBalanceFieldSet = (includeGrouppingInvestorField: boolean) => {
  return includeGrouppingInvestorField
    ? [investorNameField, communicationGroupNameField, ...dashboardBaseBalanceFields]
    : [communicationGroupNameField, ...dashboardBaseBalanceFields];
};

// TODO #11077 Temporary stub, should be fixed by returning currencyCode with balance response
const getClientCurrencyCode = (clientCode: string) => {
  if (clientCode.toLowerCase().startsWith("aquileia")) {
    return "EUR";
  }

  if (clientCode.toLowerCase().startsWith("svalner")) {
    return "SEK";
  }

  return "USD";
};

export {
  balanceField,
  commitmentField,
  communicationGroupNameField,
  contributionsField,
  dashboardBaseBalanceFields,
  distributionsField,
  entityNoField,
  getBalanceFieldSet,
  getClientCurrencyCode,
  investorNameField,
  netIRRField,
  unfundedField,
};
