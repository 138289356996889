import { Grid2, Stack } from "@mui/material";
import { ContainerBlockAttributes } from "../../../api/inputFormTypes";
import DataSubmissionFormBlock from "./DataSubmissionFormBlock";

interface Props {
  attributes: ContainerBlockAttributes;
}

const ContainerFormBlock = ({ attributes }: Props) => {
  const size =
    attributes.relativeWidth !== undefined && attributes.relativeWidth > 0
      ? Math.min(12, Math.round(attributes.relativeWidth * 12))
      : attributes.fixedWidth !== undefined && attributes.fixedWidth > 0
        ? "grow"
        : "auto";

  return (
    <Grid2 size={size} sx={{ width: attributes.fixedWidth }}>
      <Stack spacing={2.5}>
        {attributes.children.map((child) => (
          <DataSubmissionFormBlock key={child.id} block={child} />
        ))}
      </Stack>
    </Grid2>
  );
};

export default ContainerFormBlock;
