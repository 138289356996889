import MailIcon from "@mui/icons-material/MailOutlineRounded";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import {
  combineValidators,
  emailValidator,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
  ValidationResult,
} from "../../../../../shared/utilities/validators";
import adminApi, { Contact } from "../../../../api/adminApi";

interface Props {
  mode: "add" | "create";
  onClose: () => void;
  onAdd?: (name: string, email: string) => void;
  onCreate?: (newContact: Contact) => void;
  existingContactNames: string[];
  existingContactEmails: string[];
}

const maxNameLength = 100;

interface FormState {
  name: string;
  email: string;
  nameValidationResult: ValidationResult;
  emailValidationResult: ValidationResult;
  nameCollisionWarning?: string;
}

const createContact = withErrorHandling(adminApi.createContact);

const AddContactDialog = ({ mode, onClose, onAdd, onCreate, existingContactNames, existingContactEmails }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);

  const [formState, setFormState] = useState<FormState>({
    name: "",
    email: "",
    nameValidationResult: { isValid: false, error: "" },
    emailValidationResult: { isValid: false, error: "" },
  });

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validateName = combineValidators(requiredValidator, maxCharactersValidator(maxNameLength));

    const nameValidationResult = validateName(e.target.value);

    const nameCollisionWarning =
      nameValidationResult.isValid && existingContactNames.includes(e.target.value)
        ? "A contact with this name already exists"
        : undefined;

    setFormState((prev) => ({
      ...prev,
      name: e.target.value,
      nameValidationResult,
      nameCollisionWarning,
    }));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validateEmail = combineValidators(
      requiredValidator,
      emailValidator,
      uniqueValidator("A contact with this email already exists", existingContactEmails)
    );

    const emailValidationResult = validateEmail(e.target.value.toLowerCase());

    setFormState((prev) => ({
      ...prev,
      email: e.target.value,
      emailValidationResult,
    }));
  };

  const handleSubmit = async () => {
    if (mode === "add") {
      onClose();
      onAdd?.(formState.name, formState.email);
      return;
    }

    setSaving(true);
    const [resp, error] = await createContact({
      name: formState.name,
      email: formState.email,
    });
    setSaving(false);

    if (error) {
      logError(error, "[AddContactDialog] createContact");
      sendNotificationError("Failed to create contact");
      return;
    }

    sendNotification("Contact created successfully");
    onClose();
    onCreate?.(resp.contact);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add Contact</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Full Name"
            variant="outlined"
            value={formState.name}
            onChange={handleNameChange}
            helperText={formState.nameValidationResult.error || formState.nameCollisionWarning}
            error={!!formState.nameValidationResult.error}
            inputProps={{ maxLength: maxNameLength }}
            InputProps={{
              endAdornment: (
                <Typography color="text.secondary" variant="caption">
                  {maxNameLength - formState.name.length}
                </Typography>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={formState.email}
            onChange={handleEmailChange}
            helperText={formState.emailValidationResult.error}
            error={!!formState.emailValidationResult.error}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          disabled={!formState.nameValidationResult.isValid || !formState.emailValidationResult.isValid}
          onClick={handleSubmit}
          loading={isSaving}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddContactDialog;
