import { Box, Stack, Typography } from "@mui/material";
import DocumentIcon from "../../../../shared/icons/DocumentIcon";

const EmptyDocumentPreview = () => (
  <Box display="flex" alignItems="center" justifyContent="center" flexGrow={1} width="100%" height="100%">
    <Stack alignItems="center" spacing={2}>
      <DocumentIcon sx={{ width: 80, height: 80 }} />
      <Typography color="text.secondary">Select a file to see its preview</Typography>
    </Stack>
  </Box>
);

export default EmptyDocumentPreview;
