import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../../../../shared/icons/BinIcon";
import { logError } from "../../../../../../../shared/logging";
import adminApi from "../../../../../../api/adminApi";

interface Props {
  categoryId: string;
  categoryName: string;
  onClose: () => void;
  onDeleted: () => void;
}

const deleteFundraisingCategory = withErrorHandling(adminApi.deleteFundraisingCategory);

const DeleteCategoryConfirmationDialog = ({ categoryId, categoryName, onClose, onDeleted }: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    setLoading(true);
    const [, error] = await deleteFundraisingCategory(categoryId);
    setLoading(false);

    if (error) {
      logError(error, "[DeleteCategoryConfirmationDialog]");
      sendNotificationError("Failed to delete the category");
      return;
    }

    onDeleted();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Delete category?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You&apos;re about to delete category <strong>{categoryName}</strong>. This action will remove the category
              from all fundraisings and revoke access to any documents assigned to it.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={loading} onClick={handleDeleteClick}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCategoryConfirmationDialog;
