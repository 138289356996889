import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { Grid2, Stack, Typography } from "@mui/material";
import { convertISODateTime } from "../../../../shared/utilities/dateUtils";
import KeyValueColumn from "../../common/KeyValueColumn";

interface Props {
  lastChangedBy?: string;
  lastChangedOn?: string;
  lastBackupDate?: string;
  isLatestBackupSuccessful?: boolean;
}

const DataBackupSetup = ({ lastBackupDate, lastChangedBy, lastChangedOn, isLatestBackupSuccessful }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Setup</Typography>
      <Grid2 container spacing={5}>
        <Grid2>
          <KeyValueColumn title="Modified by" value={lastChangedBy || "-"} />
        </Grid2>
        <Grid2>
          <KeyValueColumn title="Modified at" value={lastChangedOn ? convertISODateTime(lastChangedOn) : "-"} />
        </Grid2>
        <Grid2>
          <KeyValueColumn
            title="Last Backup"
            value={lastBackupDate ? convertISODateTime(lastBackupDate) : "-"}
            icon={
              lastBackupDate ? (
                isLatestBackupSuccessful === true ? (
                  <CheckRoundedIcon color="primary" />
                ) : (
                  <CancelRoundedIcon color="error" />
                )
              ) : null
            }
          />
        </Grid2>
      </Grid2>
    </Stack>
  );
};

export default DataBackupSetup;
