import { SvgIconComponent } from "@mui/icons-material";
import { Box } from "@mui/material";
import PaperIcon from "../../icons/PaperIcon";

interface Props {
  Icon: SvgIconComponent;
  iconColor?: "primary" | "secondary" | "action" | "error" | "warning" | "info" | "success" | "inherit";
}

const CombinedPaperIcon = ({ Icon, iconColor }: Props) => {
  return (
    <Box position="relative" pb={1} pr={1} width={88} height={88}>
      <PaperIcon sx={{ width: 80, height: 80 }} />
      <Box position="absolute" width={26} height={26} bgcolor="white" bottom={13} right={13} zIndex={1000}>
        <Icon color={iconColor ?? "primary"} sx={{ width: 26, height: 26 }} />
      </Box>
    </Box>
  );
};

export default CombinedPaperIcon;
