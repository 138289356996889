import { Alert, AlertTitle, Typography } from "@mui/material";
import { Link } from "react-router";

const IntegrationNotConnected = ({ clientCode }: { clientCode: string }) => {
  return (
    <Alert severity={"error"}>
      <AlertTitle>Integration Not Connected</AlertTitle>
      <Typography>
        Set up integration with Passthrough in Settings &gt;
        <Typography component={"span"}>
          <Link to={`/${clientCode}/settings/integrations`}> Apps & Integrations </Link>
        </Typography>
        &gt; Passthrough. If you don&apos;t have access, please reach out to a team member with admin permissions.
      </Typography>
    </Alert>
  );
};

export default IntegrationNotConnected;
