import { Typography } from "@mui/material";
import DocumentIcon from "../../../../../shared/icons/DocumentIcon";

const EmptyFileDetailsPanel = () => {
  return (
    <>
      <DocumentIcon color="secondary" sx={{ width: 80, height: 80 }} />
      <Typography color="text.secondary">Select a file to see its details</Typography>
    </>
  );
};

export default EmptyFileDetailsPanel;
