import { Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import objectHash from "object-hash";
import { useCallback, useReducer } from "react";
import { useNavigate } from "react-router";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi, { InvestorFund } from "../../../../../api/adminApi";
import { Category } from "../../../../../api/types/accessTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import FundStructureDocumentFilters from "../../common/FundStructureDocumentFilters";
import FundStructureDocumentsGrid from "../../common/FundStructureDocumentsGrid";
import {
  FundStructureDocumentsFilterValue,
  getInitialState,
  reducer,
  stateToRequest,
} from "../../common/fundStructureDocumentsState";

interface Props {
  investorId: string;
  categories: Category[];
  investorFunds: InvestorFund[];
}

const InvestorDetailsDocumentsTab = ({ investorId, categories, investorFunds }: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const apiRef = useGridApiRef();

  const fetchInvestorDocuments = useCallback(
    () => adminApi.searchInvestorDocuments(investorId, stateToRequest(state.page, state.filter)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [investorId, objectHash(state.filter), state.page]
  );

  const [, fetchError, { isFetching }] = useFetch(fetchInvestorDocuments, (data) =>
    dispatch({ type: "load_data", data })
  );

  if (fetchError) {
    logError(fetchError.message, "InvestorDetailsDocumentsTab:searchInvestorDocuments");
    return <DataLoadingFailed bgColor="none" title="Loading investor documents failed" />;
  }

  const handleFiltersChange = (filterValue: FundStructureDocumentsFilterValue) => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    dispatch({ type: "filter", filterValue });
  };

  const handleClearFilters = () => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    dispatch({ type: "clear_filters" });
  };

  const handleGridScrollEnd = () => setTimeout(() => dispatch({ type: "next_page_requested" }), 100);

  const handleNavigateToDocumentActivity = (postMessageRequestId: string, attachmentId: string) => {
    navigate(
      `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.investors}/${investorId}/${pageRoutes.messages}/${postMessageRequestId}/${pageRoutes.documents}/${attachmentId}/${pageRoutes.activity}`
    );
  };

  const categoryOptions = categories
    .filter((c) => c.type === "Message")
    .map(({ externalId, name }) => ({ value: externalId, label: name }));

  const fundOptions = investorFunds.map(({ fundId, fundName }) => ({
    value: fundId,
    label: fundName,
  }));

  return (
    <Stack spacing={2.5} sx={{ height: "100%" }}>
      <FundStructureDocumentFilters
        categoryOptions={categoryOptions}
        fundOptions={fundOptions}
        filterState={state.filter}
        onChange={handleFiltersChange}
        onClear={handleClearFilters}
        isLoading={isFetching}
        recordsCount={state.documents.length}
        totalRecordsCount={state.totalRecords}
      />
      <FundStructureDocumentsGrid
        documents={state.documents}
        categories={categories}
        excludeColumns={["investorName"]}
        onNavigateToDocumentActivity={handleNavigateToDocumentActivity}
        onRowsScrollEnd={handleGridScrollEnd}
        isLoading={isFetching}
        apiRef={apiRef}
      />
    </Stack>
  );
};

export default InvestorDetailsDocumentsTab;
