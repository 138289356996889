import { Checkbox, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import AutocompleteCreatable from "../../../../../shared/components/AutocompleteCreatable";
import useDebounce from "../../../../../shared/hooks/useDebounce";

interface Props<T> {
  options: T[];
  values: T[];
  noItemsText?: string;
  placeholder?: string;
  onSelected: (items: T[]) => void;
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => string;
  getOptionDescription?: (option: T) => string;
  onCreateOption?: () => void;
}

const MultiselectAutocomplete = <T,>({
  options,
  values,
  noItemsText,
  placeholder,
  onSelected,
  getOptionLabel,
  getOptionValue,
  getOptionDescription,
  onCreateOption,
}: Props<T>) => {
  const [filtered, setFiltered] = useState<T[]>(options);

  const onInputChange = useDebounce((value: string) => {
    if (value) {
      const valueLowerCase = value.toLowerCase();
      setFiltered(
        options.filter(
          (o) =>
            (getOptionLabel(o) || "").toLowerCase().includes(valueLowerCase) ||
            (getOptionValue(o) || "").toLowerCase().includes(valueLowerCase) ||
            (getOptionDescription && (getOptionDescription(o) || "").toLowerCase().includes(valueLowerCase))
        )
      );
    } else {
      setFiltered(options);
    }
  }, 300);

  return (
    <AutocompleteCreatable
      noItemsText={noItemsText}
      withLazyRendering
      onCreateOption={onCreateOption}
      multiple
      fullWidth
      sx={(t) => ({ ".MuiInputBase-input": { color: t.palette.secondary.main } })}
      options={options}
      filterOptions={() => filtered}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, item) => option && item && getOptionValue(option) === getOptionValue(item)}
      value={values}
      onChange={(event, selectedOptions) => {
        event.stopPropagation();
        onSelected(selectedOptions);
      }}
      onInputChange={(event, value) => {
        if (event.type === "change") {
          onInputChange(value);
        }
      }}
      onClose={() => setFiltered(options)}
      renderOption={(props, option, { selected, index }) => (
        <ListItem {...props} key={index} value={getOptionValue(option)} dense>
          <Checkbox checked={selected} />
          <ListItemText
            slotProps={{ primary: { component: "span" } }}
            primary={
              <Stack spacing={0.2}>
                <Typography variant="subtitle2">{getOptionLabel(option)}</Typography>
                {getOptionDescription && (
                  <Typography variant="caption" color="text.secondary">
                    {getOptionDescription(option)}
                  </Typography>
                )}
              </Stack>
            }
          />
        </ListItem>
      )}
      renderInput={(params) => <TextField {...params} placeholder={values.length === 0 ? placeholder : undefined} />}
    />
  );
};

export default MultiselectAutocomplete;
