import { TabPanel } from "@mui/lab";
import { useState } from "react";
import { Outlet, useParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import PageTabs from "../../../common/PageTabs";
import InvoicesActionsToolbar from "./InvoicesActionsToolbar";
import InvoicesList from "./InvoicesList";
import { InvoicesTab } from "./InvoicesTab";

const tabPanelSx = { height: "100%", width: "100%", m: 0, px: 3, pt: 2.5, pb: 0 };

const tabs = [
  InvoicesTab.InProgress,
  InvoicesTab.TransactionCreated,
  InvoicesTab.TransactionPosted,
  InvoicesTab.Rejected,
  InvoicesTab.All,
] as const;

const InvoicesPage = () => {
  const { id } = useParams();

  const [settings, settingsError] = useFetch(adminApi.getExpenseManagementSettings);
  const [selectedTab, setSelectedTab] = useState<InvoicesTab>(InvoicesTab.InProgress);

  if (id) {
    return <Outlet />;
  }

  if (settingsError) {
    logError(settingsError, "[InvoicesPage]");
    return <DataLoadingFailed bgColor="none" title="Could not load expense management settings" />;
  }

  return (
    <>
      <GeneralPageHeader title="Invoices" hideBorderLine>
        <InvoicesActionsToolbar settings={settings} />
      </GeneralPageHeader>
      <PageTabs tabs={tabs} labels={tabs} value={selectedTab} onChange={setSelectedTab}>
        {tabs.map((tab) => (
          <TabPanel key={tab} value={tab} sx={tabPanelSx}>
            <InvoicesList tab={tab} />
          </TabPanel>
        ))}
      </PageTabs>
    </>
  );
};

export default InvoicesPage;
