import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { GridColumnDefinition } from "../../../../api/types/fileTypes";
import { getCheckedGridSx } from "../../grid/gridStyles";
import { useFilesContext } from "../FilesContext";
import { FilesRowModel } from "../filesState";
import FilesGridHeaderToolbar from "./FilesGridHeaderToolbar";
import { getColumns } from "./filesGridDataProvider";

interface Props {
  columnDefinitions: GridColumnDefinition[];
  isLoading: boolean;
  selectedRowIds: string[];
  setSelectedRowIds: (ids: string[]) => void;
  apiRef?: React.MutableRefObject<GridApiPremium>;
}

const FilesGrid = ({ columnDefinitions, isLoading, selectedRowIds, setSelectedRowIds, apiRef }: Props) => {
  const { filesState, dispatchFiles } = useFilesContext();

  const handleScrollEnd = () => {
    setTimeout(() => {
      const canLoadMore = filesState.totalPages > 0 && filesState.page < filesState.totalPages - 1;
      if (canLoadMore) {
        dispatchFiles({ type: "next_page" });
      }
    }, 100);
  };

  const showToolbar = selectedRowIds.length > 0;

  const rows = [...filesState.uploadingFiles, ...filesState.files];

  return (
    <DataGrid<FilesRowModel>
      apiRef={apiRef}
      sx={(theme) => getCheckedGridSx(theme, showToolbar)}
      onRowsScrollEnd={handleScrollEnd}
      getRowId={(row) => row.catalogueId}
      rows={rows}
      columns={getColumns(columnDefinitions)}
      loading={isLoading}
      noRowsText="No files yet"
      slots={{
        toolbar: () =>
          showToolbar ? (
            <FilesGridHeaderToolbar
              selectedIds={selectedRowIds}
              setSelectedIds={setSelectedRowIds}
              disabled={isLoading}
            />
          ) : null,
      }}
      disableColumnReorder
      checkboxSelection
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
      isRowSelectable={({ row }) => row.uploadState === undefined}
    />
  );
};

export default FilesGrid;
