import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { DataCollectionRequestInfo } from "../../../../api/types/dataCollectionTypes";

interface Props {
  hasPermissionsToManage: boolean;
  onPublish: (dataRequest: DataCollectionRequestInfo) => void;
  onDelete: (dataRequest: DataCollectionRequestInfo) => void;
}

const DataRequestsListActionsContext = createContext<Props | undefined>(undefined);

export const DataRequestsListActionsContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DataRequestsListActionsContext.Provider value={contextValue}>{children}</DataRequestsListActionsContext.Provider>
);

export const useDataRequestsListActionsContext = () =>
  defined(useContext(DataRequestsListActionsContext), "Attempt to use DataRequestsListActionsContext without provider");
