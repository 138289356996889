import { GridCellModes, GridCellModesModel, GridCellParams, GridEventListener } from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import { MetricExtension } from "../../../../api/dataCollectionTypes";
import { NumericGridTableBlockRow, NumericMetricTableBlockRow, TableBlockColumn } from "../../../../api/inputFormTypes";
import useSingleClickGridEditing from "../../../../hooks/useSingleClickGridEditing";
import DataGrid from "../../../grid/DataGrid";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import { getSubmissionTableFormStyles } from "../submissionTableFormStyles";
import AddMetricExtensionDialog from "./AddMetricExtensionDialog";
import { getColumns, getRows, RowModel } from "./monthlyFinancialsInputGridDataProvider";
import { PortfolioMonitoringInputTableContextProvider } from "./PortfolioMonitoringInputTableContext";
import { calculateTotals } from "./tableTotalsCalculator";

interface Props {
  blockId: string;
  columnDefinitions: TableBlockColumn[];
  rowDefinitions: NumericGridTableBlockRow[];
}

interface AddExtensionDialogState {
  metricId: string;
  metricName: string;
  addedExtensionIds: string[];
}

const MonthlyFinancialsInputForm = ({ blockId, columnDefinitions, rowDefinitions }: Props) => {
  const { isSubmissionEditable, onBlockCellValueEdit } = useDataSubmissionFormContext();

  const [rows, setRows] = useState<RowModel[]>(getRows(rowDefinitions));
  const [dialogState, setDialogState] = useState<AddExtensionDialogState>();

  const columns = useMemo(
    () => getColumns(columnDefinitions, isSubmissionEditable),
    [columnDefinitions, isSubmissionEditable]
  );

  const { cellModesModel, setCellModesModel, handleCellClick } = useSingleClickGridEditing();

  const isCellEditable = useCallback(({ row }: { row: RowModel }) => row.type === "Metric", []);

  const handleCellModesModelChange = (newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  };

  const handleRowUpdate = (newRow: RowModel, oldRow: RowModel) => {
    if (newRow.type !== "Metric" || oldRow.type !== "Metric") {
      return newRow;
    }

    const updatedRow = { ...newRow };

    const updatedValueEntry = Object.entries(updatedRow.values).find(
      ([columnId, currentValue]) => oldRow.values[columnId] !== currentValue
    );

    const rowId = updatedRow.isMetricExtensionRow ? updatedRow.metricId : updatedRow.id;

    if (updatedValueEntry !== undefined) {
      const [columnId, value] = updatedValueEntry;
      const rowExtensionId = updatedRow.isMetricExtensionRow ? updatedRow.extensionId : undefined;

      onBlockCellValueEdit(blockId, {
        rowId,
        rowExtensionId,
        columnId,
        value,
      });
    }

    setTimeout(
      () =>
        setRows((prevRows) => {
          const newRows = prevRows.map((row) => {
            if (row.id !== updatedRow.id) {
              return row;
            }

            return { ...updatedRow };
          });

          return calculateTotals(newRows);
        }),
      100
    );

    return updatedRow;
  };

  const handleAddMetricExtension = (metricId: string, metricName: string) => {
    const addedExtensionIds = rows
      .filter((r) => r.type === "Metric" && r.isMetricExtensionRow && r.metricId === metricId)
      .map((r) => (r as NumericMetricTableBlockRow).extensionId ?? "")
      .filter(Boolean);

    setDialogState({ metricId, metricName, addedExtensionIds });
  };

  const handleMetricExtensionAdded = (metricExtension: MetricExtension, isNew: boolean) => {
    setDialogState(undefined);

    setRows((prevRows) =>
      prevRows.reduce<RowModel[]>((result, row) => {
        if (row.id !== metricExtension.metricId) {
          return [...result, row];
        }

        const newExtensionRow: RowModel = {
          type: "Metric",
          isNew,
          isMetricExtensionRow: true,
          id: `${metricExtension.metricId}_${metricExtension.value}`,
          extensionId: metricExtension.id,
          metricId: metricExtension.metricId,
          name: metricExtension.value,
          indentationLevel: row.indentationLevel + 1,
          values: {},
          previousValues: {},
          dataType: "Money", // TODO get from metric configuration
          aggregationType: "Sum",
        };

        return [...result, row, newExtensionRow];
      }, [])
    );
  };

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = (params: GridCellParams<RowModel>, event) => {
    if (event.code !== "Tab") {
      return;
    }

    event.defaultMuiPrevented = true;
    event.preventDefault();

    const rowId = params.row.id;
    const colId = params.colDef.field;

    let nextEditableRow: RowModel | undefined;

    if (event.shiftKey) {
      const prevRowIdIndex = rows.findIndex((row) => row.id === rowId) - 1;
      nextEditableRow = [...rows.slice(0, prevRowIdIndex + 1)].reverse().find((row) => isCellEditable({ row }));
    } else {
      const nextRowIdIndex = rows.findIndex((row) => row.id === rowId) + 1;
      nextEditableRow = rows.slice(nextRowIdIndex).find((row) => isCellEditable({ row }));
    }

    if (!nextEditableRow) {
      return;
    }

    // Move Edit state to the next editable row
    setCellModesModel((prevModel) => ({
      ...prevModel,
      [rowId]: {
        ...prevModel[rowId],
        [colId]: { mode: GridCellModes.View },
      },
      [nextEditableRow.id]: {
        [colId]: { mode: GridCellModes.Edit },
      },
    }));

    setTimeout(() => {
      // Find the cell element from the next row and column
      const nextRowElement = document.querySelector(`[data-id="${nextEditableRow.id}"]`);
      if (nextRowElement) {
        const input = nextRowElement.querySelector("input");
        if (input) {
          input.select();
        }
      }
    }, 100);
  };

  return (
    <PortfolioMonitoringInputTableContextProvider onAddMetricExtension={handleAddMetricExtension}>
      <DataGrid<RowModel>
        columns={columns}
        rows={rows}
        columnHeaderHeight={52}
        pinnedColumns={{ left: ["name"] }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        onCellKeyDown={handleCellKeyDown}
        disableColumnReorder
        disableColumnSorting
        showCellVerticalBorder
        showColumnVerticalBorder
        editMode="cell"
        isCellEditable={isCellEditable}
        processRowUpdate={handleRowUpdate}
        noScrollableContainer
        sx={getSubmissionTableFormStyles}
      />

      {/* TODO for extensions of type GlobalDictionary or StaticValues allowAddingExtensionValues should be false */}
      {dialogState && (
        <AddMetricExtensionDialog
          metricId={dialogState.metricId}
          metricName={dialogState.metricName}
          addedExtensionIds={dialogState.addedExtensionIds}
          allowAddingExtensionValues
          onAdded={handleMetricExtensionAdded}
          onClose={() => setDialogState(undefined)}
        />
      )}
    </PortfolioMonitoringInputTableContextProvider>
  );
};

export default MonthlyFinancialsInputForm;
