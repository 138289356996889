import CachedIcon from "@mui/icons-material/CachedRounded";
import { Button, Stack } from "@mui/material";
import RecordCounter from "../../../../shared/components/filters/RecordCounter";
import { useImportDataContext } from "./ImportDataContext";
import ImportDataGrid from "./grid/ImportDataGrid";

interface Props {
  isLoading: boolean;
}

const ImportDataList = ({ isLoading }: Props) => {
  const { pageState, refreshData } = useImportDataContext();

  return (
    <Stack spacing={2.5} py={2.5} px={3} height="100%">
      <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
        <RecordCounter
          records={pageState.items.length}
          totalRecords={pageState.totalRecords}
          hide={isLoading || pageState.totalRecords === 0}
        />
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<CachedIcon />}
          onClick={() => refreshData()}
          disabled={isLoading}
        >
          Refresh
        </Button>
      </Stack>
      <ImportDataGrid isLoading={isLoading} />
    </Stack>
  );
};

export default ImportDataList;
