import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Grid2, IconButton, SxProps, Typography } from "@mui/material";
import HorizontalFill from "../HorizontalFill";

interface Props {
  text: string;
  disabled?: boolean;
  onClose: () => void;
  sx?: SxProps;
}
export default function PromptDialogHeader({ text, disabled, onClose, sx }: Props) {
  return (
    <Grid2
      container
      sx={{ px: 3, py: 2, flexDirection: "row", alignItems: "center", flexWrap: "nowrap", width: "100%", ...sx }}
    >
      <Typography component={"span"} variant="h6" minWidth={0} noWrap>
        {text}
      </Typography>
      <HorizontalFill />
      <Box>
        <IconButton disabled={disabled} onClick={onClose} sx={{ width: 20, height: 20 }}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Grid2>
  );
}
