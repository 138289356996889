import { generatePath, useMatch } from "react-router";
import { useClientContext } from "../../context/ClientContext";
import { pageRoutes } from "../../routes";
import MenuLinkItem from "../common/MenuLinkItem";
import SubMenuList from "./SubMenuList";

const PortfolioMenu = () => {
  const { clientCode, portfolioMonitoringApp } = useClientContext();
  const routePattern = `/:clientCode/${pageRoutes.portfolio}/:page/*`;
  const routeMatch = useMatch(routePattern);
  const currentPage = routeMatch?.params["page"];

  const buildFullPath = (page: string) => generatePath(routePattern, { clientCode, page });

  const isDataCollectionEnabled = portfolioMonitoringApp?.dataCollection?.enabled;

  return (
    <SubMenuList>
      <MenuLinkItem
        subItem
        active={currentPage === pageRoutes.fundInvestments}
        title="Fund Investments"
        href={buildFullPath(pageRoutes.fundInvestments)}
      />
      <MenuLinkItem
        subItem
        active={currentPage === pageRoutes.portfolioCompanies}
        title="Portfolio Companies"
        href={buildFullPath(pageRoutes.portfolioCompanies)}
      />
      {isDataCollectionEnabled && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.dataRequestTemplates}
          title="Data Request Templates"
          href={buildFullPath(pageRoutes.dataRequestTemplates)}
        />
      )}
      {isDataCollectionEnabled && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.dataRequests}
          title="Data Requests"
          href={buildFullPath(pageRoutes.dataRequests)}
        />
      )}
      {isDataCollectionEnabled && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.dataSubmissions}
          title="Data Submissions"
          href={buildFullPath(pageRoutes.dataSubmissions)}
        />
      )}
      {isDataCollectionEnabled && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.metrics}
          title="Metrics"
          href={buildFullPath(pageRoutes.metrics)}
        />
      )}
      {isDataCollectionEnabled && (
        <MenuLinkItem
          subItem
          active={currentPage === pageRoutes.portfolioPortal}
          title="Portfolio Portal"
          href={buildFullPath(pageRoutes.portfolioPortal)}
        />
      )}
    </SubMenuList>
  );
};

export default PortfolioMenu;
