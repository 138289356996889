import StatusChip from "../../../../../shared/components/StatusChip";
import { DataCollectionRequestStatus } from "../../../../api/types/dataCollectionTypes";

interface Props {
  status: DataCollectionRequestStatus;
}

const DataRequestStatusTag = ({ status }: Props) => {
  switch (status) {
    case "Draft":
      return <StatusChip label="Draft" withDot color={(t) => t.palette.text.secondary} />;
    case "InProgress":
      return <StatusChip label="In Progress" withDot color={(t) => t.palette.info.main} />;
    case "Completed":
      return <StatusChip label="Completed" withDot color={(t) => t.palette.success.main} />;
    default:
      return null;
  }
};

export default DataRequestStatusTag;
