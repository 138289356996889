import { useState } from "react";
import FilesDropArea from "../../../../../../../shared/components/FilesDropArea";
import { formatFileSize } from "../../../../../../../shared/utilities/fileHelper";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import { acceptedFileExtensions, addDocumentsToUploadAction, maxFileSize } from "./fundraisingDocumentsState";
import FundraisingDocumentsUploadDialog from "./FundraisingDocumentsUploadDialog";

const subtitle = `Maximum file size: ${formatFileSize(
  maxFileSize
)}. Supported formats: pdf, doc(x), xls(x)(m), ppt(x), jpg, jpeg, png, webp.`;

const FundraisingDocumentsUploadArea = () => {
  const { isContentEditable } = useFundraisingDetailsPageContext();
  const { setDocumentsState } = useFundraisingDocumentsContext();

  const [addedFiles, setAddedFiles] = useState<File[]>([]);

  if (!isContentEditable) {
    return null;
  }

  const handleFilesConfirmed = (externalCategoryId: string | undefined, section: string) => {
    if (addedFiles !== undefined) {
      setDocumentsState(addDocumentsToUploadAction(addedFiles, externalCategoryId, section));
    }

    setAddedFiles([]);
  };

  return (
    <>
      <FilesDropArea
        allowMultiple
        acceptedFileExtensions={acceptedFileExtensions}
        subtitle={subtitle}
        onFilesAdd={setAddedFiles}
      />

      {addedFiles.length > 0 ? (
        <FundraisingDocumentsUploadDialog
          files={addedFiles}
          onClose={() => setAddedFiles([])}
          onConfirm={handleFilesConfirmed}
        />
      ) : null}
    </>
  );
};

export default FundraisingDocumentsUploadArea;
