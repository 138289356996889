import { Box, Grid2, Typography } from "@mui/material";
import { convertISODateTime } from "../../../../shared/utilities/dateUtils";
import KeyValueColumn from "../../common/KeyValueColumn";
import { useCrmConnectorContext } from "./CrmConectorContext";

const CrmSetupInfo = () => {
  const { connectionInfo } = useCrmConnectorContext();
  const { environment, createdBy, createdAt, lastSyncAt, connectedTo } = connectionInfo;

  return (
    <Box px={3} py={2}>
      <Typography variant="subtitle1" mb={1}>
        Setup
      </Typography>
      <Grid2 container spacing={5}>
        {environment && (
          <Grid2>
            <KeyValueColumn title="Environment" value={environment} />
          </Grid2>
        )}
        <Grid2>
          <KeyValueColumn title="Created by" value={createdBy || ""} />
        </Grid2>
        <Grid2>
          <KeyValueColumn title="Created at" value={convertISODateTime(createdAt)} />
        </Grid2>
        <Grid2>
          <KeyValueColumn title="Last synchronization" value={convertISODateTime(lastSyncAt ?? "")} />
        </Grid2>
        <Grid2>
          <KeyValueColumn link title="Connected to" value={connectedTo || ""} />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default CrmSetupInfo;
