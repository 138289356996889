import { Badge, BadgeProps } from "@mui/material";
import useMergedSx from "../hooks/useMergedSx";

const customSx = {
  ".MuiBadge-badge": { position: "relative", transform: "none", transformOrigin: 0 },
};

const BadgeDetached = ({ sx, ...props }: BadgeProps) => {
  const sxProps = useMergedSx(customSx, sx);
  return <Badge max={999} {...props} sx={sxProps} />;
};

export default BadgeDetached;
