import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import BadgeDetached from "../../../../../../shared/components/BadgeDetached";

export type EmailsFilterValue = "All" | "Failed";

interface Props {
  allCount: number;
  failedCount: number;
  value: EmailsFilterValue;
  onChange: (value: EmailsFilterValue) => void;
}

const EmailsFilter = ({ allCount, failedCount, value, onChange }: Props) => {
  const handleChange = (_: React.MouseEvent<HTMLElement>, value: EmailsFilterValue) => {
    onChange(value);
  };

  return (
    <ToggleButtonGroup value={value} exclusive onChange={handleChange} sx={{ height: "2.25rem" }}>
      <ToggleButton value="All" sx={{ gap: 1 }}>
        All Emails <BadgeDetached showZero badgeContent={allCount} color="secondary" />
      </ToggleButton>
      <ToggleButton value="Failed" sx={{ gap: 1 }}>
        Failed <BadgeDetached showZero badgeContent={failedCount} color={failedCount > 0 ? "error" : "secondary"} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default EmailsFilter;
