import { AnyRecord } from "../types";

export const convertToFormData = (obj: AnyRecord, rootName?: string) => {
  const formData = new FormData();
  const root = rootName || "";

  const appendFormData = (data: AnyRecord, root: string) => {
    if (data instanceof Date) {
      formData.append(root, data.toISOString());
    } else if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        appendFormData(data[i], root + "[" + i + "]");
      }
    } else if (typeof data === "object" && data && !(data instanceof File)) {
      Object.keys(data).forEach((key) => {
        appendFormData(data[key], root ? `${root}[${key}]` : key);
      });
    } else {
      if (data !== null && typeof data !== "undefined") {
        formData.append(root, data);
      }
    }
  };

  appendFormData(obj, root);
  return formData;
};
