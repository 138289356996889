import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { Category } from "../../../../api/types/accessTypes";

interface Props {
  hasEditPermissions: boolean;
  categories: Category[];
  onDeleteConsent: (consentName: string) => void;
}

const UserConsentsSectionContext = createContext<Props | undefined>(undefined);

export const UserConsentsSectionContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <UserConsentsSectionContext.Provider value={contextValue}>{children}</UserConsentsSectionContext.Provider>
);

export const useUserConsentsSectionContext = () =>
  defined(useContext(UserConsentsSectionContext), "Attempt to use UserConsentsSectionContext without provider");
