export enum RedirectKeys {
  Code = "code",
  State = "state",
  Prompt = "prompt",
}

export interface AuthSettings {
  authEndpoint: string;
  userFlowSignIn: string;
  userFlowSignUp: string;
  userFlowPasswordReset: string;
  clientId: string;
  portalAccessScope: string;
  authCookieNames: string;
  refreshCookieNames: string;
}
