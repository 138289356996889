import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack } from "@mui/material";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import RecordCounter from "../../../../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../../../../shared/components/inputs/SearchField";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../shared/logging";
import adminApi from "../../../../../../api/adminApi";
import { Fundraising } from "../../../../../../api/types/fundraisingTypes";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";
import {
  doChangesExist,
  doChangesOrErrorsExist,
  finishSavingAction,
  getInitialFundraisingDocumentsState,
  getUpdateRequest,
  getUploadRequests,
  searchAction,
  startSavingAction,
  uploadCompletedAction,
  uploadErrorAction,
  uploadInProgressAction,
} from "./fundraisingDocumentsState";

const updateFundraising = withErrorHandling(adminApi.updateFundraising);
const uploadFundraisingFile = withErrorHandling(adminApi.uploadFundraisingFile);

const FundraisingDocumentsActions = () => {
  const { isContentEditable, fundraising, onUpdated, onConfirmSave } = useFundraisingDetailsPageContext();
  const { documentsState, setDocumentsState, filteredDocuments } = useFundraisingDocumentsContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const handleSearch = (value: string) => setDocumentsState(searchAction(value));

  const save = async () => {
    setDocumentsState(startSavingAction());

    let updatedFundraising: Fundraising | undefined = undefined;

    const uploadRequests = getUploadRequests(documentsState);
    const uploadedFileIdMap: Record<string, string> = {};
    let uploadErrorCount = 0;

    for (const uploadRequestWithFileId of uploadRequests) {
      const { fileId, ...uploadFileRequest } = uploadRequestWithFileId;
      setDocumentsState(uploadInProgressAction(fileId));
      const [resp, error] = await uploadFundraisingFile(fundraising.id, uploadFileRequest);
      if (error) {
        logError(error, "[FundraisingDocumentsActions] uploadFundraisingFile");
        setDocumentsState(uploadErrorAction(fileId, error));
        uploadErrorCount++;
      } else {
        uploadedFileIdMap[fileId] = resp.fileId;
        updatedFundraising = resp.fundraising;
        setDocumentsState(uploadCompletedAction(fileId));
      }
    }

    const updateRequest = getUpdateRequest(documentsState, uploadedFileIdMap);

    const [resp, error] = await updateFundraising(fundraising.id, updateRequest);
    if (error) {
      logError(error, "[FundraisingDocumentsActions] updateFundraising");
      sendNotificationError("Could not update fundraising");
    } else {
      updatedFundraising = resp;

      const notificationMessage =
        uploadErrorCount > 0
          ? `Fundraising "${fundraising.name}" updated; ${uploadErrorCount} file(s) could not be uploaded`
          : `Fundraising "${fundraising.name}" updated successfully`;

      sendNotification(notificationMessage);
    }

    setDocumentsState(finishSavingAction(updatedFundraising?.files ?? fundraising.files));

    if (updatedFundraising) {
      onUpdated(updatedFundraising);
    }
  };

  const handleSave = () => onConfirmSave(save);

  const handleReset = () => {
    setDocumentsState(getInitialFundraisingDocumentsState(fundraising.files, fundraising.fileSections));
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" pt={0.5}>
      <RecordCounter records={filteredDocuments.length} totalRecords={documentsState.documents.length} />
      <Stack direction="row" spacing={1} alignItems="center">
        <SearchField
          placeholder="Search by document name"
          initialValue={documentsState.searchTerm}
          onSearch={handleSearch}
          debounceTimeMs={300}
          disabled={documentsState.documents.length === 0}
        />
        {isContentEditable && (
          <Button
            variant="text"
            color="secondary"
            onClick={handleReset}
            disabled={documentsState.isSaving || !doChangesOrErrorsExist(documentsState)}
          >
            Cancel
          </Button>
        )}
        {isContentEditable && (
          <LoadingButton
            variant="contained"
            loading={documentsState.isSaving}
            onClick={handleSave}
            disabled={!doChangesExist(documentsState)}
          >
            Save
          </LoadingButton>
        )}
      </Stack>
    </Box>
  );
};

export default FundraisingDocumentsActions;
