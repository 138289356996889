import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid2,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useCallback, useMemo } from "react";
import PromptDialogHeader from "../../../components/dialog/PromptDialogHeader";
import LoaderBox from "../../../components/LoaderBox";
import useFetch from "../../../hooks/useFetch";
import {
  ReportAccessType,
  ReportAuthorization,
  ReportAuthorizationInfo,
  ReportInfo,
  UserForSharing,
} from "../../api/biClient.types";
import { useBiApiClientProvider } from "../../contexts/BiApiClientProviderContext";
import AuthorizedUsers from "./AuthorizedUsers";
import { SharingReportContextProvider, useSharingReportContext } from "./contexts/SharingReportContext";
import EveryoneSharing from "./EveryoneSharing";
import { CurrentUser, ReportCompany } from "./ShareReport.types";
import UserSelection from "./UserSelection";

interface Props {
  report: ReportInfo;
  currentUser: CurrentUser;
  reportCompany: ReportCompany;
  onClose: () => void;
  onAuthorizationUpdated: (authorization: ReportAuthorizationInfo) => void;
}

export default function ShareReport({
  report,
  currentUser,
  reportCompany: company,
  onClose,
  onAuthorizationUpdated,
}: Props) {
  const { getReportSharing, updateReportSharing } = useBiApiClientProvider();

  const getReportSharingOptions = useCallback(
    () => getReportSharing(report.reportId),
    [report.reportId, getReportSharing]
  );
  const [data, error, { isFetching }] = useFetch(getReportSharingOptions);

  const owner = data?.authorization.users.find((u) => u.access === ReportAccessType.Owner);

  return (
    <Dialog
      open={true}
      maxWidth="sm"
      fullWidth
      sx={{ ".MuiDialog-container": { alignItems: "start", pt: 20 } }}
      PaperProps={{ sx: { minHeight: 250 } }}
    >
      <PromptDialogHeader text={`Share report ${report.name}`} onClose={onClose} />
      <LoaderBox loading={isFetching} overlay>
        {error && <StyledAlert color="error">Access denied</StyledAlert>}
        {data && !error && (
          <SharingReportContextProvider
            currentUser={currentUser}
            report={report}
            owner={owner}
            company={company}
            defaultAuthorization={data.authorization}
            onClose={onClose}
            onAuthorizationUpdated={onAuthorizationUpdated}
            updateReportSharing={updateReportSharing}
          >
            <ShareReportContent authorization={data.authorization} users={data.users} onClose={onClose} />
          </SharingReportContextProvider>
        )}
      </LoaderBox>
    </Dialog>
  );
}

interface ShareReportContentProps {
  authorization: ReportAuthorization;
  users: UserForSharing[];
  onClose: () => void;
}
function ShareReportContent({ authorization, users, onClose }: ShareReportContentProps) {
  const {
    currentUser,
    sharedForCompanyAccess,
    authorizedUsers,
    report,
    isAuthorizationChanged,
    isSaving,
    onUserSelected,
    onUserAccessChanged,
    onRemoveUser,
    onSave,
  } = useSharingReportContext();

  const isOwnerSet = useMemo(() => {
    return authorizedUsers.some((u) => u.access === ReportAccessType.Owner);
  }, [authorizedUsers]);

  const isReportPrivate = useMemo(() => {
    if (sharedForCompanyAccess !== ReportAccessType.NoAccess) {
      return false;
    }
    const usersCount = authorizedUsers.length || 0;
    if (usersCount === 1) {
      return authorizedUsers[0]?.userId === currentUser.id;
    }
    return !(usersCount > 1);
  }, [authorizedUsers, currentUser.id, sharedForCompanyAccess]);

  const availableNotAuthorizedUsers = useMemo(() => {
    return users.filter((u) => !authorizedUsers.some((au) => au.userId === u.userId));
  }, [users, authorizedUsers]);

  return (
    <>
      <DialogContent sx={{ pt: 0.5 }}>
        <Grid2 container gap={2} direction="column" width="100%">
          <EveryoneSharing disabled={isSaving} />
          <UserSelection
            disabled={isSaving}
            availableUsers={availableNotAuthorizedUsers}
            authorization={authorization}
            onUsersSelected={onUserSelected}
          />
        </Grid2>
      </DialogContent>
      <Divider />
      <DialogContent sx={{ pt: 1 }}>
        {!isOwnerSet && (
          <StyledAlert severity="error" sx={{ mb: 1 }}>
            <Typography variant="body1">The report must have an owner. Please assign one.</Typography>
          </StyledAlert>
        )}
        {isReportPrivate && <PrivateReportMessage />}
        {!isReportPrivate && (
          <Typography variant="caption" color="text.secondary">
            More members with access
          </Typography>
        )}
        <Grid2 pt={1}>
          <AuthorizedUsers
            currentUser={currentUser}
            currentUserAccess={report.authorization.access}
            authorizedUsers={authorizedUsers}
            allUsers={users}
            disabled={isSaving}
            onAccessChanged={onUserAccessChanged}
            onRemoveUser={onRemoveUser}
          />
        </Grid2>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="secondary" disabled={isSaving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!isAuthorizationChanged || !isOwnerSet}
          loading={isSaving}
          onClick={onSave}
        >
          Apply
        </LoadingButton>
      </DialogActions>
    </>
  );
}

function PrivateReportMessage() {
  return (
    <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
      <LockOutlinedIcon sx={{ fontSize: "13px" }} color="secondary" />
      <Typography variant="body2" color="secondary">
        Only you have access to this report
      </Typography>
    </Stack>
  );
}

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderColor: theme.palette.error.main,
  borderWidth: 1,
  borderStyle: "solid",
  alignItems: "center",
}));
