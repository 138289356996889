import { getAuthSettings } from "../auth/settings";

const boolCookieExists = (cookieName: string | undefined) =>
  !!cookieName && document.cookie.includes(`${cookieName}=true`);

export const authCookieExists = (): boolean =>
  (getAuthSettings().authCookieNames ?? "").split(",").some((cookieName) => boolCookieExists(cookieName));

export const refreshCookieExists = (): boolean =>
  (getAuthSettings().refreshCookieNames ?? "").split(",").some((cookieName) => boolCookieExists(cookieName));
