import { Box, Button, Typography } from "@mui/material";
import { ChangeEventHandler, DragEventHandler, useRef, useState } from "react";
import { makeLighterBackgroundFromColor } from "../utilities/colorHelper";

interface Props {
  acceptedFileExtensions: string[];
  subtitle?: string;
  onFilesAdd: (files: File[]) => void;
  allowMultiple?: boolean;
}

const FilesDropArea = ({ acceptedFileExtensions, subtitle, onFilesAdd, allowMultiple }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isActive, setActive] = useState(false);

  const handleDragOver: DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Allow drag & drop of files from the browsing window
    e.dataTransfer.dropEffect = "link";
  };

  const handleDrop: DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(false);
    onFilesAdd(Array.from(e.dataTransfer.files));
  };

  const handleDragEnter: DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(true);
  };

  const handleDragExit: DragEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActive(false);
  };

  const handleBrowseFiles = () => {
    fileInputRef.current?.click();
  };

  const handleInputFilesChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const files = e.target.files;
    if (files) {
      onFilesAdd(Array.from(files));
      // Allow adding the same file again right away
      setTimeout(() => {
        if (fileInputRef?.current) {
          fileInputRef.current.value = "";
        }
      });
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        multiple={allowMultiple}
        style={{ display: "none" }}
        accept={acceptedFileExtensions.join(",")}
        onChange={handleInputFilesChange}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        p={2}
        width="100%"
        sx={(t) => ({
          borderWidth: 1,
          borderStyle: isActive ? "solid" : "dashed",
          borderColor: isActive ? t.palette.primary.main : t.palette.divider,
          borderRadius: "4px",
          backgroundColor: isActive ? makeLighterBackgroundFromColor(t.palette.primary.main) : undefined,
        })}
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragExit={handleDragExit}
        onDragOver={handleDragOver}
      >
        <Typography variant="subtitle2">
          Drag and drop here or
          <Button
            variant="text"
            color="primary"
            onClick={handleBrowseFiles}
            sx={{ pl: "4px", pt: 0, pb: "1px", pr: 0 }}
          >
            browse files
          </Button>
        </Typography>
        {subtitle && (
          <Typography variant="caption" color="text.secondary">
            {subtitle}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default FilesDropArea;
