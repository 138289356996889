import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { MenuItem, Select, SelectChangeEvent, useTheme } from "@mui/material";
import { parseISO } from "date-fns";
import { useState } from "react";
import { makeLighterBackgroundFromColor } from "../utilities/colorHelper";
import { formatDate } from "../utilities/dateUtils";

interface Props {
  initialDate: Date | string | undefined | null;
  optionDates: Date[];
  disabled?: boolean;
  onChange: (date: Date) => void;
}

const DateSelector = ({ optionDates, onChange, initialDate, disabled }: Props) => {
  const theme = useTheme();
  const date = typeof initialDate === "string" ? parseISO(initialDate) : initialDate;
  const initialDateIndex = date ? optionDates.findIndex((d) => d.toISOString() === date.toISOString()) : 0;
  const initialIndex = initialDateIndex > -1 ? initialDateIndex : 0;
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(initialIndex);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedDateIndex = event.target.value as number;
    const selectedDate = optionDates[selectedDateIndex];
    setSelectedDateIndex(selectedDateIndex);
    onChange(selectedDate as Date);
  };

  const selectedIndex = initialDateIndex > -1 ? initialIndex : selectedDateIndex;

  const selectedStyles =
    selectedDateIndex > 0
      ? {
          backgroundColor: makeLighterBackgroundFromColor(theme.palette.primary.main),
          fieldset: {
            color: theme.palette.primary.main,
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
          },
        }
      : {};

  return (
    <Select
      sx={() => ({ px: 1, minWidth: 160, ...selectedStyles })}
      startAdornment={<CalendarTodayOutlinedIcon sx={{ mr: 1 }} color="secondary" />}
      disabled={disabled}
      value={selectedIndex}
      onChange={handleChange}
    >
      {optionDates.map((date, i) => (
        <MenuItem key={i} value={i}>
          {formatDate(date)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DateSelector;
