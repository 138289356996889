import { Theme } from "@mui/material";
import { GridEventListener, GridRowSelectionModel, useGridApiRef } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useMemo } from "react";
import DataGrid from "../../../../../../shared/components/grid/DataGrid";
import {
  getVisibleRowIds,
  selectNexRow,
  selectPreviousRow,
} from "../../../../../../shared/utilities/dataGridApiHelper";
import { DocumentInfo } from "../../../../../api/types/documentCollectionTypes";
import { getCheckedGridSx } from "../../../../common/grid/gridStyles";
import { InvestorLevelRowModel, getInvestorLevelRows, investorLevelColumns } from "./documentsTableDataProvider";

interface Props {
  documents: DocumentInfo[];
  showPublishingStatus: boolean;
  onSelectionChange: (selection: GridRowSelectionModel) => void;
}

const DocumentsTable = ({ documents, showPublishingStatus, onSelectionChange }: Props) => {
  const apiRef = useGridApiRef();

  const rows = useMemo(() => getInvestorLevelRows(documents), [documents]);

  useEffect(() => {
    const firstRowId = getVisibleRowIds(apiRef)[0];
    if (firstRowId) {
      setTimeout(() => {
        apiRef.current.setRowSelectionModel([firstRowId]);
        apiRef.current.setCellFocus(firstRowId, "investorTitle");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return apiRef.current.subscribeEvent("rowSelectionChange", (newSelection) => {
      onSelectionChange(newSelection);
    });
  }, [apiRef, onSelectionChange]);

  const handleCellKeyDown: GridEventListener<"cellKeyDown"> = useCallback(
    (_, e) => {
      if (e.code !== "ArrowUp" && e.code !== "ArrowDown") {
        return;
      }

      if (e.code === "ArrowDown") {
        selectNexRow(apiRef);
      } else if (e.code === "ArrowUp") {
        selectPreviousRow(apiRef);
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [apiRef]
  );

  const handleRowClick: GridEventListener<"rowClick"> = useCallback(
    (params) => {
      apiRef.current.setRowSelectionModel([params.id]);
    },
    [apiRef]
  );

  const getSx = useCallback(
    (theme: Theme) => ({
      ...getCheckedGridSx(theme, false),
      ".MuiDataGrid-row": {
        "&:hover": {
          cursor: "pointer",
        },
      },
    }),
    []
  );

  return (
    <DataGrid<InvestorLevelRowModel>
      apiRef={apiRef}
      columns={investorLevelColumns}
      sx={getSx}
      rows={rows}
      onCellKeyDown={handleCellKeyDown}
      onRowClick={handleRowClick}
      checkboxSelection
      disableColumnSorting
      columnVisibilityModel={{
        publishingStatus: showPublishingStatus,
      }}
    />
  );
};

export default DocumentsTable;
