import CheckIcon from "@mui/icons-material/CheckRounded";
import { Tooltip } from "@mui/material";
import { GridColDef, GridGroupingColDefOverride, GridKeyValue } from "@mui/x-data-grid-premium";
import { Maybe } from "../../../../../../shared/types";
import { convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import {
  DocumentInfo,
  InvestorDeliveryInfo,
  MessagePublishStatus,
} from "../../../../../api/types/documentCollectionTypes";
import CategoryInlineTags from "../../../../common/CategoryInlineTags";
import DocumentStatus from "./DocumentStatus";
import FundLevelDataName from "./FundLevelDataName";
import FundLevelGroupName from "./FundLevelGroupName";

export interface InvestorLevelRowModel {
  id: string;
  publishingStatus: MessagePublishStatus;
  category: string;
  investorTitle: string;
  name: string;
  isViewed: boolean;
  viewedAt?: string;
}

export interface FundLevelRowModel {
  id: string;
  group: string;
  publishingStatus: MessagePublishStatus;
  name: string;
  category: string;
  isViewed: boolean;
  viewedAt?: string;
  investor: string;
}

export const investorLevelColumns: GridColDef<InvestorLevelRowModel>[] = [
  {
    field: "publishingStatus",
    headerName: "Status",
    flex: 1,
    minWidth: 120,
    renderCell: ({ value }) => <DocumentStatus status={value as MessagePublishStatus} />,
  },
  {
    field: "investorTitle",
    headerName: "Investor",
    flex: 2,
    minWidth: 120,
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
    minWidth: 120,
    renderCell: ({ value }) => <CategoryInlineTags categoryNames={[value]} />,
  },
  {
    field: "name",
    headerName: "Name",
    flex: 3,
  },
  {
    field: "isViewed",
    headerName: "Viewed",
    type: "boolean",
    flex: 0.5,
    minWidth: 68,
    renderCell: ({ row }) =>
      row.isViewed ? (
        <Tooltip arrow title={row.viewedAt} disableHoverListener={!row.viewedAt}>
          <CheckIcon color="primary" />
        </Tooltip>
      ) : null,
  },
];

export const getInvestorLevelRows = (documents: DocumentInfo[]): InvestorLevelRowModel[] =>
  documents.map((doc) => ({
    id: doc.id,
    publishingStatus: doc.publishingStatus,
    name: doc.name,
    investorTitle: doc.investorTitle || "",
    category: doc.category,
    isViewed: doc.isViewed,
    viewedAt: doc.viewedAt ? convertISODateTime(doc.viewedAt) : undefined,
  }));

const groupingKeySeparator = "|||";

const createGroupingKey = (document: DocumentInfo) =>
  [document.id, document.name, document.category, document.publishingStatus].join(groupingKeySeparator);

const parseGroupingKey = (groupingKey: Maybe<GridKeyValue>) => {
  const [id, name, category, publishingStatus] = (groupingKey?.toString() ?? "").split(groupingKeySeparator);
  return { id, name, category, publishingStatus };
};

const createRowId = (document: DocumentInfo, delivery: InvestorDeliveryInfo) =>
  `${document.id}${groupingKeySeparator}${delivery.investorId}`;

export const parseRowId = (rowId: string) => {
  const [documentId, investorId] = rowId.split(groupingKeySeparator);
  return { documentId, investorId };
};

export const fundLevelGroupingColumn: GridGroupingColDefOverride<FundLevelRowModel> = {
  headerName: "Name",
  flex: 2,
  hideDescendantCount: true,
  renderCell: ({ id, rowNode, row }) =>
    rowNode.type === "group" ? (
      <FundLevelGroupName id={id} rowNode={rowNode} text={parseGroupingKey(rowNode.groupingKey).name || ""} />
    ) : (
      <FundLevelDataName text={row.investor} />
    ),
};

export const fundLevelColumns: GridColDef<FundLevelRowModel>[] = [
  {
    field: "publishingStatus",
    headerName: "Status",
    flex: 1,
    minWidth: 120,
    renderCell: ({ rowNode }) => {
      if (rowNode.type === "group") {
        const status = parseGroupingKey(rowNode.groupingKey).publishingStatus as MessagePublishStatus | undefined;
        if (status) {
          return <DocumentStatus status={status} />;
        }
      }
      return null;
    },
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1,
    minWidth: 120,
    renderCell: ({ rowNode }) => {
      if (rowNode.type === "group") {
        const { category } = parseGroupingKey(rowNode.groupingKey);
        if (category) {
          return <CategoryInlineTags categoryNames={[category]} />;
        }
      }
      return null;
    },
  },
  {
    field: "isViewed",
    headerName: "Viewed",
    type: "boolean",
    flex: 0.5,
    minWidth: 68,
    renderCell: ({ row }) =>
      row.isViewed ? (
        <Tooltip arrow title={row.viewedAt} disableHoverListener={!row.viewedAt}>
          <CheckIcon color="primary" />
        </Tooltip>
      ) : null,
  },
];

const unwrapDocument = (document: DocumentInfo): FundLevelRowModel[] => {
  const result: FundLevelRowModel[] = [];

  const allInvestors = new Map<string, number>();
  document.investorDeliveries.forEach((delivery) => {
    // To ensure unique path in case there are multiple investors with the same title, preserving visible representation of title
    const investorTitleCounter = allInvestors.get(delivery.investorTitle) ?? 0;
    allInvestors.set(delivery.investorTitle, investorTitleCounter + 1);
    const investorTitle = delivery.investorTitle.padEnd(delivery.investorTitle.length + investorTitleCounter, " ");

    result.push({
      id: createRowId(document, delivery),
      group: createGroupingKey(document),
      publishingStatus: document.publishingStatus,
      name: document.name,
      investor: investorTitle,
      category: document.category,
      isViewed: delivery.isViewed,
      viewedAt: delivery.viewedAt ? convertISODateTime(delivery.viewedAt) : undefined,
    });
  });

  return result;
};

export const getFundLevelRows = (documents: DocumentInfo[]): FundLevelRowModel[] => documents.flatMap(unwrapDocument);
