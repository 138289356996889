import { Box, BoxProps, useTheme } from "@mui/material";

interface Props {
  htmlContent: string;
  containerProps?: BoxProps;
}

const HtmlPreview = ({ htmlContent, containerProps }: Props) => {
  const theme = useTheme();
  const {
    typography: { body1 },
    palette: {
      primary: { main },
    },
  } = theme;

  return (
    <Box
      sx={{
        "& p": { ...body1, margin: 0, overflowWrap: "break-word" },
        "& a": { ...body1, margin: 0, textDecoration: "none", color: main, "&:hover": { textDecoration: "underline" } },
        "& ul": { marginBlockStart: 0.5, marginBlockEnd: 0.5 },
        "& ol": { listStyleType: "decimal" },
        "& ol ol": { listStyleType: "lower-alpha" },
        "& ol ol ol": { listStyleType: "lower-roman" },
      }}
      {...containerProps}
    >
      <span dangerouslySetInnerHTML={{ __html: htmlContent }}></span>
    </Box>
  );
};

export default HtmlPreview;
