import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Grid2, IconButton, Typography } from "@mui/material";
import HorizontalFill from "../HorizontalFill";

interface Props {
  text: string;
  disabled?: boolean;
  onClose: () => void;
}
export default function DialogHeader({ text, disabled, onClose }: Props) {
  return (
    <Grid2 container sx={{ px: 2.5, py: 1, flexDirection: "row", alignItems: "center", width: "100%" }}>
      <Typography variant="h6">{text}</Typography>
      <HorizontalFill />
      <Box>
        <IconButton disabled={disabled} onClick={onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
    </Grid2>
  );
}
