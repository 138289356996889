import { Box, Stack } from "@mui/material";
import { useMemo } from "react";
import { Page, usePageContext } from "react-pdf";
import { getMobileOs } from "../../../utilities/mobileOsDetection";
import { PdfViewerState } from "./pdfViewerState";

interface Props {
  viewerState: PdfViewerState;
  pageRefs: React.MutableRefObject<HTMLElement[] | null>;
  pageHeight: number | undefined;
  pageWidth: number | undefined;
}

const PagePlaceholder = () => {
  const pageContext = usePageContext();

  const viewport = useMemo(() => {
    if (!pageContext?.page) {
      return undefined;
    }

    return pageContext.page.getViewport({ scale: pageContext.scale, rotation: pageContext.rotate });
  }, [pageContext?.page, pageContext?.rotate, pageContext?.scale]);

  return <Box width={viewport?.width} height={viewport?.height}></Box>;
};

const PdfPagesContainer = ({ viewerState, pageRefs, pageHeight, pageWidth }: Props) => {
  const mobileOs = getMobileOs();

  const shouldRenderPage = (pageIndex: number) =>
    mobileOs === undefined ||
    (pageIndex + 1 >= viewerState.currentPageNumber - 10 && pageIndex + 1 <= viewerState.currentPageNumber + 10);

  return (
    <Stack alignItems="center">
      {viewerState.isDocumentLoaded &&
        Array.from(new Array(viewerState.numPages), (_, pageIndex) => (
          <Box
            key={`pdf-page-${pageIndex}`}
            ref={(el: HTMLElement) => {
              if (pageRefs.current) {
                pageRefs.current[pageIndex] = el;
              } else {
                pageRefs.current = [el];
              }
            }}
            p={2}
          >
            <Page
              pageNumber={pageIndex + 1}
              scale={viewerState.scale}
              height={pageHeight}
              width={pageWidth}
              customRenderer={shouldRenderPage(pageIndex) ? undefined : PagePlaceholder}
              renderMode={shouldRenderPage(pageIndex) ? "canvas" : "custom"}
              renderAnnotationLayer={shouldRenderPage(pageIndex)}
              renderTextLayer={shouldRenderPage(pageIndex)}
            />
          </Box>
        ))}
    </Stack>
  );
};

export default PdfPagesContainer;
