import React, { Dispatch, PropsWithChildren, SetStateAction, useContext } from "react";
import { defined } from "../../../../../shared/utilities/typeHelper";
import { DocumentCollectionOperations } from "../../../../api/types/documentCollectionTypes";

interface Props {
  hasPermissionsToManage: boolean;
  sentInvites: Record<string, Date>;
  setSentInvites: Dispatch<SetStateAction<Record<string, Date>>>;
  onChangeDocumentCollectionStatus: (operation: DocumentCollectionOperations) => void;
}

const DocumentCollectionContext = React.createContext<Props | undefined>(undefined);

export const DocumentCollectionContextProvider = ({ children, ...value }: PropsWithChildren<Props>) => (
  <DocumentCollectionContext.Provider value={value}>{children}</DocumentCollectionContext.Provider>
);

export const useDocumentCollectionContext = () =>
  defined(useContext(DocumentCollectionContext), "Attempt to use DocumentCollectionContext without provider");
