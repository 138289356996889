type MobileOsName = "iOS" | "Android";

declare global {
  interface Window {
    opera: string;
    MSStream: string;
  }
}

export const getMobileOs = (): MobileOsName | undefined => {
  const userAgent = navigator.userAgent || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return undefined;
};
