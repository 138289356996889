import { FormControl, MenuItem, Select, SxProps, Theme, Typography } from "@mui/material";
import { forwardRef } from "react";
import { DictionaryItem } from "../../../../../../shared/api/dictionaryTypes";
import InvoiceControlWithWarning from "./InvoiceControlWithWarning";

interface Props {
  value?: string;
  onChange: (value?: string) => void;
  values: DictionaryItem[];
  sx?: SxProps<Theme>;
  validationError?: string;
  disabled?: boolean;
}

const InvoiceSelect = forwardRef((props: Props, ref) => {
  const { onChange, value, values, validationError, disabled, sx } = props;

  return (
    <InvoiceControlWithWarning validationError={validationError} containerSx={{ flex: 0 }}>
      <FormControl size="small" sx={sx}>
        <Select
          value={value || ""}
          size="small"
          onChange={(e) => onChange(e.target.value || undefined)}
          ref={ref}
          error={!!validationError}
          disabled={disabled}
        >
          {values.map((v) => (
            <MenuItem key={v.code} value={v.code}>
              <Typography>{v.displayName}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InvoiceControlWithWarning>
  );
});

InvoiceSelect.displayName = "InvoiceSelect";

export default InvoiceSelect;
