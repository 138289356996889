import { importEnvVariable } from "../shared/variables";

type EnvVar = "REPORTING_PORTAL_URI" | "IR_PORTAL_URI" | "HELP_CENTER_URI";

let variables: Record<EnvVar, string> | undefined = undefined;

const importVariables = () => ({
  REPORTING_PORTAL_URI: importEnvVariable("REACT_APP_REPORTING_PORTAL_URI"),
  IR_PORTAL_URI: importEnvVariable("REACT_APP_IR_PORTAL_URI"),
  HELP_CENTER_URI: "https://help.entrilia.com/",
});

export const getVariables = (): Record<EnvVar, string> => {
  if (variables === undefined) {
    variables = importVariables();
  }
  return variables;
};

export const importAuthSettings = () => ({
  authEndpoint: importEnvVariable("REACT_APP_ADMIN_PORTAL_AUTH_ENDPOINT"),
  userFlowSignIn: "",
  userFlowSignUp: "",
  userFlowPasswordReset: "",
  clientId: importEnvVariable("REACT_APP_ADMIN_PORTAL_CLIENT_ID"),
  portalAccessScope: importEnvVariable("REACT_APP_ADMIN_PORTAL_ACCESS_SCOPE"),
  authCookieNames: "x-entrilia-ap-authorized",
  refreshCookieNames: "x-entrilia-ap-refresh-available",
});
