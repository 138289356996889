import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../shared/utilities/typeHelper";
import { DocumentCollectionOperations } from "../../../api/types/documentCollectionTypes";

interface Props {
  hasPermissionsToManage: boolean;
  onDownloadDocumentCollection: (id: string) => void;
  onChangeDocumentCollectionsStatus: (ids: string[], operation: DocumentCollectionOperations) => void;
}

const DocumentCollectionsPageContext = createContext<Props | undefined>(undefined);

export const DocumentCollectionsPageContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DocumentCollectionsPageContext.Provider value={contextValue}>{children}</DocumentCollectionsPageContext.Provider>
);

export const useDocumentCollectionsPageContext = () =>
  defined(useContext(DocumentCollectionsPageContext), "Attempt to use DocumentCollectionsPageContext without provider");
