import { Select } from "@mui/material";
import ColorTag from "../../../../../../../shared/components/ColorTag";
import MenuItemWithoutHighlight from "../../../../../../../shared/components/MenuItemWithoutHighlight";
import CategoryTag from "../../../../../common/CategoryTag";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";

interface Props {
  externalCategoryId: string | undefined;
  onChange: (externalCategoryId: string | undefined) => void;
  variant?: "outlined" | "standard";
  fullWidth?: boolean;
}

const FundraisingCategorySelect = ({ externalCategoryId, onChange, variant, fullWidth }: Props) => {
  const { fundraisingCategories } = useFundraisingDetailsPageContext();

  return (
    <Select
      variant={variant}
      fullWidth={fullWidth}
      disableUnderline={variant === "standard"}
      value={externalCategoryId ?? ""}
      displayEmpty
      renderValue={(value) => {
        const categoryName = fundraisingCategories.find((c) => c.externalId === value)?.name;
        return categoryName ? (
          <CategoryTag categoryName={categoryName} />
        ) : (
          <ColorTag withBorder color="white" text="No category" />
        );
      }}
      onChange={(e) => onChange(e.target.value || undefined)}
      sx={{
        ".MuiInputBase-input": {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      }}
    >
      <MenuItemWithoutHighlight value="">
        <ColorTag withBorder color="white" text="No category" />
      </MenuItemWithoutHighlight>
      {fundraisingCategories.map((category) => (
        <MenuItemWithoutHighlight key={category.externalId} value={category.externalId}>
          <CategoryTag categoryName={category.name} />
        </MenuItemWithoutHighlight>
      ))}
    </Select>
  );
};

export default FundraisingCategorySelect;
