import { Navigate, useParams } from "react-router";
import ObjectDetailsPage from "../ObjectDetailsPage";

const GenericObjectDetailsPage = () => {
  const { id, type } = useParams();

  if (!id || !type) {
    return <Navigate to=".." />;
  }

  return (
    <ObjectDetailsPage
      objectId={id}
      objectType={type}
      showDefaultBackButtonTo={`..?type=${encodeURIComponent(type)}`}
    />
  );
};

export default GenericObjectDetailsPage;
