import { SxProps, TextField } from "@mui/material";
import InvoiceControlWithWarning from "./InvoiceControlWithWarning";

interface Props {
  value: string;
  onChange: (name: string) => void;
  validationError?: string;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  containerSx?: SxProps;
}

const InvoiceTextField = ({ value, onChange, validationError, label, placeholder, disabled, containerSx }: Props) => {
  return (
    <InvoiceControlWithWarning validationError={validationError} containerSx={containerSx}>
      <TextField
        placeholder={placeholder}
        label={label}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        error={!!validationError}
        sx={{ flex: 1 }}
        disabled={disabled}
      />
    </InvoiceControlWithWarning>
  );
};

export default InvoiceTextField;
