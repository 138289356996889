import { Divider, Grid2, Typography } from "@mui/material";
import { useMemo } from "react";
import InlineLoader from "../../../../../../../shared/components/inlineLoader/InlineLoader";
import {
  ChartReportValidationResponse,
  MeasuresValidationResult,
  PivotReportValidationResponse,
  ReportValidationResponse,
  TabularReportValidationResponse,
} from "../../../../../../api/biApi";
import { useUserContext } from "../../../../../../context/UserContext";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import { SelectedCompany, SelectedCompanyReport } from "../hooks/SelectedCompany";
import { DimensionValidation, MeasureValidation } from "./DimensionValidation";
import { ConditionsValidation, FieldsContainer, FieldsValidation } from "./FieldsValidation";
import Group from "./Group";
import NoCompanySelected from "./NoCompanySelected";

interface Props {
  company: SelectedCompany | undefined;
  companyReport: SelectedCompanyReport | undefined;
  updateCompanyReport: (report: SelectedCompanyReport, changes: Partial<SelectedCompanyReport>) => void;
}
export function ValidationDetailsContainer({ company, companyReport, updateCompanyReport }: Props) {
  const { reports_copyto: locale } = useLocalization();
  const { clients } = useUserContext();

  const companyInfo = useMemo(() => {
    return clients.find((c) => c.clientCode === companyReport?.companyCode);
  }, [clients, companyReport]);

  if (!companyReport || !company) {
    return <NoCompanySelected />;
  }

  if (isReportWaitingOrValidating(companyReport)) {
    return <InlineLoader />;
  }

  if (companyReport?.error) {
    return (
      <Grid2 container sx={{ flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%" }}>
        <Typography color="error">{locale.validation_error}</Typography>
      </Grid2>
    );
  }

  return (
    <Grid2 container sx={{ flexDirection: "column", width: "100%" }}>
      <Grid2 sx={{ display: "flex", flexDirection: "column", gap: 0.5, py: 2, px: 2.5 }}>
        <Typography variant="subtitle1">{companyReport.report.name}</Typography>
        <Typography color="secondary">{companyInfo?.title}</Typography>
      </Grid2>
      <Divider />
      <Grid2 sx={{ display: "flex", flexDirection: "column", gap: 2, py: 2, px: 2.5 }}>
        <Typography color="secondary">{locale.select_group}</Typography>
        <Group company={company} companyReport={companyReport} updateCompanyReport={updateCompanyReport} />
        <Typography color="secondary">{locale.fields_that_will_be_copied}</Typography>
      </Grid2>
      <Grid2 container sx={{ flex: 1, position: "relative", width: "100%" }}>
        <Grid2
          container
          sx={{ flex: 1, gap: 2, px: 2.5, position: "absolute", height: "100%", width: "100%", overflowY: "auto" }}
        >
          {companyReport.result && <ValidatedFieldsContainer validationResult={companyReport.result} />}
        </Grid2>
      </Grid2>
    </Grid2>
  );
}

export function ValidatedFieldsContainer({ validationResult }: { validationResult: ReportValidationResponse }) {
  if (validationResult.reportType === "Pivot") {
    return <PivotValidationContainer validationResult={validationResult} />;
  }
  if (validationResult.reportType === "Tabular") {
    return <TabularValidationContainer validationResult={validationResult} />;
  }
  if (validationResult.reportType === "Chart") {
    return <ChartValidationContainer validationResult={validationResult} />;
  }
  return null;
}

function PivotValidationContainer({ validationResult }: { validationResult: PivotReportValidationResponse }) {
  return (
    <Grid2 container sx={{ flexDirection: "column", gap: 1.5, pb: 1 }}>
      <ConditionsValidation conditions={validationResult.conditions} />
      <FieldsValidation caption="Rows" fields={validationResult.rows} />
      <FieldsValidation caption="Columns" fields={validationResult.columns} />
      <MeasuresValidation measures={validationResult.measures} />
    </Grid2>
  );
}

function TabularValidationContainer({ validationResult }: { validationResult: TabularReportValidationResponse }) {
  return (
    <Grid2 container sx={{ flexDirection: "column", gap: 1.5, pb: 1 }}>
      <ConditionsValidation conditions={validationResult.conditions} />
      <FieldsContainer caption="Fields">
        {validationResult.fields?.map((c) => {
          if (c.dimension !== undefined) {
            return <DimensionValidation key={c.dimension.name} field={c.dimension} sx={{ py: 0.5 }} />;
          } else if (c.measure !== undefined) {
            return <MeasureValidation key={c.measure.guid} field={c.measure} />;
          }
          return null;
        })}
      </FieldsContainer>
    </Grid2>
  );
}

function ChartValidationContainer({ validationResult }: { validationResult: ChartReportValidationResponse }) {
  return (
    <Grid2 container sx={{ flexDirection: "column", gap: 1.5, pb: 1 }}>
      <ConditionsValidation conditions={validationResult.conditions} />
      <FieldsValidation caption="Arguments" fields={validationResult.arguments} />
      <MeasuresValidation measures={validationResult.measures} />
    </Grid2>
  );
}

function MeasuresValidation({ measures }: { measures?: MeasuresValidationResult[] | null | undefined }) {
  if (!measures) return null;
  return (
    <FieldsContainer caption="Measures">
      {measures.map((m) => (
        <MeasureValidation key={m.guid} field={m} />
      ))}
    </FieldsContainer>
  );
}

function isReportWaitingOrValidating(report: SelectedCompanyReport | undefined) {
  return report?.validating || (!report?.validated && !report?.validating && !report?.error);
}
