import { DataCollectionSubmissionStatus, ReportingPeriod } from "../api/dataCollectionTypes";
import { EntityOptionFieldType } from "../api/dictionaryTypes";
import { MetricAggregationType } from "../api/portfolioMonitoringTypes";

export const reportingPeriodCaptionsMap: Record<ReportingPeriod, string> = {
  Month: "Month",
  Quarter: "Quarter",
  Year: "Year",
  LastTwelveMonths: "LTM",
};

export const dataSubmissionStatusCaptionsMap: Record<DataCollectionSubmissionStatus, string> = {
  Draft: "Draft",
  Pending: "Pending Submission",
  Completed: "Completed",
};

export const metricAggregationTypeCaptionMap: Record<MetricAggregationType, string> = {
  EndOfPeriod: "End of Period",
  Sum: "Sum",
};

export const entityOptionFieldTypeCaptionMap: Record<EntityOptionFieldType, string> = {
  Country: "Countries",
  State: "States",
  Currency: "Currencies",
  TaxForm: "Tax Forms",
  InvestorCategory: "Investor Categories",
  FormPfName: "Form PF Names",
  Chapter3Status: "Chapter 3 Statuses",
  Chapter4FatcaStatus: "Chapter 4 Fatca Statuses",
  Irs1099Code: "IRS 1099 Codes",
  IndustryOrSector: "Industries / Sectors",
  Geography: "Geographies",
};
