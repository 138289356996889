import { GRID_CHECKBOX_SELECTION_FIELD } from "@mui/x-data-grid-premium";
import { useMemo, useState } from "react";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import { getCheckedGridSx } from "../../common/grid/gridStyles";
import { ContactAccessMatrixRow, getColumnDefinitions, GridActions } from "./contactAccessMatrixGridDataProvider";
import ContactAccessMatrixGridToolbar from "./ContactAccessMatrixGridToolbar";

interface Props {
  categories: string[];
  rows: ContactAccessMatrixRow[];
  isLoading?: boolean;
  actions: GridActions;
  hasPermissionsToManage: boolean;
}

const ContactAccessMatrixGrid = ({ categories, rows, isLoading, actions, hasPermissionsToManage }: Props) => {
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);

  const columns = useMemo(
    () => getColumnDefinitions(categories, actions, hasPermissionsToManage),
    [categories, actions, hasPermissionsToManage]
  );

  const handleEditSelectedContacts = (ids: string[]) => {
    actions.onEditContacts(rows.filter((row) => ids.includes(row.id)));
  };

  const handleRemoveSelectedContacts = (ids: string[]) => {
    actions.onRemoveContacts(rows.filter((row) => ids.includes(row.id)));
  };

  const showToolbar = selectedRowIds.length > 0;

  return (
    <DataGrid<ContactAccessMatrixRow>
      rows={rows}
      columns={columns}
      loading={isLoading}
      pinnedColumns={{ left: [GRID_CHECKBOX_SELECTION_FIELD, "contactName"] }}
      noRowsText="No contacts"
      multilineRows
      checkboxSelection={hasPermissionsToManage}
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={(selection) => setSelectedRowIds(selection as string[])}
      disableColumnReorder
      disableColumnSorting
      sx={(theme) => getCheckedGridSx(theme, showToolbar)}
      slots={{
        toolbar: () =>
          showToolbar ? (
            <ContactAccessMatrixGridToolbar
              selectedIds={selectedRowIds}
              setSelectedIds={setSelectedRowIds}
              onEditSelectedContacts={handleEditSelectedContacts}
              onRemoveSelectedContacts={handleRemoveSelectedContacts}
            />
          ) : null,
      }}
    />
  );
};

export default ContactAccessMatrixGrid;
