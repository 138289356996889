import { Stack, Typography } from "@mui/material";
import PortalPageSettingsEdit from "../investor-portal/pages/PortalPageSettingsEdit";

const PortfolioPortalPagesSection = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="subtitle1">Portal Pages</Typography>
      <PortalPageSettingsEdit
        title="Data Collection"
        toggleValue="live"
        toggleDisabled
        onToggleChange={() => undefined}
      />
    </Stack>
  );
};

export default PortfolioPortalPagesSection;
