import { Stack } from "@mui/material";
import { useCallback, useReducer, useState } from "react";
import { useNavigate } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi, { FundWithFieldValues } from "../../../../api/adminApi";
import { useClientContext } from "../../../../context/ClientContext";
import { FilterContextProvider } from "../../../common/filters/FilterContext";
import FiltersPanel from "../../../common/filters/FiltersPanel";
import { createReducer, filterRecords, getInitialState } from "../../../common/filters/filterState";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import ViewSelector, { ViewType } from "../../../common/ViewSelector";
import FundsCardList from "./FundsCardList";
import { filterDefinitions, searchDefinition } from "./fundsFilters";
import FundsList from "./FundsList";

const FundStructureFundsPage = () => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();

  const [viewType, setViewType] = useState<ViewType>("List");

  const [filterState, dispatchFilters] = useReducer(
    createReducer<FundWithFieldValues>(),
    getInitialState(`${clientCode}_funds_filter_v1`, filterDefinitions, searchDefinition, [])
  );

  const getFunds = useCallback(() => adminApi.searchFunds({ fieldIds: ["Stage"], includeInvestorCount: true }), []);

  const [fundsResp, error, { isFetching }] = useFetch(getFunds);

  const navigateToFund = useCallback(
    (fundId: string) => {
      navigate(`./${fundId}/main`);
    },
    [navigate]
  );

  if (error) {
    logError(error, "FundStructureFundsPage");
    return <DataLoadingFailed bgColor="none" title="Loading funds" />;
  }

  const funds = fundsResp?.items ?? [];
  const filteredFunds = filterRecords(filterState, funds).sort((a, b) => a.name.localeCompare(b.name));
  const isLoading = isFetching || fundsResp === undefined;

  return (
    <>
      <GeneralPageHeader title="Funds" />
      <FilterContextProvider
        filterState={filterState}
        dispatchFilters={dispatchFilters}
        allRowsForSelectOptions={funds}
      >
        <Stack spacing={2} height="100%" px={3} pb={1} pt={2.5}>
          <FiltersPanel totalCount={funds.length} recordCount={filteredFunds.length} isLoading={isLoading}>
            <ViewSelector view={viewType} onChange={setViewType} />
          </FiltersPanel>
          {viewType === "List" && (
            <FundsList onFundClick={navigateToFund} funds={filteredFunds} isLoading={isLoading} />
          )}
          {viewType === "Grid" && (
            <FundsCardList onFundClick={navigateToFund} funds={filteredFunds} isLoading={isLoading} />
          )}
        </Stack>
      </FilterContextProvider>
    </>
  );
};

export default FundStructureFundsPage;
