import { useGridApiContext } from "@mui/x-data-grid-premium";
import { useCallback, useMemo } from "react";
import { ReportInfo } from "../../../../../shared/reporting/api/biClient.types";
import { checkSelection } from "../../../../../shared/utilities/dataGridApiHelper";
import CustomDataGridHeaderToolbar from "../../../common/grid/CustomDataGridHeaderToolbar";
import { CustomToolbarActions } from "../../reporting/reports/grid/CustomToolbarActions";

interface Props {
  selectedIds: string[];
  selectedReports: ReportInfo[];
  copyReports?: (ids: string[]) => void;
  deleteReports: (ids: string[]) => void;
  hideCopy?: boolean;
}

const CustomHeader = ({ selectedIds, selectedReports, copyReports, deleteReports, hideCopy }: Props) => {
  const apiRef = useGridApiContext();

  const { children, checked } = useMemo(() => checkSelection(apiRef), [apiRef]);

  const clearSelection = useCallback(() => {
    apiRef.current.selectRows(children, false);
  }, [apiRef, children]);

  return (
    <CustomDataGridHeaderToolbar
      sx={{
        px: 0,
        pl: 0.7,
        width: "100%",
        borderBottom: "1px solid",
        borderColor: "divider",
      }}
      fullSelection={checked}
      selection={[selectedIds, clearSelection]}
    >
      <CustomToolbarActions
        selectedReports={selectedReports}
        onCopy={copyReports}
        onDelete={deleteReports}
        hideCopy={hideCopy}
      />
    </CustomDataGridHeaderToolbar>
  );
};

export default CustomHeader;
