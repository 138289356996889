import { parseISO } from "date-fns";
import { Maybe } from "../../../shared/types";
import { ClientDictionaries } from "../../api/types/clientTypes";
import { Field, ObjectWithFieldValuesBase } from "../../api/types/objectTypes";
import { getDictionaryDisplayNameFromCode } from "../../utilities/dictionariesHelper";
import { TableColumnDefinition } from "../common/columns/columnTypes";
import { SearchFilterDefinition, TableFilterDefinition } from "../common/filters/filterTypes";
import { getMultiselectOptionsFromValue } from "./helpers";

export const getFilterDefinitionForField = <R extends ObjectWithFieldValuesBase>(
  field: Field,
  dictionaries: ClientDictionaries
): TableFilterDefinition<R> => {
  const { id, type, name, configuration } = field;
  const filterDefinition = { id, name, dynamic: true };

  switch (type) {
    case "Number":
    case "Money":
    case "Percent": {
      return {
        ...filterDefinition,
        type: "number",
        getFieldValue: (row) => {
          const value = row.fieldValues[id];
          return value ? Number(value) : undefined;
        },
      };
    }
    case "Checkbox": {
      return {
        ...filterDefinition,
        type: "boolean",
        getFieldValue: (row) => !!row.fieldValues[id],
      };
    }
    case "Date": {
      return {
        ...filterDefinition,
        type: "date",
        getFieldValue: (row) => {
          const value = row.fieldValues[id];
          return value ? parseISO(value.toString()) : undefined;
        },
      };
    }
    case "Select": {
      return {
        ...filterDefinition,
        type: "multi_select",
        getFieldValue: (row) => {
          const fieldValue = row.fieldValues[id]?.toString();
          if (!fieldValue) {
            return undefined;
          }
          const optionFieldType = configuration?.$type === "Select" && configuration.fieldType;
          return (
            (optionFieldType && getDictionaryDisplayNameFromCode(fieldValue, optionFieldType, dictionaries)) ||
            fieldValue
          );
        },
      };
    }
    case "UserDefinedOptionsSelect": {
      return {
        ...filterDefinition,
        type: "multi_select",
        getFieldValue: (row) => row.fieldValues[id]?.toString(),
      };
    }
    case "MultiSelect":
    case "UserDefinedOptionsMultiSelect": {
      return {
        ...filterDefinition,
        type: "multi_select",
        getFieldValue: (row) => getMultiselectOptionsFromValue(row.fieldValues[id] as Maybe<string | string[]>),
      };
    }
    default: {
      return {
        ...filterDefinition,
        type: "text",
        getFieldValue: (row) => row.fieldValues[id]?.toString(),
      };
    }
  }
};

export const getColumnDefinitionForField = (field: Field, visible?: boolean): TableColumnDefinition => ({
  id: field.id,
  title: field.name,
  dynamic: true,
  defaultHidden: !visible,
});

export const getEntitySearchDefinition = <R extends ObjectWithFieldValuesBase>(
  fieldIds: string[]
): SearchFilterDefinition<R> => ({
  getFieldValues: (row) => fieldIds.map((fieldId) => row.fieldValues[fieldId]?.toString()),
});

// TODO #9346 We don't receive lookup object values with response yet => don't allow column selection of such fields
export const isFieldAllowedForGrid = (field: Field) => field.type !== "Lookup";

export const getEntityFilterDefinitions = (
  allFields: Field[],
  defaultGridFieldIds: string[],
  dictionaries: ClientDictionaries
) => {
  const allowedFields = allFields.filter(isFieldAllowedForGrid);

  const columnDefinitions = allowedFields.map((field) =>
    getColumnDefinitionForField(field, defaultGridFieldIds.includes(field.id))
  );

  const filterDefinitions = allowedFields.map((field) => getFilterDefinitionForField(field, dictionaries));

  const searchDefinition = getEntitySearchDefinition(["Name"]);
  return { columnDefinitions, filterDefinitions, searchDefinition };
};
