import ArrowDownwardIcon from "@mui/icons-material/ArrowDownwardRounded";
import StopIcon from "@mui/icons-material/StopRounded";
import { Box, Button, Fab, Grid2 } from "@mui/material";
import { useState } from "react";
import { generateGuid } from "../../../../shared/utilities/generateGuid";
import InputPrompt from "./InputPrompt";
import ChatHistory from "./chat/ChatHistory";
import HelpInfo from "./chat/HelpInfo";
import { ChatItem } from "./chatState";

interface Props {
  items: ChatItem[];
  promptDisabled: boolean;
  onSubmit: (prompt: string) => void;
  onStopGenerating: () => void;
}

const Chat = ({ items, promptDisabled, onSubmit, onStopGenerating }: Props) => {
  const [showScrollBottomButton, setShowScrollBottomButton] = useState(false);
  const [scrollToBottomTrigger, setScrollToBottomTrigger] = useState("");
  const [showRequestExamples, setShowRequestExamples] = useState("");

  const showChatHistory = items.length > 0;
  const showStopGenerationButton = items.some((item) => item.isPending);

  return (
    <Grid2
      container
      sx={{
        flex: 1,
        flexDirection: "row",
        gap: 3,
        flexWrap: "nowrap",
        bgcolor: "background.grey",
        width: "100%",
      }}
    >
      <Grid2 container sx={{ flex: 2, justifyContent: "center", position: "relative", width: "100%" }}>
        <Grid2
          container
          sx={(theme) => ({
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
            gap: 2.5,
            maxWidth: theme.spacing(90),
            position: "relative",
            py: 3,
            px: 7,
            width: "100%",
          })}
        >
          {showChatHistory ? (
            <>
              <ChatHistory
                items={items}
                scrollToBottomTrigger={scrollToBottomTrigger}
                onScrolled={setShowScrollBottomButton}
              />
              <Grid2 sx={(theme) => ({ minHeight: theme.spacing(3.7) })}>
                {showStopGenerationButton && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={(theme) => ({ py: "2px", bgcolor: theme.palette.background.paper })}
                    startIcon={<StopIcon color="secondary" />}
                    onClick={onStopGenerating}
                  >
                    Stop Generating
                  </Button>
                )}
              </Grid2>
            </>
          ) : (
            <HelpInfo showRequestExamples={() => setShowRequestExamples(generateGuid())} />
          )}

          <InputPrompt disabled={promptDisabled} showRequestExamplesTrigger={showRequestExamples} onSubmit={onSubmit} />
          {showScrollBottomButton && (
            <Box position="absolute" right={0} bottom={30}>
              <Fab color="secondary" size="small" onClick={() => setScrollToBottomTrigger(generateGuid())}>
                <ArrowDownwardIcon />
              </Fab>
            </Box>
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default Chat;
