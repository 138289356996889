import { Box, Stack } from "@mui/material";
import { useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import RecordCounter from "../../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import DataRequestTemplatesGrid from "./DataRequestTemplatesGrid";

const DataRequestTemplatesPage = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [templates, fetchError, { isFetching }] = useFetch(adminApi.getDataCollectionRequestTemplates);

  if (fetchError) {
    logError(fetchError, "[DataRequestTemplatesPage] getDataCollectionRequestTemplates");
    return <DataLoadingFailed title="Could not load data request templates" />;
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const allTemplates = templates ?? [];

  const filteredTemplates = searchTerm
    ? allTemplates.filter((t) => t.name.toLowerCase().includes(searchTerm))
    : allTemplates;

  return (
    <>
      <GeneralPageHeader title="Data Request Templates" />

      <Stack width="100%" spacing={2} pt={2.5} px={3} flex={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <RecordCounter records={filteredTemplates.length} totalRecords={allTemplates.length} />
          <SearchField initialValue={searchTerm} debounceTimeMs={300} onSearch={handleSearch} />
        </Box>
        <DataRequestTemplatesGrid rows={filteredTemplates} isLoading={isFetching} />
      </Stack>
    </>
  );
};

export default DataRequestTemplatesPage;
