import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../shared/logging";
import adminApi from "../../../../../../api/adminApi";
import { FundraisingAccessItem } from "../../../../../../api/types/fundraisingTypes";
import DeletePersonIcon from "../../../../../../icons/DeletePersonIcon";

interface Props {
  editedAccessItem: FundraisingAccessItem;
  fundInvestorsWithContactCount: number;
  onSubmit: () => void;
  onClose: () => void;
}

const updateFundInvestor = withErrorHandling(adminApi.updateFundInvestor);

const RemoveFundraisingAccessDialog = ({
  editedAccessItem,
  fundInvestorsWithContactCount,
  onSubmit,
  onClose,
}: Props) => {
  const { sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);

    const [, error] = await updateFundInvestor(editedAccessItem.fundInvestorId, {
      existingContacts: [],
      newContacts: [],
      removedContactIds: [editedAccessItem.contactId],
    });

    setSaving(false);

    if (error) {
      logError(error, "[RemoveFundraisingAccessDialog] updateFundInvestor");
      sendNotificationError("Failed to update access");
      return;
    }

    onSubmit();
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Remove {editedAccessItem.contactName}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <DialogContent>
        <Stack alignItems="center" spacing={2} px={1}>
          <DeletePersonIcon />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Remove contact?</Typography>
            <Box>
              <Typography component="span" color="text.secondary">
                You&apos;re about to remove{" "}
                <strong>
                  {editedAccessItem.contactName} &lt;{editedAccessItem.contactEmail}&gt;
                </strong>{" "}
                from investor <strong>{editedAccessItem.investorName}. </strong>
              </Typography>
              {fundInvestorsWithContactCount <= 1 && (
                <Typography component="span" color="text.secondary">
                  The contact will lose access to this fund.
                </Typography>
              )}
              {fundInvestorsWithContactCount > 1 && (
                <Typography component="span" color="text.secondary">
                  This contact will retain access to this fund via{" "}
                  <strong>
                    {fundInvestorsWithContactCount - 1} other{" "}
                    {fundInvestorsWithContactCount === 2 ? "investor" : "investors"}.
                  </strong>
                </Typography>
              )}
            </Box>
          </Stack>
        </Stack>
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={isSaving} onClick={handleSave}>
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveFundraisingAccessDialog;
