import { Box, Grid2, SimplePaletteColorOptions, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import DefaultIcon from "../../images/info_page_icon.svg";
import { lightThemeOptions } from "../../theme";
import StyledPage from "./StyledPage";

interface Props {
  emphasize?: string;
  title: string;
  subtitle?: JSX.Element;
  Icon?: JSX.Element;
}

const defaultPrimaryColor = (lightThemeOptions.palette?.primary as SimplePaletteColorOptions).main;

const InfoPage = ({ title, subtitle, emphasize, Icon, children }: PropsWithChildren<Props>) => (
  <StyledPage
    containerProps={{ alignItems: "center", justifyContent: "center", direction: "column", spacing: 3 }}
    sx={{ px: 2 }}
  >
    <Grid2>
      <Stack spacing={1} alignItems="center">
        <Box mt={-20} mb={5}>
          {Icon || <img src={Icon || DefaultIcon} alt="logo" />}
        </Box>
        {emphasize && (
          <Typography variant="h1" sx={{ color: defaultPrimaryColor }}>
            {emphasize}
          </Typography>
        )}
      </Stack>
    </Grid2>
    <Grid2>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h4">{title}</Typography>
        {subtitle}
      </Stack>
    </Grid2>
    <Grid2>
      <Box>{children}</Box>
    </Grid2>
  </StyledPage>
);

export default InfoPage;
