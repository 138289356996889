import { Checkbox, Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowId, useGridApiContext } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import { AccessPermission } from "../../../../../../../shared/api/types";
import AuthorizedBox from "../../../../../../../shared/components/AuthorizedBox";
import { ExpandCollapseButton } from "../../../../../../../shared/reporting/components/reports/ExpandCollapseButton";

export default function GroupName({
  params,
  permissions,
  onExpandToggle,
}: {
  params: GridRenderCellParams;
  permissions: AccessPermission[];
  onExpandToggle: (expanded: boolean, rowId: GridRowId) => void;
}) {
  const apiRef = useGridApiContext();

  const childRows = apiRef.current.getRowGroupChildren({ groupId: params.id });
  const selectedChildren = childRows.filter((child) => apiRef.current.isRowSelected(child)).length;
  const allChildrenSelected = childRows.length === selectedChildren;
  const selected = allChildrenSelected || apiRef.current.isRowSelected(params.id);

  const changeSelection = useCallback(
    (newState: boolean) => {
      apiRef.current.selectRows(childRows, newState);
    },
    [apiRef, childRows]
  );

  if (params.rowNode.type !== "group") {
    return null;
  }

  const expanded = params.rowNode.childrenExpanded === true;

  return (
    <Stack direction="row" alignItems="center">
      <AuthorizedBox permissions={permissions}>
        <Checkbox
          indeterminate={selectedChildren > 0 && !allChildrenSelected}
          checked={selected}
          onChange={(_, checked) => changeSelection(checked)}
        />
      </AuthorizedBox>
      <ExpandCollapseButton
        expanded={expanded}
        onClick={(value) => {
          apiRef.current?.setRowChildrenExpansion(params.id, value);
          onExpandToggle(value, params.id);
        }}
      />
      <Typography variant="subtitle2" pl={1}>
        {params.value} ({params.rowNode.children.length})
      </Typography>
    </Stack>
  );
}
