import { useCallback, useState } from "react";
import { useParams } from "react-router";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { defined } from "../../../../../shared/utilities/typeHelper";
import adminApi, { Investor } from "../../../../api/adminApi";
import { Category } from "../../../../api/types/accessTypes";
import InvestorDetailsPage from "./InvestorDetailsPage";

const FundStructureInvestorPage = () => {
  const { id } = useParams();

  const [categories, setCategories] = useState<Category[]>([]);
  const [accessCategories, fetchAccessCategoriesError] = useFetch(adminApi.getAccessCategories, setCategories);

  const getInvestorDetails = useCallback(() => adminApi.getInvestorDetails(defined(id)), [id]);
  const [investor, fetchInvestorError, { setData: setInvestor }] = useFetch(getInvestorDetails);

  const getObjectDefinition = useCallback(() => adminApi.getObjectDefinition("Investor"), []);
  const [objectDefinitionWithPermissions, fetchDefinitionError] = useFetch(getObjectDefinition);

  if (fetchAccessCategoriesError || fetchInvestorError || fetchDefinitionError) {
    logError(fetchAccessCategoriesError || fetchInvestorError || fetchDefinitionError, "[FundStructureInvestorPage]");
    return <DataLoadingFailed bgColor="none" title="Loading investor details failed" />;
  }

  if (!accessCategories || !investor || !objectDefinitionWithPermissions) {
    return <InlineLoader />;
  }

  const { objectClassDefinition, objectPermissions } = objectDefinitionWithPermissions;

  const handleInvestorDetailsChange = (details: Partial<Investor>) => {
    setInvestor({ ...investor, ...details });
  };

  return (
    <InvestorDetailsPage
      investor={investor}
      categories={categories}
      objectDefinition={objectClassDefinition}
      objectPermissions={objectPermissions}
      onInvestorDetailsChange={handleInvestorDetailsChange}
    />
  );
};

export default FundStructureInvestorPage;
