import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, DateView } from "@mui/x-date-pickers-pro";
import { endOfMonth, subYears } from "date-fns";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { ReportingPeriod } from "../../../../../../shared/api/dataCollectionTypes";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import { formatDate } from "../../../../../../shared/utilities/dateUtils";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import {
  DataCollectionRequestDetails,
  DataCollectionRequestTemplateVersion,
} from "../../../../../api/types/dataCollectionTypes";
import {
  EditDataRequestForm,
  formToCreateRequestPayload,
  formToUpdateRequestPayload,
  getInitialEditDataRequestForm,
  validateForm,
} from "./editDataCollectionRequestForm";
import TemplateSettingsSection from "./TemplateSettingsSection";

interface Props {
  editedDataRequest?: DataCollectionRequestDetails;
  templates: DataCollectionRequestTemplateVersion[];
  onClose: () => void;
  onSaved: (dataRequest: DataCollectionRequestDetails) => void;
}

const createDataCollectionRequest = withErrorHandling(adminApi.createDataCollectionRequest);
const updateDataCollectionRequest = withErrorHandling(adminApi.updateDataCollectionRequest);

const reportingDatePickerViewsMap: Record<ReportingPeriod, DateView[]> = {
  Month: ["year", "month"],
  Quarter: ["year", "month"],
  Year: ["year"],
  LastTwelveMonths: ["year", "month"],
};

const EditDataRequestDialog = ({ templates, editedDataRequest, onClose, onSaved }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [saving, setSaving] = useState(false);

  const [form, setForm] = useState<EditDataRequestForm>(getInitialEditDataRequestForm(editedDataRequest));

  const handleCreate = async () => {
    const payload = formToCreateRequestPayload(form);
    if (!payload) {
      return;
    }

    setSaving(true);
    const [resp, error] = await createDataCollectionRequest(payload);
    setSaving(false);

    if (error) {
      logError(error, "[EditDataCollectionRequestDialog] createDataCollectionRequest");
      sendNotificationError("Failed to create data request");
      return;
    }

    sendNotification("Data request created successfully");
    onSaved(resp);
  };

  const handleUpdate = async () => {
    const payload = formToUpdateRequestPayload(form);
    if (!payload) {
      return;
    }

    setSaving(true);
    const [resp, error] = await updateDataCollectionRequest(defined(editedDataRequest?.id), payload);
    setSaving(false);

    if (error) {
      logError(error, "[EditDataCollectionRequestDialog] updateDataCollectionRequest");
      sendNotificationError("Failed to update data request");
      return;
    }

    sendNotification("Data request updated successfully");
    onSaved(resp);
  };

  const selectedTemplate = templates.find((t) => t.templateId === form.templateId);

  const isNewDataRequest = editedDataRequest === undefined;

  const handleNameChange = (name: string) => {
    setForm((prev) => ({ ...prev, name, touchedFields: [...prev.touchedFields, "name"] }));
  };

  const getDefaultName = (template: DataCollectionRequestTemplateVersion | undefined, reportingDate: Date | null) =>
    template && reportingDate ? `${template.name} - ${formatDate(reportingDate)}` : "";

  const handleTemplateChange = (templateId: string) => {
    setForm((prev) => ({
      ...prev,
      templateId,
      name:
        prev.name ||
        getDefaultName(
          templates.find((t) => t.templateId === templateId),
          form.reportingDate
        ),
      touchedFields: [...prev.touchedFields, "templateId"],
    }));
  };

  const handleReportingDateChange = (date: Date | null) => {
    setForm((prev) => ({
      ...prev,
      reportingDate: date ? endOfMonth(date) : date,
      name: prev.name || getDefaultName(selectedTemplate, date),
      touchedFields: [...prev.touchedFields, "reportingDate"],
    }));
  };

  const handleDueDateChange = (date: Date | null) => {
    setForm((prev) => ({ ...prev, dueDate: date, touchedFields: [...prev.touchedFields, "dueDate"] }));
  };

  const { isFormValid, validationErrors } = validateForm(form);

  const reportingDatePickerViews = selectedTemplate
    ? reportingDatePickerViewsMap[selectedTemplate.reportingPeriod]
    : undefined;

  const defaultReportingDatePickerView = reportingDatePickerViews?.includes("month") ? "month" : undefined;

  return (
    <Dialog open onClose={onClose} PaperProps={{ sx: { width: "40rem" } }}>
      <DialogTitle>{isNewDataRequest ? "New Data Request" : "Edit Data Request"}</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={3}>
          <Stack spacing={1}>
            {isNewDataRequest && (
              <FormControl fullWidth>
                <InputLabel id="template-select-label">Template</InputLabel>
                <Select
                  fullWidth
                  label="Template"
                  value={form.templateId}
                  disabled={!isNewDataRequest}
                  onChange={(e) => handleTemplateChange(e.target.value)}
                  error={!!validationErrors.templateId}
                >
                  {templates.map(({ templateId, name }) => (
                    <MenuItem key={templateId} value={templateId}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {validationErrors.templateId && <FormHelperText error>{validationErrors.templateId}</FormHelperText>}
              </FormControl>
            )}

            {!isNewDataRequest && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="subtitle2" color="secondary">
                  Request Template:
                </Typography>
                <Typography variant="subtitle2">{editedDataRequest.templateName}</Typography>
              </Stack>
            )}

            <TemplateSettingsSection template={selectedTemplate} />
          </Stack>

          <TextField
            fullWidth
            label="Name"
            value={form.name}
            onChange={(e) => handleNameChange(e.target.value)}
            error={!!validationErrors.name}
            helperText={validationErrors.name}
          />

          <Stack direction="row" spacing={3}>
            <DatePicker
              value={form.reportingDate}
              onChange={handleReportingDateChange}
              disabled={selectedTemplate === undefined}
              views={reportingDatePickerViews}
              openTo={defaultReportingDatePickerView}
              minDate={subYears(new Date(), 10)}
              shouldDisableMonth={
                selectedTemplate?.reportingPeriod === "Quarter" ? (date) => date.getMonth() % 3 !== 2 : undefined
              }
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  label: "Reporting Date",
                  error: !!validationErrors.reportingDate,
                  helperText: validationErrors.reportingDate,
                },
              }}
            />

            <DatePicker
              disablePast
              value={form.dueDate}
              onChange={handleDueDateChange}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  label: "Due Date",
                  error: !!validationErrors.dueDate,
                  helperText: validationErrors.reportingDate,
                },
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        {isNewDataRequest && (
          <LoadingButton variant="contained" loading={saving} onClick={handleCreate} disabled={!isFormValid}>
            Add
          </LoadingButton>
        )}
        {!isNewDataRequest && (
          <LoadingButton variant="contained" loading={saving} onClick={handleUpdate} disabled={!isFormValid}>
            Save & update submissions
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default EditDataRequestDialog;
