import { Grid2, Stack, Typography } from "@mui/material";
import { convertISODateTime } from "../../../../shared/utilities/dateUtils";
import KeyValueColumn from "../../common/KeyValueColumn";

interface Props {
  lastChangedBy: string | undefined;
  lastChangedOn: string | undefined;
}

const PassthroughSetup = ({ lastChangedBy, lastChangedOn }: Props) => {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle1">Setup</Typography>
      <Grid2 container spacing={5}>
        <Grid2>
          <KeyValueColumn title="Modified by" value={lastChangedBy || "-"} />
        </Grid2>
        <Grid2>
          <KeyValueColumn title="Modified at" value={lastChangedOn ? convertISODateTime(lastChangedOn) : "-"} />
        </Grid2>
      </Grid2>
    </Stack>
  );
};

export default PassthroughSetup;
