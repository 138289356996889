import { Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import TypographyTooltipEllipsis from "../../../../../../shared/components/TypographyTooltipEllipsis";
import { convertISODateTime } from "../../../../../../shared/utilities/dateUtils";
import { SentEmail } from "../../../../../api/types/emailsTypes";
import EmailStatus from "../../../../tables/EmailStatus";

export const contactEmailsColumns: GridColDef<SentEmail>[] = [
  {
    field: "subject",
    headerName: "Subject",
    flex: 2,
    renderCell: ({ row }) => <TypographyTooltipEllipsis text={row.subject} />,
  },
  {
    field: "source",
    headerName: "Source",
    flex: 1,
    renderCell: ({ row }) => <TypographyTooltipEllipsis text={row.source} />,
  },
  {
    field: "sentAt",
    headerName: "Sent",
    width: 160,
    renderCell: ({ row }) => <Typography color="text.secondary">{convertISODateTime(row.sentAt)}</Typography>,
  },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: ({ row }) => (
      <EmailStatus
        status={row.status}
        deliveryFailureMessage={row.deliveryFailureMessage}
        deliveryFailureMessageExplanation={row.deliveryFailureMessageExplanation}
      />
    ),
  },
];
