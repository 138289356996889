import { Alert } from "@mui/material";
import InlineLoader from "../../../../shared/components/inlineLoader/InlineLoader";

export interface FullResyncState {
  status: "hidden" | "starting" | "success" | "error";
  errorMessage?: string;
}

interface Props {
  state: FullResyncState;
  onClose: () => void;
}

const AlertMessage = ({ status, errorMessage }: FullResyncState) => {
  if (status === "success") {
    return (
      <>
        Full re-synchronization started. All logs you can see in tab <strong>Logs</strong>.
      </>
    );
  }

  let msg = "Full re-synchronization failed";
  if (errorMessage) {
    msg += `: ${errorMessage}`;
  }

  return <>{msg}</>;
};

const FullResyncInfo = ({ state, onClose }: Props) => {
  const { status, errorMessage } = state;

  if (status === "hidden") {
    return null;
  }

  if (status === "starting") {
    return <InlineLoader />;
  }

  return (
    <Alert severity={status} onClose={onClose}>
      <AlertMessage status={status} errorMessage={errorMessage} />
    </Alert>
  );
};

export default FullResyncInfo;
