import { Container } from "@mui/material";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import SupportInfoEditor from "./SupportInfoEditor";

const PortalSupportInfo = () => {
  const [portalSettingsResponse, error] = useFetch(adminApi.getInvestorPortalSettings);

  if (error) {
    logError(error, "getInvestorPortalSettings");
    return <DataLoadingFailed title="Failed to load settings" />;
  }

  if (!portalSettingsResponse || !portalSettingsResponse.settings) {
    return <InlineLoader />;
  }

  return (
    <Container maxWidth="md" disableGutters>
      <SupportInfoEditor settings={portalSettingsResponse.settings} />
    </Container>
  );
};

export default PortalSupportInfo;
