import { GridColDef } from "@mui/x-data-grid-premium";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { FundWithFieldValues } from "../../../../api/adminApi";

export const columnDefinitions: GridColDef<FundWithFieldValues>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    cellClassName: "highlighted-action",
  },
  {
    field: "stage",
    headerName: "Stage",
    flex: 1,
    valueGetter: (_, row) => {
      const stage = row.fieldValues["Stage"]?.toString();
      return stage ? autoFormatCamelCase(stage) : "";
    },
  },
  {
    field: "investorsCount",
    headerName: "Investors",
    flex: 1,
  },
];
