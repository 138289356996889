import { Stack, Typography } from "@mui/material";

interface Props {
  text: string;
  textAppend?: string;
  description?: string;
}

const SectionTitle = ({ text, textAppend, description }: Props) => (
  <Stack spacing={1}>
    <Typography variant="subtitle1">
      {text}
      {textAppend && (
        <Typography component="span" variant="subtitle1" color="text.secondary">
          &nbsp;{textAppend}
        </Typography>
      )}
    </Typography>
    {description && <Typography color="text.secondary">{description}</Typography>}
  </Stack>
);

export default SectionTitle;
