import { Stack, Typography } from "@mui/material";
import { DataCollectionRequestDetails } from "../../../../../api/types/dataCollectionTypes";
import DataRequestStatusTag from "../DataRequestStatusTag";

interface Props {
  dataRequest: DataCollectionRequestDetails;
}

const DataRequestPageTitle = ({ dataRequest }: Props) => {
  return (
    <Stack py={1.5} spacing={0.5}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="h6" noWrap>
          {dataRequest.name}
        </Typography>
        <DataRequestStatusTag status={dataRequest.status} />
      </Stack>

      <Typography variant="caption" color="text.secondary">
        Data Request
      </Typography>
    </Stack>
  );
};

export default DataRequestPageTitle;
