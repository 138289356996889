import { Box, Button, Grid2, Popover, PopoverProps, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import useDebounce from "../../../shared/hooks/useDebounce";
import { getColorBrightness, hexStringToRgb, isValidHexColor, rgbToHex } from "../../../shared/utilities/colorHelper";

interface Props extends Partial<PopoverProps> {
  buttonSize: number;
  color: string;
  popover: Partial<PopoverProps>;
  showAchor?: "leftCenter" | "rightCenter";
  disabled?: boolean;
  onColorChange: (newColor: string) => void;
}

const ColorEdit = ({ buttonSize, color, onColorChange, popover, showAchor: showAchorArrow, disabled }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isValidColor, setIsValidColor] = useState<boolean>(true);
  const [colorValue, setColorValue] = useState<string>(color);

  const updateColor = useDebounce(
    (newColor: string) => {
      if (isValidHexColor(newColor)) {
        onColorChange(newColor);
      }
      setColorValue(newColor);
    },
    10,
    true
  );

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => !disabled && setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const showBorder = getColorBrightness(color) > 0.9;

  const rgb = hexStringToRgb(colorValue);
  const hexRgbColor = { r: rgb[0], g: rgb[1], b: rgb[2], hex: color };

  const handleRgbChange = (key: string, value: string) => {
    const hexRgb = { ...hexRgbColor, [key]: value };
    const hex = rgbToHex(+hexRgb.r, +hexRgb.g, +hexRgb.b);
    setIsValidColor(isValidHexColor(hex));
    updateColor(hex);
  };

  const handleColorChnange = (color: string) => {
    const isValid = isValidHexColor(color);
    setIsValidColor(isValid);
    updateColor(color.length > 0 && color[0] !== "#" ? "#" + color : color);
  };

  const getAnchorArrow = (arrowSizePx: number) => {
    if (!showAchorArrow || !popover?.transformOrigin?.vertical || !popover?.transformOrigin?.horizontal) {
      return null;
    }

    const position = (p: number) => `calc(${p}% - ${arrowSizePx / 2}px)`;
    const offset = (o: 1 | -1) => (o * arrowSizePx) / 2 + "px";

    const positionX = showAchorArrow === "leftCenter" ? "left" : "right";
    const positionY = "top";
    const valueX = offset(-1);
    const valueY = position(50);

    return {
      "&::before": {
        backgroundColor: "white",
        content: '""',
        display: "block",
        position: "absolute",
        width: arrowSizePx,
        height: arrowSizePx,
        transform: "rotate(45deg)",
        [positionX]: valueX,
        [positionY]: valueY,
      },
    };
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleButtonClick}
        sx={{
          width: buttonSize,
          height: buttonSize,
          p: 0,
          minWidth: 0,
          backgroundColor: color,
          "&:hover": { backgroundColor: color },
          border: showBorder ? "0.5px solid black" : "none",
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      />
      <Popover
        {...popover}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
            overflow: "hidden",
          },
        }}
      >
        <Box p="1rem">
          <Box
            p="1rem"
            width={360}
            sx={{
              backgroundColor: "white",
              boxShadow:
                "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
              borderRadius: "4px",
              position: "relative",
              ...getAnchorArrow(20),
            }}
          >
            <HexColorPicker style={{ width: "100%" }} color={color} onChange={handleColorChnange} />
            <Grid2 container spacing={1} mt={1} columns={10}>
              <Grid2 size={{ md: 4 }}>
                <Typography variant="subtitle2">HEX</Typography>
                <TextField
                  error={!isValidColor}
                  variant="outlined"
                  value={colorValue}
                  onChange={(e) => handleColorChnange(e.target.value)}
                />
              </Grid2>
              <Grid2 size={{ md: 2 }}>
                <Typography variant="subtitle2">R</Typography>
                <TextField
                  error={hexRgbColor.r > 255 || hexRgbColor.r < 0}
                  variant="outlined"
                  value={hexRgbColor.r}
                  onChange={(e) => handleRgbChange("r", e.target.value)}
                />
              </Grid2>
              <Grid2 size={{ md: 2 }}>
                <Typography variant="subtitle2">G</Typography>
                <TextField
                  error={hexRgbColor.g > 255 || hexRgbColor.g < 0}
                  variant="outlined"
                  value={hexRgbColor.g}
                  onChange={(e) => handleRgbChange("g", e.target.value)}
                />
              </Grid2>
              <Grid2 size={{ md: 2 }}>
                <Typography variant="subtitle2">B</Typography>
                <TextField
                  error={hexRgbColor.b > 255 || hexRgbColor.b < 0}
                  variant="outlined"
                  value={hexRgbColor.b}
                  onChange={(e) => handleRgbChange("b", e.target.value)}
                />
              </Grid2>
            </Grid2>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ColorEdit;
