import { Button, Stack } from "@mui/material";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import SearchField from "../../../../../../shared/components/inputs/SearchField";
import { MembersTab, useMembersContext } from "../MembersContext";
import CompaniesFilter from "./filters/CompaniesFilter";
import MemberTypeFilter from "./filters/MemberTypeFilter";
import OrganizationRoleFilter from "./filters/OrganizationRoleFilter";

type Filter = "type" | "role" | "companies";

const availableFiltersPerTab: Record<MembersTab, Filter[]> = {
  active: ["type", "role", "companies"],
  pending: ["type", "role"],
  deactivated: [],
};

const MembersGridToolbar = () => {
  const {
    handleMembersSearch,
    filters,
    setFilters,
    clearFilters,
    companiesList,
    activeToolbarTab,
    isLoading,
    members,
  } = useMembersContext();

  const showSupportValueInMembersFilter =
    activeToolbarTab === "active" && members.some((member) => member.userType === "Support");

  return (
    <Stack direction="row" justifyContent="space-between" gap={1} alignItems="center">
      <Stack direction="row" spacing={2} alignItems="center">
        <MemberTypeFilter
          disabled={isLoading || !availableFiltersPerTab[activeToolbarTab].includes("type")}
          selectedValues={filters.types}
          showSupportValue={showSupportValueInMembersFilter}
          onChange={(values) => setFilters({ ...filters, types: values })}
        />

        <OrganizationRoleFilter
          disabled={isLoading || !availableFiltersPerTab[activeToolbarTab].includes("role")}
          role={filters.role}
          onChange={(role) => setFilters({ ...filters, role })}
        />

        <CompaniesFilter
          disabled={isLoading || !availableFiltersPerTab[activeToolbarTab].includes("companies")}
          availableCompanies={companiesList}
          selectedValues={filters.companies}
          onChange={(values: ListItemData[]) => {
            setFilters({ ...filters, companies: values });
          }}
        />

        {(filters.role || filters.types.length > 0 || filters.companies.length > 0) && (
          <Button onClick={clearFilters}>Clear All</Button>
        )}
      </Stack>
      <SearchField onSearch={handleMembersSearch} debounceTimeMs={300} />
    </Stack>
  );
};

export default MembersGridToolbar;
