import { Alert, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { priorityComparer } from "../../../../../shared/utilities/arrayHelper";
import { InvestorChangeRequestDetails } from "../../../../api/types/changeRequestTypes";
import AssignedEntitiesSection from "./AssignedEntitiesSection";
import ChangeRequestDiffsSection from "./ChangeRequestDiffsSection";
import ChangeRequestInfoSection from "./ChangeRequestInfoSection";

interface Props {
  changeRequestDetails: InvestorChangeRequestDetails;
}

const ChangeRequestDetailsSection = ({ changeRequestDetails }: Props) => {
  return (
    <Container>
      <Stack spacing={5} py={2.5}>
        <ChangeRequestInfoSection changeRequestDetails={changeRequestDetails} />
        {changeRequestDetails.workflowStatus === "Rejected" && changeRequestDetails.statusChangeComment && (
          <Stack spacing={1} width="47.5rem">
            <Typography variant="subtitle1">Rejection Reason</Typography>
            <Alert color="error" icon={false} sx={{ ".MuiAlert-message": { whiteSpace: "break-spaces" } }}>
              {changeRequestDetails.statusChangeComment}
            </Alert>
          </Stack>
        )}
        <Stack spacing={3.5}>
          {changeRequestDetails.changeSets
            .sort(priorityComparer((i) => i.changeSetType === "BankAccount"))
            .map((changeSet) => (
              <React.Fragment key={changeSet.changeSetType}>
                <ChangeRequestDiffsSection changeSet={changeSet} workflowStatus={changeRequestDetails.workflowStatus} />
                {changeSet.assignedEntityChanges && changeSet.assignedEntityChanges.length > 0 && (
                  <AssignedEntitiesSection assignedEntityChanges={changeSet.assignedEntityChanges} />
                )}
              </React.Fragment>
            ))}
        </Stack>
      </Stack>
    </Container>
  );
};

export default ChangeRequestDetailsSection;
