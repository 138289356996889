import axios from "axios";
import { getSharedVariables } from "../../variables";
import { ApiResponse } from "../types";

interface DocumentEventMessage<T> {
  viewEventsData: DocumentViewEvent<T>[];
}

export interface DocumentViewEvent<T> {
  sessionId: string;
  eventData: T;
}

export interface PageViewEventData {
  page: number;
  pagesNumber: number;
  duration: number;
}

export interface DownloadEventData {
  download: true | string; // string can be used for date and time of the event
}

const baseUri = `${getSharedVariables().PORTAL_BACKEND_BASE_URI}/document-events-ingestion`;

const ingestDocumentPageViewEvents = async (payload: DocumentEventMessage<PageViewEventData>) => {
  await axios.post<ApiResponse<void>>(`${baseUri}/DocumentEventsIngestion`, payload);
};

const ingestDocumentDownloadEvent = async (payload: DocumentEventMessage<DownloadEventData>) => {
  await axios.post<ApiResponse<void>>(`${baseUri}/DocumentEventsIngestion`, payload);
};

const documentEventsApi = {
  ingestDocumentPageViewEvents,
  ingestDocumentDownloadEvent,
};

export default documentEventsApi;
