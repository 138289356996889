import { MenuItem, Select } from "@mui/material";
import { DictionaryItem, EntityOptionFieldType } from "../../../../api/dictionaryTypes";
import { MetricValueSourceConfiguration } from "../../../../api/portfolioMonitoringTypes";
import { stringComparerBy } from "../../../../utilities/arrayHelper";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";
import UnsupportedField from "./UnsupportedField";

interface Props {
  value: string | undefined;
  onChange: (newValue: string) => void;
  valueSource: MetricValueSourceConfiguration;
  readOnly?: boolean;
  disabled?: boolean;
}

const getSelectOptions = (
  valueSource: MetricValueSourceConfiguration,
  globalDictionaries: Partial<Record<EntityOptionFieldType, DictionaryItem[]>>
) => {
  const { allowedValues, globalDictionaryName } = valueSource;
  if (allowedValues?.length) {
    return { options: allowedValues.map((val) => ({ value: val, label: val })) };
  }

  if (globalDictionaryName) {
    const dictionary = globalDictionaries[globalDictionaryName];
    if (!dictionary) {
      return { error: `Global dictionary "${globalDictionaryName}" is not available` };
    }

    return {
      options: [...dictionary]
        .sort(stringComparerBy((d) => d.displayName))
        .map((item) => ({ value: item.code, label: item.displayName })),
    };
  }

  return { error: "Select options were not defined for this metric" };
};

const MetricSelectField = ({ value, onChange, valueSource, readOnly, disabled }: Props) => {
  const { globalDictionaries } = useDataSubmissionFormContext();

  const { options, error } = getSelectOptions(valueSource, globalDictionaries);
  if (error || !options) {
    return <UnsupportedField>{error}</UnsupportedField>;
  }

  return (
    <Select
      fullWidth
      disabled={disabled}
      value={value ?? ""}
      onChange={(e) => onChange(e.target.value)}
      sx={{ bgcolor: readOnly ? "background.grey" : undefined }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MetricSelectField;
