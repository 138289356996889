import { Stack, Typography } from "@mui/material";
import { InfoSectionBlockAttributes, InfoSectionBlockContent } from "../../../api/inputFormTypes";
import BlockSection from "./BlockSection";
import InfoSectionFormBlockLine from "./InfoSectionFormBlockLine";

interface Props {
  attributes: InfoSectionBlockAttributes;
  content: InfoSectionBlockContent;
}

const InfoSectionFormBlock = ({ attributes, content }: Props) => {
  return (
    <BlockSection>
      <Typography variant="h6">{attributes.title}</Typography>
      <Stack spacing={2} pb={1}>
        {content.items.map((item, index) => (
          <InfoSectionFormBlockLine key={index} item={item} />
        ))}
      </Stack>
    </BlockSection>
  );
};

export default InfoSectionFormBlock;
