import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import LanguageButton from "@mui/icons-material/LanguageRounded";
import RevertIcon from "@mui/icons-material/ReplayRounded";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import { FormCellValue } from "../../../../../../shared/api/dataCollectionTypes";
import DataSubmissionForm from "../../../../../../shared/components/dataCollection/submissionForms/DataSubmissionForm";
import {
  addFormCellValueAction,
  EditedFormValuesState,
  getFormUpdatesPerBlock,
  getInitialEditedFormValuesState,
  isEditedFormValuesStateEmpty,
} from "../../../../../../shared/components/dataCollection/submissionForms/editedFormValuesState";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import useToggleState from "../../../../../../shared/hooks/useToggleState";
import { logError } from "../../../../../../shared/logging";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import adminApi from "../../../../../api/adminApi";
import { DataCollectionSubmissionDetails } from "../../../../../api/types/dataCollectionTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import LanguageOffIcon from "../../../../../icons/LanguageOffIcon";
import { pageRoutes } from "../../../../../routes";
import { dictionariesPropMap } from "../../../../../utilities/dictionariesHelper";
import GeneralPageHeader from "../../../../common/GeneralPageHeader";
import DeleteSubmissionsDialog from "../../data-collection-dialogs/DeleteSubmissionsDialog";
import PublishSubmissionsDialog from "../../data-collection-dialogs/PublishSubmissionsDialog";
import RequestChangesForSubmissionsDialog from "../../data-collection-dialogs/RequestChangesForSubmissionsDialog";
import UnpublishSubmissionsDialog from "../../data-collection-dialogs/UnpublishSubmissionsDialog";
import DataSubmissionPageTitle from "./DataSubmissionPageTitle";
import SubmitConfirmationDialog from "./SubmitConfirmationDialog";

const saveSubmissionLayoutBlockInput = withErrorHandling(adminApi.saveSubmissionLayoutBlockInput);

interface DialogState {
  openDialog?: "submit" | "publish" | "unpublish" | "request_changes" | "delete";
}

const DataSubmissionPage = () => {
  const { clientCode, hasPermissions, dictionaries } = useClientContext();
  const { sendNotificationError } = useNotificationContext();

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [formRenderKey, toggleFormRenderKey] = useToggleState(false);
  const [isSaving, setSaving] = useState(false);
  const [editedFormValues, setEditedFormValues] = useState<EditedFormValuesState>(getInitialEditedFormValuesState());
  const [dialogState, setDialogState] = useState<DialogState>({});

  const getDataSubmissionDetails = useCallback(() => adminApi.getDataSubmissionDetails(defined(id)), [id]);

  const [dataSubmission, fetchError, { isFetching, setData: setDataSubmission, fetch: fetchSubmission }] =
    useFetch(getDataSubmissionDetails);

  const handleBlockCellValueEdit = useCallback((blockId: string, formCellValue: FormCellValue) => {
    setEditedFormValues(addFormCellValueAction(blockId, formCellValue));
  }, []);

  const globalDictionaries = useMemo(
    () =>
      Object.fromEntries(Object.entries(dictionariesPropMap).map(([key, propName]) => [key, dictionaries[propName]])),
    [dictionaries]
  );

  const hasPermissionsToManage = useMemo(() => hasPermissions(["ManagePortfolioMonitoring"]), [hasPermissions]);

  const isSubmissionEditable = useMemo(
    () => hasPermissionsToManage && dataSubmission?.status === "Pending",
    [dataSubmission?.status, hasPermissionsToManage]
  );

  if (fetchError) {
    logError(fetchError, "[DataSubmissionPage] getDataSubmissionDetails");
    return <DataLoadingFailed title="Could not load data submission details" />;
  }

  if (isFetching || dataSubmission === undefined) {
    return <InlineLoader />;
  }

  const isSaveDisabled = isEditedFormValuesStateEmpty(editedFormValues);

  const isSubmitDisabled = !isSaveDisabled || isSaving;

  const backButtonPath =
    searchParams.get("ref") === "request" && searchParams.get("refid") !== null
      ? `/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataRequests}/${searchParams.get("refid")}`
      : `/${clientCode}/${pageRoutes.portfolio}/${pageRoutes.dataSubmissions}`;

  const handleSaved = () => {
    setDialogState({});
    fetchSubmission();
  };

  const handleDeleted = () => {
    setDialogState({});
    navigate(backButtonPath);
  };

  const handleSave = async () => {
    const formUpdates = getFormUpdatesPerBlock(editedFormValues);
    let updatedSubmission: DataCollectionSubmissionDetails | undefined = undefined;

    setSaving(true);

    for (const [blockId, payload] of formUpdates) {
      const [resp, error] = await saveSubmissionLayoutBlockInput(defined(id), blockId, payload);

      if (error) {
        logError(error, `[DataSubmissionPage] saveSubmissionLayoutBlockInput (${id}:${blockId})`);
        sendNotificationError("Failed to save form values");
        continue;
      }

      updatedSubmission = resp;
    }

    setSaving(false);

    if (updatedSubmission) {
      setEditedFormValues(getInitialEditedFormValuesState());
      setDataSubmission(updatedSubmission);
      toggleFormRenderKey();
    }
  };

  return (
    <>
      <GeneralPageHeader
        TitleComponent={<DataSubmissionPageTitle dataSubmission={dataSubmission} />}
        showDefaultBackButtonTo={backButtonPath}
      >
        {hasPermissionsToManage && (
          <>
            {dataSubmission.status === "Draft" && (
              <Button
                variant="outlined"
                startIcon={<LanguageButton />}
                onClick={() => setDialogState({ openDialog: "publish" })}
              >
                Publish
              </Button>
            )}
            {dataSubmission.status === "Pending" && (
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<LanguageOffIcon />}
                onClick={() => setDialogState({ openDialog: "unpublish" })}
              >
                Unpublish
              </Button>
            )}
            {dataSubmission.status === "Completed" && (
              <Button
                color="warning"
                variant="outlined"
                startIcon={<RevertIcon />}
                onClick={() => setDialogState({ openDialog: "request_changes" })}
              >
                Request Changes
              </Button>
            )}
            {dataSubmission.status === "Draft" && (
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={() => setDialogState({ openDialog: "delete" })}
              >
                Delete
              </Button>
            )}
            {dataSubmission.status === "Pending" && (
              <Button
                variant="outlined"
                disabled={isSubmitDisabled}
                onClick={() => setDialogState({ openDialog: "submit" })}
              >
                Submit
              </Button>
            )}
            {dataSubmission.status === "Pending" && (
              <LoadingButton variant="contained" loading={isSaving} disabled={isSaveDisabled} onClick={handleSave}>
                Save
              </LoadingButton>
            )}
          </>
        )}
      </GeneralPageHeader>

      <DataSubmissionForm
        key={formRenderKey.toString()}
        recipientObjectId={dataSubmission.recipientObjectId}
        layout={dataSubmission.layout}
        blockContents={dataSubmission.blockContents}
        isSubmissionEditable={isSubmissionEditable}
        globalDictionaries={globalDictionaries}
        metricExtensionsProvider={{
          getMetricExtensions: adminApi.getMetricExtensionsForObject,
          createMetricExtension: adminApi.createMetricExtension,
        }}
        onBlockCellValueEdit={handleBlockCellValueEdit}
      />

      <SubmitConfirmationDialog
        submission={dataSubmission}
        open={dialogState.openDialog === "submit"}
        onClose={() => setDialogState({})}
        onSubmitted={handleSaved}
      />

      <PublishSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "publish"}
        onClose={() => setDialogState({})}
        onApply={handleSaved}
      />

      <UnpublishSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "unpublish"}
        onClose={() => setDialogState({})}
        onApply={handleSaved}
      />

      <RequestChangesForSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "request_changes"}
        onClose={() => setDialogState({})}
        onApply={handleSaved}
      />

      <DeleteSubmissionsDialog
        dataRequestId={dataSubmission.dataCollectionRequestId}
        submissionIds={[dataSubmission.id]}
        recipientNames={[dataSubmission.recipientName]}
        open={dialogState.openDialog === "delete"}
        onClose={() => setDialogState({})}
        onApply={handleDeleted}
      />
    </>
  );
};

export default DataSubmissionPage;
