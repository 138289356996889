import { Stack, StackProps } from "@mui/material";
import CrmFieldEdit, { CrmField } from "./CrmFieldEdit";
import { FieldMapping, MappingType } from "./fieldMappingsState";
import MappingObjectText from "./MappingObjectText";
import MappingTypeEdit from "./MappingTypeEdit";

interface Props {
  fieldMapping: FieldMapping;
  crmFields: CrmField[];
  hasError?: boolean;
  onChange: (fieldMapping: FieldMapping) => void;
  containerProps?: StackProps;
}

const CrmFieldMappingEdit = ({ fieldMapping, crmFields, hasError, onChange, containerProps }: Props) => {
  const handleCrmFieldChange = (crmFieldId: string) =>
    onChange({
      entriliaFieldId: fieldMapping.entriliaFieldId,
      entriliaFieldName: fieldMapping.entriliaFieldName,
      crmFieldId,
      mappingType:
        crmFieldId === ""
          ? MappingType.None
          : fieldMapping.mappingType === MappingType.None
            ? MappingType.FromCrm
            : fieldMapping.mappingType,
    });

  const handleMappingTypeChange = (mappingType: MappingType) =>
    onChange({
      entriliaFieldId: fieldMapping.entriliaFieldId,
      entriliaFieldName: fieldMapping.entriliaFieldName,
      crmFieldId: fieldMapping.crmFieldId,
      mappingType,
    });

  return (
    <Stack direction="row" spacing={1.5} alignItems="center" {...containerProps}>
      <MappingObjectText text={fieldMapping.entriliaFieldName} />
      <MappingTypeEdit mappingType={fieldMapping.mappingType} onChange={handleMappingTypeChange} />
      <CrmFieldEdit
        crmFieldId={fieldMapping.crmFieldId}
        options={crmFields}
        hasError={hasError}
        onChange={handleCrmFieldChange}
      />
    </Stack>
  );
};

export default CrmFieldMappingEdit;
