import { PropsWithChildren, createContext, useContext } from "react";
import { defined } from "../../../../../../shared/utilities/typeHelper";
import { RequestSubmissionInfo } from "../../../../../api/types/dataCollectionTypes";

interface Props {
  hasPermissionsToManage: boolean;
  onPublishSubmissions: (submissions: RequestSubmissionInfo[]) => void;
  onUnpublishSubmissions: (submissions: RequestSubmissionInfo[]) => void;
  onRequestChangesForSubmissions: (submissions: RequestSubmissionInfo[]) => void;
  onDeleteSubmissions: (submissions: RequestSubmissionInfo[]) => void;
}

const DataRequestPageContext = createContext<Props | undefined>(undefined);

export const DataRequestPageContextProvider = ({ children, ...contextValue }: PropsWithChildren<Props>) => (
  <DataRequestPageContext.Provider value={contextValue}>{children}</DataRequestPageContext.Provider>
);

export const useDataRequestPageContext = () =>
  defined(useContext(DataRequestPageContext), "Attempt to use DataRequestPageContext without provider");
