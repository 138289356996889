import { ApiResponse } from "./types";

export const allDataCollectionSubmissionStatuses = ["Draft", "Pending", "Completed"] as const;
export type DataCollectionSubmissionStatus = (typeof allDataCollectionSubmissionStatuses)[number];

export type ReportingPeriod = "Month" | "Quarter" | "Year" | "LastTwelveMonths";

export interface SaveSubmissionLayoutBlockInputRequest {
  formCellValues: FormCellValue[];
}

export interface FormCellValue {
  rowId: string;
  rowExtensionId?: string;
  columnId: string;
  value: unknown;
}

export interface CreateMetricExtensionRequest {
  metricId: string;
  value: string;
  objectId: string;
}

export interface MetricExtension {
  id: string;
  metricId: string;
  value: string;
  objectId: string;
}

export interface MetricExtensionsProvider {
  getMetricExtensions: (metricId: string, objectId: string) => Promise<ApiResponse<MetricExtension[]>>;
  createMetricExtension: (request: CreateMetricExtensionRequest) => Promise<ApiResponse<MetricExtension>>;
}
