import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import FundraisingCategorySelect from "./FundraisingCategorySelect";
import { useFundraisingDocumentsContext } from "./FundraisingDocumentsContext";

interface Props {
  files: File[];
  onClose: () => void;
  onConfirm: (externalCategoryId: string | undefined, section: string) => void;
}

const FundraisingDocumentsUploadDialog = ({ files, onClose, onConfirm }: Props) => {
  const { documentsState } = useFundraisingDocumentsContext();

  const [externalCategoryId, setExternalCategoryId] = useState<string | undefined>(undefined);
  const [section, setSection] = useState<string | null>(null);

  const isValid = Boolean(section);

  const handleApplyClick = () => {
    if (section) {
      onConfirm(externalCategoryId, section);
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Upload {files.length} {files.length === 1 ? "file" : "files"}
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography color="text.secondary">Select category</Typography>
            <FundraisingCategorySelect
              fullWidth
              externalCategoryId={externalCategoryId}
              onChange={setExternalCategoryId}
            />
          </Stack>

          <Stack spacing={1}>
            <Typography color="text.secondary">Select section or type a new section name</Typography>
            <Autocomplete
              freeSolo
              fullWidth
              options={[...documentsState.orderedSections]}
              renderInput={(params) => <TextField {...params} placeholder="Section" />}
              value={section}
              onInputChange={(_, newValue) => setSection(newValue || null)}
            />
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleApplyClick} disabled={!isValid}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FundraisingDocumentsUploadDialog;
