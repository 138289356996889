import { alpha } from "@mui/material";
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridEventListener,
  GridRowClassNameParams,
  GridRowParams,
} from "@mui/x-data-grid-premium";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import DataGrid from "../../../../shared/components/grid/DataGrid";
import useMergedSx from "../../../../shared/hooks/useMergedSx";
import { DocumentCollection } from "../../../api/types/documentCollectionTypes";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";
import { getCheckedGridSx } from "../../common/grid/gridStyles";
import { columnDefinitions } from "./documentCollectionsDataProvider";
import DocumentCollectionsGridToolbar from "./DocumentCollectionsGridToolbar";
import { useDocumentCollectionsPageContext } from "./DocumentCollectionsPageContext";

interface Props {
  documentCollections: DocumentCollection[];
  loading: boolean;
  highlightedRowId?: string;
}

const DocumentCollectionsGrid = ({ documentCollections, loading, highlightedRowId }: Props) => {
  const navigate = useNavigate();
  const { clientCode } = useClientContext();
  const { hasPermissionsToManage } = useDocumentCollectionsPageContext();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const showToolbar = useMemo(() => selectedIds.length > 0, [selectedIds.length]);

  const sx = useMergedSx(
    (theme) => getCheckedGridSx(theme, showToolbar),
    (theme) => ({
      ".MuiDataGrid-row": {
        "&.is-highlighted": {
          backgroundColor: alpha(theme.palette.primary.main, 0.08),
        },
      },
    })
  );

  const isRowSelectable = useCallback(
    ({ row }: GridRowParams<DocumentCollection>) => row.messageSource === "DataImport",
    []
  );

  const getRowClassName = useCallback(
    ({ row }: GridRowClassNameParams<DocumentCollection>) => (row.id === highlightedRowId ? "is-highlighted" : ""),
    [highlightedRowId]
  );

  const handleCellClick: GridEventListener<"cellClick"> = ({ row, field }) => {
    if (field !== "actions" && field !== GRID_CHECKBOX_SELECTION_FIELD) {
      navigate(`/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.documentCollections}/${row.id}`);
    }
  };

  return (
    <DataGrid<DocumentCollection>
      columns={columnDefinitions}
      rows={documentCollections}
      loading={loading}
      onCellClick={handleCellClick}
      selectableRows
      checkboxSelection={hasPermissionsToManage}
      isRowSelectable={isRowSelectable}
      rowSelectionModel={selectedIds}
      onRowSelectionModelChange={(selection) => setSelectedIds(selection as string[])}
      noRowsText="No document collections"
      getRowClassName={selectedIds.length === 0 ? getRowClassName : undefined}
      disableColumnReorder
      disableColumnSorting
      sx={sx}
      slots={{
        toolbar: () =>
          showToolbar ? (
            <DocumentCollectionsGridToolbar
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              allRows={documentCollections}
            />
          ) : null,
      }}
    />
  );
};

export default DocumentCollectionsGrid;
