import { Tooltip, Typography } from "@mui/material";
import { isBefore, isValid, parseISO, startOfToday, startOfTomorrow } from "date-fns";
import { formatDate } from "../../utilities/dateUtils";

interface Props {
  dueDateIsoString: string;
}

const FormattedDueDate = ({ dueDateIsoString }: Props) => {
  if (!dueDateIsoString) {
    return null;
  }

  const dueDate = parseISO(dueDateIsoString);
  if (!isValid(dueDate)) {
    return null;
  }

  if (isBefore(dueDate, startOfToday())) {
    return (
      <Tooltip arrow title="Overdue">
        <Typography color="error.main">{formatDate(dueDate)}</Typography>
      </Tooltip>
    );
  }

  if (isBefore(dueDate, startOfTomorrow())) {
    return (
      <Tooltip arrow title="Due today">
        <Typography color="warning.main">Today</Typography>
      </Tooltip>
    );
  }

  return <Typography>{formatDate(dueDate)}</Typography>;
};

export default FormattedDueDate;
