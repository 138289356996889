import AddIcon from "@mui/icons-material/AddRounded";
import { Box, Button, Stack } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import DataLoadingFailed from "../../../../shared/components/DataLoadingFailed";
import RecordCounter from "../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../shared/components/inputs/SearchField";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import adminApi, { Contact } from "../../../api/adminApi";
import { ObjectClassDefinition, ObjectContact } from "../../../api/types/objectTypes";
import AddContactAccessDialog from "./AddContactAccessDialog";
import ContactAccessMatrixGrid from "./ContactAccessMatrixGrid";
import { ContactAccessMatrixRow, getContactAccessMatrixRows } from "./contactAccessMatrixGridDataProvider";
import EdiContactAccessDrawer from "./EditContactAccessDrawer";
import RemoveContactAccessDialog from "./RemoveContactAccessDialog";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  hasPermissionsToManage: boolean;
}

interface DialogState {
  openDialog?: "add" | "edit" | "remove";
  editedContacts?: ContactAccessMatrixRow[];
}

const searchContacts = (searchTerm: string, allRows: ContactAccessMatrixRow[]) =>
  searchTerm
    ? allRows.filter(
        (row) =>
          row.contactName.toLowerCase().includes(searchTerm) || row.contactEmail.toLowerCase().includes(searchTerm)
      )
    : allRows;

const ContactAccessMatrix = ({ objectId, objectDefinition, hasPermissionsToManage }: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dialogState, setDialogState] = useState<DialogState>({});

  const getObjectContacts = useCallback(
    () => adminApi.getObjectContacts(objectDefinition.objectType, objectId),
    [objectDefinition.objectType, objectId]
  );

  const [objectContacts, fetchObjectContactsError, { setData: setObjectContacts }] = useFetch(getObjectContacts);

  const getAllContacts = useCallback(
    () =>
      adminApi.searchContacts({ fieldIds: [], includeFunds: false, includeInvestors: false, includeInboxData: false }),
    []
  );

  const [allContactsResp, fetchAllContactsError, { setData: setAllContacts }] = useFetch(getAllContacts);

  const allRows = useMemo(
    () =>
      objectContacts !== undefined && allContactsResp?.items !== undefined
        ? getContactAccessMatrixRows(objectContacts, allContactsResp.items)
        : [],
    [allContactsResp?.items, objectContacts]
  );

  const handleAddContacts = useCallback(() => {
    setDialogState({ openDialog: "add" });
  }, []);

  const handleEditContacts = useCallback((editedContacts: ContactAccessMatrixRow[]) => {
    setDialogState({ openDialog: "edit", editedContacts });
  }, []);

  const handleRemoveContacts = useCallback((editedContacts: ContactAccessMatrixRow[]) => {
    setDialogState({ openDialog: "remove", editedContacts });
  }, []);

  const fetchError = fetchObjectContactsError || fetchAllContactsError;

  if (fetchError) {
    logError(fetchError, "[ContactAccessMatrix]");
    return <DataLoadingFailed title="Could not load contacts" />;
  }

  const handleSearch = (value: string) => {
    setSearchTerm(value.trim().toLowerCase());
  };

  const handleContactsUpdated = (updatedContacts: ObjectContact[]) => {
    setObjectContacts(updatedContacts);
  };

  const handleContactCreated = (newContact: Contact) => {
    setAllContacts((prev) =>
      prev ? { items: [...prev.items, { ...newContact, fieldValues: {}, investors: [], funds: [] }] } : undefined
    );
  };

  const isLoading = objectContacts === undefined || allContactsResp === undefined;
  const rows = searchContacts(searchTerm, allRows);

  return (
    <>
      <Stack spacing={2.5} height="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <RecordCounter records={rows.length} totalRecords={allRows.length} hide={isLoading} />
          <Stack spacing={1} direction="row">
            <SearchField initialValue={searchTerm} debounceTimeMs={300} onSearch={handleSearch} disabled={isLoading} />
            {hasPermissionsToManage && (
              <Button variant="contained" onClick={handleAddContacts} disabled={isLoading} startIcon={<AddIcon />}>
                Add New
              </Button>
            )}
          </Stack>
        </Box>

        <ContactAccessMatrixGrid
          rows={rows}
          categories={objectDefinition.supportedContactCategories}
          isLoading={isLoading}
          actions={{ onEditContacts: handleEditContacts, onRemoveContacts: handleRemoveContacts }}
          hasPermissionsToManage={hasPermissionsToManage}
        />
      </Stack>

      {dialogState.openDialog === "add" && objectContacts !== undefined && allContactsResp !== undefined && (
        <AddContactAccessDialog
          objectId={objectId}
          objectDefinition={objectDefinition}
          currentObjectContacts={objectContacts}
          allContacts={allContactsResp.items}
          onClose={() => setDialogState({})}
          onSave={handleContactsUpdated}
          onContactCreated={handleContactCreated}
        />
      )}

      {dialogState.openDialog === "edit" &&
        dialogState.editedContacts?.length &&
        objectContacts !== undefined &&
        allContactsResp !== undefined && (
          <EdiContactAccessDrawer
            objectId={objectId}
            objectDefinition={objectDefinition}
            currentObjectContacts={objectContacts}
            editedContacts={dialogState.editedContacts}
            onClose={() => setDialogState({})}
            onSave={handleContactsUpdated}
          />
        )}

      {dialogState.openDialog === "remove" &&
        dialogState.editedContacts?.length &&
        objectContacts !== undefined &&
        allContactsResp !== undefined && (
          <RemoveContactAccessDialog
            objectId={objectId}
            objectType={objectDefinition.objectType}
            removedContacts={dialogState.editedContacts}
            currentObjectContacts={objectContacts}
            onClose={() => setDialogState({})}
            onSave={handleContactsUpdated}
          />
        )}
    </>
  );
};

export default ContactAccessMatrix;
