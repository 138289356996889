import EditIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { MetricExtensionConfiguration } from "../../../../shared/api/portfolioMonitoringTypes";
import { autoFormatCamelCase } from "../../../../shared/utilities/stringHelper";
import { Metric } from "../../../api/types/portfolioMonitoringTypes";
import MetricName from "./MetricName";
import { useMetricsPageContext } from "./MetricsPageContext";

const ActionsCell = ({ row }: { row: Metric }) => {
  const { hasEditPermissions, onEditMetric } = useMetricsPageContext();

  if (!hasEditPermissions) {
    return null;
  }

  return (
    <IconButton size="small" onClick={() => onEditMetric(row)}>
      <EditIcon />
    </IconButton>
  );
};

export const columnDefinitions: GridColDef<Metric>[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1.5,
    minWidth: 160,
    renderCell: ({ row }) => <MetricName name={row.name} dataType={row.dataType} description={row.description} />,
  },
  {
    field: "dataType",
    headerName: "Type",
    width: 112,
    valueFormatter: (value: string) => autoFormatCamelCase(value),
  },
  {
    field: "category",
    headerName: "Category",
    flex: 1.5,
    minWidth: 160,
  },
  {
    field: "extensionConfiguration",
    headerName: "Extension",
    flex: 1,
    minWidth: 160,
    valueFormatter: (value: MetricExtensionConfiguration) => (value?.extensionName ? value.extensionName : ""),
  },
  {
    field: "actions",
    headerName: "",
    width: 96,
    align: "right",
    cellClassName: "grid-row-actions",
    renderCell: ({ row }) => <ActionsCell row={row} />,
  },
];
