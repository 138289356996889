import { LoadingButton } from "@mui/lab";
import { Box, Container, FormControl, RadioGroup, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DownloadFileNameFormatOptions } from "../../../../api/types/investorPortalTypes";
import { useClientContext } from "../../../../context/ClientContext";
import BorderedRadioLabel from "../../../common/BorderedRadioLabel";

const updateInvestorPortalSettings = withErrorHandling(adminApi.updateInvestorPortalSettings);

const InvestorDocumentsSettings = () => {
  const { hasPermissions } = useClientContext();
  const { sendNotificationError } = useNotificationContext();

  const [downloadFileNameFormat, setDownloadFileNameFormat] = useState<DownloadFileNameFormatOptions | null>(null);

  const [isSaving, setSaving] = useState(false);

  const [resp, fetchError, { setData: setSettings }] = useFetch(adminApi.getInvestorPortalSettings, (data) =>
    setDownloadFileNameFormat(data.settings.downloadFileNameFormat ?? null)
  );

  const handleSaveClick = async () => {
    if (downloadFileNameFormat === null) {
      return;
    }

    setSaving(true);
    const [resp, error] = await updateInvestorPortalSettings({ downloadFileNameFormat });
    setSaving(false);

    if (error) {
      logError(error, "[InvestorDocumentsSettings] updateInvestorPortalSettings");
      sendNotificationError("Failed to save settings");
      return;
    }

    setSettings(resp);
  };

  if (fetchError) {
    return <DataLoadingFailed />;
  }

  if (resp === undefined) {
    return <InlineLoader />;
  }

  const { settings } = resp;
  const canEditSettings = hasPermissions(["ManageInvestorPortalSettings"]);
  const saveDisabled = downloadFileNameFormat === null || downloadFileNameFormat === settings.downloadFileNameFormat;

  return (
    <Container maxWidth="md" disableGutters>
      <Stack py={2.5} spacing={3}>
        <Stack spacing={1}>
          <Typography variant="subtitle1">Download File Name Format</Typography>
          <Typography color="text.secondary">
            You can change the file name for the documents that is used when the user downloads them.
          </Typography>
        </Stack>
        <FormControl>
          <RadioGroup
            value={downloadFileNameFormat}
            onChange={({ target }) => setDownloadFileNameFormat(target.value as DownloadFileNameFormatOptions)}
            sx={{ rowGap: 2 }}
          >
            <BorderedRadioLabel<DownloadFileNameFormatOptions>
              value="OriginalFileName"
              disabled={!canEditSettings}
              selectedValue={downloadFileNameFormat}
              sx={{ pl: 2, py: 1 }}
              label={
                <Box>
                  <Typography variant="subtitle2">Use original file name</Typography>
                  <Typography color="text.secondary">
                    Downloads will use the same name that was set during the upload.
                  </Typography>
                </Box>
              }
            />
            <BorderedRadioLabel<DownloadFileNameFormatOptions>
              value="FundInvestorDocument"
              disabled={!canEditSettings}
              selectedValue={downloadFileNameFormat}
              sx={{ pl: 2, py: 1 }}
              label={
                <Box>
                  <Typography variant="subtitle2">Use predefined format</Typography>
                  <Typography color="text.secondary">Downloads will follow a predefined format:</Typography>
                  <Typography>[Fund Name]-[Investor Name]-[Document Name]</Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
        {canEditSettings && (
          <LoadingButton
            loading={isSaving}
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            disabled={saveDisabled}
            sx={{ width: "5rem" }}
          >
            Save
          </LoadingButton>
        )}
      </Stack>
    </Container>
  );
};

export default InvestorDocumentsSettings;
