import { Link, Typography } from "@mui/material";

interface Props {
  consentName: string;
  customAgreementText?: string;
  url?: string;
}

const ConsentAgreementLabel = ({ consentName, customAgreementText, url }: Props) => {
  if (customAgreementText) {
    return url ? (
      <Link href={url} target="_blank" rel="noopener noreferrer">
        {customAgreementText}
      </Link>
    ) : (
      <Typography>{customAgreementText}</Typography>
    );
  }

  return (
    <Typography>
      <Typography component="span">I agree to </Typography>
      {url ? (
        <Link href={url} target="_blank" rel="noopener noreferrer">
          {consentName}
        </Link>
      ) : (
        <Typography component="span">{consentName}</Typography>
      )}
    </Typography>
  );
};

export default ConsentAgreementLabel;
