import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Grid2, Typography } from "@mui/material";
import { useMemo } from "react";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ListItemData } from "../../../../../../shared/components/inputs/CheckItemsList";
import { useLocalization } from "../../../../../hooks/useLocalization";
import { CompanySingleReportListItem } from "./CompanySingleReportListItem";
import { Failed } from "./controls/Failed";
import { ReportInProgress } from "./controls/ReportInProgress";
import { WaitingForValidation } from "./controls/WaitingForValidation";
import { SelectedCompany, SelectedCompanyReport } from "./hooks/SelectedCompany";
import { ReportValidationType } from "./hooks/useReportValidation";
import { getInvalidFieldsCount } from "./utils/getInvalidFieldsCount";

interface Props {
  company: SelectedCompany;
  report: SelectedCompanyReport;
  activeCompany: SelectedCompany | undefined;
  availableCompanies: ListItemData[];
  validation: ReportValidationType;
  onSelectedCompanyReport: (companyReport: SelectedCompanyReport | undefined) => void;
}

export function CompanySingleReportListItemContainer({
  activeCompany,
  company,
  report,
  availableCompanies,
  validation,
  onSelectedCompanyReport,
}: Props) {
  const { reports_copyto: locale } = useLocalization();
  return (
    <CompanySingleReportListItem
      key={company.code}
      company={company}
      selectable
      inProgress={report.validating}
      active={activeCompany?.code === company.code}
      companyBranding={availableCompanies.find((c) => c.value === company.code)}
      Action={
        <CloseIconButton
          onClick={() => validation.removeCompany(company.code)}
          color="secondary"
          iconProps={{ sx: (theme) => ({ color: theme.palette.secondary.light }) }}
        />
      }
      onSelected={() => onSelectedCompanyReport(report)}
    >
      {!report.validating && !report.validated && !report.error && <WaitingForValidation />}
      {report.validating && <ReportInProgress text={locale.validating} />}
      {report.validated && <Validated companyReport={report} />}
      {report.error && <Failed onRetry={() => validation.retryValidation(report)} />}
    </CompanySingleReportListItem>
  );
}

export function Validated({ companyReport }: { companyReport: SelectedCompanyReport }) {
  const { reports_copyto: locale } = useLocalization();

  const invalidFieldsCount = useMemo(() => getInvalidFieldsCount(companyReport.result), [companyReport.result]);

  const selectedGroupName = useMemo(
    () => companyReport.selectedGroup?.caption || companyReport.result?.group?.name,
    [companyReport.result?.group?.name, companyReport.selectedGroup?.caption]
  );

  const isNewGroup = useMemo(
    () => companyReport.result?.group?.exist === false && selectedGroupName === companyReport.result.group.name,
    [companyReport.result?.group?.exist, companyReport.result?.group.name, selectedGroupName]
  );

  return (
    <Grid2 container sx={{ gap: 0.8, flexWrap: "nowrap", alignItems: "center", width: "100%" }}>
      <Typography variant="subtitle2" sx={{}}>
        To Group:
      </Typography>
      {isNewGroup && (
        <Typography color="secondary" sx={{ mr: -0.2 }}>
          {locale.will_be_added_to_new_group}
        </Typography>
      )}
      <Typography sx={{}}>{selectedGroupName}</Typography>
      <HorizontalFill />
      {invalidFieldsCount === 0 && (
        <>
          <Typography sx={{ color: "success.main" }}>{locale.report_is_valid}</Typography>
          <CheckRoundedIcon color="success" />
        </>
      )}
      {invalidFieldsCount > 0 && (
        <>
          <Typography sx={{ color: "warning.main" }}>
            {`${invalidFieldsCount} field${invalidFieldsCount > 1 ? "s" : ""} can't be copied`}
          </Typography>
          <WarningRoundedIcon color="warning" />
        </>
      )}
    </Grid2>
  );
}
