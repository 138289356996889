import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import { logError } from "../../../../../../../shared/logging";
import { ReportInfo } from "../../../../../../../shared/reporting/api/biClient.types";
import { defined } from "../../../../../../../shared/utilities/typeHelper";
import biClient from "../../../../../../api/biApi";
import { SelectedCompany, SelectedCompanyReport } from "./SelectedCompany";
import validationStateSlice, { actions } from "./ValidationState";

export default function useReportValidation(reports: ReportInfo[]) {
  const [state, dispatch] = useReducer(validationStateSlice.reducer, validationStateSlice.getInitialState());
  const companiesRef = useRef<SelectedCompany[]>([]);

  const reportsToValidate = useMemo(() => {
    return state.companies
      .flatMap((c) => c.reports)
      .filter((c) => c.validated === false && c.validating === false && c.error !== true);
  }, [state.companies]);

  const companiesToLoadGroups = useMemo(() => {
    return state.companies.filter((c) => c.groupsLoaded !== true && c.groupsLoading !== true);
  }, [state.companies]);

  const validateReport = useCallback(async (report: SelectedCompanyReport) => {
    dispatch(actions.updateReportAction({ report: report, changes: { validating: true } }));

    const ct = biClient.validateReport(
      {
        reportId: report.report.reportId,
        fromCompanyCode: report.report.clientCode,
        toCompanyCode: report.companyCode,
      },
      (resp) => {
        const changes: Partial<SelectedCompanyReport> = { validating: false };
        if (!resp.success || resp.error) {
          changes.error = true;
        } else {
          changes.validated = true;
          changes.result = resp.data;
        }
        dispatch(actions.updateReportAction({ report, changes }));
      },
      () => {
        dispatch(
          actions.updateReportAction({
            report,
            changes: { validating: false, validated: false, error: true },
          })
        );
      }
    );

    dispatch(actions.updateReportAction({ report: report, changes: { ct } }));
  }, []);

  const loadGroups = useCallback(async (company: SelectedCompany) => {
    dispatch(actions.updateCompanyAction({ code: company.code, changes: { groupsLoading: true } }));

    try {
      const resp = await biClient.getReportGroups(company.code);
      if (resp.success) {
        dispatch(actions.updateCompanyAction({ code: company.code, changes: { groups: resp.data } }));
      } else {
        dispatch(actions.updateCompanyAction({ code: company.code, changes: { groupsLoadError: true } }));
      }
    } catch (err) {
      logError(err, "[useReportValidation] getReportGroups");
      dispatch(actions.updateCompanyAction({ code: company.code, changes: { groupsLoadError: true } }));
    } finally {
      dispatch(
        actions.updateCompanyAction({ code: company.code, changes: { groupsLoading: false, groupsLoaded: true } })
      );
    }
  }, []);

  useEffect(() => {
    companiesRef.current = state.companies;
  }, [state.companies]);

  useEffect(() => {
    const reports = companiesRef.current.flatMap((c) => c.reports);
    const inProgressCount = reports.filter((r) => r.validating === true).length;
    const toValidate = reports.filter((r) => !r.validating && !r.validated && !r.error);
    if (inProgressCount < 3 && toValidate.length > 0) {
      validateReport(defined(toValidate[0]));
    }
  }, [reportsToValidate, validateReport]);

  useEffect(() => {
    companiesToLoadGroups.forEach(loadGroups);
  }, [companiesToLoadGroups, loadGroups]);

  const updateCompanySelection = useCallback(
    (companyCodes: string[]) => {
      dispatch(actions.setCompaniesAction({ companyCodes, reports }));
    },
    [reports]
  );

  const removeCompany = useCallback((companyCode: string) => {
    dispatch(actions.removeCompanyAction(companyCode));
  }, []);

  const updateCompanyReport = useCallback((report: SelectedCompanyReport, changes: Partial<SelectedCompanyReport>) => {
    dispatch(actions.updateReportAction({ report, changes }));
  }, []);

  const retryValidation = useCallback((report: SelectedCompanyReport) => {
    dispatch(
      actions.updateReportAction({
        report,
        changes: {
          validated: false,
          validating: false,
          error: false,
        },
      })
    );
  }, []);

  return {
    selectedCompanies: state.companies,
    updateCompanySelection,
    removeCompany,
    updateCompanyReport,
    retryValidation,
  };
}

export type ReportValidationType = ReturnType<typeof useReportValidation>;
