import { createSvgIcon } from "@mui/material";

const CheckboxFieldTypeIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor">
    <path d="M7.5 17.5C6.11111 17.5 4.93056 17.0139 3.95833 16.0417C2.98611 15.0694 2.5 13.8889 2.5 12.5V7.5C2.5 6.11111 2.98611 4.93056 3.95833 3.95833C4.93056 2.98611 6.11111 2.5 7.5 2.5H12.5C13.8889 2.5 15.0694 2.98611 16.0417 3.95833C17.0139 4.93056 17.5 6.11111 17.5 7.5V12.5C17.5 13.8889 17.0139 15.0694 16.0417 16.0417C15.0694 17.0139 13.8889 17.5 12.5 17.5H7.5ZM9.16667 13.3333L14.1667 8.33333L13 7.16667L9.16667 11L7.33333 9.16667L6.16667 10.3333L9.16667 13.3333ZM7.5 15.8333H12.5C13.4167 15.8333 14.2014 15.5069 14.8542 14.8542C15.5069 14.2014 15.8333 13.4167 15.8333 12.5V7.5C15.8333 6.58333 15.5069 5.79861 14.8542 5.14583C14.2014 4.49306 13.4167 4.16667 12.5 4.16667H7.5C6.58333 4.16667 5.79861 4.49306 5.14583 5.14583C4.49306 5.79861 4.16667 6.58333 4.16667 7.5V12.5C4.16667 13.4167 4.49306 14.2014 5.14583 14.8542C5.79861 15.5069 6.58333 15.8333 7.5 15.8333Z" />
  </svg>,
  "CheckboxFieldTypeIcon"
);

export default CheckboxFieldTypeIcon;
