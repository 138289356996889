import ClockIcon from "@mui/icons-material/AccessTimeOutlined";
import { InputAdornment, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";

interface Props {
  filterValue: string;
  onChange: (filterValue: string) => void;
}

const filterValues = ["Sent", "Delivered", "Opened", "Delivered & Opened", "Bounced"];

const StatusSelect = ({ filterValue, onChange }: Props) => {
  const handleChange = ({ target }: SelectChangeEvent) => onChange(target.value);

  return (
    <Select
      value={filterValue}
      onChange={handleChange}
      startAdornment={
        <InputAdornment position="start">
          <ClockIcon color="action" />
        </InputAdornment>
      }
      displayEmpty
      sx={{ width: "14rem" }}
      renderValue={(value) => value || "Status"}
    >
      {filterValues.map((value) => (
        <MenuItem key={value} value={value}>
          <Typography>{value}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default StatusSelect;
