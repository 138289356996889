import { Stack, Typography } from "@mui/material";
import { GridRenderCellParams, GridRowId, useGridApiContext } from "@mui/x-data-grid-premium";
import { PropsWithChildren } from "react";
import { ExpandCollapseButton } from "../../../../../../shared/reporting/components/reports/ExpandCollapseButton";
import { OrganizationReportInfo } from "../../../../../api/biApi";

interface Props {
  expanded: boolean;
  params: GridRenderCellParams<OrganizationReportInfo, string, unknown>;
  onExpandToggle: (expanded: boolean, rowId: GridRowId) => void;
}
const GroupNameCore = ({ expanded, params, children, onExpandToggle }: PropsWithChildren<Props>) => {
  const apiRef = useGridApiContext();

  if (params.rowNode.type !== "group") {
    return null;
  }

  const reportsNumber =
    params.rowNode.depth === 0
      ? apiRef.current.getRowGroupChildren({ groupId: params.rowNode.id }).length
      : params.rowNode.children.length;

  return (
    <Stack direction={"row"} alignItems={"center"} gap={1} pl={params.rowNode.depth * 2}>
      <ExpandCollapseButton
        expanded={expanded}
        onClick={(value) => {
          onExpandToggle(value, params.rowNode.id);
          apiRef.current.setRowChildrenExpansion(params.rowNode.id, value);
        }}
      />
      {children}
      <Typography variant="subtitle2">
        {params.value} ({reportsNumber})
      </Typography>
    </Stack>
  );
};

export default GroupNameCore;
