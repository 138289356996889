import { Box, Checkbox, Divider, Stack, Typography } from "@mui/material";
import { PortalRoleWithNoneValue } from "../../api/types/accessTypes";
import PortalRoleChip from "./PortalRoleChip";
import PortalRoleSelector from "./PortalRoleSelector";

export interface AccessValue {
  selectedRole: PortalRoleWithNoneValue | undefined;
  selectedCategoryIds: string[];
  indeterminateCategoryIds?: string[];
}

interface CategoryOption {
  id: string;
  label: string;
}

interface Props {
  value: AccessValue;
  onChange: (update: Partial<AccessValue>) => void;
  roleOptions: PortalRoleWithNoneValue[];
  categoryOptions: CategoryOption[];
}

const AccessEditor = ({ value, onChange, roleOptions, categoryOptions }: Props) => {
  const handleAllCategoriesChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange({ selectedCategoryIds: checked ? categoryOptions.map((c) => c.id) : [] });
  };

  const handleCategoryChange = (categoryId: string) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange({
      selectedCategoryIds: checked
        ? [...value.selectedCategoryIds, categoryId]
        : value.selectedCategoryIds.filter((id) => id !== categoryId),
      indeterminateCategoryIds: value.indeterminateCategoryIds?.filter((id) => id !== categoryId),
    });
  };

  const areAllCategoriesSelected = value.selectedCategoryIds.length === categoryOptions.length;
  const isAnyCategorySelected = value.selectedCategoryIds.length > 0;

  return (
    <Stack spacing={2}>
      <Typography variant="subtitle2">Access</Typography>

      {roleOptions.length === 1 && value.selectedRole !== undefined && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Role</Typography>
          <PortalRoleChip role={value.selectedRole} />
        </Box>
      )}

      {roleOptions.length > 1 && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography>Role</Typography>
          <PortalRoleSelector
            role={value.selectedRole}
            onChange={(selectedRole) => onChange({ selectedRole })}
            roleOptions={roleOptions}
          />
        </Box>
      )}

      <Divider flexItem />

      <Stack spacing={0.5} pt={0.5}>
        {categoryOptions.length > 1 && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography>All Categories</Typography>
            <Checkbox
              checked={areAllCategoriesSelected}
              indeterminate={!areAllCategoriesSelected && isAnyCategorySelected}
              onChange={handleAllCategoriesChange}
            />
          </Box>
        )}
        {categoryOptions.map((category) => (
          <Box key={category.id} display="flex" justifyContent="space-between" alignItems="center">
            <Typography>{category.label}</Typography>
            <Checkbox
              checked={value.selectedCategoryIds.includes(category.id)}
              indeterminate={value.indeterminateCategoryIds?.includes(category.id)}
              onChange={handleCategoryChange(category.id)}
            />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default AccessEditor;
