import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import PublishIcon from "@mui/icons-material/LanguageRounded";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { intersect } from "../../../../shared/utilities/arrayHelper";
import { DocumentCollection, DocumentCollectionOperations } from "../../../api/types/documentCollectionTypes";
import UnpublishIcon from "../../../icons/LanguageOffIcon";
import CustomDataGridHeaderToolbar from "../../common/grid/CustomDataGridHeaderToolbar";
import { useDocumentCollectionsPageContext } from "./DocumentCollectionsPageContext";

interface Props {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  allRows: DocumentCollection[];
}

const getAvailableOperations = (selectedIds: string[], allRows: DocumentCollection[]) => {
  const docCollectionsIdMap = allRows.reduce((result, docCollection) => {
    result.set(docCollection.id, docCollection);
    return result;
  }, new Map<string, DocumentCollection>());

  let availableOperations: DocumentCollectionOperations[] = ["MakeLive", "MakePreview", "Revoke"];

  for (const id of selectedIds) {
    const docCollection = docCollectionsIdMap.get(id);
    if (docCollection) {
      availableOperations = intersect(availableOperations, docCollection.availableOperations);
    }

    if (availableOperations.length === 0) {
      return [];
    }
  }

  return availableOperations;
};

const DocumentCollectionsGridToolbar = ({ selectedIds, setSelectedIds, allRows }: Props) => {
  const { onChangeDocumentCollectionsStatus } = useDocumentCollectionsPageContext();

  const availableOperations = useMemo(() => getAvailableOperations(selectedIds, allRows), [allRows, selectedIds]);

  return (
    <CustomDataGridHeaderToolbar selection={[selectedIds, setSelectedIds]}>
      <Button
        disabled={!availableOperations.includes("MakeLive")}
        variant="text"
        color="secondary"
        startIcon={<PublishIcon />}
        onClick={() => onChangeDocumentCollectionsStatus(selectedIds, "MakeLive")}
      >
        Publish
      </Button>
      <Button
        disabled={!availableOperations.includes("MakePreview")}
        variant="text"
        color="secondary"
        startIcon={<UnpublishIcon />}
        onClick={() => onChangeDocumentCollectionsStatus(selectedIds, "MakePreview")}
      >
        Unpublish
      </Button>
      <Button
        disabled={!availableOperations.includes("Revoke")}
        variant="text"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={() => onChangeDocumentCollectionsStatus(selectedIds, "Revoke")}
      >
        Delete
      </Button>
    </CustomDataGridHeaderToolbar>
  );
};

export default DocumentCollectionsGridToolbar;
