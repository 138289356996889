import DeleteIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/EditOutlined";
import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { convertISODate } from "../../../../shared/utilities/dateUtils";
import { DwhListingType, DwhShare } from "../../../api/types/dwhTypes";
import { formatDwhShareType } from "./updateShareForm";

interface BodyRowProps {
  accountCode: string;
  type: DwhListingType;
  description?: string;
  createdByUsername?: string;
  createdAt: string;
  showActions: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

interface Props {
  shares: DwhShare[];
  readOnly?: boolean;
  onEditClick: (share: DwhShare) => void;
  onDeleteClick: (share: DwhShare) => void;
}

const HeadRow = ({ showActions }: { showActions: boolean }) => (
  <TableRow>
    <TableCell>Shared with</TableCell>
    <TableCell>Type</TableCell>
    <TableCell>Description</TableCell>
    <TableCell>Shared by</TableCell>
    <TableCell>Shared at</TableCell>
    {showActions && <TableCell></TableCell>}
  </TableRow>
);

const BodyCell = (props: TableCellProps) => <TableCell {...props} sx={(t) => ({ p: t.spacing(2) })} />;

const BodyRow = ({
  accountCode,
  type,
  description,
  createdByUsername,
  createdAt,
  showActions,
  onEditClick,
  onDeleteClick,
}: BodyRowProps) => {
  const isSystem = createdByUsername === "System";
  return (
    <TableRow hover>
      <BodyCell>
        <Typography variant="subtitle2">{accountCode}</Typography>
      </BodyCell>
      <BodyCell>
        <Typography>{formatDwhShareType(type)}</Typography>
      </BodyCell>
      <BodyCell width="35%">
        <Typography>{description}</Typography>
      </BodyCell>
      <BodyCell>
        <Typography>{createdByUsername}</Typography>
      </BodyCell>
      <BodyCell>
        <Typography color="text.secondary">{convertISODate(createdAt)}</Typography>
      </BodyCell>
      {showActions && (
        <BodyCell width="10%" className="DwhSharesTable-actions">
          <Stack direction="row" spacing={1}>
            <Tooltip title={isSystem ? "Edit not allowed for System shares" : "Edit"} arrow>
              <span>
                <IconButton onClick={onEditClick} disabled={isSystem}>
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={isSystem ? "Delete not allowed for System shares" : "Delete"} arrow>
              <span>
                <IconButton color="error" onClick={onDeleteClick} disabled={isSystem}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </BodyCell>
      )}
    </TableRow>
  );
};

const EmptyTableRow = () => {
  return (
    <TableRow sx={{ height: 120 }}>
      <TableCell align="center" colSpan={5} sx={{ borderBottom: 0 }}>
        No data share
      </TableCell>
    </TableRow>
  );
};

const DwhSharesTable = ({ shares, readOnly, onEditClick, onDeleteClick }: Props) => {
  const handleEditClick = (share: DwhShare) => () => onEditClick(share);
  const handleDeleteClick = (share: DwhShare) => () => onDeleteClick(share);

  return (
    <TableContainer>
      <Table
        sx={{
          ".MuiTableRow-root": {
            "&:hover": {
              ".MuiTableCell-root.DwhSharesTable-actions": {
                button: { visibility: readOnly ? "hidden" : "visible" },
              },
            },
          },
          ".MuiTableCell-root.DwhSharesTable-actions": {
            button: { visibility: "hidden" },
          },
        }}
      >
        <TableHead>
          <HeadRow showActions={!readOnly} />
        </TableHead>
        <TableBody>
          {shares.length === 0 ? (
            <EmptyTableRow />
          ) : (
            shares.map((share) => (
              <BodyRow
                {...share}
                showActions={!readOnly}
                onEditClick={handleEditClick(share)}
                onDeleteClick={handleDeleteClick(share)}
                key={share.id}
              />
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DwhSharesTable;
