import { ConsentAgreement } from "./types";

export interface ConsentAgreementsState {
  allAgreements: ConsentAgreement[];
  acceptedAgreements: ConsentAgreement[];
  nextAgreementToAccept?: ConsentAgreement;
  isLast: boolean;
}

type StateAction = (state: ConsentAgreementsState) => ConsentAgreementsState;

export const getInitialConsentAgreementsState = (consentAgreements: ConsentAgreement[]): ConsentAgreementsState => ({
  allAgreements: [...consentAgreements],
  acceptedAgreements: [],
  nextAgreementToAccept: consentAgreements[0],
  isLast: consentAgreements.length <= 1,
});

const getAgreementKey = (consentAgreement: ConsentAgreement) =>
  `${consentAgreement.type}_${consentAgreement.name || ""}`;

export const acceptAgreementAction = (): StateAction => (state) => {
  if (!state.nextAgreementToAccept) {
    return state;
  }

  const acceptedAgreements = [...state.acceptedAgreements, state.nextAgreementToAccept];
  const acceptedAreementKeys = acceptedAgreements.map(getAgreementKey);
  const agreementsToAccept = state.allAgreements.filter((a) => !acceptedAreementKeys.includes(getAgreementKey(a)));
  return {
    allAgreements: state.allAgreements,
    acceptedAgreements,
    nextAgreementToAccept: agreementsToAccept[0],
    isLast: agreementsToAccept.length <= 1,
  };
};
