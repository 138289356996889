import { Avatar, Box, Color, Stack, Tooltip, Typography } from "@mui/material";
import { blue, grey, orange } from "@mui/material/colors";
import { ScenarioTableBlockColumn } from "../../../../api/inputFormTypes";
import { convertISODate } from "../../../../utilities/dateUtils";
import HorizontalFill from "../../../HorizontalFill";

interface Props {
  columnDefinition: ScenarioTableBlockColumn;
}

const scenarioColorMap: Record<string, Color> = {
  Actual: blue,
  Budget: orange,
};

const ScenarioAvatar = ({ scenarioName }: { scenarioName: string }) => {
  if (!scenarioName) {
    return <Box width={16} height={16} />;
  }

  const color = scenarioColorMap[scenarioName] ?? grey;

  return (
    <Tooltip arrow title={scenarioName}>
      <Avatar sx={{ width: 16, height: 16, bgcolor: color[50], color: color[700] }}>
        <Typography variant="caption" fontWeight={500}>
          {scenarioName[0]}
        </Typography>
      </Avatar>
    </Tooltip>
  );
};

const ScenarioColumnHeader = ({ columnDefinition }: Props) => {
  return (
    <Stack width="100%">
      <Box display="flex" justifyContent="space-between">
        <ScenarioAvatar scenarioName={columnDefinition.scenarioName} />
        <Typography variant="subtitle2">{convertISODate(columnDefinition.titleAsDate, "MMM yyyy")}</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <HorizontalFill />
        <Typography variant="caption" color="text.secondary">
          {columnDefinition.subtitle}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ScenarioColumnHeader;
