import { Stack } from "@mui/material";
import { useCallback, useReducer } from "react";
import { useNavigate } from "react-router";
import { PaginatedList } from "../../../../../../shared/api/types";
import DataLoadingFailed from "../../../../../../shared/components/DataLoadingFailed";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { distinctBy } from "../../../../../../shared/utilities/arrayHelper";
import adminApi, { ContactPermissions, FundStructureDocument } from "../../../../../api/adminApi";
import { Category } from "../../../../../api/types/accessTypes";
import { useClientContext } from "../../../../../context/ClientContext";
import { pageRoutes } from "../../../../../routes";
import FundStructureDocumentFilters from "../../common/FundStructureDocumentFilters";
import FundStructureDocumentsGrid from "../../common/FundStructureDocumentsGrid";
import { FundStructureDocumentsFilterValue, getInitialState, reducer } from "../../common/fundStructureDocumentsState";
import { filterContactDocuments } from "./contactDocumentsFiltering";

interface Props {
  contactId: string;
  categories: Category[];
  contactPermissions: ContactPermissions[];
}

// We are always fetching all contact documents, pagination is merely for consistency
const toPaginatedList = (items: FundStructureDocument[]): PaginatedList<FundStructureDocument> => ({
  items,
  total: items.length,
  totalPages: 1,
});

const ContactDetailsDocumentsTab = ({ contactId, categories, contactPermissions }: Props) => {
  const { clientCode } = useClientContext();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const fetchContactDocuments = useCallback(() => adminApi.getContactDocuments(contactId), [contactId]);

  const [allContactDocuments, fetchError, { isFetching }] = useFetch(fetchContactDocuments, (data) =>
    dispatch({ type: "load_data", data: toPaginatedList(data) })
  );

  if (fetchError) {
    logError(fetchError, "[ContactDetailsDocumentsTab]");
    return <DataLoadingFailed bgColor="none" title="Loading contact documents failed" />;
  }

  const handleFiltersChange = (filterValue: FundStructureDocumentsFilterValue) => {
    dispatch({ type: "filter", filterValue });
    if (allContactDocuments) {
      dispatch({ type: "load_data", data: toPaginatedList(filterContactDocuments(allContactDocuments, filterValue)) });
    }
  };

  const handleClearFilters = () => {
    dispatch({ type: "clear_filters" });
    if (allContactDocuments) {
      dispatch({ type: "load_data", data: toPaginatedList(allContactDocuments) });
    }
  };

  const handleNavigateToDocumentActivity = (postMessageRequestId: string, attachmentId: string) => {
    navigate(
      `/${clientCode}/${pageRoutes.investorRelations}/${pageRoutes.contacts}/${contactId}/${pageRoutes.messages}/${postMessageRequestId}/${pageRoutes.documents}/${attachmentId}/${pageRoutes.activity}`
    );
  };

  const categoryOptions = categories
    .filter((c) => c.type === "Message")
    .map(({ externalId, name }) => ({ value: externalId, label: name }));

  const investorOptions = distinctBy(
    contactPermissions.map(({ investorName }) => ({
      value: investorName,
      label: investorName,
    })),
    ({ value }) => value
  );

  const fundOptions = distinctBy(
    contactPermissions.map(({ fundName }) => ({ value: fundName, label: fundName })),
    ({ value }) => value
  );

  return (
    <Stack spacing={2.5} sx={{ height: "100%" }}>
      <FundStructureDocumentFilters
        categoryOptions={categoryOptions}
        investorOptions={investorOptions}
        fundOptions={fundOptions}
        filterState={state.filter}
        onChange={handleFiltersChange}
        onClear={handleClearFilters}
        isLoading={isFetching}
        recordsCount={state.documents.length}
        totalRecordsCount={state.totalRecords}
      />
      <FundStructureDocumentsGrid
        documents={state.documents}
        categories={categories}
        isLoading={isFetching}
        onNavigateToDocumentActivity={handleNavigateToDocumentActivity}
      />
    </Stack>
  );
};

export default ContactDetailsDocumentsTab;
