import { useGridApiRef, useKeepGroupedColumnsHidden } from "@mui/x-data-grid-premium";
import { useCallback } from "react";
import DataGrid from "../../../../../../../shared/components/grid/DataGrid";
import { FundraisingAccessItem } from "../../../../../../api/types/fundraisingTypes";
import { columnDefinitions, groupingColumnDefinition } from "./fundraisingAccessGridDataProvider";

interface Props {
  rows: FundraisingAccessItem[];
  isLoading: boolean;
}

const FundraisingAccessGrid = ({ rows, isLoading }: Props) => {
  const gridApiRef = useGridApiRef();

  const gridInitialState = useKeepGroupedColumnsHidden({
    apiRef: gridApiRef,
    initialState: {
      rowGrouping: {
        model: ["investorName"],
      },
      columns: {
        columnVisibilityModel: {
          contactName: false,
        },
      },
    },
  });

  const isGroupExpanded = useCallback(() => true, []);

  return (
    <DataGrid<FundraisingAccessItem>
      apiRef={gridApiRef}
      columns={columnDefinitions}
      groupingColDef={groupingColumnDefinition}
      rows={rows}
      getRowId={(row) => `${row.investorId}_${row.contactId}`}
      multilineRows
      loading={isLoading}
      initialState={gridInitialState}
      isGroupExpandedByDefault={isGroupExpanded}
      noRowsText="No users"
      disableColumnPinning
      disableColumnSorting
      disableColumnReorder
      disableMultipleRowSelection
    />
  );
};

export default FundraisingAccessGrid;
