import { DataImportChangesFilterValue } from "../../../../../../api/types/dataImportTypes";

export interface DataImportChangesListState {
  page: number;
  rowsPerPage: number;
  totalFilteredItems?: number;
  totalFilteredPages?: number;
  filter: DataImportChangesFilterValue | undefined;
  showChanges: boolean;
}

export const getInitialState = (
  initialFilter: DataImportChangesFilterValue | undefined
): DataImportChangesListState => ({
  page: 0,
  rowsPerPage: 100,
  filter: initialFilter,
  showChanges: true,
});

type StateAction = (state: DataImportChangesListState) => DataImportChangesListState;

// Actions
export const changePageAction =
  (page: number): StateAction =>
  (state) => ({ ...state, page });

export const changeRowsPerPageAction =
  (rowsPerPage: number): StateAction =>
  (state) => ({
    ...state,
    rowsPerPage,
    page: 0,
  });

export const changeFilterAction =
  (filter: DataImportChangesFilterValue | undefined): StateAction =>
  (state) => ({ ...state, filter, page: 0 });

export const updateTotalsAction =
  (total: number, totalPages: number): StateAction =>
  (state) => {
    return {
      ...state,
      totalFilteredItems: total,
      totalFilteredPages: totalPages,
    };
  };

export const toggleShowChangesAction = (): StateAction => (state) => ({
  ...state,
  showChanges: !state.showChanges,
});
