import { Stack, Typography } from "@mui/material";
import { convertISODate } from "../../../../../shared/utilities/dateUtils";
import { DataImportInfo } from "../../../../api/types/dataImportTypes";
import ImportDataStatusTag from "../ImportDataStatusTag";

interface Props {
  dataImportDetails: DataImportInfo;
}

const ImportDataDetailsTitle = ({ dataImportDetails }: Props) => {
  return (
    <Stack direction="row" py={1.5} spacing={1} alignItems="center">
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="h6">{dataImportDetails.description}</Typography>
        <Typography variant="h6">•</Typography>
        <Typography variant="h6">{convertISODate(dataImportDetails.createdAt)}</Typography>
      </Stack>
      <ImportDataStatusTag dataImportState={dataImportDetails.state} />
    </Stack>
  );
};

export default ImportDataDetailsTitle;
