import { createSvgIcon } from "@mui/material";

const MoneyIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="currentColor">
    <path d="M2.49984 2.5C2.03984 2.5 1.6665 2.87333 1.6665 3.33333V11.6667C1.6665 12.1267 2.03984 12.5 2.49984 12.5H17.4998C17.9598 12.5 18.3332 12.1267 18.3332 11.6667V3.33333C18.3332 2.87333 17.9598 2.5 17.4998 2.5H2.49984ZM4.09326 4.16667H15.9064C15.9686 4.34192 16.0691 4.5011 16.2006 4.63259C16.3321 4.76408 16.4912 4.86458 16.6665 4.92676V10.0732C16.4912 10.1354 16.3321 10.2359 16.2006 10.3674C16.0691 10.4989 15.9686 10.6581 15.9064 10.8333H4.09326C4.03108 10.6581 3.93059 10.4989 3.79909 10.3674C3.6676 10.2359 3.50843 10.1354 3.33317 10.0732V4.92676C3.50843 4.86458 3.6676 4.76408 3.79909 4.63259C3.93059 4.5011 4.03108 4.34192 4.09326 4.16667ZM9.99984 5C9.3368 5 8.70091 5.26339 8.23207 5.73223C7.76323 6.20107 7.49984 6.83696 7.49984 7.5C7.49984 8.16304 7.76323 8.79893 8.23207 9.26777C8.70091 9.73661 9.3368 10 9.99984 10C10.6629 10 11.2988 9.73661 11.7676 9.26777C12.2364 8.79893 12.4998 8.16304 12.4998 7.5C12.4998 6.83696 12.2364 6.20107 11.7676 5.73223C11.2988 5.26339 10.6629 5 9.99984 5ZM5.83317 6.66667C5.37317 6.66667 4.99984 7.03917 4.99984 7.5C4.99984 7.96083 5.37317 8.33333 5.83317 8.33333C6.29317 8.33333 6.6665 7.96083 6.6665 7.5C6.6665 7.03917 6.29317 6.66667 5.83317 6.66667ZM14.1665 6.66667C13.7065 6.66667 13.3332 7.03917 13.3332 7.5C13.3332 7.96083 13.7065 8.33333 14.1665 8.33333C14.6265 8.33333 14.9998 7.96083 14.9998 7.5C14.9998 7.03917 14.6265 6.66667 14.1665 6.66667ZM3.32503 14.1667L16.0236 17.474C16.4694 17.5898 16.9234 17.3216 17.0392 16.8766L17.7456 14.1667H16.0236L15.8267 14.917C15.4525 14.9495 15.1146 15.149 14.9038 15.459L9.93962 14.1667H3.32503Z" />
  </svg>,
  "MoneyIcon"
);

export default MoneyIcon;
