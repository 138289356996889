import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import IndeterminateCheckBoxRoundedIcon from "@mui/icons-material/IndeterminateCheckBoxRounded";
import { Checkbox, Divider, SxProps, Typography } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-premium";
import { PropsWithChildren } from "react";

interface Props {
  fullSelection?: boolean;
  selection?: [string[], (ids: string[]) => void];
  sx?: Partial<SxProps>;
}

const CustomDataGridHeaderToolbar = ({ sx, fullSelection, selection, children }: PropsWithChildren<Props>) => {
  const combinedSx: SxProps = {
    zIndex: 1,
    py: 0,
    px: 0.6,
    height: 36,
    ...(sx || {}),
  };

  return (
    <GridToolbarContainer sx={combinedSx}>
      {selection && (
        <>
          <Checkbox
            checkedIcon={fullSelection ? <CheckBoxRoundedIcon /> : <IndeterminateCheckBoxRoundedIcon />}
            checked={selection[0]?.length > 0}
            onClick={() => selection[1]([])}
          />
          <Typography variant="subtitle2">{selection[0]?.length} selected</Typography>
          <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 2, mr: 1 }} />
        </>
      )}
      {children}
    </GridToolbarContainer>
  );
};

export default CustomDataGridHeaderToolbar;
