import { getVariables } from "../../../../../variables";

export const getEditReportUrl = (clientCode: string, reportId: string) => {
  return `${getVariables().REPORTING_PORTAL_URI}/${clientCode}/builder/${reportId}`;
};
export const getViewReportUrl = (clientCode: string, reportId: string) => {
  return `${getVariables().REPORTING_PORTAL_URI}/${clientCode}/reports/${reportId}`;
};
export const getEditApiReportUrl = (clientCode: string, reportId: string) => {
  return `${getVariables().REPORTING_PORTAL_URI}/${clientCode}/api-builder/${reportId}`;
};
export const getViewApiReportUrl = (clientCode: string, reportId: string) => {
  return `${getVariables().REPORTING_PORTAL_URI}/${clientCode}/api-reports/${reportId}`;
};
export const getEditReportTemplateUrl = (organization: string, dataSource: string, templateId: string) => {
  return `${getVariables().REPORTING_PORTAL_URI}/${dataSource}/${organization}/template/${templateId}`;
};
export const getCompanyReportTemplateUrl = (clientCode: string, templateId: string, reportId: string) => {
  return `${getVariables().REPORTING_PORTAL_URI}/${clientCode}/builder/${reportId}?templateId=${templateId}`;
};
