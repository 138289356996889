import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { DataCollectionRequestTemplateVersion } from "../../../../api/types/dataCollectionTypes";
import { columnDefinitions } from "./dataRequestTemplatesGridDataProvider";

interface Props {
  rows: DataCollectionRequestTemplateVersion[];
  isLoading: boolean;
}

const DataRequestTemplatesGrid = ({ rows, isLoading }: Props) => {
  return (
    <DataGrid<DataCollectionRequestTemplateVersion>
      rows={rows}
      columns={columnDefinitions}
      noRowsText="No request templates"
      loading={isLoading}
      disableColumnReorder
      disableColumnSorting
    />
  );
};

export default DataRequestTemplatesGrid;
