import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import { Stack } from "@mui/material";
import {
  GridAutoGeneratedGroupNode,
  GridDataGroupNode,
  GridLeafNode,
  GridRenderCellParams,
  useGridApiContext,
} from "@mui/x-data-grid-premium";
import BadgeDetached from "../../../../shared/components/BadgeDetached";
import ExpandCollapseButton from "../../../../shared/components/ExpandCollapseButton";
import TypographyTooltipEllipsis from "../../../../shared/components/TypographyTooltipEllipsis";
interface Props extends GridRenderCellParams {
  showCount?: boolean;
  showGroupColumnTextInTooltip?: boolean;
  renderGroupCellElement?: (
    value: unknown,
    node: GridDataGroupNode | GridAutoGeneratedGroupNode | GridLeafNode
  ) => JSX.Element;
  renderCellElement?: (row: unknown) => JSX.Element;
  onRowExpand?: () => void;
}

const DataGridGroupingCell = ({
  id,
  value,
  rowNode,
  showCount,
  showGroupColumnTextInTooltip,
  renderGroupCellElement,
  renderCellElement,
  onRowExpand,
  row,
}: Props) => {
  const apiRef = useGridApiContext();

  const isGroupingField = rowNode.depth === 0 && rowNode.type === "group";
  const isGroupingLeaf = rowNode.depth === 0 && rowNode.type === "leaf";

  const handleToggle = () => {
    if (!isGroupingField) {
      return;
    }

    const newExpanded = !rowNode.childrenExpanded;
    apiRef.current.setRowChildrenExpansion(id, newExpanded);
    if (newExpanded && onRowExpand) {
      onRowExpand();
    }
  };

  if (isGroupingField || isGroupingLeaf) {
    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        maxWidth="100%"
        sx={{
          position: showGroupColumnTextInTooltip ? "inherit" : "absolute",
          pr: 2,
        }}
      >
        {isGroupingField && (
          <ExpandCollapseButton
            expanded={rowNode.childrenExpanded === true}
            onToggle={handleToggle}
            tabIndex={-1}
            ExpandIcon={ArrowRightRoundedIcon}
            CollapseIcon={ArrowDropDownRoundedIcon}
          />
        )}
        {renderGroupCellElement && renderGroupCellElement(value, rowNode)}
        {!renderGroupCellElement && (
          <TypographyTooltipEllipsis
            text={value}
            typographyProps={{ mr: 1, variant: "subtitle1", fontWeight: [500] }}
          />
        )}
        {isGroupingField && showCount && rowNode.children && (
          <BadgeDetached color="secondary" showZero badgeContent={rowNode.children.length} />
        )}
      </Stack>
    );
  }

  if (renderCellElement) {
    return renderCellElement(row);
  }

  return <TypographyTooltipEllipsis text={value} typographyProps={{ ml: 8 }} />;
};

export default DataGridGroupingCell;
