import DataGrid from "../../../../../shared/components/grid/DataGrid";
import { FundWithFieldValues } from "../../../../api/adminApi";
import { columnDefinitions } from "./fundsGridDataProvider";

interface Props {
  funds: FundWithFieldValues[];
  isLoading: boolean;
  onFundClick: (id: string) => void;
}

const FundsList = ({ funds, isLoading, onFundClick }: Props) => {
  return (
    <DataGrid<FundWithFieldValues>
      getRowId={(row) => row.id}
      loading={isLoading}
      noRowsText="No funds"
      columns={columnDefinitions}
      rows={funds}
      selectableRows
      onCellClick={(params) => onFundClick(params.row.id)}
      disableColumnSorting
    />
  );
};

export default FundsList;
