import { Box, Typography } from "@mui/material";
import {
  InfoSectionBlockContent,
  LayoutBlock,
  LayoutBlockType,
  MonthlyFinancialsBlockContent,
  MonthlyInternalTableBlockContent,
} from "../../../api/inputFormTypes";
import ContainerFormBlock from "./ContainerFormBlock";
import { useDataSubmissionFormContext } from "./DataSubmissionFormContext";
import InfoSectionFormBlock from "./InfoSectionFormBlock";
import MonthlyFinancialsFormBlock from "./portfolioMonitoring/MonthlyFinancialsFormBlock";
import MonthlyInternalTableFormBlock from "./portfolioMonitoring/MonthlyInternalTableFormBlock";

interface Props {
  block: LayoutBlock;
}

const UnsupportedBlock = ({ type }: { type: LayoutBlockType }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="4rem"
      width="100%"
      sx={(t) => ({ border: `1px dashed ${t.palette.divider}` })}
    >
      <Typography color="warning.main">Unsupported block type: {type}</Typography>
    </Box>
  );
};

const DataSubmissionFormBlock = ({ block }: Props) => {
  const { getBlockContent } = useDataSubmissionFormContext();

  const content = getBlockContent(block.id);

  if (block.type === "Container") {
    return <ContainerFormBlock attributes={block.attributes} />;
  }

  if (block.type === "InfoSection") {
    return content ? (
      <InfoSectionFormBlock attributes={block.attributes} content={content as InfoSectionBlockContent} />
    ) : null;
  }

  if (block.type === "MonthlyFinancials") {
    return (
      <MonthlyFinancialsFormBlock
        blockId={block.id}
        attributes={block.attributes}
        content={content as MonthlyFinancialsBlockContent}
      />
    );
  }

  if (block.type === "MonthlyInternalTable") {
    return (
      <MonthlyInternalTableFormBlock
        blockId={block.id}
        attributes={block.attributes}
        content={content as MonthlyInternalTableBlockContent}
      />
    );
  }

  return <UnsupportedBlock type={block.type} />;
};

export default DataSubmissionFormBlock;
