import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import DeleteFileIcon from "../../../../../shared/icons/DeleteFileIcon";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { InvestorChangeRequest } from "../../../../api/types/changeRequestTypes";

interface Props {
  workflowId: string;
  open: boolean;
  onClose: () => void;
  onConfirmed: (changeRequest: InvestorChangeRequest, statusChangeComment: string | undefined) => void;
}

const rejectRequest = withErrorHandling(adminApi.rejectInvestorChangeRequest);

const RejectionConfirmationDialog = ({ workflowId, open, onClose, onConfirmed }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  const handleRejectClick = async () => {
    setLoading(true);
    const statusChangeComment = comment.trim() || undefined;
    const [changeRequest, error] = await rejectRequest({ workflowId, statusChangeComment });
    setLoading(false);

    if (error) {
      logError(error, "[RejectionConfirmationDialog]");
      sendNotificationError("Failed to reject the request");
      return;
    }

    onClose();
    sendNotification("The request was rejected");
    onConfirmed(changeRequest, statusChangeComment);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Reject</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <DeleteFileIcon sx={{ width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={0.5}>
            <Typography variant="h6">Reject request?</Typography>
            <Typography color="text.secondary" textAlign="center">
              The proposed changes will be dismissed. This action is permanent and can&apos;t be undone.
            </Typography>
          </Stack>
          <TextField
            multiline
            rows={4}
            maxRows={4}
            spellCheck
            fullWidth
            placeholder="Add rejection reason..."
            onChange={(e) => setComment(e.target.value)}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="outlined" color="error" loading={loading} onClick={handleRejectClick}>
          Reject
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default RejectionConfirmationDialog;
