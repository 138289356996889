import { TabPanel } from "@mui/lab";
import { Box, Typography } from "@mui/material";
import approvalsApi from "../../../api/mock/approvals/approvalsMockApi";
import PageTabs from "../../common/PageTabs";
import TabLabelWithCount from "../../common/TabLabelWithCount";
import ApprovalsList from "./ApprovalsList";
import { ApprovalsFilter, NavigationView, approvalsFilters, formatViewTitle } from "./approvalsPageHelper";

interface Props {
  view: NavigationView;
  selectedTab: ApprovalsFilter;
  onTabChange: (tab: ApprovalsFilter) => void;
}

const ApprovalsView = ({ view, selectedTab, onTabChange }: Props) => {
  const approvalType = view === "All" ? undefined : view;
  const userCount = approvalsApi.getApprovalsCounterForCurrentUser(approvalType);
  const pendingCount = approvalsApi.getPendingApprovalsCounter(approvalType);

  return (
    <Box width="100%">
      <Box py={2} px={3}>
        <Typography variant="h6">{formatViewTitle(view)}</Typography>
      </Box>
      <PageTabs
        tabs={approvalsFilters}
        labels={[
          <TabLabelWithCount
            key="my"
            label="Need my approval"
            count={userCount}
            total={userCount}
            isActive={selectedTab === "my"}
          />,
          <TabLabelWithCount
            key="pending"
            label="Pending"
            count={pendingCount}
            total={pendingCount}
            isActive={selectedTab === "pending"}
          />,
          "All",
        ]}
        value={selectedTab}
        onChange={onTabChange}
      >
        {approvalsFilters.map((filter) => (
          <TabPanel key={filter} value={filter} sx={{ height: "100%", px: 3, py: 2.5 }}>
            <ApprovalsList filter={filter} approvalType={approvalType} />
          </TabPanel>
        ))}
      </PageTabs>
    </Box>
  );
};

export default ApprovalsView;
