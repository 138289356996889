import { logError } from "../../../shared/logging";
import { Maybe } from "../../../shared/types";
import { arraysHaveSameItems } from "../../../shared/utilities/arrayHelper";
import { formatDate } from "../../../shared/utilities/dateUtils";
import {
  DateEntityFieldConfiguration,
  FieldConfigurationType,
  LookupObjectReference,
  MultiLabelSelectOption,
} from "../../api/types/objectTypes";

export const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === "string");

export const getMultiselectOptionsFromValue = (value: string | string[] | undefined | null) => {
  if (value === undefined || value === null) {
    return [];
  }

  const valueArray = Array.isArray(value) ? value : value.split(fieldsEditSettings.multiselectItemsSeparator);
  return valueArray.filter((item) => Boolean(item.trim()));
};

export const getSelectOptionsForLookup = (lookupReferences: LookupObjectReference[]): MultiLabelSelectOption[] =>
  lookupReferences.map((ref) => ({
    value: ref.objectId,
    label: ref.primaryFieldValue,
    secondaryLabel: ref.secondaryFieldValue,
  }));

export const getLookupDisplayValue = (
  value: Maybe<string>,
  availableLookupReferences: LookupObjectReference[],
  objectLookupReferences: LookupObjectReference[]
): string | undefined => {
  if (!value) {
    return undefined;
  }

  // First use all reference options if available when editing
  const selectedOption = availableLookupReferences.find((ref) => ref.objectId === value);
  if (selectedOption) {
    return selectedOption.primaryFieldValue;
  }

  // Fallback to lookup objects from stored object - all reference options are not available when lookup field is read-only
  const lookupReference = objectLookupReferences.find((ref) => ref.objectId === value);
  if (lookupReference) {
    return lookupReference.primaryFieldValue;
  }

  return value;
};

export const getCurrencySymbol = (currencyCode: string) => {
  try {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
    })
      .format(0)
      .replace(/[^\p{Sc}]/gu, "");
  } catch (error) {
    logError(error, "getCurrencySymbolByCode");
    return "";
  }
};

export const formatDateFieldValue = (
  date: Maybe<Date>,
  fieldConfiguration: DateEntityFieldConfiguration | undefined
): string => {
  if (!date) {
    return "";
  }

  if (!fieldConfiguration) {
    return formatDate(date);
  }

  const { dateComponents } = fieldConfiguration;

  if (dateComponents === undefined || dateComponents.length === 0 || dateComponents.length === 3) {
    return formatDate(date);
  }

  if (arraysHaveSameItems(dateComponents, ["Year"])) {
    return formatDate(date, "yyyy");
  }

  if (arraysHaveSameItems(dateComponents, ["Month"])) {
    return formatDate(date, "MMMM");
  }

  if (arraysHaveSameItems(dateComponents, ["Month", "Year"])) {
    return formatDate(date, "MMMM, yyyy");
  }

  if (arraysHaveSameItems(dateComponents, ["Month", "Day"])) {
    return formatDate(date, "MMMM dd");
  }

  return formatDate(date); // Day & Year and Day-only cases are odd and not supported
};

export const fieldsEditSettings = {
  multiselectItemsSeparator: ";",
};

export const getInvalidFieldValueError = (
  fieldId: string,
  fieldType: FieldConfigurationType | undefined,
  value: unknown
) => `Invalid value in ${fieldType ?? ""} field ${fieldId}: ${JSON.stringify(value)} (${typeof value})`;
