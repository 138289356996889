import { createSvgIcon } from "@mui/material";

const PortfolioMonitoringAppIcon = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none">
    <path
      d="M34 0H6C2.68629 0 0 2.68629 0 6V34C0 37.3137 2.68629 40 6 40H34C37.3137 40 40 37.3137 40 34V6C40 2.68629 37.3137 0 34 0Z"
      fill="#F6F8FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.581 16.6985L25.9202 21.6366L20.1936 20.6454L14.4312 26.4078L7.91211 21.2947L9.42067 19.3713L14.2349 23.1472L19.3614 18.0206L25.1903 19.0295L29.9741 14.8564L31.581 16.6985Z"
      fill="#C7DBE5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.4453 4.76367V33.6663H7.44531V11.1249L14.6647 13.8321L19.8754 9.61924L25.1582 11.4902L32.4453 4.76367ZM30.0009 10.3467L25.7324 14.2868L20.3486 12.3801L15.1148 16.6116L9.88976 14.6522V31.2219H30.0009V10.3467Z"
      fill="#00A866"
    />
  </svg>,
  "PortfolioMonitoringAppIcon"
);

export default PortfolioMonitoringAppIcon;
