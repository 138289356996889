import { formatISO, isValid, parseISO } from "date-fns";
import { ValidationResult } from "../../../../shared/utilities/validators";
import { DateEntityFieldConfiguration } from "../../../api/types/objectTypes";
import { EntityFieldComponentPropsBase } from "../entityFieldTypes";
import EntityDateFieldValueComponent from "./EntityDateFieldValueComponent";

const minDateString = "0001-01-01"; // Used to indicate an empty date value on the backend

const getValidDateValue = (value: unknown): Date | null => {
  const isMinDate = (date: Date): boolean => date.getFullYear() === 1 && date.getMonth() === 0 && date.getDate() === 1;
  const date = value instanceof Date ? value : typeof value === "string" ? parseISO(value) : null;
  return date && isValid(date) && !isMinDate(date) ? date : null;
};

const EntityDateField = ({
  value,
  fieldId,
  fieldConfiguration,
  fieldAttributes,
  onChange,
  fieldInputId,
}: EntityFieldComponentPropsBase) => {
  const date = getValidDateValue(value);

  const handleChange = (newValue: Date | null, validationResult: ValidationResult) => {
    const dateISOString = newValue ? formatISO(newValue, { representation: "date" }) : minDateString;
    onChange(dateISOString, fieldId, validationResult);
  };

  return (
    <EntityDateFieldValueComponent
      value={date}
      fieldConfiguration={fieldConfiguration as DateEntityFieldConfiguration}
      fieldAttributes={fieldAttributes}
      onChange={handleChange}
      fieldInputId={fieldInputId}
    />
  );
};

export default EntityDateField;
